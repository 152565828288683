import { Header } from 'presentation/components/HoneTable/types';

export const acHeaders: Header[] = [
  {
    title: 'Account',
    key: 'label',
    width: 200,
    align: 'left',
    footerStyles: {
      fontWeight: 700,
      fontSize: '14px',
      textAlign: 'right',
      paddingRight: '8px',
      width: 740,
    },
    styles: {
      backgroundColor: 'transparent',
    },
    hidden: false,
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: 'Original (10/03/23)',
    key: 'entry',
    headerTitleColor: '#959697',
    align: 'left',
    styles: {
      backgroundColor: 'transparent',
    },
    footerStyles: {
      background: '#E4E5E7',
      fontWeight: '700',
      fontSize: '13px',
      height: '40px',
      borderTop: '3px solid white',
      borderRight: '3px solid white',
      textAlign: 'right',
      paddingRight: '8px',
      width: 102,
    },
    hidden: false,
    subItems: [
      {
        title: 'DEBIT',
        key: 'entry',
        align: 'left',
        sortable: true,
        render: (value: any) => {
          return value;
        },
      },
      {
        title: 'Credit',
        key: 'credit',
        footerStyles: {
          background: '#E4E5E7',
          fontWeight: '700',
          fontSize: '13px',
        },
        align: 'left',
        sortable: true,
        render: (value: any) => {
          return value;
        },
      },
    ],
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: 'PR ACC 9/30/23',
    key: 'accrual',
    headerTitleColor: '#338316',
    align: 'left',
    styles: {
      backgroundColor: 'transparent',
    },
    footerStyles: {
      background: '#E0F0DB',
      fontWeight: '700',
      fontSize: '13px',
      height: '40px',
      borderTop: '3px solid white',
      borderRight: '3px solid white',
      textAlign: 'right',
      paddingRight: '8px',
      width: 103,
    },
    hidden: false,
    subItems: [
      {
        title: 'Debit',
        key: 'debit',

        align: 'left',
        sortable: true,
        render: (value: any) => {
          return value;
        },
      },
      {
        title: 'Credit',
        key: 'credit',

        align: 'left',
        sortable: true,
        render: (value: any) => {
          return value;
        },
      },
    ],
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: 'PR ACC 10/01/23 R',
    key: 'accrualReversal',
    align: 'left',
    sortable: true,
    styles: {
      backgroundColor: 'transparent',
    },
    footerStyles: {
      background: '#DBEDF0',
      fontWeight: '700',
      fontSize: '13px',
      height: '40px',
      borderTop: '3px solid white',
      borderRight: '3px solid white',
      textAlign: 'right',
      paddingRight: '8px',
      width: 103,
    },
    hidden: false,
    subItems: [
      {
        title: 'Debit',
        key: 'debit',

        align: 'left',
        sortable: true,
        render: (value: any) => {
          return value;
        },
      },
      {
        title: 'Credit',
        key: 'credit',

        align: 'left',
        sortable: true,
        render: (value: any) => {
          return value;
        },
      },
    ],
    render: (value: any) => {
      return value;
    },
  },
  {
    title: 'Description',
    key: 'description',
    width: 200,
    align: 'right',
    hidden: true,
    styles: {
      textAlign: 'right',
      paddingRight: '8px',
    },
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
];
