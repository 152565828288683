import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';

type onChangePage = {
  (nextPage: number): void;
  (fn: (prevPage: number) => number): void;
};

type PaginationProps<T> = {
  data: T[];
  activePage: number;
  dataLimit: number;
  onChangePage: onChangePage;
  maxButtons?: number;
  ellipsis?: boolean;
  boundaryLinks?: boolean;
};

function Pagination<T>({
  data,
  activePage,
  dataLimit,
  onChangePage,
  maxButtons = 5,
  ellipsis = true,
  boundaryLinks = true,
}: PaginationProps<T>): JSX.Element {
  const pages = Math.ceil(data.length / dataLimit);
  const prefix = 'pagination';
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activePage]);

  function goToNextPage() {
    onChangePage((page: number) => page + 1);
  }

  function goToPreviousPage() {
    onChangePage((page: number) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    onChangePage(pageNumber);
  }

  const renderItem = useCallback(
    (key: string | number, itemProps: any) => {
      const { eventKey, disabled, children, active } = itemProps;
      return (
        <button
          key={`${key}-${eventKey}`}
          onClick={changePage}
          className={classNames('pagination-btn', {
            'pagination-btn-active': active,
            'pagination-btn-disabled': disabled,
          })}
        >
          {children}
        </button>
      );
    },
    [changePage]
  );

  const renderPageButtons = () => {
    const pageButtons: React.ReactElement[] = [];
    let startPage;
    let endPage;
    let hasHiddenPagesAfter;

    if (maxButtons) {
      const hiddenPagesBefore = activePage - Math.floor(maxButtons / 2);
      startPage = hiddenPagesBefore > 1 ? hiddenPagesBefore : 1;
      hasHiddenPagesAfter = startPage + maxButtons <= pages;

      if (!hasHiddenPagesAfter) {
        endPage = pages;
        startPage = pages - maxButtons + 1;
        if (startPage < 1) {
          startPage = 1;
        }
      } else {
        endPage = startPage + maxButtons - 1;
      }
    } else {
      startPage = 1;
      endPage = pages;
    }

    for (let pagenumber = startPage; pagenumber <= endPage; pagenumber += 1) {
      pageButtons.push(
        renderItem(pagenumber, {
          eventKey: pagenumber,
          active: pagenumber === activePage,
          children: pagenumber,
        })
      );
    }

    if (boundaryLinks && ellipsis && startPage !== 1) {
      pageButtons.unshift(
        renderItem('more', {
          eventKey: 'ellipsisFirst',
          disabled: true,
          children: <span className={`${prefix}-symbol`}>{ellipsis ? '...' : ellipsis}</span>,
        })
      );

      pageButtons.unshift(renderItem(1, { eventKey: 1, children: 1 }));
    }

    if (maxButtons && hasHiddenPagesAfter && ellipsis) {
      pageButtons.push(
        renderItem('more', {
          eventKey: 'ellipsis',
          disabled: true,
          children: <span className={`${prefix}-symbol`}>{ellipsis ? '...' : ellipsis}</span>,
        })
      );

      if (boundaryLinks && endPage !== pages) {
        pageButtons.push(renderItem(pages, { eventKey: pages, disabled: false, children: pages }));
      }
    }
    return pageButtons;
  };

  return (
    <div className="pagination-group pagination-group-xs">
      <div className="pagination-group-total">
        <span>Total Rows: </span>
        <span>{data.length}</span>
        <span></span>
      </div>
      <div className="pagination-group-grow" />
      <div role="separator" className="divider divider-vertical" aria-orientation="vertical" />
      <div className="pagination pagination-xs">
        <button
          aria-label="Previous"
          title="Previous"
          onClick={goToPreviousPage}
          disabled={activePage === 1}
          className={classNames('pagination-btn', {
            'pagination-btn-disabled': activePage === 1,
          })}
        >
          <span className="pagination-symbol">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 7 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              className="icon"
              aria-label="page previous"
              data-category="legacy"
            >
              <path d="M2.333 7l3.5-4-.583-.667L1.167 7l4.083 4.667.583-.667z"></path>
            </svg>
          </span>
        </button>
        {renderPageButtons()}
        <button
          onClick={goToNextPage}
          aria-label="Next"
          title="Next"
          className={classNames('pagination-btn', {
            'pagination-btn-disabled': activePage === pages,
          })}
        >
          <span className="pagination-symbol">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 7 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              className="icon"
              aria-label="page next"
              data-category="legacy"
            >
              <path d="M4.667 7l-3.5 4 .583.667L5.833 7 1.75 2.333 1.167 3z"></path>
            </svg>
          </span>
          <span className="ripple-pond">
            <span
              className="ripple"
              style={{ width: '47.4635px', height: '47.4635px', left: '-14.3724px', top: '-14.4192px' }}
            ></span>
          </span>
        </button>
      </div>
    </div>
  );
}

export default Pagination;
