import './ValueFormatters.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';

const prefix = prefixClass('value-formatters');

export const formatValue = (amount: number, decimals: number = 0, locale: string = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);
};

export const formatAmount = (
  amount: number | null,
  decimals: number = 0,
  compact: boolean = false,
  unitless: boolean = false
) => {
  if (amount === null) return ' ';
  if (amount === 0) return compact ? '' : ' -- ';
  const units = unitless ? '' : compact ? '$' : '$ ';
  if (amount < 0) {
    return unitless ? formatValue(amount, decimals) : `(${units}${formatValue(Math.abs(amount), decimals)})`;
  }
  return `${units}${formatValue(amount, decimals)}`;
};

export const FormatActual = (value?: number | null, total?: boolean, small?: boolean) => {
  if (!value || value === 0)
    return (
      <div className={prefix('value')}>
        <div className={prefix('value-empty')}>--</div>
      </div>
    );
  if (value < 0) {
    return (
      <div className={classNames(prefix('value'), { total: total, small: small })}>
        (<span>$</span>
        {formatValue(Math.abs(value))})
      </div>
    );
  }
  return (
    <div className={classNames(prefix('value'), { total: total, small: small })}>
      <span>$</span>
      {formatValue(value)}
    </div>
  );
};

export const FormatPercentage = (value?: number | null, total?: boolean) => {
  if (!value || value === 0)
    return (
      <div className={prefix('percentage')}>
        <div className={prefix('percentage-empty')}>--</div>
      </div>
    );
  const perc = value * 100;
  if (value < 0) {
    return (
      <div className={classNames(prefix('percentage'), { total: total })}>
        -{formatValue(Math.abs(perc), 2)}
        <span>%</span>
      </div>
    );
  }
  return (
    <div className={classNames(prefix('percentage'), { total: total })}>
      {formatValue(perc, 2)}
      <span>%</span>
    </div>
  );
};
