import { SummaryItem } from '@hone-automation/common';

import classNames from 'classnames';

import { IconSmooth } from 'components/Icons';
import { BussinessMetricsTypes } from 'domain/models';

import { useDashboardReport } from 'hooks/useDashboard';
import { currencyFormatter, percentFormatter } from 'lib/utils';

interface DashboardBussinessMetricsItemProps {
  cardData: SummaryItem;
  active: boolean;
}

export default function DashboardBussinessMetricsItem({
  cardData,
  active = false,
}: DashboardBussinessMetricsItemProps): JSX.Element {
  const { setActiveBussinessMetric } = useDashboardReport();

  const handleActivateBussinessMetric = () => {
    if (cardData.title === 'Net Sales') return;
    setActiveBussinessMetric(cardData.title as BussinessMetricsTypes);
  };
  const identation = String(cardData && cardData?.level && `${cardData?.level * 16}px`);
  return (
    <>
      <div
        className={classNames('dashboard-bm-item', {
          'net-sales': cardData.title === 'Net Sales',
          'item-active': active,
        })}
        onClick={handleActivateBussinessMetric}
        style={{ paddingLeft: identation }}
      >
        <div className="dashboard-bm-item-title">
          <div>{cardData.title}</div>
          {cardData.smoothedAmount && <div>{currencyFormatter(cardData.smoothedAmount)}</div>}
          {!cardData.smoothedAmount && cardData?.amount && <div>{currencyFormatter(cardData?.amount)}</div>}
        </div>
        {cardData.smoothedAmount && (
          <div className="dashboard-bm-item--icon-smooth">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <IconSmooth width={16} height={16} />
          </div>
        )}
        <div className={classNames('dashboard-bm-item-sub-title', { 'item-active': active })}>
          {(cardData.smoothedPercent || cardData.percent) && (
            <div className="dashboard-bm-item-sub-title-text">% of {cardData?.percentTitle}</div>
          )}
          {cardData.smoothedPercent && <div>{percentFormatter(cardData.smoothedPercent)}</div>}
          {!cardData.smoothedPercent && (
            <div>{cardData.percent ? percentFormatter(Number(cardData.percent)) : null}</div>
          )}
        </div>
        {cardData.title === 'Net Sales' || active ? (
          <div className="dashboard-bm-item--icon">
            {/*<Link to={`/app/location/${currentLocationId}/report/${currentReportId}`}>*/}
            {/*  <IconForwardBtn />*/}
            {/*</Link>*/}
          </div>
        ) : null}
      </div>
    </>
  );
}
