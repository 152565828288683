type VarianceType = 'positive' | 'negative' | 'neutral';

type VarianceDetails = {
  min: number;
  max: number;
  type: VarianceType;
};

type Categories = {
  [category: string]: {
    keywords: string[];
    acceptableVariance: VarianceDetails;
  };
};

type VarianceMessagesProps = {
  [key: string]: {
    positive: string;
    negative: string;
  };
};

export type VarianceAnalysisResult = {
  category: string;
  variance: number;
  message: string;
  explanation?: string;
  tendency: 'positive' | 'negative' | 'neutral';
};

// Defining the categories with their variance details
const categories: Categories = {
  Revenue: {
    keywords: ['sales'],
    acceptableVariance: { min: 2, max: 5, type: 'neutral' },
  },
  COGS: {
    keywords: ['cost'],
    acceptableVariance: { min: 1, max: 3, type: 'neutral' },
  },
  LaborCosts: {
    keywords: ['wages', 'salaries', 'payroll'],
    acceptableVariance: { min: 1, max: 5, type: 'neutral' },
  },
  OperatingExpenses: {
    keywords: ['rent', 'utilities', 'insurance', 'marketing'],
    acceptableVariance: { min: 1, max: 5, type: 'neutral' },
  },
  AdministrativeExpenses: {
    keywords: ['supplies', 'professional fees'],
    acceptableVariance: { min: 1, max: 3, type: 'neutral' },
  },
  Depreciation: {
    keywords: ['depreciation'],
    acceptableVariance: { min: 0, max: 0, type: 'neutral' },
  },
};

// Defining variance messages for each category, for both positive and negative variances
const varianceMessages: VarianceMessagesProps = {
  Revenue: {
    positive: 'Higher than expected revenue variance may be due to effective promotions or higher sales demand.',
    negative: 'Lower than expected revenue variance may indicate inaccurate sales forecasting or seasonal downturn.',
  },
  COGS: {
    positive: 'Higher COGS variance could result from increased supplier costs, wastage, or inefficiencies.',
    negative:
      'Lower COGS variance may indicate efficient inventory management or lower than anticipated ingredient costs.',
  },
  LaborCosts: {
    positive:
      'Higher labor cost variance can result from excessive overtime, scheduling issues, or higher staff wages.',
    negative: 'Lower labor cost variance may indicate understaffing, which can impact service quality.',
  },
  OperatingExpenses: {
    positive:
      'Higher operating expenses may be due to unexpected repair costs, increased utilities, or higher insurance premiums.',
    negative: 'Lower operating expenses may indicate cost-saving measures or decreased usage of services.',
  },
  AdministrativeExpenses: {
    positive:
      'Higher administrative expenses may result from increased use of office supplies or higher professional service fees.',
    negative:
      'Lower administrative expenses may indicate a reduction in office costs or fewer external professional services.',
  },
  Depreciation: {
    positive: 'Depreciation variance is not typically applicable, as it depends on fixed accounting schedules.',
    negative: 'Depreciation variance is not typically applicable, as it depends on fixed accounting schedules.',
  },
  Unclassified: {
    positive: 'Account could not be classified, so variance analysis is not applicable.',
    negative: 'Account could not be classified, so variance analysis is not applicable.',
  },
};

const classifyGLAccount = (account: string, varianceAmount: number): string => {
  const accountName = account.toLowerCase();
  for (const category in categories) {
    const categoryKeywords = categories[category].keywords;
    if (categoryKeywords.some(keyword => accountName.includes(keyword))) {
      return category;
    }
  }
  return 'Unclassified';
};

export const analyzeVariance = (account: string, varianceAmount: number): VarianceAnalysisResult => {
  const category = classifyGLAccount(account, varianceAmount);
  const formattedVarianceAmount = Number((varianceAmount * 100).toFixed(2));
  if (category === 'Unclassified' || varianceAmount === 0) {
    return {
      category,
      variance: formattedVarianceAmount,
      message: 'Account could not be classified, so variance analysis is not applicable.',
      tendency: 'neutral',
      explanation: '',
    };
  }
  const varianceDetails = categories[category].acceptableVariance;
  const varianceType: VarianceType =
    formattedVarianceAmount > varianceDetails.max
      ? 'positive'
      : formattedVarianceAmount < varianceDetails.min
        ? 'negative'
        : 'neutral';
  const message =
    varianceType === 'neutral'
      ? 'Variance is within acceptable range.'
      : `Variance of ${formattedVarianceAmount}% is outside the acceptable range (${varianceType === 'positive' ? varianceDetails.min : -varianceDetails.max}% to ${varianceType === 'positive' ? varianceDetails.max : -varianceDetails.min}%).`;
  return {
    category,
    variance: formattedVarianceAmount,
    message,
    tendency: varianceType,
    explanation:
      varianceType === 'neutral' ? 'Variance is within acceptable range.' : varianceMessages[category][varianceType],
  };
};
