import React, { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import { IconClear } from 'components/Icons';
import './ForecastInput.scss';
import classNames from 'classnames';
import { Simulate } from 'react-dom/test-utils';
import input = Simulate.input;

interface ForecastInputProps {
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  onClear: (name: string) => void;
  defaultValue?: any;
  value: string | number;
  name: string;
}

function ForecastInput({ name, value: defaultValue, onChange, onClear }: ForecastInputProps): JSX.Element | null {
  const [value, setValue] = useState(format(parseFloat(defaultValue.toString())));

  function format(value: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(value);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const _value = value.replace('$', '').replace(',', '');
    const parsedValue = parseInt(_value);

    if (isNaN(parsedValue) || _value === '') {
      setValue('');
      e.target.value = '';
    } else {
      const formatNumber = format(parsedValue);
      setValue(formatNumber);
      e.target.value = formatNumber;
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onChange(e);
  };

  useEffect(() => {
    setValue(format(parseInt(defaultValue.toString())));
  }, [defaultValue]);

  return (
    <div className={classNames('forecast-input')}>
      {/*<IconClear onClick={() => onClear(name)} />*/}
      <input type="text" onBlur={handleBlur} name={name} value={value} onChange={handleChange} />
    </div>
  );
}

export default ForecastInput;
