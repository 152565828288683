import { GetMetricsReportData } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemoteGetMetricsReportBookkeeperData
  extends BaseHttpService<GetMetricsReportData.Model>
  implements GetMetricsReportData
{
  async getMetricsReportById(params: GetMetricsReportData.Params): Promise<GetMetricsReportData.Model> {
    return await this.request('get', {}, params);
  }
}
