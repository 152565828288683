import { UIFlaggedTransaction } from 'types';
import { isDateBetween } from './utils';
import { FlaggedCellProps } from './types';

export const processFlaggedCells = (
  report: NestedHoneReport,
  flaggedTransactions?: UIFlaggedTransaction[]
): FlaggedCellProps[] => {
  if (!report || !flaggedTransactions || flaggedTransactions.length === 0) {
    return [];
  }

  // Filter out flagged transactions that are not Flagged Cells or Transactions
  const flaggedCells = flaggedTransactions
    ?.map((flagged: any, index: number) => {
      if ((flagged.type === 'Flagged Cell' || !flagged.type) && !flagged.deleted) {
        const status =
          flagged.status === 'Categorized' ? 'closed' : flagged.status === 'Submitted' ? 'inProgress' : 'open';
        const flaggedCell: FlaggedCellProps = {
          id: flagged.id,
          index: index,
          account: flagged.identifier,
          type: flagged.type || 'trx',
          status: status,
          period: flagged.period,
          createdAt: flagged.createdAt,
          amount: flagged.flaggedAmount ? Number(flagged.flaggedAmount) : 0,
        };
        if (flagged.transaction) {
          flaggedCell.account = flagged.transaction.account;
          flaggedCell.amount = flagged.transaction.amount;
          flaggedCell.period = flagged.transaction.date.split('T')[0];
          flaggedCell.transaction = {
            id: flagged.transaction.id,
            memo: flagged.transaction.memo,
            name: flagged.transaction.name,
            trxType: flagged.transaction.externalTransactionType,
          };
        }
        return flaggedCell;
      }
      return false;
    })
    .filter(Boolean);

  const flaggedCellsinRange: FlaggedCellProps[] = (flaggedCells as FlaggedCellProps[])?.filter((flagged: any) => {
    return report.dates.some((date: any) => {
      return isDateBetween(date.start, date.end, flagged.period);
    });
  });
  return flaggedCellsinRange;
};
