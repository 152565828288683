import { set } from 'lodash';
import { JournalEntry } from '@hone-automation/common';
import { JournalEntryLineFormmatted } from 'domain/models/JournalEntries';
import { Header, HighlighRow } from 'presentation/components/HoneTable/types';
import { create } from 'zustand';

interface JournalEntriesState {
  journalEntries: JournalEntry[];
  setJournalEntries: (journalEntries: JournalEntry[]) => void;
  journalEntriesEditable: JournalEntryLineFormmatted[] | undefined;
  setJournalEntriesEditable: (journalEntries: JournalEntryLineFormmatted[] | undefined) => void;
  journalEntriesHeaders: Header[] | undefined;
  setJournalEntriesHeaders: (journalEntriesHeaders: Header[] | undefined) => void;
  enableEditMode: boolean;
  setEnableEditMode: (enableEditMode: boolean) => void;
  confirmationCancelModalOpen: boolean;
  setConfirmationCancelModalOpen: (confirmationCancelModalOpen: boolean) => void;
  entriesHasChanged: boolean;
  setEntriesHasChanged: (entriesHasChanged: boolean) => void;
  highlighRow: HighlighRow | undefined;
  setHighlighRow: (row: HighlighRow | undefined) => void;
  setReorderLoading: (reorderLoading: boolean) => void;
  reorderLoading: boolean;
  journalEntriesPayload: JournalEntryLineFormmatted[] | undefined;
  setJournalEntriesPayload: (journalEntries: JournalEntryLineFormmatted[] | undefined) => void;
}

export const useJournalEntriesStore = create<JournalEntriesState>(set => ({
  journalEntries: [],
  setJournalEntries: journalEntries => set({ journalEntries }),
  journalEntriesEditable: undefined,
  setJournalEntriesEditable: journalEntries => set({ journalEntriesEditable: journalEntries }),
  journalEntriesHeaders: [],
  setJournalEntriesHeaders: journalEntriesHeaders => set({ journalEntriesHeaders }),
  enableEditMode: false,
  setEnableEditMode: enableEditMode => set({ enableEditMode }),
  confirmationCancelModalOpen: false,
  setConfirmationCancelModalOpen: confirmationCancelModalOpen => set({ confirmationCancelModalOpen }),
  entriesHasChanged: false,
  setEntriesHasChanged: entriesHasChanged => set({ entriesHasChanged }),
  highlighRow: undefined,
  setHighlighRow: row => set({ highlighRow: row }),
  setReorderLoading: reorderLoading => set({ reorderLoading }),
  reorderLoading: false,
  journalEntriesPayload: undefined,
  setJournalEntriesPayload: journalEntries => set({ journalEntriesPayload: journalEntries }),
}));
