import './KSListItem.scss';
import classNames from 'classnames';
import { IconValidateNew, IconAddNew, IconCancel } from 'components/Icons';

export type KSListItemProps = {
  /** The label of the list item */
  label: string;
  id?: string;
  isSelected: boolean;
  isRecent?: boolean;
  isLocation?: boolean;
  onClickListItem?: (label: string) => void;
  onAddListItem?: (label: string) => void;
  onRemoveListItem?: (label: string) => void;
  disabled?: boolean;
};

export default function KSListItem({
  label,
  id,
  isSelected = false,
  isRecent = false,
  isLocation = false,
  onClickListItem,
  onAddListItem,
  onRemoveListItem,
}: KSListItemProps) {
  const tick = (isSelected || isRecent) && isLocation;
  return (
    <div
      className={classNames('ks-list-item', {
        'is-location': isLocation,
        'is-recent': isRecent && !isSelected,
        'is-selected': isSelected,
      })}
      onClick={() => !isLocation && onClickListItem?.(id || label)}
    >
      <div className="ks-list-item__label" onClick={() => isLocation && !isSelected && onClickListItem?.(id || label)}>
        {label}
      </div>

      {isLocation && (
        <>
          {/* normal location item extra controls */}
          {!isSelected && !isRecent && (
            <div className="ks-list-item_options-container">
              <div className="ks-list-item_options-container__icon" onClick={() => onAddListItem?.(id || label)}>
                <IconAddNew />
              </div>
            </div>
          )}
          {/* reent location item controls */}
          {isRecent && !isSelected && (
            <div className="ks-list-item_options-container always-visible">
              <div className="ks-list-item_options-container__icon" onClick={() => onRemoveListItem?.(id || label)}>
                <IconCancel />
              </div>
            </div>
          )}
          {/* selected location item controls */}
          {isSelected && (
            <div className="ks-list-item__icon">
              <IconValidateNew />
            </div>
          )}
        </>
      )}
    </div>
  );
}
