import React, { useState } from 'react';
import Modal from 'react-modal';
import { IconClear } from './Icons';
import './SlidingPane.css';

export const CLOSE_TIMEOUT = 500;

interface Props {
  isOpen?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
  width?: string;
  closeIcon?: React.ReactNode;
  shouldCloseOnEsc?: boolean;
  onRequestClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  hideHeader?: boolean;
}

export function SlidingPane({
  width,
  isOpen,
  shouldCloseOnEsc,
  hideHeader,
  onRequestClose,
  closeIcon,
  title,
  subtitle,
  children,
}: Props): JSX.Element {
  const [wasOpen, setWasOpen] = useState<boolean>(false);
  const handleAfterOpen = () => {
    // Timeout fixes animation in Safari
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  };

  const handleAfterClose = () => {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  };

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName={{
        base: `sliding-pane__overlay`,
        afterOpen: wasOpen ? 'overlay-after-open' : '',
        beforeClose: 'overlay-before-close',
      }}
      className={{
        base: `sliding-pane sliding-pane_from_right`,
        afterOpen: wasOpen ? 'content-after-open' : '',
        beforeClose: 'content-before-close',
      }}
      style={{ content: { width: width || '80%' } }}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={isOpen ?? false}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={onRequestClose}
    >
      {!hideHeader && (
        <div className="sliding-pane__header">
          <div className="sliding-pane__close" onClick={onRequestClose} role="button" tabIndex={0}>
            {closeIcon || <IconClear />}
          </div>
          <div className="sliding-pane__title-wrapper">
            <h2 className="sliding-pane__title">{title}</h2>
            <div className="sliding-pane__subtitle">{subtitle}</div>
          </div>
        </div>
      )}
      <div className="sliding-pane__content">{children}</div>
    </Modal>
  );
}
