import React from 'react';
import { HoneUserRoles } from '@hone-automation/common';
import Select from 'react-select';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { RoleLabels } from '../../../constants';

interface RoleSelectorProps {
  control: Control<any>;
  name: string;
  rules: RegisterOptions;
  resetAfterSuccess?: boolean;
  className?: string;
}

function RoleSelector(props: RoleSelectorProps) {
  const { resetAfterSuccess = true, className, control, name, rules } = props;
  const options = React.useMemo(() => {
    return Object.values(HoneUserRoles).map(option => ({
      label: RoleLabels[option],
      value: option,
    }));
  }, [JSON.stringify(HoneUserRoles)]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState: { errors, isSubmitSuccessful } }) => {
        return (
          <>
            <Select
              menuPlacement="auto"
              {...field}
              ref={ref => {
                if (isSubmitSuccessful && resetAfterSuccess) {
                  ref?.clearValue();
                }
              }}
              options={options}
              className={className}
            />
            {errors?.[props.name] && (
              <p style={{ color: 'red' }}>{(errors[props.name]!.message as string) ?? 'Required'}</p>
            )}
          </>
        );
      }}
    />
  );
}

export default RoleSelector;
