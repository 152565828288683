export type HttpRequest = {
  url: string;
  method: HttpMethod;
  body?: any;
  headers?: any;
  params?: any;
};

export interface HttpClient<R = any> {
  request: (data: HttpRequest, opts?: { signal: AbortSignal }) => Promise<HttpResponse<R>>;
}

export type HttpMethod = 'post' | 'get' | 'put' | 'delete' | 'patch';

export enum HttpStatusCode {
  ok = 200,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  serverError = 500,
  clientClosedRequest = 499,
}

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode;
  body?: T;
  message?: string;
};

function toMessage(response: HttpResponse): any {
  return response.message || typeof response.body === 'string' ? response.body : JSON.stringify(response.body);
}

export class HttpErrorResponse<T = any> extends Error {
  constructor(response: HttpResponse<T>) {
    super(toMessage(response));
    this.statusCode = response.statusCode;
    this.body = response.body;
  }
  statusCode: HttpStatusCode;
  body?: T;
}
