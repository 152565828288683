import { report } from 'process';
import './NTSearchAndFilter.scss';
import SFActions from './SFActions';
import SFLiveReport from './SFLiveReport';
import SFSearch from './SFSearch';

export type ReportType = 'Report' | 'Payable' | 'Other';

type SearchAndFilterProps = {
  searchValue?: string;
  selectedReportType: HoneReportType;
  liveReport?: HoneReportTimeframe | null;
  onSearch?: (search: string) => void;
  onClear?: () => void;
};

const NTSearchAndFilter = ({
  searchValue = '',
  liveReport = null,
  selectedReportType,
  onSearch,
  onClear,
}: SearchAndFilterProps) => {
  const reportType: ReportType =
    selectedReportType === 'Income Statement' || selectedReportType === 'P&L Comparison'
      ? 'Report'
      : selectedReportType === 'AP Aging'
        ? 'Payable'
        : 'Other';
  return (
    <div className="search-and-filter">
      <SFSearch searchValue={searchValue} onSearch={onSearch} onClear={onClear} />
      <SFLiveReport timeFrame={liveReport} />
      <SFActions reportType={reportType} selectedReportType={selectedReportType} />
    </div>
  );
};

export default NTSearchAndFilter;
