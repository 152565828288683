import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ForecastReport } from 'presentation/pages';
import { AppLayout } from 'presentation/components';
import { useLocationsStore } from 'hooks/useLocationsStore';

type ReportEntryParams = {
  locationId: string;
};

export const MakeForecastReport = () => {
  const { locationId } = useParams<ReportEntryParams>();

  const allLocations = useLocationsStore(state => state.allLocations);

  useEffect(() => {
    let currentLocationId = locationId;
    if (!currentLocationId) {
      if (allLocations.length) {
        currentLocationId = allLocations[0].id;
      }
    }

    if (currentLocationId) {
      useLocationsStore.setState({ currentLocationId });
    }
  }, []);

  return (
    <AppLayout noFooter={true}>
      <ForecastReport />
    </AppLayout>
  );
};
