import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AnalyticsEvents, trackEvent } from 'lib/analytics';
import Loading from './Loading';
import { useQueryState } from 'hooks/useQueryState';
import { useAuthContext } from 'context/useAuthContext';
import Logo from '../assets/kitchensync_logo.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';

type LoginInputs = {
  email: string;
  password: string;
};

function AuthForm() {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const auth = useAuthContext();
  const { user, loading } = auth;
  const [back] = useQueryState('back');

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<LoginInputs>();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigateTo = useNavigate();

  // Handle browser autofill WIP
  useEffect(() => {
    const autofillDetected = () => {
      console.log('+++++ AUTOFILL');
    };

    const emailInput = emailInputRef.current;
    const passwordInput = passwordInputRef.current;

    if (emailInput && passwordInput) {
      emailInput.addEventListener('animationstart', autofillDetected);
      passwordInput.addEventListener('animationstart', autofillDetected);
    }

    return () => {
      if (emailInput && passwordInput) {
        emailInput.removeEventListener('animationstart', autofillDetected);
        passwordInput.removeEventListener('animationstart', autofillDetected);
      }
    };
  }, []);

  // Redicrect home if session exists
  useEffect(() => {
    if (user && localStorage.getItem('hone:session')) {
      redirectToHome();
    }
  }, [user]);

  function redirectToHome() {
    if (back && typeof back === 'string') {
      navigateTo(back);
      return;
    }
    navigateTo('/app/location');
  }

  const onSubmit: SubmitHandler<LoginInputs> = async ({ email, password }) => {
    setErrorMessage('');
    const response = await auth.login({ username: email, password });

    trackEvent(AnalyticsEvents.Login);
    if (response === 'success') {
      redirectToHome();
    } else {
      setError('email', { message: '' });
      setError('password', { message: '' });
      setValue('password', '');
      setFocus('password');
      setErrorMessage('Invalid credentials, please verify and try again.');
    }
  };

  if (user || loading) {
    return (
      <div className="h-100 center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="AuthForm">
      <Logo />
      <h1 className="visually-hidden">KITCHEN SYNC LOGIN</h1>
      <div className="AF-area">
        <form className="AuthForm-form" onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span className="visually-hidden">Email</span>
            <input
              type="text"
              placeholder="Email"
              autoComplete="email"
              className={classNames({ 'has-error': errors.email })}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
            />
            {errors.email && <span role="alert">{errors.email.message}</span>}
          </label>
          <label>
            <span className="visually-hidden">Password</span>
            <input
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              className={classNames({ 'has-error': errors.password })}
              {...register('password', { required: 'Password is required' })}
            />
            {errors.password && <span role="alert">{errors.password.message}</span>}
          </label>
          <button className="button w-100" type="submit">
            Sign in
          </button>
          {errorMessage ? <label className="d-block mt-4 text-error text-center">{errorMessage}</label> : ''}
        </form>
      </div>
      <Link to={'/forgot-password'}>Forgot password</Link>
    </div>
  );
}

export default AuthForm;
