import React, { useEffect, useState } from 'react';
import { Option } from 'types';
import { IconDown, IconUp, IconCalendar } from 'components/Icons';
import getWeek from 'date-fns/getWeek';

import './KSDropdown.scss';

import classNames from 'classnames';
import { formatRangeValues } from 'lib/reportUtils';

interface CustomDropdownProps {
  options: Option[];
  onSelect: (option: Option) => void;
  placeholder?: string;
  icon?: React.ReactNode;
  label?: string;
  value: Option | null;
  period?: 'Week' | 'Month' | 'Quarter' | 'Year' | 'Year to Date' | undefined;
  theme?: 'white' | 'grey';
  disabled?: boolean;
  enableScrollReload?: boolean;
  enableMinWidth?: boolean;
  reloadNewItemsFn?: () => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  options,
  value,
  icon,
  theme = 'white',
  period,
  placeholder,
  enableScrollReload = false,
  enableMinWidth = false,
  disabled = false,
  onSelect,
  reloadNewItemsFn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refContainer = React.useRef<HTMLDivElement>(null);
  const refContent = React.useRef<HTMLDivElement>(null);
  const refItemsContainer = React.useRef<HTMLDivElement>(null);
  const isDisabled = disabled || (!period && options.length < 2);
  const isGreyTheme = theme === 'grey' || period;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (refContainer.current && !refContainer.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleScroll = (ev: any) => {
    if (
      refItemsContainer.current?.clientHeight &&
      refItemsContainer.current?.scrollTop &&
      enableScrollReload &&
      reloadNewItemsFn
    ) {
      const isEndScroll =
        refItemsContainer.current.scrollTop + refItemsContainer.current.clientHeight >=
        refItemsContainer.current.scrollHeight;
      if (isEndScroll) {
        reloadNewItemsFn();
      }
    }
  };

  return (
    <div className="ks-custom-dropdown" ref={refContainer}>
      {label && <div className="ks-dropdown-label">{label}</div>}
      <div className={classNames('ks-dropdown-content', { 'enable-min-width': enableMinWidth })} ref={refContent}>
        <button
          className={classNames('ks-dropdown-toggle', {
            'enable-min-width': enableMinWidth,
            period: period,
            disabled: isDisabled,
            'grey-theme': isGreyTheme,
          })}
          type="button"
          onClick={handleToggle}
          disabled={isDisabled}
        >
          {period && (
            <div className="ks-dropdown-toggle-icon">
              <IconCalendar />
            </div>
          )}
          {value?.customLabel && <span className="ks-dropdown-range-units">{value?.customLabel}</span>}
          {value?.label}
          {options.length > 1 && <span className="ks-arrow">{isOpen ? <IconUp /> : <IconDown />}</span>}
        </button>

        {isOpen && (
          <div
            className={classNames('ks-dropdown-menu', {
              'enable-min-width': enableMinWidth,
              'not-label': !label,
              period: period,
            })}
            onScroll={handleScroll}
            ref={refItemsContainer}
          >
            {options.map((option, idx) => (
              <div
                key={`${option.value}_${idx}`}
                className={classNames({ period: period })}
                onClick={() => handleOptionClick(option)}
              >
                <span className="ks-dropdown-range-units">{option.customLabel}</span> <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
