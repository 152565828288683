import React, { Profiler, ProfilerOnRenderCallback } from 'react';

const NTProfiler: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const onRender: ProfilerOnRenderCallback = (id, phase, actualDuration, baseDuration, startTime, commitTime) => {
    //console.log(`Rendering time for ${id}: ${actualDuration.toFixed(2)}ms`);
  };

  return (
    <Profiler id="NewTable" onRender={onRender}>
      {children}
    </Profiler>
  );
};

export default NTProfiler;
