import React from 'react';
import Loading from 'components/Loading';
import useLocationRouting from 'hooks/useLocationRouting';
import { useReportsStore } from '../../../../hooks/useReportsStore';
import { useActiveReportStore } from '../../../../hooks/useActiveReportStore';

function ReportLocationFactory(): JSX.Element {
  useLocationRouting(true);
  useActiveReportStore.setState({ currentReport: undefined });
  useReportsStore.setState({ selectedReport: undefined });

  return <Loading />;
}

export default ReportLocationFactory;
