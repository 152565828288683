import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import './BookkeeperForm.css';
import Checkbox from 'presentation/components/Checkbox/Checkbox';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getReportConfig, updateReportConfig } from 'hooks/useReportsStore';
import { ReportConfiguration, UpdateReportConfigPayload } from '@hone-automation/common';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { fromShortString, toShortString } from '@hone-automation/common/lib/commonUtil';

import { showToast, TOAST_SUCCESS, TOAST_ERROR, FIVE_SECONDS, TEN_SECONDS } from 'lib/utils';

function BookkeeperReportingConfig(): JSX.Element {
  const { status: locationsStatus, currentLocationId, currentLocation } = useLocationsStore();

  const [configEnabled, setConfigEnabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [periodConfig, setPeriodConfig] = useState('4-4-5');
  const [selectedDates, setSelectedDates] = useState([new Date()]);
  const [leapWeek2ndLast, setLeapWeek2ndLast] = useState(false);

  useEffect(() => {
    setConfigEnabled(false);
    setSelectedDate(new Date());
    setPeriodConfig('4-4-5');
    setSelectedDates([]);
    setLeapWeek2ndLast(false);

    if (currentLocation) {
      getReportConfig(currentLocation.id)
        .then(reportConfigModel => {
          const reportConfig = reportConfigModel as ReportConfiguration;
          if (reportConfig && reportConfig.data) {
            setConfigEnabled(reportConfig.data.periodReporting);

            const genDate = reportConfig.data.generateStartDate;
            setSelectedDate(genDate ? fromShortString(genDate) : new Date());

            const leapDates = reportConfig.data.leapYearRestartDates;
            setSelectedDates(leapDates ? leapDates.map((d: any) => fromShortString(d)) : []);

            const config = reportConfig.data.periodConfiguration
              ? reportConfig.data.periodConfiguration.join('-')
              : '4-4-5';
            setPeriodConfig(config);

            setLeapWeek2ndLast(reportConfig.data.leapWeek2ndLast ?? false);
          }
        })
        .catch(function (error: any) {
          if (!error.toString().includes('not found')) {
            showToast(error.toString(), TOAST_ERROR, FIVE_SECONDS);
          }
        });
    }
  }, [currentLocation]);

  if (locationsStatus === 'loading') {
    return (
      <div className="d-flex justify-content-center mt-8 mb-4">
        <Loading unmount={false} />
      </div>
    );
  }

  const handleOnChangeEnabled = (value: any, checked: boolean) => {
    setConfigEnabled(checked);
  };

  const handleOnchangeDate = (date: Date) => {
    setSelectedDate(date);
  };

  const handleOnchangeMultipleDates = (dates: any) => {
    setSelectedDates(dates);
  };

  const handleCalendarTypeChange = (value: string) => {
    setPeriodConfig(value);
  };

  const handleOnChangeLeapWeek2ndLast = (value: any, checked: boolean) => {
    setLeapWeek2ndLast(checked);
  };

  const savePeriodConfig = () => {
    const body = {
      locationId: currentLocationId,
      configData: {
        periodReporting: configEnabled,
        generateStartDate: selectedDate ? toShortString(selectedDate) : toShortString(new Date()),
        periodConfiguration: periodConfig.split('-').map(n => Number.parseInt(n)),
        leapYearRestartDates: selectedDates ? selectedDates.map(d => toShortString(d)) : [],
        leapWeek2ndLast: leapWeek2ndLast,
      },
    } as UpdateReportConfigPayload;

    updateReportConfig(body)
      .then((result: any) => {
        showToast('Updated Report Configuration', TOAST_SUCCESS, FIVE_SECONDS);
      })
      .catch(function (error: any) {
        showToast(error.toString(), TOAST_ERROR, TEN_SECONDS);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-8 mb-4">
        {(currentLocation?.id || currentLocationId === '') && (
          <div className="mb-4" id="tab-settings" style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div />
            <form className="">
              <table className="BKForm-table">
                <tr>
                  <td>
                    <label className="mr-3">Period Reporting Enabled: </label>
                  </td>
                  <td>
                    <Checkbox checked={configEnabled} onChange={handleOnChangeEnabled} />
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Start of Year: </label>
                  </td>
                  <td>
                    <DatePicker
                      className="ks-calendar-input-date mr-3 width-150"
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      selected={selectedDate}
                      onChange={handleOnchangeDate}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Calendar Period Type: </label>
                  </td>
                  <td>
                    <select
                      id="calendarType"
                      name="calendarType"
                      value={periodConfig}
                      onChange={e => handleCalendarTypeChange(e.currentTarget.value)}
                    >
                      <option value="4-4-5">4-4-5</option>
                      <option value="4-5-4">4-5-4</option>
                      <option value="5-4-4">5-4-4</option>
                      <option value="4-4-4">4-4-4</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Leap Year Dates: </label>
                  </td>
                  <td>
                    <DatePicker
                      className="ks-calendar-input-date width-300 height-300"
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      selectsMultiple={true}
                      selectedDates={selectedDates}
                      shouldCloseOnSelect={false}
                      disabledKeyboardNavigation
                      onChange={handleOnchangeMultipleDates}
                      isClearable
                      showMonthDropdown
                      showYearDropdown
                      useShortMonthInDropdown
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="mr-3">Leap week on P11 (4-4-5 or 5-4-4):</label>
                  </td>
                  <td>
                    <Checkbox checked={leapWeek2ndLast} onChange={handleOnChangeLeapWeek2ndLast} />
                  </td>
                  <td></td>
                </tr>
              </table>
            </form>
            <div>
              <button className="button BKForm-btn-save" type="button" onClick={savePeriodConfig}>
                <span>Save</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookkeeperReportingConfig;
