//@ts-nocheck
import ExcelJS, { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';

import { ExportData } from '../types';
import { compileHeaders, compileData, mergeCells } from './utlis';
import { getKSSettings } from './styles';

import logoImage from '/kitchensync_logo.png';
import { spacer } from '../ksExporterUtils';

// Format info cells
const infoCell = (
  worksheet: Worksheet,
  row: string,
  column: number,
  alignment: 'middle' | 'bottom' | 'top',
  value: any,
  font: any
) => {
  const spacerUnit = '     ';
  const spacerIndent = 0;
  if (value.text) {
    value.text = spacer(spacerIndent, spacerUnit) + value.text;
  } else {
    value = spacer(spacerIndent, spacerUnit) + value;
  }
  worksheet.getCell(`${row}${column}`).value = value;
  worksheet.getCell(`${row}${column}`).alignment = { vertical: alignment, horizontal: alignment };
  worksheet.getCell(`${row}${column}`).font = font;
};

export const exportToExcel = async (data: ExportData, sheet: boolean = false) => {
  //Headers and Data generation
  const { ksStyles, ksBaseStyleSettings, ksScale, sheets } = getKSSettings(sheet);

  const { headers, headersB, headersC, widths, formats, percentOf } = compileHeaders(data, ksBaseStyleSettings);
  const { rows: tableContent, heights: rowHeights, extra } = compileData(data);

  // Row heights calculation
  let heights = [
    { hpx: ksBaseStyleSettings.height.first },
    { hpx: ksBaseStyleSettings.height.second },
    { hpx: ksBaseStyleSettings.height.third },
    { hpx: ksBaseStyleSettings.height.header },
  ];

  // Empty rows before the header to place the logo, location and report type
  const tableRowStart = [[''], [''], ['']];
  const tableStartRow = tableRowStart.length;

  // Create workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(data.type);

  // Define column widths
  worksheet.columns = widths.map(width => ({ width: width.wch }));

  // Add empty rows (for logo space)
  tableRowStart.forEach(emptyRow => worksheet.addRow(emptyRow));

  // Add headers
  const headerRow1 = worksheet.addRow(headers);
  let headerRows = 0;
  if (headersB.length === headers.length) {
    worksheet.addRow(headersB);
    heights.push({ hpx: ksBaseStyleSettings.height.header });
    headerRows += 1;
  }
  if (headersC.length === headers.length) {
    worksheet.addRow(headersC);
    heights.push({ hpx: ksBaseStyleSettings.height.lastHeader });
    headerRows += 1;
  }

  // Add table content (data)
  tableContent.forEach(row => {
    const newRow = worksheet.addRow(row);

    // Dynamically apply formatting based on values
    newRow.eachCell((cell, colNumber) => {
      const value = cell.value;

      // Apply formatting based on the value type
      if (formats[colNumber - 1] === 'percentage') {
        // Percentage formatting
        cell.numFmt = '0.00%';
      }
      if (formats[colNumber - 1] === 'money') {
        // Money formatting
        cell.numFmt = '"$"#,##0.00;[Red]"($"#,##0.00)';
      }
      if (formats[colNumber - 1] === 'number') {
        // Number formatting
        if (typeof cell.value === 'string') {
          cell.value = !isNaN(parseInt(cell.value)) ? parseInt(cell.value) : cell.value;
        }
      }
    });
  });

  const applyStyles = (cell: any, style: any) => {
    if (style.fill) cell.fill = style.fill;
    if (style.font) cell.font = style.font;
    if (style.border) cell.border = style.border;
    if (style.alignment) cell.alignment = style.alignment;
  };

  // Data rows start here
  const dataRowsStart = tableStartRow + headerRows + 1;

  // Initalize the column styles
  for (let i = 1; i <= headers.length + 20; i++) {
    worksheet.getColumn(i + 1).fill = ksStyles.white.fill;
    worksheet.getColumn(i + 1).border = {};
  }

  // Initalize the row styles
  worksheet.getRows(1, tableContent.length + tableStartRow + headerRows + 1).fill = ksStyles.cell.fill;
  worksheet.getRows(1, tableContent.length + tableStartRow + headerRows + 1).font = ksStyles.cell.font;

  // Apply style to Rows, including the alternate colors
  for (let i = 0; i < tableContent.length + tableStartRow + headerRows + 1; i++) {
    worksheet.getRow(i + 1).font = ksStyles.cell.font;
    worksheet.getRow(i + 1).alignment = ksStyles.cell.alignment;
    if (i >= dataRowsStart + 1 && i % 2 == 0) worksheet.getRow(i + 1).fill = ksStyles.cellAlternate.fill;
  }

  // Traverse all rows and apply the heights
  heights = [...heights, ...rowHeights];
  // Total table rows is calculated like this: tableContent.length + tableStartRow + headerRows + 1,
  // where + 1 is the first line of the header, that it is always there
  heights.forEach((height, index) => {
    worksheet.getRow(index + 1).height = height.hpx;
    if (index >= dataRowsStart) {
      const thisExtra = extra[index - dataRowsStart];
      if (thisExtra) {
        if (thisExtra.isTotal) {
          // Total_2 from report is isHeader:true && isTotal:true here
          if (!thisExtra.isHeader) worksheet.getRow(index + 1).fill = ksStyles.isBigTotal.fill;
          worksheet.getRow(index + 1).font = thisExtra.level === 0 ? ksStyles.isBigTotal.font : ksStyles.isTotal.font;
        }
        if (thisExtra.isHeader) {
          // Total_2 from report is isHeader:true && isTotal:true here
          worksheet.getRow(index + 1).font = thisExtra.isTotal ? ksStyles.isTotal2.font : ksStyles.isHeader.font;
        }
        if (thisExtra.isBigHeader) {
          worksheet.getRow(index + 1).eachCell((cell, rowNumber) => {
            cell.font = ksStyles.isBigHeader.font;
            if (rowNumber >= percentOf ? 3 : 2) {
              cell.alignment = { vertical: 'bottom', indent: 1 };
            }
          });
        }
      }
    }
  });

  // Accounts Column
  worksheet.getColumn(2).eachCell((cell, colNumber) => {
    if (colNumber >= dataRowsStart + 1) {
      const thisExtra = extra[colNumber - dataRowsStart - 1];
      if (thisExtra)
        cell.alignment = {
          vertical: thisExtra.isBigHeader ? 'bottom' : 'middle',
        };
      cell.value = spacer(thisExtra.level, '     ') + cell.value;
    }
  });

  // GL Column
  worksheet.getColumn(1).eachCell((cell, colNumber) => {
    if (colNumber >= dataRowsStart) {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = ksStyles.glColumn.font;
      cell.border = ksStyles.glColumn.border;
      const thisExtra = extra[colNumber - dataRowsStart - 1];
      if (thisExtra && thisExtra.isBigHeader) {
        cell.alignment = { vertical: 'bottom', horizontal: 'center' };
      }
    }
  });

  // Percent Column. Only if it exists
  if (percentOf) {
    worksheet.getColumn(3).eachCell((cell, colNumber) => {
      if (colNumber >= dataRowsStart + 1) {
        cell.font = ksStyles.percentColumn.font;
        cell.border = ksStyles.percentColumn.border;
      }
    });
  }

  // Add cell merges for Horizontal headers
  mergeCells(headers, tableStartRow).forEach(({ s, e }) => {
    worksheet.mergeCells(s.r + 1, s.c + 1, e.r + 1, e.c + 1);
  });

  mergeCells(headersB, tableStartRow + 1).forEach(({ s, e }) => {
    worksheet.mergeCells(s.r + 1, s.c + 1, e.r + 1, e.c + 1);
  });

  // Vertical Merges
  worksheet.mergeCells(tableStartRow + 1, 1, tableStartRow + headerRows + 1, 1);
  worksheet.mergeCells(tableStartRow + 1, 2, tableStartRow + headerRows + 1, 2);
  // Merging vertically if Percent of exists
  if (percentOf) worksheet.mergeCells(tableStartRow + 1, 3, tableStartRow + headerRows + 1, 3);

  // Headers styling
  // Make them white first (Clear)
  for (let i = 0; i < 3; i++) {
    worksheet.getRow(i + 1).fill = ksStyles.white.fill;
    worksheet.getRow(i + 1).border = ksStyles.white.border;
  }
  for (let i = 0; i < headerRows + 1; i++) {
    worksheet.getRow(tableStartRow + i + 1).font = ksStyles.header.font;
    worksheet.getRow(tableStartRow + i + 1).fill = ksStyles.header.fill;
    worksheet.getRow(tableStartRow + i + 1).border = ksStyles.header.border;

    // Let's treat the secondary header differently
    for (let j = 3; j < headers.length; j++) {
      worksheet.getCell(tableStartRow + i + 1, j + 1).font =
        i == 0
          ? ksStyles.headerDataFirst.font
          : i == headerRows
            ? ksStyles.headerDataLast.font
            : ksStyles.headerData.font;
      worksheet.getCell(tableStartRow + i + 1, j + 1).alignment = ksStyles.headerData.alignment;
    }
  }

  // Paint column dividers
  // Get frontier columns to do it
  const mergesMain: any = mergeCells(headers, tableStartRow);
  const mergesSub: any = mergeCells(headersB, tableStartRow + 1);
  const borders = ksBaseStyleSettings.colors.borders;

  // dividers for the data area columns. This is the base
  for (let i = 0; i < headers.length + 1; i++) {
    if (i > (percentOf ? 3 : 2)) {
      if (headerRows === 0 || (headerRows === 1 && !mergesMain.length)) worksheet.getColumn(i).width = 21;
      worksheet.getColumn(i).eachCell((cell, colNumber) => {
        if (colNumber >= dataRowsStart + 1) {
          cell.border = {
            right: {
              style: headerRows === 0 || sheet ? 'thin' : 'dotted',
              color: mergesMain ? borders.lightest : borders.lighter,
            },
          };
        }
      });
    }
  }
  if (mergesSub) {
    mergesSub.forEach(({ e }) => {
      const mainColumn = e.c + 1;
      worksheet.getColumn(mainColumn).eachCell((cell, colNumber) => {
        if (colNumber >= dataRowsStart + 1) {
          cell.border = { right: { style: 'thin', color: borders.concepts } };
        }
      });
    });
  }
  if (mergesMain) {
    mergesMain.forEach(({ e }) => {
      const mainColumn = e.c + 1;
      worksheet.getColumn(mainColumn).eachCell((cell, colNumber) => {
        if (colNumber >= dataRowsStart + 1) {
          cell.border = { right: { style: 'thin', color: mergesSub ? borders.mainColumns : borders.lighter } };
        }
      });
    });
  }

  //////// LOGO & Info Headers

  // Load the image (this should be a buffer in Node or base64 in the browser)
  const imageBuffer = await fetch(logoImage).then(res => res.arrayBuffer());

  // Add the image to the workbook
  const imageId = workbook.addImage({
    buffer: imageBuffer,
    extension: 'png',
  });

  // Insert the image into a cell (adjust the range for positioning)
  worksheet.addImage(imageId, {
    tl: { col: 1, row: 1 }, // top-left corner (B2)
    ext: { width: 280 * ksScale, height: 30 * ksScale }, // size of the image Original dimensions are 333x36
  });

  // Report Info
  const reportInfoCell = percentOf ? 'E' : 'D';
  infoCell(worksheet, reportInfoCell, 1, 'bottom', data.date?.range, ksStyles.infoTimeFrame.font);
  infoCell(worksheet, reportInfoCell, 2, 'middle', data.name, ksStyles.infoCompany.font);
  infoCell(
    worksheet,
    reportInfoCell,
    3,
    'top',
    {
      text: data.type,
      // if we are running locally, we need to convert the url to the dev server, otherwise we just use the current url
      hyperlink: window.location.hostname.includes('localhost')
        ? 'https://dev.kitchensync.us/v2' + window.location.pathname
        : window.location.href,
    },
    ksStyles.infoReport.font
  );

  // Set Frozen Rows and Columns
  const frozen: Partial<ExcelJS.WorksheetView> = {
    state: 'frozen',
    ySplit: 4 + headerRows,
    xSplit: percentOf ? 3 : 2,
    activeCell: 'A200', // by default excel always select the first cell. We just move it below the table
  };

  worksheet.views = [frozen];

  // Save file (browser environment)
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${data.fileName}${sheet ? '_gs' : ''}.xlsx`);
};
