import { useEffect, useRef } from 'react';
import pdfobject from 'pdfobject';
import './PDFObject.css';

interface Props {
  url: string;
}

function PDFObject({ url }: Props): JSX.Element {
  const pdfElement = useRef(null);

  useEffect(() => {
    const { current: el } = pdfElement;

    if (!el) return;
    if (!url) return;

    pdfobject.embed(url, el);
  }, [pdfElement, url]);

  return <div className="PDFObject" ref={pdfElement} />;
}

export default PDFObject;
