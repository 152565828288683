import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { isNull } from 'lodash';
import { matchesAccount } from '../../../../lib/templateUtils';
import { IconClear } from '../../../../components/Icons';

interface Props {
  initialInputItems: HoneAccount[];
  placeholder: string;
  selectedItem?: HoneAccount | null;
  disabled?: boolean;
  onChange: (selected: HoneAccount | undefined) => void;
}

function BookkeeperAccountsAutocomplete({
  initialInputItems,
  placeholder = 'Choose one ...',
  selectedItem = null,
  disabled = false,
  onChange,
}: Props): JSX.Element {
  const [inputItems, setInputItems] = useState(initialInputItems);
  const itemToString = (item: HoneAccount | null) => {
    if (item) {
      if (item.AcctNum) {
        return `(${item.AcctNum}) ${item.Name}`;
      }

      return item.Name;
    }

    return '';
  };

  // keep options updated when input items changes
  useEffect(() => {
    setInputItems(initialInputItems);
  }, [initialInputItems]);

  const {
    isOpen,
    openMenu,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    reset,
    getComboboxProps,
  } = useCombobox({
    items: inputItems,
    itemToString,
    selectedItem,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(initialInputItems.filter(item => matchesAccount(inputValue || '', item)));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (isNull(selectedItem)) return onChange(undefined);
      onChange(selectedItem);
    },
  });

  const resetAndChange = () => {
    reset();
    onChange(undefined);
  };

  return (
    <div className="Autocomplete">
      <label {...getLabelProps()} className="visually-hidden">
        Choose an account:
      </label>
      <div {...getComboboxProps()}>
        <input
          {...getInputProps({ disabled, onFocus: () => !isOpen && openMenu() })}
          placeholder={placeholder}
          className="select"
        />
        {selectedItem && (
          <button type="button" onClick={resetAndChange} aria-label="Clear">
            <IconClear />
          </button>
        )}
      </div>
      <ul {...getMenuProps()} className="Autocomplete-baseMenu">
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className="Autocomplete-item"
              style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
              key={`${item.Name}${index}`}
              {...getItemProps({ item, index })}
            >
              {itemToString(item)}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default BookkeeperAccountsAutocomplete;
