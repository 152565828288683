import Modal from 'react-modal';
import { IconClear, IconCircleClose } from 'components/Icons';
import { useReportsStore } from 'hooks/useReportsStore';
import { useLocationsStore } from 'hooks/useLocationsStore';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';

import 'rsuite/dist/rsuite.min.css';
import { useQueryState } from 'hooks/useQueryState';
import { differenceInCalendarDays, differenceInMonths, format, lastDayOfMonth, parse } from 'date-fns';
import { getDateRange } from 'lib/utils';
import { shallow } from 'zustand/shallow';
import { useCallback, useState } from 'react';

import qs from 'querystring';

import { GroupReportPayload } from 'domain/models';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const customRangeModalStyles = {
  content: {
    top: 0,
    bottom: 0,
    margin: 'auto',
    left: 0,
    right: 0,
    width: '300px',
    height: '250px',
  },
  overlay: {
    zIndex: 7,
  },
};

type ModalCustomDateRangeProps = {
  uniqueTitles: HoneReportSummary[];
};

const DATE_FORMAT = 'MM-dd-yyyy';

/**
 * @deprecated
 */
export default function ModalCustomDateRange({ uniqueTitles }: ModalCustomDateRangeProps) {
  const location = useLocation();
  const navigateTo = useNavigate();

  const { selectedReport, openDateRangeModal, setOpenDateRangeModal } = useReportsStore(
    state => ({
      selectedReport: state.selectedReport,
      openDateRangeModal: state.openDateRangeModal,
      setOpenDateRangeModal: state.setOpenDateRangeModal,
    }),
    shallow
  );
  const { locationId, reportType } = useParams();

  const { currentLocation } = useLocationsStore(state => ({ currentLocation: state.currentLocation }));
  const newDateRangeTimeframes: HoneReportTimeframe[] = ['Monthly', 'Weekly'];
  const [, setDateRange] = useQueryState<string>('dateRange');
  const [tempDateRangeParams, settempDateRangeParams] = useState<Omit<GroupReportPayload, 'type'> | undefined>(
    undefined
  );

  const startDate = tempDateRangeParams?.startDate && parse(tempDateRangeParams.startDate, DATE_FORMAT, new Date());
  const endDate = tempDateRangeParams?.endDate && parse(tempDateRangeParams.endDate, DATE_FORMAT, new Date());

  const handleConfirmDateRange = () => {
    const { total, difference, startDate, endDate } = tempDateRangeParams!;

    setOpenDateRangeModal(false);
    if (startDate && endDate) {
      const currentTemplateId = selectedReport?.templateId;
      const templateId = currentTemplateId
        ? currentTemplateId
        : uniqueTitles[0]?.templateId && uniqueTitles[0]?.templateId;

      const queryParams = qs.parse(location.search.replace('?', ''));
      const newQueries = {
        ...queryParams,
        dateRange: `${tempDateRangeParams?.startDate},${tempDateRangeParams?.endDate}`,
        templateId,
        total: String(total),
        difference,
      };

      if (currentLocation?.id && tempDateRangeParams) {
        navigateTo({
          pathname: `/app/location/${locationId}/report/${reportType}`,
          search: `?${qs.stringify(newQueries)}`,
        });
      }
    }
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'total') {
      settempDateRangeParams({ ...tempDateRangeParams, [name]: checked, difference: false });
    } else if (name === 'difference') {
      settempDateRangeParams({ ...tempDateRangeParams, [name]: checked, total: false });
    }
  };

  const isMonthly = selectedReport?.timeframe === 'Monthly';

  const getDisableTotal = () => {
    if (startDate && endDate) {
      const differenceInMonth = differenceInMonths(endDate, startDate);
      const differenceInDays = differenceInCalendarDays(endDate, startDate);
      if (differenceInMonth !== undefined && differenceInDays !== undefined) {
        return isMonthly ? !(differenceInMonth > 0) : !(differenceInDays > 7);
      }
    }
    return true;
  };

  const getDisableDifference = () => {
    if (startDate && endDate) {
      const differenceInMonth = differenceInMonths(endDate, startDate);
      const differenceInDays = differenceInCalendarDays(endDate, startDate);
      if (differenceInMonth !== undefined && differenceInDays !== undefined) {
        return isMonthly ? !(differenceInMonth === 1) : !(differenceInDays === 13);
      }
    }
    return true;
  };

  const getMonthlyDifference = useCallback(
    (selectedDate: DateRange) => {
      if (!tempDateRangeParams?.difference) return false;

      return differenceInMonths(selectedDate[1], selectedDate[0]) === 1 ? tempDateRangeParams.difference : false;
    },
    [tempDateRangeParams]
  );

  const getWeeklyDifference = useCallback(
    (selectedDate: DateRange) => {
      if (!tempDateRangeParams?.difference) return false;

      return differenceInCalendarDays(selectedDate[1], selectedDate[0]) === 1 ? tempDateRangeParams.difference : false;
    },
    [tempDateRangeParams]
  );

  const handleOnChange = (selectedDate: DateRange | null) => {
    if (selectedDate && currentLocation && selectedReport) {
      const isFuture = startDate ? selectedDate[0].getTime() > startDate?.getTime() : false;

      const differenceValMonthly = getMonthlyDifference(selectedDate);
      const differenceValWeekly = getWeeklyDifference(selectedDate);

      settempDateRangeParams({
        ...tempDateRangeParams,
        difference: isMonthly ? differenceValMonthly : differenceValWeekly,
        startDate: isMonthly
          ? format(startDate && isFuture ? startDate : selectedDate[0], 'MM-dd-yyyy')
          : !isFuture
            ? format(selectedDate[0], 'MM-dd-yyyy')
            : startDate && format(startDate, 'MM-dd-yyyy'),
        endDate: isMonthly
          ? format(isFuture || !endDate ? lastDayOfMonth(selectedDate[0]) : endDate, 'MM-dd-yyyy')
          : !isFuture && endDate
            ? format(endDate, 'MM-dd-yyy')
            : format(
                getDateRange(
                  selectedDate[1],
                  currentLocation.weekStart || 'Mo',
                  selectedReport?.timeframe,
                  startDate
                )[1],
                'MM-dd-yyyy'
              ),
      });
    }
  };

  return (
    <Modal
      id="custom-range-selector-modal"
      style={customRangeModalStyles}
      isOpen={openDateRangeModal}
      onRequestClose={() => setOpenDateRangeModal(false)}
    >
      <>
        {currentLocation && selectedReport && newDateRangeTimeframes.includes(selectedReport.timeframe) && (
          <div className="report-view__custom-date-modal">
            <div className="modal-header mb-4">
              <span>Custom Range Selector</span>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <IconClear onClick={() => setOpenDateRangeModal(false)} />
            </div>
            <DateRangePicker
              className="report-view__custom-date-modal_date"
              hoverRange={
                isMonthly
                  ? 'month'
                  : (date: Date) =>
                      getDateRange(date, currentLocation.weekStart || 'Mo', selectedReport?.timeframe, startDate)
              }
              ranges={[]}
              onClean={() => {
                settempDateRangeParams(undefined);
                setDateRange('');
              }}
              cleanable
              shouldDisableDate={date => {
                const today = new Date();
                const rangeDate = getDateRange(
                  today,
                  currentLocation.weekStart || 'Mo',
                  selectedReport?.timeframe,
                  date
                );

                const lastDay = isMonthly ? lastDayOfMonth(today) : rangeDate[1];
                const lastDayIso = new Date(`${lastDay.toISOString().split('T')[0]}T00:00:00`);
                const dateIso = new Date(`${date.toISOString().split('T')[0]}T00:00:00`);

                return dateIso.getTime() > lastDayIso.getTime();
              }}
              onChange={handleOnChange}
              oneTap
              showOneCalendar
              format="MM/dd/yyyy"
              value={startDate && endDate ? [startDate, endDate] : null}
            />
            <div>
              <input
                className="mx-2"
                type="checkbox"
                name="total"
                disabled={getDisableTotal()}
                onChange={handleCheck}
                checked={tempDateRangeParams?.total}
              />
              <label>Show Total</label>
            </div>
            <div>
              <input
                className="mx-2"
                type="checkbox"
                name="difference"
                disabled={getDisableDifference()}
                onChange={handleCheck}
                checked={tempDateRangeParams?.difference}
              />
              <label>Show Differences</label>
            </div>
            <button disabled={!startDate || !endDate} className="button" onClick={() => handleConfirmDateRange()}>
              CONFIRM
            </button>
          </div>
        )}
      </>
    </Modal>
  );
}
