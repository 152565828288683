import { prefixClass } from 'lib/utils';
import './KSMultipleLocationModal.scss';
import classNames from 'classnames';
import KSMLMSearch from './KSMLMSearch';
import KSMLMLocations from './KSMLMLocations';
import react, { useState, useRef, useEffect, createContext } from 'react';
import KSMLMFooter from './KSMLMFooter';
import { useClickAway } from 'react-use';

export interface KSMLocation extends HoneLocationUser {
  id: string;
  locationId: string;
  locationName: string;
  org: string;
}

type KSMultipleLocationModalProps = {
  open: boolean;
  selectedLocations: KSMLocation[];
  allLocations: KSMLocation[];
  activeLocation: KSMLocation;
  onApplyChanges?: (selected: KSMLocation[]) => void;
  onLocationsSelected?: (location: KSMLocation[]) => void;
  onClose?: () => void;
};

const AllSelectedContext = createContext<
  | {
      allSelected: boolean;
      setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const AllSelected = AllSelectedContext;

const KSMultipleLocationModal = ({
  open = true,
  selectedLocations,
  allLocations,
  activeLocation,
  onApplyChanges,
  onLocationsSelected,
  onClose,
}: KSMultipleLocationModalProps) => {
  const prefix = prefixClass('ks-multiple-location-modal');
  const [searchQuery, setSearchQuery] = useState('');
  const [isActive, setIsActive] = useState(open);
  const [availableLocations, setAvailableLocations] = useState<KSMLocation[]>([]);
  const [selLocations, setSelLocations] = useState<KSMLocation[]>(selectedLocations);
  const [originalSelectedLocations] = useState<KSMLocation[]>(selectedLocations);

  const [allSelected, setAllSelected] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const getAvailableLocations = (query: string | null = null) => {
    const avLocations = allLocations.filter(location => {
      const valueMatch = query
        ? location.locationName.toLowerCase().includes(query) || location.org?.toLowerCase().includes(query)
        : true;
      const selectedMatch = selLocations.some(selectedLocation => selectedLocation.locationId === location.locationId);
      return valueMatch && !selectedMatch && location.locationId !== activeLocation.locationId;
    });
    setAvailableLocations(avLocations);
  };

  const handleApplyChanges = () => {
    if (selLocations?.length > 0) {
      onApplyChanges?.(selLocations);
    }
  };

  const handleSelectAll = () => {
    setSelLocations(allLocations);
  };

  const handleSelectNone = () => {
    setSelLocations([activeLocation]);
  };

  const handleSelectLocation = (location: KSMLocation) => {
    setSelLocations([...selLocations, location]);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    getAvailableLocations(value);
  };

  const handleRemoveLocation = (location: KSMLocation) => {
    const newLocations = selLocations.filter(selLocation => selLocation.locationId !== location.locationId);
    setSelLocations(newLocations);
  };

  const closeModal = () => {
    // Reset the selected locations to the original selected locations
    onLocationsSelected?.(originalSelectedLocations);
    onClose?.();
  };

  /* useClickAway( modalRef , closeModal); */

  useEffect(() => {
    getAvailableLocations();
    onLocationsSelected?.(selLocations);
    setAllSelected(selLocations.length === allLocations.length);
  }, [selLocations]);

  return (
    <AllSelectedContext.Provider value={{ allSelected, setAllSelected }}>
      <div className={classNames('ks-multiple-location-modal-container', { active: isActive })}>
        <div className={prefix()} ref={modalRef}>
          <KSMLMSearch
            placeholder="Search Locations"
            searchValue={searchQuery}
            setSearchValue={handleSearch}
            handleClose={closeModal}
          />
          <KSMLMLocations
            activeLocation={activeLocation}
            allLocations={availableLocations}
            selectedLocations={selLocations}
            onLocationSelect={handleSelectLocation}
            onLocationRemove={handleRemoveLocation}
          />
          <KSMLMFooter
            selectedLocations={selLocations}
            onSelectAll={handleSelectAll}
            onSelectNone={handleSelectNone}
            onApplyChanges={handleApplyChanges}
          />
        </div>
      </div>
    </AllSelectedContext.Provider>
  );
};

export default KSMultipleLocationModal;
