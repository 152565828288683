import './IconButton.scss';
import { Whisper, Tooltip } from 'rsuite';
import classNames from 'classnames';

type IconButtonProps = {
  tooltipLabel: string;
  status?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  iconTrue?: JSX.Element;
  label?: string;
  boundary?: boolean;
  onClick?: () => void;
  iconSize?: number;
};

const IconButton = ({
  tooltipLabel,
  status = true,
  disabled = false,
  icon,
  iconTrue,
  label,
  boundary = false,
  onClick,
  iconSize = 24,
}: IconButtonProps) => {
  const handleClick = () => {
    onClick?.();
  };
  return (
    <Whisper
      placement="top"
      trigger="hover"
      disabled={disabled}
      speaker={<Tooltip>{tooltipLabel}</Tooltip>}
      delayOpen={800}
      delayClose={100}
    >
      <div
        style={{ width: label ? 'auto' : `${iconSize}px`, height: `${iconSize}px` }}
        className={classNames('icon-button', {
          status: status && !iconTrue,
          'icon-true': iconTrue,
          boundary: boundary,
          disabled: disabled,
          label: !!label,
        })}
        onClick={handleClick}
      >
        {label ? label : iconTrue ? (status ? iconTrue : icon) : icon}
      </div>
    </Whisper>
  );
};

export default IconButton;
