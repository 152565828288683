import classNames from 'classnames';
import { currencyFormatter2Decimals } from 'lib/utils';

import React, { useEffect, useState } from 'react';

type TableDatCellProps<T> = {
  rowData: T;
  contentStyles: React.CSSProperties;
  onChange?: (value: string) => void;
  isEditable: boolean;
  cellType?: 'number' | 'text';
  hasChanged?: boolean;
};

export default function TableDataEditableCell<T>({
  rowData,
  contentStyles,
  onChange,
  isEditable,
  cellType,
}: TableDatCellProps<T>): JSX.Element {
  const [data, setData] = useState<string>((rowData as string) || 'x');
  //create input ref
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (rowData !== data) {
      setData(rowData as string);
    }
  }, [rowData]);

  const sanitizeForNumber = (value: string) => {
    if (value === 'x') return value;
    let sanitazeNumber = value.includes('$') ? value.replaceAll('$', '') : value;
    sanitazeNumber = sanitazeNumber.includes(',') ? sanitazeNumber.replaceAll(',', '') : sanitazeNumber;
    sanitazeNumber = sanitazeNumber.includes('x') ? sanitazeNumber.replaceAll('x', '') : sanitazeNumber;
    return sanitazeNumber;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (cellType === 'number') {
      const sanitazeNumber = sanitizeForNumber(value);

      const numbersWithPointsRegex = /^[0-9]+(\.[0-9]{0,2})?$/;
      const isNumber = numbersWithPointsRegex.test(sanitazeNumber);
      if (!isNumber) return;

      setData(sanitazeNumber);
      onChange && onChange(sanitazeNumber);
      return;
    }

    setData(value);
    onChange && onChange(value);
  };

  const onBlur = () => {
    let newData = data;
    if (data === null || cellType === 'text' || data === 'x') return;
    if (data === '') {
      newData = 'x';
    }
    const newValue = !isNaN(Number(data)) ? currencyFormatter2Decimals(Number(data)) : data;
    newData = newValue;
    setData(newData);
    onChange && onChange(newData);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLInputElement;
    const sanitizedValue = sanitizeForNumber(target.value);

    setData(sanitizedValue);
  };

  const getTextSelected = () => {
    return document.getSelection()?.toString();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const element = inputRef.current;
      const selectedText = element && getTextSelected();
      if (data.length === 1) {
        setData('x');
        onChange && onChange('x');
        return;
      }
      if (selectedText) {
        e.preventDefault();
        e.stopPropagation();
        if (selectedText === data) {
          setData('');
        } else {
          //get difference between data and selected text
          const differenceDataAndSelectedText = selectedText && data.replace(selectedText, '');
          differenceDataAndSelectedText && setData(sanitizeForNumber(differenceDataAndSelectedText));
        }
      }
    }
  };

  return (
    <div className="hone-table-body-row-cell bg-transparent">
      <div className={classNames('hone-table-body-row-cell-item')} style={{ ...contentStyles }}>
        <input
          type={'text'}
          value={data}
          onChange={handleOnChange}
          onBlur={onBlur}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          style={{ ...contentStyles }}
          disabled={!isEditable}
          ref={inputRef}
        />
      </div>
    </div>
  );
}
