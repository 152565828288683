import find from 'lodash/find';
import { cloneDeep, get, isString } from 'lodash';
import { ForecastPeriod } from 'hooks/useForecasts';

export function getDisplaySections(sections: ForecastSection[]): ForecastSection[] {
  let children: ForecastSection[] = [];
  sections.forEach(s => {
    if (s.sections && s.sections.length) {
      children = [...children, ...s.sections];
    }
  });

  return children;
}

export function updateUIReport(
  uiReport: UIForecastReport,
  name: 'net-sales' | 'revenue' | 'expenses'
): UIForecastReport {
  const sections = Object.entries(uiReport).map(
    ([key, subsection]: [key: string, subsection: UIForecastReportElement[]]) => {
      if (key !== 'net-sales') {
        return Object.values(subsection).map(s => {
          if (s.foreignKey !== '') {
            let referencedValue;

            if (s.foreignKey === 'net-sales') {
              referencedValue = Number(uiReport['net-sales'].goalAmount.toString().replace(/[^0-9.-]+/g, ''));
            } else {
              referencedValue = getRevenueAmountByTag(Object.values(uiReport['revenue']), s.foreignKey, 'goalAmount');
            }

            const currentPercentage = Number(s.goalPerc) / 100;
            const currentGoalAmount = Number(s.goalAmount);
            const nextGoalAmount = currentPercentage * referencedValue;
            const nextPerc = (currentGoalAmount / referencedValue) * 100;

            if (name === 'net-sales' || (name === 'revenue' && key === 'expenses')) {
              return {
                ...s,
                goalAmount: nextGoalAmount,
              };
            } else if (name === 'revenue' || (name === 'expenses' && key === 'revenue')) {
              return {
                ...s,
                goalPerc: nextPerc,
              };
            }
          }
          return s;
        });
      }
      return subsection;
    }
  );

  return {
    ...uiReport,
    revenue: { ...sections[1] },
    expenses: { ...sections[2] },
  };
}

export function calculateAmountsFromReport(
  activeReport: Forecast,
  activeDate: string,
  timeframe: 'weeks' | 'months'
): Forecast {
  const newReport = cloneDeep(activeReport);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const timeframeData = newReport.forecastData[timeframe].map((period: ForecastPeriod, idx: number) => {
    const activeRemoteForecastPeriod =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newReport.forecastData[timeframe][idx];

    const netSales = activeRemoteForecastPeriod.sections[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const netSalesAmount = netSales.amount * (1 + netSales.goalPerc);

    period.sections = period.sections.map((category: any) => {
      category.goalAmount = category.amount * (1 + category.goalPerc);
      category.sections = category.sections.map((section: any) => {
        const goalPerc = section?.goalPerc;
        const goalPercTag = section?.goalPercTag;

        if (typeof goalPerc !== 'undefined' && typeof goalPercTag !== 'undefined') {
          let goalAmount;
          let referenceAmount;

          if (goalPercTag === 'Net Sales') {
            referenceAmount = netSalesAmount;
          } else {
            referenceAmount = getRevenueAmountByTag(netSales.sections, goalPercTag, 'goalAmount');
          }

          if (round(section.amount / referenceAmount) === section.goalPerc) {
            goalAmount = section.amount;
          } else {
            goalAmount = goalPerc * referenceAmount;
          }
          return {
            ...section,
            goalAmount,
          };
        }
      });
      return category;
    });

    return period;
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  newReport.forecastData[timeframe] = timeframeData;

  return newReport;
}

export function getUIValues(sections: ForecastSection[]) {
  const flattenSections = getDisplaySections(sections);
  const revenue: UIForecastReportElement[] = [];
  const expenses: UIForecastReportElement[] = [];

  function createUIElement({
    title,
    amount,
    goalPerc,
    goalPercTag,
    tag,
    goalAmount,
  }: ForecastSection): UIForecastReportElement {
    const _amount = typeof amount !== 'undefined' ? amount : 0;
    return {
      title,
      amount: _amount,
      tag: tagToKey({ tag: tag ?? '' } as ForecastSection),
      goalAmount: typeof goalAmount !== 'undefined' ? goalAmount : _amount,
      goalPercTag: goalPercTag,
      foreignKey: tagToKey({ tag: goalPercTag } as ForecastSection),
      goalPerc: typeof goalPerc !== 'undefined' ? goalPerc * 100 : -1,
    };
  }

  flattenSections.map(section => {
    const newElement = section && createUIElement(section);

    if (section?.type === 'Revenue') {
      revenue.push(newElement);
    }
    if (section?.type === 'Expense') {
      expenses.push(newElement);
    }
  });

  return {
    'net-sales': createUIElement(sections[0]),
    revenue: { ...revenue },
    expenses: { ...expenses },
  };
}

export function tagToKey(section: ForecastSection): string {
  if (section.tag) {
    return section.tag.replace(' ', '-').toLowerCase();
  }
  return '';
}

export function mapTagValuesToReference(section: ForecastSection): any {
  const key = tagToKey(section);

  if (typeof section.sections === 'undefined') {
    return {
      [key]: section.amount,
    };
  } else {
    return {
      [key]: section.amount,
      ...mapTagValuesToReference(section.sections[0]),
      ...mapTagValuesToReference(section.sections[1]),
    };
  }
}

export function generateUIReport(
  remoteReport: RemoteForecastReport,
  timeframe: ForecastTimeframe,
  period: number,
  activeDate: string | null
): {
  report: UIForecastReport;
  goalsSet: boolean;
} {
  if (activeDate) {
    const activeForecastPeriod: ForecastBase = remoteReport[timeframe][activeDate];
    if (activeForecastPeriod) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const activeForecastedSections = activeForecastPeriod.forecast.forecastData[timeframe][period].sections;
      return {
        report: getUIValues(activeForecastedSections),
        goalsSet: false,
      };
    }
  }
  return {
    report: {} as UIForecastReport,
    goalsSet: false,
  };
}

/**
 * Get the start date of the first period with a forecast in a remote forecast report.
 *
 * @param {RemoteForecastReport} report - The remote forecast report.
 * @param {ForecastTimeframe} timeframe - The timeframe to search for the first period with a forecast in.
 * @returns {string | null} The start date of the first period with a forecast, or null if none is found.
 */
export function getActiveDate(report: RemoteForecastReport, timeframe: ForecastTimeframe): string | null {
  const periods = Object.values(report[timeframe]);
  const firstDateWithForecast = periods.find(x => Object.prototype.hasOwnProperty.call(x, 'forecast'));

  if (firstDateWithForecast) {
    return firstDateWithForecast.startDate;
  }
  return null;
}

export function transformPercentage(value: string | number): number {
  return Number((Number(value) * 100).toFixed(2));
}

export function getSectionByName(report: any, name: string, needle: string): UIForecastReportElement {
  return get(report, name.replace(`.${needle}`, ''));
}

export function round(num: number, decimals = 3): number {
  return +(Math.round(Number(num + `e+${decimals}`)) + `e-${decimals}`);
}

export function getRevenueAmountByTag(sections: any, search: string, field = 'amount') {
  return find(sections, function (n) {
    if (search === n.tag) {
      return true;
    }
  })[field];
}

function roundPercentage(goalPerc: string | number): string | number {
  if (isString(goalPerc)) {
    return round(parseFloat(goalPerc), 2);
  }

  return round(goalPerc, 2);
}

/**
 * roundReport
 * @desc Resets the forecast data in a report.
 *
 * @param {UIForecastReport} report - The ui report data to be modified.
 *
 * @return {UIForecastReport} - The modified report data.
 */
export function roundReport(report: UIForecastReport): UIForecastReport {
  const roundedReport = Object.entries(report).map(([key, value]: [string, any]) => {
    // If the key of the report is "net-sales", the goalAmount is rounded up to the
    // nearest whole number and the goalPerc is rounded to a whole number using
    // roundPercentage function.
    if (key === 'net-sales') {
      const goalAmount = Math.ceil(value.goalAmount.toString().replace(',', ''));
      return [key, { ...value, goalPerc: roundPercentage(value.goalPerc), goalAmount }];
    }

    return [
      key,
      Array.prototype.map.call(
        {
          ...value,
          length: Object.values(value).length,
        },
        x => {
          return Object.assign({}, x, {
            goalAmount: round(x.goalAmount, 0),
            goalPerc: roundPercentage(x.goalPerc),
          });
        }
      ),
    ];
  });

  return Object.fromEntries(roundedReport);
}

/**
 * resetForecast
 * @desc Resets the forecast data in a report.
 *
 * @param {RemoteForecastReport} report - The report data to be modified.
 * @param {string} activeDate - The active date in the report.
 * @param {ForecastTimeframe} timeframe - The timeframe of the report (e.g. "months", "weeks").
 * @param {number} activePeriod - The active period in the report.
 *
 * @return {RemoteForecastReport} - The modified report data.
 */
export function resetForecast(
  report: RemoteForecastReport,
  activeDate: string,
  timeframe: ForecastTimeframe,
  activePeriod: number
): RemoteForecastReport {
  const newReport = cloneDeep(report);

  const activeReportSections = (newReport[timeframe][activeDate] as WithRequiredProperty<ForecastBase, 'forecast'>)
    .forecast.forecastData[timeframe][activePeriod].sections;

  for (const section of activeReportSections) {
    section.goalPerc = 0;

    if (section.sections) {
      for (const category of section.sections) {
        if (category.type === 'Revenue') {
          category.goalPerc = category.amount / section.amount;
        } else {
          let referenceAmount;
          if (category.goalPercTag === 'Net Sales') {
            referenceAmount = activeReportSections[0].amount;
          } else if (category?.goalPercTag) {
            referenceAmount = getRevenueAmountByTag(activeReportSections[0].sections, category.goalPercTag!);
          }

          category.goalPerc = category.amount / referenceAmount;
        }
      }
    }
  }

  return newReport;
}
