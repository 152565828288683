import React, { useEffect, useRef } from 'react';

interface SparklineProps {
  data?: number[];
  type?: 'line' | 'bar' | null;
  lineWidth?: number;
  positiveColor?: string;
  negativeColor?: string;
  showZeroLine?: boolean;
  showZeroLineColor?: string;
  width?: number;
  height?: number;
  columns?: number;
}

const Sparkline: React.FC<SparklineProps> = ({
  data,
  type = 'line',
  lineWidth = 2,
  positiveColor = '#4caf50',
  negativeColor = '#f44336',
  showZeroLine = false,
  showZeroLineColor = '#666666',
  width = 100,
  height = 38,
  columns = 10,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Generate random data if none is provided
    const sparklineData =
      data && data.length > 0 ? data : Array.from({ length: columns }, () => Math.floor(Math.random() * 21) - 10);

    // Set canvas dimensions
    canvas.width = width;
    canvas.height = height;

    const maxVal = Math.max(...sparklineData);
    const minVal = Math.min(...sparklineData) < 0 ? Math.min(...sparklineData) : 0;
    // Calculate Max Range
    const maxRange =
      (minVal < 0 && maxVal < 0) || (minVal > 0 && maxVal > 0) ? Math.abs(maxVal) : Math.abs(minVal + maxVal);
    const len = sparklineData.length;
    const adjustedMinVal = minVal < 0 ? minVal : 0;
    const zeroY = height - ((0 - adjustedMinVal) / (maxVal - adjustedMinVal)) * height;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);

    // Draw zero line if enabled
    if (showZeroLine && type === 'bar') {
      ctx.strokeStyle = showZeroLineColor;
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(0, zeroY);
      ctx.lineTo(width, zeroY);
      ctx.stroke();
    }

    // Charting Bars
    if (type === 'bar') {
      const barWidth = width / len;
      sparklineData.forEach((value, index) => {
        const barHeight = (value / maxRange) * height;
        ctx.fillStyle = value >= 0 ? positiveColor : negativeColor;
        if (value >= 0) {
          ctx.fillRect(index * barWidth, zeroY - barHeight, barWidth - 1, barHeight);
        } else {
          ctx.fillRect(index * barWidth, zeroY, barWidth - 1, -barHeight);
        }
      });

      // Charting lines
    } else if (type === 'line') {
      ctx.lineWidth = lineWidth;
      ctx.beginPath();

      if (sparklineData.every(value => value === 0)) {
        ctx.strokeStyle = positiveColor;
        ctx.moveTo(0, zeroY);
        ctx.lineTo(width, zeroY);
        ctx.stroke();
      } else {
        let lastValue = sparklineData[0];
        let lastX = 0;
        for (let index = 0; index < len; index++) {
          const value = sparklineData[index];
          const x = (index / (len - 1)) * width;
          const y = height - ((value - adjustedMinVal) / (maxVal - adjustedMinVal)) * height;
          if (index === 0) {
            ctx.moveTo(x, y);
          } else {
            if ((lastValue >= 0 && value < 0) || (lastValue < 0 && value >= 0)) {
              const intersectionX = lastX + (x - lastX) / 2;
              const intersectionY = zeroY;
              ctx.lineTo(lastX, intersectionY);
              ctx.stroke();
              ctx.beginPath();
              ctx.moveTo(intersectionX, intersectionY);
              ctx.lineTo(intersectionX, y);
            } else {
              ctx.lineTo(x, y);
            }
          }
          ctx.strokeStyle = value >= 0 ? positiveColor : negativeColor;
          lastValue = value;
          lastX = x;
        }
        ctx.stroke();
      }
    }
  }, [data, type, lineWidth, positiveColor, negativeColor, showZeroLine, width, height, columns]);

  return <canvas ref={canvasRef} style={{ width: `${width}px`, height: `${height}px` }} />;
};

export default Sparkline;

// Usage Example:
// <Sparkline data={[1, 3, -2, 5, 7, -1, 4]} type="bar" showZeroLine={true} width={150} height={50} />
// <Sparkline type="line" lineWidth={3} showZeroLine={true} width={150} height={50} columns={15} />
