import React, { ChangeEvent, createRef, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { IconClear, IconDown, IconLocation, IconUp } from 'components/Icons';
import classNames from 'classnames';
import { useSidenavStore } from 'hooks/useSidenavStore';

interface AutocompleteProps<T extends { id: string }> {
  options: T[];
  searchProp: keyof T;
  onItemClick: (selectedElement: T) => void;
  createElement?: React.ReactNode;
  loadDefaultValue: (updateValue: any, setInitialValue: any) => void;
  loading?: boolean;
  iconElement?: React.ReactNode;
}

function Autocomplete<T extends { id: string }>(props: AutocompleteProps<T>) {
  const [initialValue, setInitialValue] = useState<string>('Loading...');
  const [value, updateValue] = useState<string>('');
  const [currentFocus, setCurrentFocus] = useState<number>(-1);
  const [results, setResults] = useState<T[]>([]);
  const [noResults, setNoResults] = useState<boolean | null>(null);
  const [cleared, setCleared] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const inputRef = createRef<HTMLInputElement>();
  const listRef = createRef<HTMLDivElement>();
  const { isSidenavCollapsed, toggleSidenav } = useSidenavStore();

  const filterSearchResults = useCallback(
    (keys: string) => {
      return new Promise<T[]>(res => {
        const searchResults = props.options.filter(hL => {
          return String(hL[props.searchProp]).toLowerCase().indexOf(keys.toLowerCase()) > -1;
        });
        res(searchResults);
      });
    },
    [props.options]
  );

  // close autocomplete list
  const closeList = useCallback(() => {
    setResults([]);
    setCurrentFocus(-1);
    updateValue('');
    setIsVisible(false);
  }, [setResults, setCurrentFocus, setIsVisible, updateValue]);

  const handleAutocompleteItemClick = useCallback(
    (selectedElement: T) => {
      updateValue(selectedElement[props.searchProp] as string);

      closeList();
      props.onItemClick(selectedElement);
    },
    [closeList]
  );

  function renderHighlightedText(text: string, highlight?: string) {
    if (!highlight) {
      return text;
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
            {part}
          </span>
        ))}
      </span>
    );
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateValue(value);
    setCurrentFocus(-1);
    filterSearchResults(value).then(res => {
      setNoResults(res.length === 0);
      setResults(res);
    });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (listRef.current) {
      const x = listRef.current.getElementsByTagName('div');
      if (e.code === 'ArrowDown') {
        //down
        setCurrentFocus(currentFocus + 1);
      } else if (e.code === 'ArrowUp') {
        // up
        setCurrentFocus(currentFocus - 1);
      } else if (e.code === 'Enter') {
        e.preventDefault();
        if (currentFocus > -1 && x) {
          handleAutocompleteItemClick(results[currentFocus]);
        }
      }
    }
  };

  const handleFocus = () => {
    isSidenavCollapsed && toggleSidenav();
    if (!noResults && !value) {
      setResults(props.options);
    }
  };

  const clearInput = () => {
    updateValue('');
    setNoResults(false);
    setResults(props.options);
    setCleared(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (!cleared) {
      props.loadDefaultValue(updateValue, setInitialValue);
    }

    function handleClickOutside({ target }: MouseEvent) {
      if (autocompleteRef.current && !autocompleteRef.current?.contains(target as Node)) {
        if (!value && cleared) {
          props.loadDefaultValue(updateValue, setInitialValue);
        }
        closeList();
      }
    }

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [closeList, props.loadDefaultValue, value, cleared]);

  const toggleAutocomplete = () => {
    if (isVisible) {
      closeList();
    } else {
      setIsVisible(true);
    }
  };

  const isLoading = props.loading;

  return (
    <div className="autocomplete" ref={autocompleteRef} onKeyDown={handleKeyDown} data-testid="autocomplete">
      <div className="autocomplete-btn-ctn">
        <button data-testid="current-value-button" className="autocomplete-current-value" onClick={toggleAutocomplete}>
          <div>{props.iconElement}</div>
          <span>{initialValue}</span>
          {isVisible ? <IconUp /> : <IconDown />}
        </button>
        {props.createElement}
      </div>
      {isVisible && (
        <div className="autocomplete-items" role="listbox" data-testid="autocomplete-items" ref={listRef}>
          <div className="autocomplete-search">
            <input
              className="autocomplete-input"
              ref={inputRef}
              placeholder={isLoading ? 'Loading...' : 'Find location'}
              aria-autocomplete="none"
              autoComplete="off"
              id="autocomplete-input"
              data-testid="autocomplete-input"
              autoFocus
              value={value}
              onChange={handleInputChange}
              type="text"
              onFocus={handleFocus}
            />
            {value && (
              <button
                className="button button_clear button_icon button_icon_light mb-1"
                aria-label="Clear location"
                type="button"
                onClick={clearInput}
              >
                <IconClear />
              </button>
            )}
          </div>
          {noResults && <div>No locations</div>}
          <div className="autocomplete-results">
            {results.map((result, idx) => (
              <div
                role="option"
                aria-selected={currentFocus === idx}
                className={classNames({
                  'autocomplete-active': currentFocus === idx,
                  'autocomplete-initial': result[props.searchProp] === initialValue,
                })}
                key={`autocomplete-item-${result.id}`}
                onClick={() => handleAutocompleteItemClick(result)}
              >
                <IconLocation />
                {renderHighlightedText(result[props.searchProp] as string, value)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Autocomplete;
