import { createContext, useContext, useReducer } from 'react';
import type { PropsWithChildren } from 'react';
import { doGet, doPost, reportingOrigin } from 'lib/utils';
import { AxiosResponse } from 'axios';

export interface Context {
  downloadActuals: (locationId: string) => Promise<any | undefined>;
  forecastMapping: Array<ForecastMappingSection>;
  generateForecastMapping: (locationId: string) => Promise<any | undefined>;
  generateForecasts: (locationId: string) => Promise<any | undefined>;
  getForecastMapping: (locationId: string) => Promise<any | undefined>;
  setForecastMapping: (forecastMapping: ForecastMappingSection[]) => Promise<any>;
  saveForecastMapping: (locationId: string, data: NestedForecastMappingSection[]) => Promise<any>;
}

enum ForecastsActionKind {
  SET_FORECAST_MAPPING = 'SET_FORECAST_MAPPING',
}

type ForecastsAction = {
  type: ForecastsActionKind.SET_FORECAST_MAPPING;
  payload: ForecastMappingSection[];
};

interface ForecastsState {
  forecastMapping: Array<ForecastMappingSection>;
}

const initialState: ForecastsState = {
  forecastMapping: [],
};

function forecastsReducer(state: ForecastsState, action: ForecastsAction): ForecastsState {
  switch (action.type) {
    case ForecastsActionKind.SET_FORECAST_MAPPING:
      return {
        ...state,
        forecastMapping: action.payload,
      };
    default:
      return state;
  }
}

const HoneForecastsContext = createContext<Context | undefined>(undefined);

export default function HoneForecastsProvider(props: PropsWithChildren<unknown>): JSX.Element {
  const [state, dispatch] = useReducer(forecastsReducer, initialState);
  const { forecastMapping } = state;

  const downloadActuals = (locationId: string): Promise<AxiosResponse<any>> => {
    return doPost(`${reportingOrigin()}/downloadActuals`, { locationId });
  };

  const generateForecasts = (locationId: string): Promise<AxiosResponse<any>> => {
    return doPost(`${reportingOrigin()}/generateForecasts`, { locationId });
  };

  const getForecastMapping = (locationId: string): Promise<AxiosResponse<any>> => {
    return doGet(`${reportingOrigin()}/getForecastMapping?locationId=${locationId}`);
  };

  const setForecastMapping = async (forecastMapping: ForecastMappingSection[]) => {
    dispatch({ type: ForecastsActionKind.SET_FORECAST_MAPPING, payload: forecastMapping });
  };

  const generateForecastMapping = (locationId: string): Promise<AxiosResponse<any>> => {
    return doPost(`${reportingOrigin()}/generateForecastMapping`, { locationId });
  };

  const saveForecastMapping = (
    locationId: string,
    data: NestedForecastMappingSection[]
  ): Promise<AxiosResponse<any>> => {
    return doPost(`${reportingOrigin()}/updateForecastMapping`, { locationId, data });
  };

  return (
    <HoneForecastsContext.Provider
      value={{
        downloadActuals,
        forecastMapping,
        generateForecastMapping,
        generateForecasts,
        getForecastMapping,
        setForecastMapping,
        saveForecastMapping,
      }}
      {...props}
    />
  );
}

export function useHoneForecasts(): Context {
  const forecasts = useContext(HoneForecastsContext);
  if (!forecasts) throw new Error('You must call useHoneForecasts from within HoneForecastsProvider tree');
  return forecasts;
}
