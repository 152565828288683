import React, { createContext, useContext, useMemo, useState } from 'react';

type cellStates = {
  hover: string | null;
  selected: string | null;
};

const NTSelectionContext = createContext<{
  cellStates: cellStates;
  setCellStates: (cellStates: cellStates) => void;
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  cellStates: {
    hover: null,
    selected: null,
  },
  setCellStates: () => {},

  isScrolling: false,
  setIsScrolling: () => {},
});

export const useNTSelectionContext = () => useContext(NTSelectionContext);

export const NTSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cellStates, setCellStates] = useState<cellStates>({
    hover: null,
    selected: null,
  });
  const [isScrolling, setIsScrolling] = useState(false);

  const value = useMemo(
    () => ({ cellStates, setCellStates, isScrolling, setIsScrolling }),
    [cellStates, setCellStates, isScrolling, setIsScrolling]
  );

  return <NTSelectionContext.Provider value={value}>{children}</NTSelectionContext.Provider>;
};
