import { GetReportsConfig } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';
import { HttpErrorResponse, HttpStatusCode } from '../../protocols/http';

export class RemoteGetReportsConfig extends BaseHttpService<GetReportsConfig.Model> implements GetReportsConfig {
  async getConfig(params: GetReportsConfig.Params): Promise<GetReportsConfig.Model> {
    try {
      return await this.request('get', {}, params);
    } catch (err) {
      // 404 treated as if an empty object was returned
      if ((err as HttpErrorResponse)?.statusCode === HttpStatusCode.notFound) {
        return { locationId: params.locationId, data: { periodReporting: false } } as any;
      }
      throw err;
    }
  }
}
