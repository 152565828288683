import TableCell from './TableCell';
import { FooterCell, Header, HighlighRow, TableCellValue } from './types';
import TableHeader from './TableHeader';
import classNames from 'classnames';
import TableFooterCell from './TableFooterCell';
import React, { useEffect, useState } from 'react';
import { IconAdd, IconArrowUp, IconArrowDown } from 'components/Icons';

type HoneTableProps<T> = {
  data: T[];
  headers: Header[];
  enabledRowCounter?: boolean;
  enableOddBackground?: boolean;
  hightLightRow?: HighlighRow;
  handleClickRow?: (rowData: T, idxRow: number) => void;
  footerData?: FooterCell[];
  containerHeight?: string;
  onChange?: (cellData: TableCellValue) => void;

  handleClickOrderUp?: (idx: number) => void;
  handleClickOrderDown?: (idx: number) => void;
  handleDeleteRow?: (idx: number) => void;
  canAddNewRow?: boolean;
  childrenAddNewRow?: React.ReactNode;
};

export function HoneTable<T>({
  data: tableData,
  headers,
  enabledRowCounter = false,
  enableOddBackground = false,
  hightLightRow,
  footerData,
  containerHeight = '600px',
  canAddNewRow,
  childrenAddNewRow,
  onChange,
  handleClickRow,
  handleDeleteRow,
  handleClickOrderUp,
  handleClickOrderDown,
}: HoneTableProps<T>) {
  return (
    <>
      <div
        className="hone-table-container"
        style={{ height: containerHeight, overflowY: containerHeight ? 'auto' : 'hidden' }}
      >
        <table className="hone-table-content">
          <thead>
            <tr className="hone-table-header-row">
              {enabledRowCounter && <th className="hone-table-header-row-idx">#</th>}
              {headers.map((header: Header, idx: number) => (
                <TableHeader header={header} key={idx} />
              ))}
            </tr>
          </thead>
          <tbody className="hone-table-body">
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item: T, idxRow: number) => {
                const objectKeys = Object.keys(item as any);
                const isRowSelected =
                  !!hightLightRow && hightLightRow.idx !== undefined && idxRow === hightLightRow.idx;
                return (
                  <tr
                    key={idxRow}
                    className={classNames('hone-table-body-row', {
                      'hone-table-body-row-firts-item': idxRow === 0,
                      'hone-table-body-row-odd': enableOddBackground && idxRow % 2 === 0,
                      'hone-table-body-row-clickable': !!handleClickRow,
                      [`hone-table-body-row-${hightLightRow?.type}`]: isRowSelected,
                    })}
                    onClick={() => handleClickRow && handleClickRow(item, idxRow)}
                  >
                    {enabledRowCounter && (
                      <td align="center" className={classNames('hone-table-body-row-idx')}>
                        <div
                          className={classNames('hone-table-body-row-idx-ordering', { 'is-selected': isRowSelected })}
                        >
                          {isRowSelected && (
                            <>
                              <button
                                className="hone-table-body-row-idx-ordering up"
                                onClick={e => {
                                  e.preventDefault();
                                  handleClickOrderUp && handleClickOrderUp(idxRow);
                                }}
                              >
                                <IconArrowUp />
                              </button>
                              <button
                                className="hone-table-body-row-idx-ordering down"
                                onClick={e => {
                                  e.preventDefault();
                                  handleClickOrderDown && handleClickOrderDown(idxRow);
                                }}
                              >
                                <IconArrowDown />
                              </button>
                            </>
                          )}
                        </div>
                        <div>{idxRow + 1}</div>
                      </td>
                    )}
                    {objectKeys.map((key: any, idxCol: number) => {
                      const value = (item as any)[key];
                      const shouldHide = headers.some((header: Header) => header.hidden && header.key === key);
                      const shouldHaveBorder = headers.some((header: Header) => header.key === key && header.hasBorder);
                      const contentStyles = headers.find((header: Header) => header.key === key)?.contentStyles;
                      const isEditable = headers.find((header: Header) => header.key === key)?.editable;
                      const cellType = headers.find((header: Header) => header.key === key)?.cellType;
                      return (
                        <TableCell<T>
                          rowData={value}
                          key={`${idxCol}-${key}`}
                          shouldHide={shouldHide}
                          shouldHaveBorder={shouldHaveBorder}
                          contentStyles={contentStyles as React.CSSProperties}
                          isLastCell={idxCol === Object.keys(item as any).length - 1 ? true : false}
                          handleDeleteRow={handleDeleteRow && (() => handleDeleteRow(idxRow))}
                          isRowSelected={isRowSelected}
                          isEditable={isEditable}
                          cellType={cellType}
                          onChange={(val: string) => {
                            const cellValue = {
                              row: idxRow,
                              col: idxCol,
                              key: key,
                              value: val,
                            };
                            onChange && onChange(cellValue);
                          }}
                        />
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {canAddNewRow && <div className="hone-table-add-new-row">{childrenAddNewRow}</div>}
        <div className="hone-table-footer">
          {footerData &&
            footerData.map((footer: FooterCell, idx: number) => {
              const footerCellStyles = headers.find((header: Header) => header.key === footer.key)?.footerStyles;
              const shouldHide = headers.some((header: Header) => header.hidden && header.key === footer.key);
              return (
                <TableFooterCell
                  footerCellValue={footer.value}
                  footerCellStyles={footerCellStyles as React.CSSProperties}
                  shouldHide={shouldHide}
                  key={idx}
                />
              );
            })}
        </div>
      </div>

      {/* Footer */}
    </>
  );
}

export default HoneTable;
