import { accounts } from '../NTFile';

/**
 * Recursively hides or shows the children of a parent cell based on the collapse status.
 *
 * @param parentId - The ID of the parent cell.
 * @param collapseStatus - The collapse status, true to hide children, false to show children.
 */

const hideChildren = (parentId: string, collapseStatus: boolean, allColumn: accounts[]) => {
  // We are always collpsing from first column, so we locate  the parent cell: first column always end with _-x-_0
  const parentCellId = `${parentId}_-x-_0`;
  const parentCell = document.querySelector(`[data-cell-id="${parentCellId}"]`);

  if (!parentCell) {
    /* console.log('Parent cell not found', parentId); */
    return; // Exit if parent cell is not found
  }

  const parentData = allColumn.find((item: { id: string }) => item.id === parentId);
  const parentCollapseStatus = parentCell.getAttribute('data-collapsed') === 'true';
  // We iterate over the children of the parent cell and toggle their visibility
  parentData?.children?.forEach((child: string) => {
    const elements = document.querySelectorAll(`[data-row-id="${child}"]`);
    elements.forEach(el => {
      if (collapseStatus) {
        el.classList.add('hidden');
      } else {
        // If parent status is collapsed, hide all children
        if (parentCollapseStatus) {
          el.classList.add('hidden');
        } else {
          // If parent status is expanded,toggle visibility to preserve the status
          el.classList.remove('hidden');
        }
      }
    });

    // Recursively update children of this child
    hideChildren(child, collapseStatus, allColumn);
  });
};

export const handleCollapseRow = (params: {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  rowID: string;
  cellDataID: string;
  allColumn: accounts[];
  forceInitialState?: boolean;
}) => {
  const { e, rowID, cellDataID, allColumn, forceInitialState } = params;
  e.preventDefault();
  e.stopPropagation();
  const parentCellId = `${rowID}_-x-_0`;
  const parentCell = document.querySelector(`[data-cell-id="${parentCellId}"]`);
  const collapseControl = document.querySelector(`[data-collapse-control="${rowID}"]`);
  // If collapseControl exists, toggle its class
  if (collapseControl) collapseControl.classList.toggle('collapsed');

  // If parentCell exists, change the visibility of all of its children
  if (parentCell) {
    const collapseStatus = parentCell.getAttribute('data-collapsed') === 'true';
    parentCell.setAttribute('data-collapsed', (!collapseStatus).toString());
    hideChildren(cellDataID, forceInitialState ? forceInitialState : !collapseStatus, allColumn);
  }
};

export const handleCollapseAll = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  rowID: string,
  allColumn: accounts[]
) => {
  e.preventDefault();
  e.stopPropagation();
  const collapseControl = document.querySelector(`[data-collapse-control="${rowID}"]`);
  // If collapseControl exists, toggle its class
  if (collapseControl) collapseControl.classList.toggle('collapsed');

  // Find the lowest level in allColumn
  const lowestLevel = Math.min(...allColumn.map((item: { level: number }) => item.level));

  // Find all items with the lowest level
  const lowestLevelItems = allColumn.filter((item: { level: number }) => item.level === lowestLevel);

  // Apply hideChildren function to all lowest level items
  lowestLevelItems.forEach((item: { id: string }) => {
    if (collapseControl) {
      handleCollapseRow({
        e,
        rowID: item.id,
        cellDataID: item.id,
        allColumn,
        forceInitialState: collapseControl.classList.contains('collapsed'),
      });
    }
  });
};
