import { RefreshSlackChannels } from 'domain/usecases/slack/refresh-slack-channels';
import { BaseHttpService } from 'main/factories/http';

export class RemoteRefreshSlackChannels
  extends BaseHttpService<RefreshSlackChannels.Model>
  implements RefreshSlackChannels
{
  async refreshSlackChannels(): Promise<RefreshSlackChannels.Model> {
    return await this.request('post', {});
  }
}
