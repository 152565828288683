import { IconMenu } from 'components/Icons';
import './TopNavigationBar.css';

import { useSidenavStore } from 'hooks/useSidenavStore';
import LayoutHeader from '../LayoutHeader/LayoutHeader';

function TopNavigationBar(): JSX.Element {
  const { toggleSidenav } = useSidenavStore();

  return (
    <div className="TopNavigationBar">
      <div className="TopNavigationBar-indicators TopNavigationBar-indicators_links">
        <button
          className="TopNavigationBar-button TopNavigationBar-largeHidden d-print-none"
          onClick={toggleSidenav}
          type="button"
        >
          <span className="visually-hidden">Toggle menu</span>
          <IconMenu />
        </button>
        <LayoutHeader />
      </div>
    </div>
  );
}

export default TopNavigationBar;
