import { ReactNode, useState } from 'react';

import { IconQbo } from 'components/Icons';
import useQboAccountsQuery from '../../../hooks/useQboAccountsQuery';

const text =
  'In order to properly set up the CoA, you must first either connect the QBO account or import an Excel file containing the CoA';
const text1 = 'Importing CoA ...';
const button = 'CONNECT TO QBO';

const QboConnection = (): ReactNode => {
  const [doRequest, setDoRequest] = useState<boolean>(true);

  const { isLoading: loading, refetch } = useQboAccountsQuery(doRequest);

  const handleClick = () => {
    refetch();
  };

  return (
    <div className={'qbo-content-container'}>
      <div className={'qbo-content'}>
        <div className="qbo-big-icon">
          <IconQbo />
        </div>
        <p className={'qbo-text-info'}>{!loading ? text : text1}</p>
        {!loading && (
          <button className={'btn-qbo-action'} onClick={handleClick}>
            {button}
          </button>
        )}
      </div>
    </div>
  );
};

export default QboConnection;
