import { GetFindMonthlyDataRows } from 'domain/usecases/reports';
import { BaseHttpService } from 'main/factories/http';

export class RemoteFindMonthlyDataRows
  extends BaseHttpService<GetFindMonthlyDataRows.Model>
  implements GetFindMonthlyDataRows
{
  async findMonthlyDataRows(params: GetFindMonthlyDataRows.Params): Promise<GetFindMonthlyDataRows.Model> {
    return await this.request('get', {}, params);
  }
}
