import React, { ChangeEvent } from 'react';
import ForecastSliderInput from '../ForecastSliderInput';
import { currencyFormatter } from '../../../../../lib/utils';
import ForecastInput from '../ForecastInput';
import classNames from 'classnames';
import ForecastTableRow from './ForecastTableRow';
import { shallow } from 'zustand/shallow';
import {
  handleNetSalesAmountChange,
  handleNetSalesPercentageChange,
  handleRevenueAmountChange,
  useForecastUIStore,
} from 'hooks/useForecastUIStore';

const categoriesColor = ['blue', 'teal', 'turquoise', 'purple', 'orange', 'darkerblue', 'red'];

function ForecastTableBody(): JSX.Element {
  const { report, activeType, activeCategory } = useForecastUIStore(
    state => ({ report: state.roundedReport, activeType: state.activeType, activeCategory: state.activeCategory }),
    shallow
  );

  const onNetSalesAmountChange = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>): void => {
    handleNetSalesAmountChange(newValue.replace('$', ''));
  };

  const onRevenueAmountChange = ({ target: { value: newValue, name } }: ChangeEvent<HTMLInputElement>): void => {
    handleRevenueAmountChange(newValue, name);
  };

  const handleClearInput = () => {
    //todo
  };

  if (!report) {
    return <div />;
  }

  return (
    <>
      <tr className="main">
        <td>
          <span className="title-box collapsable">Total Sales</span>
        </td>
        <td>
          <ForecastSliderInput
            onChange={handleNetSalesPercentageChange}
            value={report['net-sales'].goalPerc}
            name="report['net-sales'].goalPerc"
          />
        </td>
        <td className="forecast align-right">{currencyFormatter(report['net-sales'].amount)}</td>
        <td className="goal">
          <ForecastInput
            onClear={handleClearInput}
            onChange={onNetSalesAmountChange}
            value={report['net-sales'].goalAmount}
            name="report['net-sales'].goalAmount"
          />
        </td>
        <td />
      </tr>
      <tr className="expandable header">
        <td colSpan={3}>Sales Categories</td>
      </tr>
      {Object.values(report.revenue)
        .filter((section: any) => {
          return section.title !== 'Net Sales';
        })
        .map((section: any, idx) => {
          return (
            <tr key={section.title} className="expandable content">
              <td colSpan={2}>
                <div
                  className={classNames('box sales', {
                    active: activeCategory === section.title,
                    [categoriesColor[idx + 1]]: activeType === 'Revenue',
                    gray: activeType === 'Expense',
                  })}
                >
                  <div>{section.title}</div>
                  <div>
                    {Number(section.goalPerc).toFixed(1)}% of {section.goalPercTag}
                  </div>
                </div>
              </td>
              <td className="align-right">
                <div>{currencyFormatter(section.amount)}</div>
              </td>
              <td className="goal">
                <div>
                  <ForecastInput
                    onClear={handleClearInput}
                    onChange={onRevenueAmountChange}
                    value={section.goalAmount}
                    name={`revenue[${idx}].goalAmount`}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      <tr className="expandable header">
        <td>Costs & Expenses</td>
        <td />
        <td>Projected</td>
        <td>Budgets</td>
      </tr>
      {Object.values(report.expenses).map((section: any, index) => (
        <ForecastTableRow
          key={section.title}
          section={section}
          prefix={`expenses[${index}]`}
          active={section.title === activeCategory}
          color={activeType === 'Expense' ? categoriesColor[index + 1] : 'gray'}
        />
      ))}
    </>
  );
}

export default ForecastTableBody;
