import { AskRequestStatus } from '@hone-automation/common';

import { IconCheck, IconClose, IconDown } from 'components/Icons';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { usePatchFlaggedTransactionMutation } from 'hooks/flaggedTransactions/usePatchFlaggedTransactionMutation';
import { useQueryState } from 'hooks/useQueryState';
import { useParams } from 'react-router-dom';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { shallow } from 'zustand/shallow';
import classNames from 'classnames';
import { useRef, useState } from 'react';

type PropsType = {
  txId?: string | undefined;
  isSideView?: boolean;
};
export default function ChatMarkAsSolvedButton({ txId, isSideView }: PropsType) {
  const [active, setActive] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const { locationId } = useParams();
  const [activeTab] = useQueryState<AskRequestStatus>('activeTab');

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { setActiveTransaction, transactionBeingFlagged, setTransactionBeingFlagged, setAnimateTransaction } =
    useFlaggedTransactions(
      state => ({
        setActiveTransaction: state.setActiveTransaction,
        setTransactionBeingFlagged: state.setTransactionBeingFlagged,
        setAnimateTransaction: state.setAnimatedTransaction,
        transactionBeingFlagged: state.transactionBeingFlagged,
      }),
      shallow
    );

  const { isPending, mutate } = usePatchFlaggedTransactionMutation(
    locationId!,
    activeTab,
    (trx: any | undefined) => {
      isSideView ? setActiveTransaction(trx) : setTransactionBeingFlagged({ ...transactionBeingFlagged, flagged: trx });
    },
    comment
  );

  const markAsSolved = async () => {
    setAnimateTransaction('Closed');
    mutate({ txId, status: 'Closed' });
  };

  const toggleDialog = () => {
    setActive(active => !active);
    setDirty(true);
    textAreaRef.current?.focus();
    setTimeout(() => {
      setComment('');
    }, 500);
  };

  return (
    <div className="resolve-wrapper">
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Close Ask Request, if it is resolved</Tooltip>}
      >
        <div className="resolved-button-wrapper">
          <button className={classNames('chat-input__mask-resolved')} type="button" onClick={markAsSolved}>
            {isPending ? (
              <>
                Loading...
                <i className="fa fa-circle-o-notch fa-spin" />{' '}
              </>
            ) : (
              <>
                <IconCheck />
                MARK AS RESOLVED
              </>
            )}
          </button>
          <div className="div" />
          <button className={classNames('toggle-modal-button', { active })} onClick={toggleDialog}>
            <IconDown />
          </button>
        </div>
      </Whisper>
      {active && (
        <div className={classNames('resolved-dialog', { active, inactive: dirty && !active })}>
          <div className="comment">
            <textarea
              ref={textAreaRef}
              value={comment}
              onChange={e => setComment(e.target.value)}
              autoFocus={active}
              placeholder="Add a closing comment."
            />
          </div>
          <div className="actions">
            <button onClick={toggleDialog}>
              <IconClose />
              Cancel
            </button>
            <button onClick={markAsSolved}>
              <IconCheck />
              MARK AS RESOLVED
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
