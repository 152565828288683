import { prefixClass } from 'lib/utils';
import './SFLiveReport.scss';
import { Whisper, Tooltip } from 'rsuite';
import { getTimeframePeriod } from 'presentation/utils';

type SFLiveReportProps = {
  timeFrame: HoneReportTimeframe | null;
};

const SFLiveReport = ({ timeFrame }: SFLiveReportProps) => {
  const enabled = timeFrame;
  const prefix = prefixClass('sf-live-report');
  const label = 'Live Report';
  const message = `Some data for current ${timeFrame && getTimeframePeriod(timeFrame).toLowerCase()} will likely
          change or be added as we receive it.`;
  return (
    <>
      {enabled && (
        <div className={prefix()}>
          <Whisper placement="right" trigger="hover" speaker={<Tooltip>{message}</Tooltip>} delayOpen={600}>
            <span className={prefix('label')}>{label}</span>
          </Whisper>
        </div>
      )}
    </>
  );
};

export default SFLiveReport;
