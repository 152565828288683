import { ReactNode } from 'react';

interface ChartAreaProps {
  children: ReactNode;
}

function ChartArea({ children }: ChartAreaProps): ReactNode {
  return <div className="chart_area chart_area_v2">{children}</div>;
}

export default ChartArea;
