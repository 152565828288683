import './KSMLMLocationGroup.scss';
import { prefixClass } from 'lib/utils';
import { KSMLocation } from '../KSMultipleLocationModal';
import KSMLMLocationItem from '../KSMLMLocationItem';

export interface KSMLMGroup {
  org: string;
  locations: KSMLocation[];
}

type KSMLMLocationGroupProps = {
  groupLocation: KSMLMGroup;
  onLocationSelect?: (location: KSMLocation) => void;
};

const KSMLMLocationGroup = ({ groupLocation, onLocationSelect }: KSMLMLocationGroupProps) => {
  const prefix = prefixClass('ks-mlm-location-group');
  return (
    <div className={prefix()}>
      <div className={prefix('org')}>{groupLocation.org}</div>
      <div className={prefix('list')}>
        {groupLocation.locations.map((location, index) => {
          return <KSMLMLocationItem key={index} location={location} onLocationSelect={onLocationSelect} />;
        })}
      </div>
    </div>
  );
};

export default KSMLMLocationGroup;
