import React, { ChangeEvent, createRef, MutableRefObject, useRef, useState, useEffect } from 'react';
import { IconChevron, IconChevronDown } from 'components/Icons';
import KSListItem from '../KSListItem';
import './KSLocationModal.scss';
import KSListTitle from '../KSListTitle/KSListTitle';
import classNames from 'classnames';

import { useClickAway } from 'react-use';
import KSMLMSearch from '../KSMultipleLocationModal/KSMLMSearch';
import KSCheckBox from '../KSCheckBox';

interface KSLocationModalProps {
  activeLocation?: string;
  selectedLocations?: HoneLocationUser[];
  isOpen: boolean;
  recentLocations: HoneLocationUser[];
  locations: HoneLocationUser[];
  onLocationChange?: (locationId: string) => void;
  onAddToRecentLocations?: (locationId: string) => void;
  onRemoveFromRecentLocations?: (locationId: string) => void;
  showTabLocations?: boolean;
  handleClose: () => void;
  preventClickAway?: boolean;
}

function KSLocationModal({
  isOpen,
  handleClose,
  locations,
  recentLocations,
  activeLocation,
  onLocationChange,
  onAddToRecentLocations,
  onRemoveFromRecentLocations,
  showTabLocations = false,
  preventClickAway = false,
}: KSLocationModalProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [displayTabLocations, setDisplayTabLocations] = useState(showTabLocations);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [collapsePinnedLocations, setCollapsePinnedLocations] = useState(false);
  const [groupedLocations, setGroupedLocations] = useState<Map<string, HoneLocationUser[]>>(new Map());

  const modalRef = useRef<HTMLDivElement>(null);

  useClickAway({ current: preventClickAway ? null : modalRef?.current }, handleClose);

  const groupByOrganization = (data: HoneLocationUser[]) => {
    return data.reduce((acc, item) => {
      const key = item.org;
      if (!acc.has(key)) {
        acc.set(key, []);
      }
      acc.get(key).push(item);
      return acc;
    }, new Map());
  };

  const handleShowTabLocations = () => {
    setDisplayTabLocations(d => !d);
  };

  useEffect(() => {
    const tempLocations = locations.filter(location => {
      const recent = recentLocations.some(recentLocation => recentLocation.locationId === location.locationId);
      if (!recent) {
        return searchQuery
          ? location.locationName.toLowerCase().includes(searchQuery) ||
              location.org?.toLowerCase().includes(searchQuery)
          : true;
      }
    });
    setFilteredLocations(tempLocations);
    setGroupedLocations(groupByOrganization(tempLocations));
  }, [locations, recentLocations, searchQuery]);

  const handleClick = (locationId: string | null | undefined) => {
    locationId && onLocationChange?.(locationId);
  };

  const handleAddToRecentLocations = (locationId: string | null | undefined) => {
    locationId && onAddToRecentLocations?.(locationId);
  };

  const handleRemoveFromRecentLocations = (locationId: string | null | undefined) => {
    locationId && onRemoveFromRecentLocations?.(locationId);
  };

  const handleCollapsePinnedLocations = () => {
    setCollapsePinnedLocations(d => !d);
  };

  return (
    <div ref={modalRef} className={classNames('ks-select', { open: isOpen, single: true })}>
      {/* Search Component */}
      <KSMLMSearch
        searchValue={searchQuery}
        setSearchValue={setSearchQuery}
        handleClose={handleClose}
        placeholder="Search Location"
      />
      {/* Pinned Locations */}
      <div className={classNames('ks-select-recent-locations', { collapsed: collapsePinnedLocations })}>
        <div className="ks-select-recent-locations-control" onClick={handleCollapsePinnedLocations}>
          <div className="ks-select-recent-locations-control-icon">
            {collapsePinnedLocations ? <IconChevron /> : <IconChevronDown />}
          </div>
          <div className="ks-select-recent-locations-control-title">Pinned Locations ({recentLocations.length})</div>
        </div>
        <div className={classNames('ks-select-recent-locations-list', { collapsed: collapsePinnedLocations })}>
          {recentLocations.length > 0 &&
            recentLocations.map(location => {
              const isActive = location.locationId === activeLocation;
              const isRecent = recentLocations?.some(item => item.locationId === location.locationId);
              return (
                <KSListItem
                  id={location.locationId}
                  onClickListItem={handleClick}
                  onAddListItem={handleAddToRecentLocations}
                  onRemoveListItem={handleRemoveFromRecentLocations}
                  isSelected={!!isActive}
                  isRecent={isRecent}
                  isLocation
                  key={location.locationId}
                  label={location.locationName}
                />
              );
            })}
        </div>
      </div>
      {/* Available Locations */}
      <div className="ks-select-items">
        {filteredLocations.length > 0 ? (
          <>
            {Array.from([...groupedLocations.entries()]).map(([organization, units], index) => (
              <div key={index + organization}>
                <KSListTitle>{organization}</KSListTitle>
                <div>
                  {units.map((unit: HoneLocationUser) => {
                    const isActive = unit.locationId === activeLocation;
                    const isRecent = recentLocations?.some(item => item.locationId === unit.locationId);
                    return (
                      <KSListItem
                        id={unit.locationId}
                        onClickListItem={handleClick}
                        onAddListItem={handleAddToRecentLocations}
                        onRemoveListItem={handleRemoveFromRecentLocations}
                        isSelected={!!isActive}
                        isRecent={isRecent}
                        isLocation
                        key={unit.locationId}
                        label={unit.locationName}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="ks-select-no-results">
            {searchQuery ? (
              <span>
                No results found for <b>"{searchQuery}"</b>
              </span>
            ) : (
              <span>No locations detected</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default KSLocationModal;
