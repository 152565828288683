import React, { useRef, useEffect, useState } from 'react';
import './NTChart.scss';
import { prefixClass } from 'lib/utils';
import { NTToolTip } from '../NTToolTip';
import NTTooltipProps from '../NTToolTip/NTToolTip';
import { useNTSelectionContext } from '../NTSelectionContext';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { TransformedData } from '../../KSExport/reportNTParser/types';

// types
export type ChartDisplayType = {
  money: boolean;
  percent: boolean;
};
export type chartGroup = 'Actual' | 'Budget' | 'Variance';
export type ChartDataElement = {
  actual: number;
  budget: number;
  variance: number;
};

export type ChartDataColumn = {
  name: string;
  data: {
    money: ChartDataElement;
    percent: ChartDataElement;
  };
};

export type ChartProps = {
  id: string;
  display: ChartDisplayType;
  budget: boolean;
  groups: chartGroup[];
  columns: ChartDataColumn[];
};

export type ChartObject = { [key: string]: ChartProps };

// Helper functions
const calculateNiceScale = (min: number, max: number, maxTicks: number) => {
  const range = niceNum(max - min, false);
  const tickSpacing = niceNum(range / (maxTicks - 1), true);
  const niceMin = Math.floor(min / tickSpacing) * tickSpacing;
  const niceMax = Math.ceil(max / tickSpacing) * tickSpacing;
  return { min: niceMin, max: niceMax, tickSpacing };
};

const niceNum = (range: number, round: boolean) => {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (round) {
    if (fraction < 1.5) niceFraction = 1;
    else if (fraction < 3) niceFraction = 2;
    else if (fraction < 7) niceFraction = 5;
    else niceFraction = 10;
  } else {
    if (fraction <= 1) niceFraction = 1;
    else if (fraction <= 2) niceFraction = 2;
    else if (fraction <= 5) niceFraction = 5;
    else niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

const drawGridAndLabels = (
  ctx: CanvasRenderingContext2D,
  display: ChartDisplayType,
  chartWidth: number,
  chartHeight: number,
  margin: { left: number; right: number; top: number; bottom: number },
  yMoneyScale: { min: number; max: number },
  yPercentScale: { min: number; max: number },
  maxTicks: number
) => {
  const width = chartWidth + margin.left + margin.right;
  const height = chartHeight + margin.top + margin.bottom;

  /*   // Draw axes
  ctx.beginPath();
  //ctx.moveTo(margin.left, margin.top);
  ctx.lineTo(margin.left, height - margin.bottom);
  ctx.lineTo(width - margin.right, height - margin.bottom);
  ctx.stroke(); */

  // Draw grid lines and labels
  ctx.textAlign = 'right';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = 'black';
  ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';

  ctx.lineWidth = 1; // Set line width to 1 pixels
  ctx.setLineDash([]); // Set solid line pattern

  const moneyTicks = maxTicks;
  const percentTicks = maxTicks;

  // Money axis
  if (display.money) {
    for (let i = 0; i < moneyTicks; i++) {
      const value = yMoneyScale.min + (i / (moneyTicks - 1)) * (yMoneyScale.max - yMoneyScale.min);
      const y =
        height - margin.bottom - ((value - yMoneyScale.min) / (yMoneyScale.max - yMoneyScale.min)) * chartHeight;

      // Grid line
      ctx.beginPath();
      ctx.moveTo(margin.left, y);
      ctx.lineTo(width - margin.right, y);
      ctx.stroke();

      // Money label (left)
      ctx.fillText(`${value.toLocaleString()}`, margin.left - 10, y);
    }
  }

  // Percentage axis
  if (display.percent) {
    for (let i = 0; i < percentTicks; i++) {
      const value = yPercentScale.min + (i / (percentTicks - 1)) * (yPercentScale.max - yPercentScale.min);
      const y =
        height - margin.bottom - ((value - yPercentScale.min) / (yPercentScale.max - yPercentScale.min)) * chartHeight;

      // Percentage label (right)
      ctx.textAlign = 'right';
      ctx.fillText(`${(value * 100).toFixed(1)}%`, width - margin.right + 50, y);
    }
  }
};

const drawChart = ({
  canvasRef,
  dimensions,
  chartData,
  font,
  useAngledLabels,
  chartFilters,
}: {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  dimensions: { width: number; height: number };
  chartData: ChartProps;
  font: string;
  useAngledLabels: boolean;
  chartFilters: {
    groups: chartGroup[];
    money: boolean;
    percent: boolean;
  };
}) => {
  const canvas = canvasRef.current;
  if (!canvas) return;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const { groups, money, percent } = chartFilters;
  console.log('filtering', chartFilters);

  const maxTicks = 5;
  // Clear the canvas
  ctx.clearRect(0, 0, dimensions.width, dimensions.height);

  // Set font
  ctx.font = `14px ${font}`; // Changed font size to 14px

  // Define chart area
  const margin = { top: 20, right: 60, bottom: 60, left: 60 };
  const chartWidth = dimensions.width - margin.left - margin.right;
  const chartHeight = dimensions.height - margin.top - margin.bottom;

  if (!chartData.columns || chartData.columns.length === 0) {
    // Display a message if no data
    ctx.font = `10px ${font}`;
    ctx.fillStyle = 'black';
    ctx.fillText('No data available', dimensions.width / 2 - 50, dimensions.height / 2);
    return;
  }

  // Find min and max values for scaling
  const moneyValues = chartData.columns.flatMap((col: ChartDataColumn) =>
    groups.map(group => col.data.money[group.toLowerCase() as keyof typeof col.data.money])
  );
  const minMoney = Math.min(0, ...moneyValues);
  const maxMoney = Math.max(0, ...moneyValues);
  const percentValues = chartData.columns.flatMap((col: ChartDataColumn) =>
    groups.map(group => col.data.percent[group.toLowerCase() as keyof typeof col.data.percent])
  );
  const minPercent = Math.min(0, ...percentValues);
  const maxPercent = Math.max(0, ...percentValues);

  // Calculate nice round numbers for Y-axis labels
  const yMoneyScale = calculateNiceScale(minMoney, maxMoney, maxTicks * 2);
  const yPercentScale = calculateNiceScale(minPercent, maxPercent, maxTicks * 2);

  // Calculate bar dimensions
  const groupGap = 8;
  const barGap = 0;
  const groupWidth = (chartWidth - (chartData.columns.length - 1) * groupGap) / chartData.columns.length;
  const barWidth = (groupWidth - 2 * barGap) / groups.length;

  // Determine if labels need to be hidden
  const labelToCheckWidth = chartData.columns[0].name;
  ctx.font = `10px ${font}`;
  const labelWidth = ctx.measureText(labelToCheckWidth).width;
  const shouldHideAlternateLabels = !useAngledLabels && labelWidth > groupWidth;

  // Calculate the y-coordinate for the zero line
  const zeroLineY =
    dimensions.height - margin.bottom - ((0 - yMoneyScale.min) / (yMoneyScale.max - yMoneyScale.min)) * chartHeight;

  // Draw zero line
  ctx.beginPath();
  ctx.moveTo(margin.left, zeroLineY);
  ctx.lineTo(dimensions.width - margin.right, zeroLineY);
  ctx.strokeStyle = 'black';
  ctx.stroke();
  // Draw bars and lines
  chartData.columns.forEach((column: ChartDataColumn, index: number) => {
    const x = margin.left + index * (groupWidth + groupGap);

    // Money bars
    if (money) {
      groups.forEach((type, typeIndex) => {
        const value = column.data.money[type.toLowerCase() as keyof typeof column.data.money];
        const barHeight = (Math.abs(value) / (yMoneyScale.max - yMoneyScale.min)) * chartHeight;
        const barX = x + typeIndex * (barWidth + barGap);
        const barY = value >= 0 ? zeroLineY - barHeight : zeroLineY;

        ctx.fillStyle =
          type === 'Actual'
            ? 'rgba(0, 128, 0, 0.3)'
            : type === 'Budget'
              ? 'rgba(0, 0, 255, 0.3)'
              : 'rgba(255, 0, 0, 0.3)';
        ctx.fillRect(barX, barY, barWidth, barHeight);
      });
    }

    // Percentage line
    if (percent) {
      const colors = [];
      groups.forEach((type, typeIndex) => {
        const ccolor =
          type === 'Actual' ? 'rgba(0, 128, 0, 1)' : type === 'Budget' ? 'rgba(0, 0, 255, 1)' : 'rgba(255, 0, 0, 1)';

        const denominator = yPercentScale.max - yPercentScale.min;
        const lastValue = index < chartData.columns.length - 1;
        const percentValue = column.data.percent[type.toLowerCase() as keyof typeof column.data.percent];

        const percentEndValue = lastValue
          ? chartData.columns[index + 1].data.percent[type.toLowerCase() as keyof typeof column.data.percent]
          : percentValue;

        const actualStartY = zeroLineY - (percentValue / denominator) * chartHeight;
        const actualEndY = lastValue ? zeroLineY - (percentEndValue / denominator) * chartHeight : actualStartY;

        const actualStartX = x + (groups.length > 1 ? barWidth / 2 + barWidth * typeIndex : groupWidth / 2);
        const actualEndX = lastValue ? actualStartX + groupWidth + groupGap : actualStartX;

        ctx.strokeStyle = ccolor;
        ctx.beginPath();
        //ctx.setLineDash([5, 5]); // Set dotted line pattern
        ctx.lineWidth = 2; // Set line width to 2 pixels

        ctx.moveTo(actualStartX, actualStartY);
        ctx.lineTo(actualEndX, actualEndY);

        ctx.stroke();

        // Draw circle at start point
        ctx.beginPath();
        ctx.setLineDash([]); // Reset line dash
        ctx.arc(actualStartX, actualStartY, 5, 0, 2 * Math.PI);
        ctx.fillStyle = ccolor;
        ctx.fill();
      });
    }
    // X-axis labels
    if (!shouldHideAlternateLabels || index % 2 === 0) {
      ctx.fillStyle = 'black';
      ctx.save();
      if (useAngledLabels) {
        ctx.translate(x + groupWidth / 2, dimensions.height - margin.bottom + 10);
        ctx.rotate(-Math.PI / 4);
        ctx.textAlign = 'right';
      } else {
        ctx.textAlign = 'center';
        ctx.translate(x + groupWidth / 2, dimensions.height - margin.bottom + 20);
      }
      ctx.font = `11px ${font}`;
      ctx.fillText(column.name, 0, 0);
      ctx.restore();
    }
  });

  // Draw grid lines and Y-axis labels
  ctx.font = `300 12px ${font}`;
  drawGridAndLabels(ctx, chartData.display, chartWidth, chartHeight, margin, yMoneyScale, yPercentScale, maxTicks);
};

type NTChartProps = {
  data?: ChartProps;
  rawData: TransformedData | null;
  width?: number;
  height?: number;
  font?: string;
  useAngledLabels?: boolean;
};
const NTChart = ({
  data,
  rawData,
  width = 350,
  height = 240,
  font = 'Montserrat',
  useAngledLabels = true,
}: NTChartProps) => {
  if (!rawData || !rawData?.utilityData?.chartData) return null;

  const { cellStates } = useNTSelectionContext();
  const { actions } = useNTUtilsContext();
  const { filters } = useNTContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [tooltipData, setTooltipData] = useState<ChartDataColumn | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState({ width, height });
  const [chartData, setChartData] = useState<ChartProps | null>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [reversed, setReversed] = useState(actions.reverseColumns);

  const rData = rawData.utilityData.chartData;

  // Use the provided data or fall back to the sample data

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    setSelectedRow(cellStates.hover ? cellStates.hover : cellStates.selected ? cellStates.selected : null);

    // Since we are going to reverse columns, we need to create a new chartData object, not a pointer
    setChartData(
      cellStates.hover ? { ...rData[cellStates.hover] } : cellStates.selected ? { ...rData[cellStates.selected] } : null
    );
  }, [cellStates]);

  useEffect(() => {
    setReversed(actions.reverseColumns);
  }, [actions.reverseColumns]);

  useEffect(() => {
    //if (cellStates.hover) {
    if (!chartData || !selectedRow) return;

    // We need to bring budgetsAvailable here somehow
    const groups: chartGroup[] = [];
    if (filters.actuals) groups.push('Actual');
    if (chartData.budget && filters.budget) groups.push('Budget');
    if (chartData.budget && filters.budget && filters.variance) groups.push('Variance');

    // Reverse needs to be calculated properly everytime
    if (reversed) {
      chartData.columns = chartData.columns.reverse();
    } else {
      // if not Reversed we must go back to the original column data
      chartData.columns = [...rData[selectedRow].columns];
    }
    const chartFilters = {
      groups: groups,
      money: filters.money,
      percent: filters.percentage,
    };
    drawChart({ canvasRef, dimensions, chartData, font, useAngledLabels, chartFilters });
  }, [chartData, dimensions, font, useAngledLabels, reversed, chartData, filters, selectedRow]);

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef?.current;
    if (!canvas || !chartData) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Define chart area
    const margin = { top: 20, right: 60, bottom: 60, left: 60 };
    const chartWidth = dimensions.width - margin.left - margin.right;
    const chartHeight = dimensions.height - margin.top - margin.bottom;

    // Calculate bar dimensions
    const groupGap = 8;
    const groupWidth = (chartWidth - (chartData.columns.length - 1) * groupGap) / chartData.columns.length;

    // Check if mouse is over a bar
    chartData.columns.forEach((column: ChartDataColumn, index: number) => {
      const barX = margin.left + index * (groupWidth + groupGap);
      if (
        x >= barX - groupWidth / 2 &&
        x <= barX + groupWidth &&
        y >= margin.top &&
        y <= dimensions.height - margin.bottom
      ) {
        setTooltipData(column);
        setTooltipPosition({ x: x, y: y });
        return;
      }
    });

    // Hide tooltip if mouse is not over a bar
    if (
      x < margin.left ||
      x > dimensions.width - margin.right ||
      y < margin.top ||
      y > dimensions.height - margin.bottom
    ) {
      setTooltipData(null);
    }
  };

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', width: '100%', maxWidth: width, height, margin: '0 50px', marginLeft: '0' }}
    >
      <canvas
        ref={canvasRef}
        width={containerRef.current?.offsetWidth}
        height={dimensions.height}
        onMouseMove={handleMouseMove}
        onMouseLeave={e => setTooltipData(null)}
      />
      <NTToolTip data={tooltipData} position={tooltipPosition} />
    </div>
  );
};

export default NTChart;
