type cellFormats = 'text' | 'number' | 'money' | 'percentage';
import { ExportData, ExportDataRow } from '../types';
let ksBaseStyleSettings: any;

const resolvemultiColumns = (data: any, key: string, target: string, title: string | null = null) => {
  const levelA: string[] = [];
  let levelB: string[] = [];
  const widths: any[] = [];
  const innerLevel = [];
  const formats: cellFormats[] = [];
  if (data[key][target]) {
    levelA.push(titleCase(title || target));
    if ('money' in data[key][target]) {
      innerLevel.push('$');
      widths.push({ wch: ksBaseStyleSettings.width.money });
      formats.push('money');
    }
    if ('percent' in data[key][target]) {
      //percentOf = true;
      innerLevel.push('%');
      widths.push({ wch: ksBaseStyleSettings.width.percentage });
      formats.push('percentage');
    }
    if (innerLevel.length > 1) {
      for (let i = 0; i < innerLevel.length - 1; i++) {
        levelA.push('');
      }
      levelB = [...levelB, ...innerLevel];
    }
  }
  return {
    levelA,
    levelB,
    widths,
    formats,
  };
};

const titleCase = (title: string) => title.charAt(0).toUpperCase() + title.slice(1).toLowerCase().replace(/ /g, '_');
export const compileHeaders = (data: ExportData, styleSettings: any) => {
  ksBaseStyleSettings = styleSettings;
  let widths: any[] = [{ wch: ksBaseStyleSettings.width.gl }, { wch: ksBaseStyleSettings.width.account }];
  let formats: cellFormats[] = ['number', 'text'];
  const headers: string[] = [];
  let headersB: string[] = [];
  let headersC: string[] = [];
  let percentOf: boolean = false;

  const sampleCell = data.data[0];

  for (const key in sampleCell) {
    if (sampleCell.hasOwnProperty(key)) {
      if (key !== 'budgetAvailable' && key !== 'style') {
        const titles: any = {
          GL: 'GL #',
          accounts: 'Account',
          percentOf: '% of',
        };
        if (titles[key]) {
          headers.push(titles[key]);
        } else {
          headers.push(key as string);
        }

        if (key === 'percentOf') {
          widths.push({ wch: ksBaseStyleSettings.width.percentOf });
          formats.push('text');
          percentOf = true;
        }
        if (key === 'GL' || key === 'accounts' || key === 'percentOf') {
          headersB.push('');
          headersC.push('');
        }
      }
      if (key !== 'GL' && key !== 'accounts' && key !== 'percentOf' && key !== 'budgetAvailable' && key !== 'style') {
        if (data.budgetAvailable) {
          let levelA: string[] = [];
          let levelB: string[] = [];

          const actuals = resolvemultiColumns(sampleCell, key, 'actuals', 'Actual');
          levelA = [...levelA, ...actuals.levelA];
          levelB = [...levelB, ...actuals.levelB];
          widths = [...widths, ...actuals.widths];
          formats = [...formats, ...actuals.formats];

          const budget = resolvemultiColumns(sampleCell, key, 'budget');
          levelA = [...levelA, ...budget.levelA];
          levelB = [...levelB, ...budget.levelB];
          widths = [...widths, ...budget.widths];
          formats = [...formats, ...budget.formats];

          const variance = resolvemultiColumns(sampleCell, key, 'variance');
          levelA = [...levelA, ...variance.levelA];
          levelB = [...levelB, ...variance.levelB];
          widths = [...widths, ...variance.widths];
          formats = [...formats, ...variance.formats];

          for (let i = 0; i < levelA.length - 1; i++) {
            headers.push('');
          }

          headersB = [...headersB, ...levelA];
          headersC = [...headersC, ...levelB];
        } else {
          // If we don't have budgets, we just want to display the money, percent columns
          const innerLevel = [];
          if ('money' in sampleCell[key].actuals) {
            innerLevel.push('$');
            widths.push({ wch: ksBaseStyleSettings.width.money });
            formats.push('money');
          }
          if ('percent' in sampleCell[key].actuals) {
            innerLevel.push('%');
            widths.push({ wch: ksBaseStyleSettings.width.percentage });
            formats.push('percentage');
          }
          if (innerLevel.length > 1) {
            for (let i = 0; i < innerLevel.length - 1; i++) {
              headers.push('');
            }
            headersB = [...headersB, ...innerLevel];
          }
        }
      }
    }
  }
  return {
    headers,
    headersB,
    headersC,
    widths,
    formats,
    percentOf,
  };
};

const cleanUpZeroes = (amount: number | string) => {
  return amount === 0 ? '' : amount;
};
export const compileData = (data: ExportData) => {
  const rows: any = [];
  const heights: any = [];
  const extra: any = [];
  if (!Array.isArray(data.data)) {
    /* console.log('Invalid data format:', data); */
    return [];
  }
  data.data.forEach((row: any) => {
    //console.log('row', row);
    const rowData: any = [];
    extra.push(row.style);
    heights.push(
      row.style.isBigHeader
        ? { hpx: ksBaseStyleSettings.height.isBigHeader }
        : row.style.isHeader
          ? { hpx: ksBaseStyleSettings.height.isHeader }
          : row.style.isTotal
            ? { hpx: ksBaseStyleSettings.height.isTotal }
            : { hpx: ksBaseStyleSettings.height.regular }
    );
    rowData.push(row.GL);
    rowData.push(row.accounts.trim());
    if (row.percentOf) rowData.push(row.percentOf.toUpperCase());
    for (const key in row) {
      if (key !== 'GL' && key !== 'accounts' && key !== 'percentOf' && key !== 'budgetAvailable' && key !== 'style') {
        if (data.budgetAvailable) {
          if (row[key].actuals) {
            if ('money' in row[key].actuals) rowData.push(cleanUpZeroes(row[key].actuals.money));
            if ('percent' in row[key].actuals) rowData.push(cleanUpZeroes(row[key].actuals.percent));
          }
          if (row[key].budget) {
            if ('money' in row[key].budget) rowData.push(cleanUpZeroes(row[key].budget.money));
            if ('percent' in row[key].budget) rowData.push(cleanUpZeroes(row[key].budget.percent));
            if (row[key].variance && row[key].actuals) {
              if ('money' in row[key].variance) rowData.push(cleanUpZeroes(row[key].variance.money));
              if ('percent' in row[key].variance) rowData.push(cleanUpZeroes(row[key].variance.percent));
            }
          }
        } else {
          if ('money' in row[key].actuals) rowData.push(cleanUpZeroes(row[key].actuals.money));
          if ('percent' in row[key].actuals) rowData.push(cleanUpZeroes(row[key].actuals.percent));
        }
      }
    }
    rows.push(rowData);
  });
  return { rows, heights, extra };
};

export const mergeCells = (cells: string[], row: number) => {
  const merges: any = [];
  let currentCol = 0;
  let startSegment = false;
  let init = 0;
  cells.forEach((cell, idx) => {
    if (cell === '' && !startSegment && currentCol !== 0 && cells.slice(0, currentCol).some(c => c !== '')) {
      init = currentCol - 1;
      startSegment = true;
    } else if (cell !== '' && startSegment) {
      merges.push({
        s: { r: row, c: init },
        e: { r: row, c: currentCol - 1 },
      });
      startSegment = false;
    }
    currentCol++;
  });
  if (startSegment) {
    merges.push({
      s: { r: row, c: init },
      e: { r: row, c: currentCol - 1 },
    });
    startSegment = false;
  }
  return merges;
};

const mergeVerticalCells = (startRow: number, endRow: number, startCol: number, endCol?: number) => {
  const merges: any = [];
  for (let i = startCol; i <= (endCol ? endCol : startCol); i++) {
    merges.push({
      s: { r: startRow, c: i },
      e: { r: endRow, c: i },
    });
  }
  return merges;
};
