import { prefixClass } from 'lib/utils';
import './SFSearch.scss';
import { IconSearch } from 'components/Icons';
import classNames from 'classnames';
import { useRef } from 'react';

type SFSearchProps = {
  searchValue?: string;
  onSearch?: (search: string) => void;
  onClear?: () => void;
};

const SFSearch = ({ searchValue = '', onSearch, onClear }: SFSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const prefix = prefixClass('sf-search');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch?.(event.target.value);
  };

  const handleClear = () => {
    onClear?.();
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <div className={classNames(prefix(), { active: searchValue })}>
      <div className={prefix('icon')}>
        <IconSearch />
      </div>
      <input type="text" placeholder="Search" value={searchValue} onChange={handleSearch} autoFocus ref={inputRef} />
      {searchValue && (
        <div className={prefix('clear-button')} onClick={handleClear}>
          Clear
        </div>
      )}
    </div>
  );
};

export default SFSearch;
