import { TransformedData, Column, Amounts, ChartObject, ChartProps, ChartDataColumn } from './types';

export const createTotalColumn = (transformedData: TransformedData, difference: boolean = false): Column => {
  const filteredColumns = transformedData.columns.filter((column: Column) => {
    return !column.compoundColumn;
  });
  const totalRowData: Amounts[] = filteredColumns.reduce((acc: any, column: any, index: number) => {
    column.data.forEach((elem: Amounts, rowIndex: number) => {
      if (!acc[rowIndex]) {
        acc[rowIndex] = { ...elem };
        acc[rowIndex].percent = acc[rowIndex].percent || 0;
      } else {
        if (rowIndex > 0 && difference) {
          acc[rowIndex].amount -= elem.amount ? elem.amount : 0;
          acc[rowIndex].percent -= elem.percent ? elem.percent : 0;
        } else {
          acc[rowIndex].baseAmount += elem.baseAmount ? elem.baseAmount : 0;
          acc[rowIndex].percent += elem.percent ? elem.percent : 0;
          acc[rowIndex].dataRows = acc[rowIndex].dataRows.concat(elem.dataRows || []);

          acc[rowIndex].amount += elem.amount ? elem.amount : 0;
          if (index === filteredColumns.length - 1) {
            if (acc[rowIndex].percent !== 0) acc[rowIndex].percent = acc[rowIndex].amount / acc[rowIndex].baseAmount;
            acc[rowIndex].transactions = acc[rowIndex].dataRows.length;
          }
        }
      }
    });
    return acc;
  }, []);

  const totalColumn: Column = {
    name: difference ? 'Difference' : 'Total',
    date: { start: difference ? 'Δ Difference' : '∑ Total', end: '' },
    type: [{ type: 'Direct', index: 0 }],
    data: totalRowData,
    total: true,
    periodReporting: false,
  };
  return totalColumn;
};

export const createChartsValues = (transformedData: TransformedData): ChartObject => {
  const filteredColumns = transformedData.columns.filter((column: Column) => {
    return !column.compoundColumn;
  });

  const rowCharts: ChartObject = {};
  const rowCount = filteredColumns[0]?.data?.length || 0;
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const chart: ChartProps = {
      id: filteredColumns[0].data?.[rowIndex].id,
      display: { money: true, percent: true },
      budget: transformedData.budgetAvailable || false,
      groups: ['Actual', 'Budget', 'Variance'],
      columns: [],
    };
    filteredColumns.forEach((column: Column) => {
      const elem: Amounts = column.data?.[rowIndex] || {};
      const col: ChartDataColumn = {
        name: column.name || '',
        data: {
          money: { actual: elem.amount || 0, budget: elem.budget || 0, variance: elem.difference || 0 },
          percent: { actual: elem.percent || 0, budget: elem.budgetPercent || 0, variance: elem.variance || 0 },
        },
      };
      chart.columns.push(col);
    });
    rowCharts[chart.id] = chart;
  }
  return rowCharts;
};
