import { currencyFormatter } from 'lib/utils';
import {
  ActiveCategoryColorMapping,
  CategoryColorMapping,
  getActiveDateForecastData,
  getActualsFromReport,
  getAllForecasts,
  getHistoricalDataFromReport,
  transformActualsIntoGraphData,
  transformForecastIntoGraphData,
  transformHistoricalDataIntoGraphData,
} from './utilities';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

const buildChartConfig = (
  report: RemoteForecastReport | null,
  timeframe: 'weeks' | 'months',
  activeCategory: string,
  periods: (string | number)[],
  activeDate: string,
  activePeriod: number,
  categories: string[]
) => {
  if (!activeCategory || !activeDate || !report || isEmpty(report)) return;
  const xAxisData = Object.entries(report[timeframe]).map(([date, base]) => {
    return format(Date.parse(base.startDate + 'T00:00:00'), 'MM/dd/yy');
  });

  const seriesStyles = [
    {
      symbol: 'circle',
      lineStyle: {
        color: '#CB701E',
        width: 4,
      },
      itemStyle: {
        border: 'none',
        color: '#CB701E',
      },
    },
    {
      symbol: 'circle',
      lineStyle: {
        color: '#3E6378',
        width: 4,
      },
      itemStyle: {
        border: 'none',
        color: '#3E6378',
      },
    },
  ];

  const allForecast = getAllForecasts(report, timeframe);
  const forecasts = transformForecastIntoGraphData(allForecast, activeCategory);

  const forecastSections = getActiveDateForecastData(report, timeframe, activeDate);

  const forecastPeriods = transformForecastIntoGraphData(forecastSections, activeCategory);
  const bars = transformActualsIntoGraphData(getActualsFromReport(report, timeframe), activeCategory);

  const activeCategoryIndex = categories.indexOf(activeCategory);

  bars[6] = {
    itemStyle: {
      color:
        0 === activePeriod
          ? ActiveCategoryColorMapping[activeCategoryIndex]
          : CategoryColorMapping[activeCategoryIndex],
      borderColor: 0 === activePeriod ? null : '#3E6378',
      borderWidth: 1,
      borderType: 'dashed',
    },
    label: {
      color: 0 === activePeriod ? '#FFF' : '#3E6378',
    },
    value: forecastPeriods && forecastPeriods[0],
  };
  bars[7] = {
    itemStyle: {
      borderColor: 1 === activePeriod ? null : '#3E6378',
      borderWidth: 1,
      borderType: 'dashed',
      color:
        1 === activePeriod
          ? ActiveCategoryColorMapping[activeCategoryIndex]
          : CategoryColorMapping[activeCategoryIndex],
    },
    label: {
      color: 1 === activePeriod ? '#FFF' : '#3E6378',
    },
    value: forecastPeriods && forecastPeriods[1],
  };

  const periodsSeries = periods.map((period, index) => {
    const data = transformHistoricalDataIntoGraphData(
      getHistoricalDataFromReport(report, period, timeframe),
      activeCategory
    );
    return {
      ...seriesStyles[index],
      name: 'Previous Year',
      type: 'line',
      symbolSize: 12,
      data: data,
    };
  });

  return {
    chartOptions: {
      tooltip: {
        trigger: 'item',
        valueFormatter: (value: number) => {
          return value ? currencyFormatter(value) : '-';
        },
      },
      legend: {
        icon: 'circle',
        selectedMode: 'multiple',
        align: 'left',
        orient: 'vertical',
        padding: [0, 0],
        right: 0,
        bottom: 40,
        data: [
          {
            name: activeCategory,
          },
          {
            name: 'Goal',
          },
          {
            name: 'Previous Year',
          },
        ],
      },
      toolbox: {
        show: false,
      },
      grid: {
        top: 60,
        left: 30,
        right: 200,
        bottom: 30,
      },
      dataZoom: {
        show: false,
        start: 0,
        end: 100,
      },
      xAxis: [
        {
          splitLine: {
            show: true,
            interval: 5,
            lineStyle: {
              width: 2,
              type: 'dotted',
              dashOffset: 5,
              color: ['transparent', 'rgba(0, 0, 0, 0.3)', 'transparent'],
            },
          },
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          data: xAxisData.reverse(),
          axisLabel: {
            padding: [5, 0, 0, 0],
            fontSize: 13,
          },
        },
      ],
      yAxis: [
        {
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: activeCategory,
          type: 'bar',
          itemStyle: {
            color: CategoryColorMapping[activeCategoryIndex],
          },
          animationEasing: 'elasticOut',
          animationDelay: function (idx: number) {
            return idx * 10;
          },
          animationDelayUpdate: function (idx: number) {
            return idx * 10;
          },
          data: bars,
          label: {
            normal: {
              show: true,
              position: 'bottom',
              distance: -20,
              formatter: (params: any) => {
                return currencyFormatter(params.value);
              },
              color: '#3E6378',
              fontWeight: 'bold',
              fontSize: 13,
            },
          },
        },
        {
          name: 'Goal',
          type: 'line',
          data: forecasts.reverse(),
          symbol: 'circle',
          symbolSize: 12,
          lineStyle: {
            color: '#D9D9D9',
            width: 4,
            type: 'dashed',
          },
          itemStyle: {
            color: '#FFF',
            border: 'none',
            borderColor: '#3E6378',
            borderWidth: 2,
          },
        },
        ...periodsSeries,
      ],
    },
    chartDates: xAxisData,
  };
};

export default buildChartConfig;
