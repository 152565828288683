import classNames from 'classnames';
import { Header } from './types';

type TableHeaderProps = {
  header: Header;
};

export default function TableHeader({ header }: TableHeaderProps): JSX.Element {
  const colspan = header.subItems?.length ? header.subItems.length : 1;
  const hasSubItems = header.subItems && header.subItems?.length;
  return (
    <th
      className={'hone-table-header'}
      style={{
        ...header.styles,
        textAlign: hasSubItems ? 'center' : header.align,
        width: header.width ? header.width : '15%',
        maxWidth: header.width ? 'none' : '100px',
        minWidth: header.width ? 'none' : '100px',
        display: header.hidden ? 'none' : 'table-cell',
      }}
      colSpan={colspan}
    >
      <div
        style={{
          ...header.headerInnerStyles,
          color: header.headerTitleColor,
          margin: hasSubItems ? '0 auto' : '0',
          marginLeft: hasSubItems ? 'auto' : '10px',
        }}
      >
        {header.title}
      </div>
      {hasSubItems && (
        <div className="hone-table-header-subitems">
          {header.subItems &&
            header.subItems.map((subItem: Header, idx: number) => <div key={idx}>{subItem.title}</div>)}
        </div>
      )}
    </th>
  );
}
