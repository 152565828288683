import { subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import { FIVE_SECONDS, showToast, TOAST_ERROR } from 'lib/utils';
import { BussinessMetricsTypes } from 'domain/models';
import { makeRemoteGetDashboard } from 'main/factories/usecases/remote-get-dashboard';
import { HoneDashboard, CardTimeframe } from '@hone-automation/common';

interface DashboardState {
  dashboardReport: HoneDashboard | undefined;
  fetchDashboardReport: (locationId: string, timeframe: CardTimeframe) => void;
  loadingDashboard: boolean;
  timeframe: CardTimeframe;
  activeBussinessMetric: BussinessMetricsTypes;
  setActiveBussinessMetric: (metric: BussinessMetricsTypes) => void;
  setTimeFrame: (timeframe: CardTimeframe) => void;
}

const getDashboardReport = makeRemoteGetDashboard();

export const useDashboardReport = create(
  subscribeWithSelector<DashboardState>(set => ({
    dashboardReport: undefined,
    loadingDashboard: false,
    timeframe: 'Last Week',
    setTimeFrame: (timeframe: CardTimeframe) => set({ timeframe }),
    activeBussinessMetric: 'Cost of Goods Sold',
    setActiveBussinessMetric: (activeBussinessMetric: BussinessMetricsTypes) => set({ activeBussinessMetric }),
    fetchDashboardReport: async (locationId: string, timeframe: CardTimeframe) => {
      set({ loadingDashboard: true });
      try {
        const dashboardData = await getDashboardReport.get({ locationId, timeframe });

        set({
          loadingDashboard: false,
          dashboardReport: dashboardData as HoneDashboard,
        });
      } catch (error) {
        showToast('Error trying to get dashboard report', TOAST_ERROR, FIVE_SECONDS);
      }
    },
  }))
);
