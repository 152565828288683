import { makeRemoteGetUserLocations } from 'main/factories/usecases/remote-get-user-locations';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from 'context/useAuthContext';

export const getUserLocations = makeRemoteGetUserLocations();

export const useUserLocationsQuery = () => {
  const { session, userId } = useAuthContext();

  return useQuery({
    queryKey: ['userLocations', userId],
    queryFn: async () => {
      if (userId) {
        return await getUserLocations.get({ userId });
      }
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!session && !!userId,
  });
};
