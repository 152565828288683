import { Whisper, Tooltip } from 'rsuite';
import { IconCollapse, IconExpand } from './Icons';

interface ExpandCollapseProps {
  expand: boolean;
  header?: boolean;
  handleExpand: () => void;
}

export function ExpandCollapse({ expand, header = true, handleExpand }: ExpandCollapseProps): JSX.Element {
  return (
    <Whisper
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      delay={400}
      speaker={<Tooltip>{expand ? 'Collapse' : 'Expand'}</Tooltip>}
    >
      <div className="table_header_button_group expand-collapse">
        {header && <span>{expand ? 'COLLAPSE' : 'EXPAND'}</span>}
        <button onClick={handleExpand} className={'collapse-btn'}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {expand ? <IconExpand /> : <IconCollapse />}
        </button>
      </div>
    </Whisper>
  );
}
