import './NTHeader.scss';
import { prefixClass } from 'lib/utils';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { column } from '../NTFile';
import { IconChevron, IconChevronDown } from 'components/Icons';
import { formatRangeValues } from 'lib/reportUtils';
import classNames from 'classnames';
import { handleCollapseAll } from '../utils/tableUtils';
import { he } from 'date-fns/locale';
import { Whisper, Tooltip } from 'rsuite';

type NTHeaderProps = {
  headerData: any | null;
  parent?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  columnID: number;
};

const prefix = prefixClass('nt-header');

const NTKSHeaderAccount = ({ columnID, headerData }: { columnID: number; headerData: column }) => {
  const { filters } = useNTContext();
  const { start, end } = headerData.date || {};
  //console.log('headerData:', headerData);
  return (
    <div className={prefix('ks-account-header')}>
      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Expand/Collapse All</Tooltip>} delayOpen={400}>
        <div
          className={prefix('ks-account-header-collapse')}
          onClick={e => handleCollapseAll(e, 'collapse-all', headerData.data || [])}
          data-collapse-control="collapse-all"
        >
          <div className={`${prefix('ks-account-header-collapse-icons')}`}>
            <div className={`${prefix('ks-account-header-collapse-icons-icon')}`}>
              <IconChevron />
            </div>
            <div className={`${prefix('ks-account-header-collapse-icons-icon')}`}>
              <IconChevronDown />
            </div>
          </div>
        </div>
      </Whisper>
      <div className={prefix('ks-account-header-label')}>Accounts</div>
      {headerData.isLocation && (
        <div className={prefix('ks-account-header-label-date')}>
          {formatRangeValues(start && end ? `${start} - ${end}` : start, 'KS')}
        </div>
      )}
    </div>
  );
};
const NTKSHeaderData = ({ columnID, headerData }: { columnID: number; headerData: column }) => {
  const { filters, setFilters, budgetAvailable } = useNTContext();
  const { capabilities } = useNTUtilsContext();
  const { start, end } = headerData.date || {};
  const title = headerData.isLocation
    ? headerData.name
    : headerData.type[0].type === 'Direct'
      ? start
      : formatRangeValues(start && end ? `${start} - ${end}` : start, 'KS');
  /* console.log(headerData.date);
   console.log(title); */
  return (
    <div key={`${headerData}-${columnID}`} className={prefix('ks-data-header')}>
      <div
        className={classNames(prefix('ks-data-header-label-container'), {
          percentage: filters.percentage,
        })}
      >
        <div
          className={classNames(prefix('ks-data-header-label-container-label'), { small: headerData.periodReporting })}
        >
          {headerData.periodReporting && <span>P01</span>}
          {title}
        </div>
      </div>
      <div className={prefix('ks-data-header-options-container')}>
        {capabilities.transactions && filters.transactions && (
          <div className={prefix('ks-data-header-options-container-transactions')}>●</div>
        )}
        {filters.actuals && (
          <div
            className={classNames(prefix('ks-data-header-options-container-data-container'), {
              percentage: filters.percentage && filters.money,
            })}
          >
            {capabilities.money && filters.money && (
              <div
                className={prefix('ks-data-header-options-container-data-container-actual')}
                onClick={() => {
                  setFilters({ ...filters, percentage: !filters.percentage });
                }}
              >
                actual
              </div>
            )}
            {capabilities.percentage && filters.percentage && (
              <div className={prefix('ks-data-header-options-container-data-container-percentage')}>%</div>
            )}
          </div>
        )}
        {capabilities.budget && budgetAvailable && filters.budget && (
          <div
            className={classNames(prefix('ks-data-header-options-container-data-container'), {
              percentage: filters.percentage && filters.money,
              budget: true,
              'no-separator': !filters.actuals,
            })}
          >
            {capabilities.money && filters.money && (
              <div className={classNames(prefix('ks-data-header-options-container-data-container-actual'), {})}>
                budget
              </div>
            )}
            {capabilities.percentage && filters.percentage && (
              <div className={prefix('ks-data-header-options-container-data-container-percentage')}>%</div>
            )}
          </div>
        )}
        {capabilities.budget && budgetAvailable && filters.budget && filters.actuals && filters.variance && (
          <div
            className={classNames(prefix('ks-data-header-options-container-variance-container'), {
              percentage: filters.percentage && filters.money,
              budget: true,
              'no-separator': !filters.actuals,
            })}
          >
            {capabilities.money && filters.money && (
              <div className={classNames(prefix('ks-data-header-options-container-data-container-actual'), {})}>
                difference
              </div>
            )}
            {capabilities.percentage && filters.percentage && (
              <div className={prefix('ks-data-header-options-container-data-container-percentage')}>Δ</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
const NTKSHeader = ({ headerData, parent, columnID, style }: NTHeaderProps) => {
  const { filters } = useNTContext();
  /*  console.log('headerData:', headerData); */

  switch (headerData.type[0].type) {
    case 'Account':
      return <NTKSHeaderAccount columnID={columnID} headerData={headerData} />;
    case 'Data':
    case 'Direct':
      return <NTKSHeaderData columnID={columnID} headerData={headerData} />;
  }
};

const NTHeader = ({ headerData, parent, columnID, style }: NTHeaderProps) => {
  const prefix = prefixClass('nt-header');

  switch (typeof headerData) {
    case 'object':
      return <NTKSHeader headerData={headerData} columnID={columnID} />;
    case 'string':
      return (
        <div style={style} key={`${headerData}-${columnID}`} className={`${prefix()} simply-header`}>
          {headerData}
        </div>
      );
  }
};

export default NTHeader;
