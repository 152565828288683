import { useEffect } from 'react';
import { useScrollProperties } from './useScrollProperties';
import { useNTUtilsContext } from '../NTUtilsContext';
import { useNTTableContext } from '../NTTableContext';

export function useTableState() {
  const { updateScrollProperties } = useScrollProperties();
  const { actions, setActions } = useNTUtilsContext();
  const { redimParent, setIsResizing, setTableWidth } = useNTTableContext();
  /// Resize the table
  useEffect(() => {
    const resizeHandle = document.createElement('div');
    resizeHandle.className = 'resize-handle';

    redimParent?.appendChild(resizeHandle);

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      setIsResizing(true);
      if (actions && !actions.constrained) {
        setActions({
          ...actions,
          constrained: true,
        });
      }
      const startX = e.clientX;
      const startWidth = redimParent?.getBoundingClientRect().width ?? 0;

      const doDrag = (e: MouseEvent) => {
        const newWidth = startWidth + e.clientX - startX;
        setTableWidth(`${newWidth}px`);
        updateScrollProperties('x');
      };

      const stopDrag = () => {
        setIsResizing(false);
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
      };

      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    };
    resizeHandle.addEventListener('mousedown', handleMouseDown);
    // Remove the event listener when the component unmounts
    return () => {
      resizeHandle.removeEventListener('mousedown', handleMouseDown);
    };
  }, [redimParent, actions]);
}
