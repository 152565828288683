import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { transformApiTrxToUITrx } from 'hooks/flaggedTransactions/useFlaggedTransactions';
import { makeRemoteGetFlaggedTransactions } from 'main/factories/usecases';
import { UIFlaggedTransaction, UIFlaggedTransactionStatus } from 'types/askcustomer';

const getFlaggedTransactions = makeRemoteGetFlaggedTransactions();

export const useFlaggedTransactionsQuery = () => {
  const { locationId } = useParams();
  return useQuery({
    queryKey: ['flaggedTransactions', locationId],
    queryFn: async () => {
      return await getFlaggedTransactions.get({ locationId });
    },
    staleTime: 1000 * 60 * 5,
    select: React.useCallback((queryResponse: any): UIFlaggedTransaction[] => {
      const UIFlaggedTransactions: UIFlaggedTransaction[] = queryResponse.map(transformApiTrxToUITrx);
      // We order the flagged transactions by the following order, to simplify the matching logic where we are trying to find the first one that matches
      const orderArray = [
        UIFlaggedTransactionStatus.INFORMATION,
        UIFlaggedTransactionStatus.SUBMITTED,
        UIFlaggedTransactionStatus.CATEGORIZED,
      ];

      UIFlaggedTransactions?.sort((a: UIFlaggedTransaction, b: UIFlaggedTransaction) => {
        const aIndex = orderArray.indexOf(a.status);
        const bIndex = orderArray.indexOf(b.status);

        // Compare the indices and return the comparison result
        return aIndex - bIndex;
      });

      return UIFlaggedTransactions;
    }, []),
  });
};
