import { DeleteMetric } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemoteDeleteMetric extends BaseHttpService<DeleteMetric.Model> implements DeleteMetric {
  async deleteMetric(params: DeleteMetric.Params): Promise<DeleteMetric.Model> {
    return await this.httpClient.request({
      url: this.url,
      method: 'post',
      params,
    });
  }
}
