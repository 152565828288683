import { AskRequestStatus } from '@hone-automation/common';

import { IconCheck, IconClose } from 'components/Icons';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { useQueryState } from 'hooks/useQueryState';
import { useParams } from 'react-router-dom';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { shallow } from 'zustand/shallow';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { ChartOfAccountsSelect } from '../../pages/bookkeeper/components/BookkeeperAccrualsSettingsForm';
import { useAskRequestReCategorizationMutation } from '../../../hooks/flaggedTransactions/useAskRequestReCategorizationMutation';

type PropsType = {
  txId?: string | undefined;
  isSideView?: boolean;
};
export default function ReCategorizeButton({ txId, isSideView }: PropsType) {
  const [active, setActive] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const { locationId } = useParams();

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [activeTab] = useQueryState<AskRequestStatus>('activeTab');

  const { setActiveTransaction } = useFlaggedTransactions(
    state => ({
      setActiveTransaction: state.setActiveTransaction,
      setTransactionBeingFlagged: state.setTransactionBeingFlagged,
      setAnimateTransaction: state.setAnimatedTransaction,
      transactionBeingFlagged: state.transactionBeingFlagged,
    }),
    shallow
  );

  const reCategorizeTransaction = useAskRequestReCategorizationMutation(locationId!, activeTab, trx => {
    setActiveTransaction(trx);
  });
  const recategorize = async () => {
    reCategorizeTransaction.mutate({ trxId: txId, newAccountId: account, comment });
  };

  const toggleDialog = () => {
    setActive(active => !active);
    setDirty(true);
    textAreaRef.current?.focus();
    setTimeout(() => {
      setComment('');
    }, 500);
  };

  const handleCOASelect = (value: { value: string; label: string }) => {
    setAccount(value.value);
  };

  return (
    <div className="resolve-wrapper recategorize">
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Re-categorize transaction</Tooltip>}
      >
        <div className="resolved-button-wrapper">
          <button className={classNames('chat-input__mask-resolved', { active })} type="button" onClick={toggleDialog}>
            {reCategorizeTransaction.isPending ? (
              <>
                Loading...
                <i className="fa fa-circle-o-notch fa-spin" />{' '}
              </>
            ) : (
              <>Re-Categorize</>
            )}
          </button>
        </div>
      </Whisper>
      {active && (
        <div className={classNames('resolved-dialog', { active, inactive: dirty && !active })}>
          <div className="form-control">
            <label>Select a new gl category</label>
            <ChartOfAccountsSelect selected={account} onChange={handleCOASelect} />
          </div>
          <div className="comment">
            <textarea
              ref={textAreaRef}
              value={comment}
              onChange={e => setComment(e.target.value)}
              autoFocus={active}
              placeholder="Specify the reason for changing the category."
            />
          </div>
          <div className="actions">
            <button onClick={toggleDialog}>
              <IconClose />
              Cancel
            </button>
            <button disabled={!account} onClick={recategorize}>
              <IconCheck />
              Re-Categorize
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
