import { titleCase, spacer, EXCLUDED_KEYS, SECONDARY_KEYS, formatAmount } from './ksExporterUtils';
import { ExportData, ExportDataRow } from './types';

interface ProcessPropertiesProps {
  exportDataRow: ExportDataRow;
  key: string;
  secondaryKey?: string;
  label?: string;
  firstColumn: boolean;
}

const formatCSVMoney = (value: number | string) => {
  return value && value !== 0 && value !== ' ' ? formatAmount(value as number, 2, true) : ' ';
};

const formatCSVPercentage = (value: number | string) => {
  return value && value !== 0 && value !== ' ' ? formatAmount((value as number) * 100, 2, true, true) + '%' : ' ';
};

// This function extract the properties Money and Percentages from the ExportData object and returns a CSV object with the properly formatted values and a flattened headers array to set the order of the columns in the CSV file.
const processProperties = ({ exportDataRow, key, secondaryKey, label, firstColumn }: ProcessPropertiesProps) => {
  const csvObj: Record<string, any> = {};
  const flattenedHeaders: { label: string; key: string }[] = [];
  const pointerObj = secondaryKey ? exportDataRow[key][secondaryKey] : exportDataRow[key];
  const localLabel = secondaryKey ? ' ' + (label ? label : '') : '';

  if (!pointerObj) {
    // console.log('processProperties', { exportDataRow, key, secondaryKey, label, firstColumn, localLabel,pointerObj});
    csvObj[`${key}${localLabel} $`] = ' ';
    csvObj[`${key}${localLabel} %`] = ' ';
    return { csvObj, flattenedHeaders };
  }
  if ('money' in pointerObj) {
    csvObj[`${key}${localLabel} $`] = formatCSVMoney(pointerObj.money);
    if (firstColumn) flattenedHeaders.push({ label: `${key}${localLabel} $`, key: `${key}${localLabel}  $` });
  }
  if ('percent' in pointerObj) {
    csvObj[`${key}${localLabel} %`] = formatCSVPercentage(pointerObj.percent);
    if (firstColumn) flattenedHeaders.push({ label: `${key}${localLabel}  %`, key: `${key}${localLabel}  %` });
  }
  return { csvObj, flattenedHeaders };
};

// This funtion processes the exportObject to create the CSV output
export const processCSV = (exportObject: ExportData, trace: boolean = false) => {
  if (trace) console.time('processCSV');
  // Setup the Headers for the CSV to be able to order them properly
  const flattenedHeaders: { label: string; key: string }[] = [];
  // Flatten data for CSV export and maintain column order
  const flattenedData = exportObject.data.map((row: ExportDataRow, index: number) => {
    const csvObj: Record<string, string | number> = {};
    const firstColumn = index === 0;
    // Set properties in desired order
    csvObj.GL = row.GL;
    // We indent the accounts column to match the report using spacer
    csvObj.Account = spacer(row.style.level) + row.accounts;
    if (firstColumn) flattenedHeaders.push({ label: 'GL #', key: 'GL' });
    if (firstColumn) flattenedHeaders.push({ label: 'Accounts', key: 'Account' });

    // If we are not exporting percentages, we can skip this step
    if (row.percentOf) {
      csvObj['% of'] = row.percentOf;
      if (firstColumn) flattenedHeaders.push({ label: '% of', key: '% of' });
    }

    // If we don't have a budgetAvailable, we only have one layer and no secondary properties to process
    if (exportObject.budgetAvailable) {
      Object.keys(row).forEach(key => {
        if (!EXCLUDED_KEYS.includes(key as any)) {
          SECONDARY_KEYS.forEach(secondaryKey => {
            const result = processProperties({
              exportDataRow: row,
              key,
              secondaryKey,
              firstColumn,
              label: titleCase(secondaryKey),
            });
            if (result) {
              const { csvObj: csvObj2, flattenedHeaders: flattenedHeaders2 } = result;
              Object.assign(csvObj, csvObj2);
              flattenedHeaders.push(...flattenedHeaders2);
            }
          });
        }
      });
    } else {
      Object.keys(row).forEach(key => {
        if (!EXCLUDED_KEYS.includes(key as any)) {
          const result = processProperties({
            exportDataRow: row,
            key,
            secondaryKey: 'actuals',
            firstColumn,
            label: titleCase('Actuals'),
          });
          if (result) {
            const { csvObj: csvObj2, flattenedHeaders: flattenedHeaders2 } = result;
            Object.assign(csvObj, csvObj2);
            flattenedHeaders.push(...flattenedHeaders2);
          }
        }
      });
    }
    return csvObj;
  });
  if (trace) console.timeEnd('processCSV');
  return { data: flattenedData, headers: flattenedHeaders };
};
