import React, { ChangeEvent } from 'react';
import ForecastPlusMinusInput from '../ForecastPlusMinusInput/ForecastPlusMinusInput';
import { currencyFormatter } from 'lib/utils';
import ForecastInput from '../ForecastInput';
import classNames from 'classnames';
import { handleExpenseAmountChange, handleExpensePercentageChange } from 'hooks/useForecastUIStore';

interface ForecastTableRowProps {
  section: UIForecastReportElement;
  prefix: string;
  color: string;
  active?: boolean;
}

function ForecastTableRow({ section, prefix, color, active }: ForecastTableRowProps): JSX.Element {
  const onClearInput = () => {
    //
  };

  const handlePercentageChange = (newValue: number | string, name: string): void => {
    handleExpensePercentageChange(newValue, name);
  };

  const handleAmountChange = ({ target: { value: newValue, name } }: ChangeEvent<HTMLInputElement>): void => {
    handleExpenseAmountChange(newValue, name);
  };

  return (
    <tr className="expandable content budget">
      <td>
        <div className={classNames('box', { [color]: true, active })}>{section.title}</div>
      </td>
      <td>
        <div className={classNames('box', { [color]: true, active })}>
          <ForecastPlusMinusInput
            name={`${prefix}.goalPerc`}
            onChange={handlePercentageChange}
            value={section.goalPerc}
            label={`of ${section.goalPercTag}`}
          />
        </div>
      </td>
      <td className="align-right">
        <div>{currencyFormatter(section.amount)}</div>
      </td>
      <td className="goal">
        <div>
          <ForecastInput
            onClear={onClearInput}
            onChange={handleAmountChange}
            value={section.goalAmount}
            name={`${prefix}.goalAmount`}
          />
        </div>
      </td>
    </tr>
  );
}

export default ForecastTableRow;
