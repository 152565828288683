import classNames from 'classnames';
import { IconChevron, IconChevronDown } from 'components/Icons';
import { useNTContext } from '../../NTContext';
import { useNTUtilsContext } from '../../NTUtilsContext';
import { accounts } from '../../NTFile';
import { prefixClass } from 'lib/utils';
import Sparkline from '../Sparklines';
import { HtmlHTMLAttributes, useRef } from 'react';
import './FormatAccounts.scss';
import { handleCollapseRow } from '../../utils/tableUtils';
import { all } from 'axios';

const FormatAccounts = ({
  cellData,
  cellID,
  rowID,
  allColumn,
}: {
  cellData: accounts;
  cellID: string | null;
  rowID?: string | null;
  allColumn?: any;
}) => {
  const prefix = prefixClass('account-values');
  const { filters, columns, sparkLine, budgetAvailable } = useNTContext();
  const { actions, capabilities } = useNTUtilsContext();
  const { amountTitle, percentTitle, level, children, glCode, display, vizHelpers, amounts } = cellData;
  const { isBigHeader, isHeader, lastItem, isTotal, isExpanded } = vizHelpers;

  const dataToChart = actions.reverseColumns ? amounts?.reverse() : amounts;
  const chartData = dataToChart
    ? // If we are representing a budget, the data available for account column still has amounts and budgets in different blocks, so we need to filter out the budgets
      budgetAvailable && dataToChart.length > 2
      ? // if we only have 2 items, that means that we can plot the budget and the amount in the same chart
        dataToChart.filter((item, index) => index % 2).map(item => (item.amount !== null ? item.amount : 0))
      : dataToChart.map(item => (item.amount !== null ? item.amount : 0))
    : new Array(columns).fill(0);

  if (actions.total || actions.difference) chartData.pop();

  return (
    <div
      className={classNames(prefix('KS-account-cell'), { 'extra-height': isBigHeader })}
      data-cell-id={cellID}
      data-status={false}
    >
      <div className={prefix('indent-container')}>
        {Array.from({ length: level }).map((_, index) => (
          <div key={index} className={prefix('indent-element')}>
            <div
              className={classNames(prefix('indent-element-shape'), {
                ['level-line']: index < level - 1,
                ['connection-line']: index === level - 1 && !lastItem,
                ['last-item-connection-line']: index === level - 1 && lastItem,
              })}
            ></div>
          </div>
        ))}
      </div>
      <div
        className={classNames(prefix('account-children'))}
        onClick={e =>
          handleCollapseRow({ e: e, rowID: rowID as string, cellDataID: cellData.id, allColumn: allColumn })
        }
        data-collapse-control={rowID}
      >
        {children && children.length > 0 ? (
          <div className={`${prefix('account-children-icons')}`}>
            <div className={`${prefix('account-children-icons-icon')}`}>
              <IconChevron />
            </div>
            <div className={`${prefix('account-children-icons-icon')}`}>
              <IconChevronDown />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={prefix('account-label')}>
        {glCode > 0 && !isBigHeader && (
          <div
            className={prefix('account-label-gl-code')}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {glCode}
          </div>
        )}
        <div
          title={amountTitle}
          className={classNames(prefix('account-label-title'), {
            'l-total': isTotal,
            'l-header': isHeader,
            'l-big-header': isBigHeader,
          })}
        >
          {amountTitle}
        </div>
        {filters.percentage && percentTitle && !isBigHeader && (
          <div className={prefix('account-label-percent-title')}>% of {percentTitle}</div>
        )}
      </div>
      {capabilities.sparklines !== 'none' && !isBigHeader && chartData.length > 1 && (
        <div className={prefix('sparkline-container')}>
          <Sparkline
            type={capabilities.sparklines}
            data={chartData}
            width={columns > 6 ? (columns * 40) / 15 : 16}
            height={16}
            showZeroLine
          />
        </div>
      )}
    </div>
  );
};
export default FormatAccounts;
