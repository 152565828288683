import { Cell, CellProps } from 'rsuite-table';
import classNames from 'classnames';
import { memo } from 'react';
import { useReportsStore } from 'hooks/useReportsStore';
import { useQueryState } from 'hooks/useQueryState';

import { HoneReportTypes, HoneReportTimeframes } from '../../../constants';

interface TitleCellProps extends CellProps {
  hasChildren?: boolean;
  showAllData: boolean;
  expandedRows: any[];
  onRowClick: (expanded: boolean, rowData: any) => void;
}

function TitleCell(props: TitleCellProps): JSX.Element {
  const selectedReport = useReportsStore(state => state.selectedReport);
  const { rowData, dataKey, showAllData, expandedRows, onRowClick, ...rest } = props;
  const linesToAdd = rowData.level;
  const isTotal = rowData.display?.split('_')[1] === 'total';
  const [_activeCategory, setActiveCategory] = useQueryState('activeCategory');
  const keys = Array.from(Array(linesToAdd).keys());

  const hideHL = selectedReport?.type !== HoneReportTypes.ApAging;

  const handleClickCell = () => {
    const expandedRow = expandedRows.some((row: any) => row === rowData.rowKey);
    onRowClick(!expandedRow, rowData);
  };

  return (
    <Cell
      {...rest}
      rowData={rowData}
      onClick={handleClickCell}
      className={classNames(rowData.display, 'title-cell', {
        'title-cell-has-children': props.hasChildren,
      })}
    >
      {keys.map((ele, index) => {
        const verticalLeft = ele * 11 + 18 * (ele + 1);
        return (
          <span
            key={dataKey + '-' + ele}
            className={classNames('vertical-line', { last: rowData.last && index === keys.length - 1 })}
            style={{ left: verticalLeft + 'px' }}
          />
        );
      })}
      {hideHL && <span className={classNames('horizontal-line', { last: rowData.last })} />}
      <span className={classNames('text', { 'text-align-right': isTotal })}>
        {dataKey && rowData[dataKey].replace('->', '').replace('- >', '')}
        <br />
        {showAllData && rowData['titlePerc'] && rowData['data'][0] && rowData['data'][0].perc !== 0 && (
          <span className="text-percentage">% of {rowData['titlePerc']}</span>
        )}
      </span>
    </Cell>
  );
}

export default memo(TitleCell);
