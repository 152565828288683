import { cloneDeep, dropWhile, findIndex, isNil, isUndefined, omit, unset } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export function isForecastMappingSection(section: NestedForecastMappingSection): section is ForecastMappingSection {
  const { title, level } = section;
  return !isNil(title) && !isNil(level);
}

export function nestedToFlat(nestedSections: NestedForecastMappingSection[]): ForecastMappingSection[] {
  const flatTemplate = [] as ForecastMappingSection[];

  const sectionsCopy = cloneDeep(nestedSections);

  // collect rows from nested sections
  while (sectionsCopy.length > 0) {
    const section = sectionsCopy.shift();

    if (section) {
      if (section.sections && section.sections.length > 0) {
        sectionsCopy.unshift(...section.sections);
      }

      if (isForecastMappingSection(section)) {
        const flatSection = omit(section, 'sections');
        flatTemplate.push(flatSection);
      }
    }
  }

  return flatTemplate;
}

export function flatToNested(flatSections: ForecastMappingSection[]): NestedForecastMappingSection[] {
  const root = { sections: [] } as NestedForecastMappingSections;
  let levels = [root.sections];
  let prevLevel = 0;

  // we expect to start with a root level 0, remove anything invalid before that
  dropWhile(flatSections, section => section.level !== 0).forEach(section => {
    const nestedSection = cloneDeep(section) as NestedForecastMappingSection;

    if (isUndefined(nestedSection.level)) {
      return;
    }

    if (nestedSection.level === 0) {
      root.sections.push(nestedSection);
      levels = [root.sections];
    } else {
      // prev level
      if (nestedSection.level < prevLevel) {
        // drop all previous levels, and then add this nestedSection to its level root (un-nest)
        levels.length = nestedSection.level + 1;

        levels[nestedSection.level].push(nestedSection);
      }

      // same level
      if (nestedSection.level === prevLevel) {
        // push this nestedSection to the previous level
        const prevRoot = levels[prevLevel];
        prevRoot.push(nestedSection);
      }

      // next level
      if (nestedSection.level > prevLevel) {
        if (nestedSection.level !== prevLevel + 1)
          console.error(
            `unexpected level increase from ${prevLevel} to ${nestedSection.level}, assuming level ${
              prevLevel + 1
            } instead`
          );

        // add a level, and push this nestedSection to the previous level last nestedSection (nest)
        const prevRoot = levels[prevLevel];
        const lastSection = prevRoot[prevRoot.length - 1];

        if (!lastSection.sections) lastSection.sections = [];

        lastSection.sections.push(nestedSection);

        // make this the new root for the level
        levels[nestedSection.level] = lastSection.sections;
      }
    }

    prevLevel = nestedSection.level;
  });

  return root.sections;
}

function isCustomCalculation(value: ForecastMappingSection['value']): value is CustomCalculation {
  if (isNil(value)) return false;
  const cast = value as CustomCalculation;
  return !isNil(cast.sum); // TODO support minus, div independently
}

function removeSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  const [removed] = newMapping.splice(index, 1);

  // we removed a root, fix it
  if (newMapping[index]) {
    const removedRoot = newMapping[index].level > removed.level;

    // strategy: just unnest the next section (unnest one)
    // if (removedRoot) {
    //   --newMapping[index].level!
    // }

    // strategy: collapse the entire nested hierierchy (unnest all)
    if (removedRoot) {
      const removedLevel = removed.level;
      while (newMapping[index] && newMapping[index].level > removedLevel) {
        --newMapping[index].level;
        ++index;
      }
    }
  }

  return newMapping;
}

function changeSectionTitle(
  section: ForecastMappingSection | null,
  index: number,
  newTitle: string,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  const updateSection = newMapping[index];
  if (updateSection) {
    updateSection.title = newTitle;
  }
  return newMapping;
}

function changeSectionAccount(
  section: ForecastMappingSection,
  index: number,
  selectedAccount: HoneAccount | undefined,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);

  // auto-update the title to the account name?
  newMapping[index] = omit(section, ['value']);

  if (selectedAccount) {
    if (selectedAccount.AcctNum !== undefined) {
      newMapping[index].value = { eq: { acct_num: selectedAccount.AcctNum } };
    } else if (selectedAccount.id !== undefined) {
      newMapping[index].value = { section: selectedAccount.id };
    }

    if (newMapping[index].title === undefined || newMapping[index].title === '') {
      newMapping[index].title = selectedAccount.Name;
    }
  } else {
    newMapping[index].value = { eq: { acct_num: '' } };
    if (newMapping[index].title === undefined || newMapping[index].title === '') {
      newMapping[index].title = '';
    }
  }

  return newMapping;
}

function changeTag(
  section: ForecastMappingSection | null,
  index: number,
  accountTag: string | undefined,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  const updateSection = newMapping[index];
  if (updateSection) {
    if (accountTag) {
      updateSection.tag = accountTag;
    } else {
      unset(updateSection, 'tag');
    }
  }
  return newMapping;
}

function changeSectionPercent(
  section: ForecastMappingSection,
  index: number,
  accountTag: string | undefined,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  const updateSection = newMapping[index];
  if (updateSection) {
    if (accountTag) {
      updateSection.goalPercTag = accountTag;
    } else {
      unset(updateSection, 'goalPercTag');
    }
  }
  return newMapping;
}

function addRootSection(currentMapping: ForecastMappingSection[], index: number): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  const newSection = { level: 0, title: '', id: uuidv4(), type: 'Revenue' };
  const nextRootIndex = findIndex(newMapping, { level: 0 }, index + 1);

  if (nextRootIndex === -1) {
    // didnt find a root after this, we clicked last root, append to end
    newMapping.push(newSection);
    return newMapping;
  }

  // insert before next root
  newMapping.splice(nextRootIndex, 0, newSection);
  return newMapping;
}

// FIXME test this
function addSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  newMapping.splice(index + 1, 0, {
    title: '',
    level: section.level,
    id: uuidv4(),
    type: section.type,
  });

  return newMapping;
}

function addNestedSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);
  newMapping.splice(index + 1, 0, {
    title: '',
    level: section.level + 1,
    id: uuidv4(),
    type: section.type,
  });

  return newMapping;
}

function moveBeforePreviousRootSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);

  // remove this section
  const nextRootIndex = findIndex(newMapping, { level: 0 }, index + 1);

  let removedSections: ForecastMappingSection[];

  if (nextRootIndex === -1) {
    // moved last section up
    removedSections = newMapping.splice(index);
  } else {
    removedSections = newMapping.splice(index, nextRootIndex - index);
  }

  // find where to insert them back before previous root
  let newRootIndex = 0;
  for (let i = index - 1; i >= 0; i--) {
    if (newMapping[i].level === 0) {
      newRootIndex = i;
      break;
    }
  }

  newMapping.splice(newRootIndex, 0, ...removedSections);
  return newMapping;
}

function moveAfterNextRootSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);

  // remove this section
  const nextRootIndex = findIndex(newMapping, { level: 0 }, index + 1);
  const removedSections = newMapping.splice(index, nextRootIndex - index);
  // find where to insert them back after next root
  const newRootIndex = nextRootIndex - removedSections.length;
  const newNextRootIndex = findIndex(newMapping, { level: 0 }, newRootIndex + 1);

  if (newNextRootIndex < 0) {
    // moved to last section, append to the end
    newMapping.push(...removedSections);
  } else {
    newMapping.splice(newNextRootIndex, 0, ...removedSections);
  }

  return newMapping;
}

function moveBeforePreviousSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);

  // remove this section
  const removedSections: ForecastMappingSection[] = newMapping.splice(index, 1);
  const newIndex = index - 1;

  newMapping.splice(newIndex, 0, ...removedSections);
  return newMapping;
}

function moveAfterNextSection(
  section: ForecastMappingSection,
  index: number,
  currentMapping: ForecastMappingSection[]
): ForecastMappingSection[] {
  const newMapping = cloneDeep(currentMapping);

  // remove this section
  const removedSections = newMapping.splice(index, 1);

  // find where to insert them back after next section
  const newIndex = index + 1;
  newMapping.splice(newIndex, 0, ...removedSections);

  return newMapping;
}

function matchesTag(inputValue: string, account: string): boolean {
  return inputValue === account;
}

export {
  isCustomCalculation,
  addRootSection,
  addSection,
  addNestedSection,
  removeSection,
  changeSectionTitle,
  changeSectionAccount,
  changeTag,
  changeSectionPercent,
  matchesTag,
  moveBeforePreviousRootSection,
  moveAfterNextRootSection,
  moveBeforePreviousSection,
  moveAfterNextSection,
};
