import classNames from 'classnames';
import React from 'react';

type Props = {
  name?: string;
  label: string;
  value?: string;
  rows?: number;
  type: 'default' | 'success' | 'info' | 'warning' | 'danger';
  disabled?: boolean;
  width?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export default function TextArea({ disabled, name, label, value, rows = 5, type, width, onChange }: Props) {
  return (
    <div className="text-area-container" style={{ width }}>
      <label className="text-area-label">{label}</label>
      <textarea
        className={classNames('text-area-content', { [type]: type })}
        rows={rows}
        name={name}
        onChange={onChange}
        disabled={disabled}
        style={{ width }}
        value={value}
      />
    </div>
  );
}
