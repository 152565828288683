import { IconAccruals, IconAdd, IconEdit, IconForwardBtn, IconTrashCan } from 'components/Icons';
import { PageHeader } from 'presentation/components';

import { ErrorBoundary } from 'react-error-boundary';

import HoneTable from 'presentation/components/HoneTable';

import { JournalEntriesGeneralHeaders } from './utils';
import JournalEntriesErrorView from './JournalEntriesErrorView';

import { JournalEntry, JournalEntryLine, PostingTypes } from '@hone-automation/common';
import { useEffect, useMemo } from 'react';
import { currencyFormatter2Decimals, currencyStringToNumber, moveObjectAtIndex, showToast } from 'lib/utils';
import { JournalEntryLineFormmatted } from 'domain/models/JournalEntries';
import { useNavigate, useParams } from 'react-router-dom';
import { useJournalEntriesByExternalId } from 'hooks/journal-entries/useJournalEntriesByExternalId';
import { Header, TableCellValue } from 'presentation/components/HoneTable/types';
import Loading from 'components/Loading';
import TextArea from 'presentation/components/TextArea';
import { useQueryState } from 'hooks/useQueryState';
import { useJournalEntriesStore } from 'hooks/journal-entries/useJournalEntriesStore';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import JournalEntriesConfirmationModal from './JournalEntryConfirmationModal';
import { useCreateUpdateJEAccruals } from 'hooks/journal-entries/useCreateUpdateJeAccruals';
import { useHoneChartOfAccounts } from 'components/HoneReportTemplates';
import JournalEntriesCoaAutomcomplete from './JournalEntriesCoaAutocomplete';

export default function JournalEntriesByIdView() {
  const navigateTo = useNavigate();
  const [dateRange] = useQueryState('dateRange');
  const [searchIds] = useQueryState('searchIds');
  const { enableEditJe } = useFlags();

  const { locationId: currentLocationId, journalEntryId: journalEntryExternalId } = useParams();

  const {
    data: journalEntriesData,
    isLoading,
    refetch,
    isRefetching,
    isSuccess,
  } = useJournalEntriesByExternalId(String(journalEntryExternalId));
  const {
    mutate: createUpdateJeMutation,
    isPending: createJeLoading,
    isSuccess: createJeSuccess,
    isError: createJeError,
  } = useCreateUpdateJEAccruals();
  const { data: coa, isLoading: coaLoading } = useHoneChartOfAccounts(currentLocationId);
  const {
    journalEntries,
    setJournalEntries,
    setEnableEditMode,
    enableEditMode,
    setJournalEntriesEditable,
    journalEntriesEditable,
    confirmationCancelModalOpen,
    setConfirmationCancelModalOpen,
    setEntriesHasChanged,
    highlighRow,
    setHighlighRow,
    setReorderLoading,
    journalEntriesPayload,
    setJournalEntriesPayload,
    entriesHasChanged,
  } = useJournalEntriesStore();

  /**
   * Transformed journal entries data.
   * @type {JournalEntryLineFormmatted[] | undefined}
   */
  const transformedJournalEntriesData: JournalEntryLineFormmatted[] | undefined = useMemo(() => {
    if (enableEditMode && journalEntriesEditable) {
      const jeEditableDropdown =
        journalEntriesEditable &&
        journalEntriesEditable.map((item, idx) => {
          const coaAccount =
            coa &&
            journalEntriesPayload &&
            journalEntriesPayload?.length > 0 &&
            coa.find((acc: any) => acc.Id === journalEntriesPayload[idx].account);
          const filterDataPayload = journalEntriesPayload && journalEntriesPayload[idx];
          const account = filterDataPayload ? filterDataPayload.account : '';
          const coaAccountName = coaAccount && (coaAccount.AcctNum || '').concat(' ').concat(coaAccount.Name);
          return {
            ...item,
            account:
              highlighRow?.idx === idx ? (
                <JournalEntriesCoaAutomcomplete
                  coa={coa}
                  value={{
                    label: (coaAccountName as string) || (account as string),
                    value: (coaAccountName as string) || (account as string),
                  }}
                  handleOnchange={(newOption: string | undefined) => {
                    const tableValue: TableCellValue = {
                      row: idx,
                      key: 'account',
                      value: newOption,
                      col: 1,
                    };
                    onChange(tableValue);
                  }}
                />
              ) : (
                `${coaAccount ? (coaAccount.AcctNum || '').concat(' ').concat(coaAccount.Name) : account}`
              ),
          };
        });
      return jeEditableDropdown;
    }
    journalEntriesData && setJournalEntries([...journalEntriesData]);
    if (!enableEditMode && journalEntriesEditable) return journalEntriesEditable;
    setJournalEntriesPayload(
      journalEntriesData &&
        journalEntriesData[0].lines.map((item, idx) => ({
          account: `${item.accountExternalNumber} ${item.accountExternalName}`,

          debit: item.postingType === PostingTypes.Debit ? currencyFormatter2Decimals(item.amount) : null,
          credit: item.postingType === PostingTypes.Credit ? currencyFormatter2Decimals(item.amount) : null,
          description: item.description as string,
        }))
    );
    return (
      journalEntriesData &&
      [...journalEntriesData][0].lines.map((item: any, idx: number) => {
        return {
          account:
            highlighRow?.idx === idx ? (
              <JournalEntriesCoaAutomcomplete
                coa={coa}
                value={{
                  label: `${item.accountExternalNumber} ${item.accountExternalName}`,
                  value: item.accountExternalId,
                }}
                handleOnchange={(newOption: string | undefined) => {
                  const tableValue: TableCellValue = {
                    row: idx,
                    key: 'account',
                    value: newOption,
                    col: 1,
                  };
                  onChange(tableValue);
                }}
              />
            ) : (
              `${item.accountExternalNumber} ${item.accountExternalName}`
            ),
          debit: item.postingType === PostingTypes.Debit ? currencyFormatter2Decimals(item.amount) : null,
          credit: item.postingType === PostingTypes.Credit ? currencyFormatter2Decimals(item.amount) : null,
          description: item.description,
        };
      })
    );
  }, [journalEntriesData, enableEditMode, journalEntriesEditable, highlighRow, isSuccess]);

  useEffect(() => {
    if (createJeSuccess || createJeError) {
      refetch();
      setEnableEditMode(false);
    }
  }, [createJeSuccess, createJeError]);

  const journalEntriesHeaders = useMemo(() => {
    if (!enableEditMode) return JournalEntriesGeneralHeaders;

    const newJournalEntriesHeaders = JournalEntriesGeneralHeaders.map((header: Header) => {
      if (header.key === 'action') {
        return { ...header, hidden: false };
      }
      return { ...header, editable: true };
    });

    return newJournalEntriesHeaders;
  }, [enableEditMode, JournalEntriesGeneralHeaders]);

  /**
   * Handles the deletion of a row in the journal entries table.
   *
   * @param {number} idx - The index of the row to be deleted.
   */
  const handleDeleteRow = (idx: number) => {
    const newLines = transformedJournalEntriesData
      ? [...transformedJournalEntriesData]
      : journalEntriesEditable
        ? [...journalEntriesEditable]
        : [];
    newLines.splice(idx, 1);
    setJournalEntriesEditable([]);
    setTimeout(() => {
      setJournalEntriesEditable(newLines);
      setJournalEntriesPayload(newLines);
    }, 1);
  };

  /**
   * Redirects to the accruals page with the specified parameters.
   */
  const redirectAccruals = () => {
    let url = `/app/location/${currentLocationId}/journal-entries/${journalEntryExternalId}/accruals?dateRange=${dateRange}`;
    if (typeof searchIds !== 'undefined') url += `&searchIds=${searchIds}`;
    navigateTo(url);
  };

  /**
   * Redirects to the journal entries page with the specified location ID and date range.
   * Optionally includes search IDs in the URL query parameters.
   */
  const redirectJournalEntries = () => {
    let url = `/app/location/${currentLocationId}/journal-entries?dateRange=${dateRange}`;
    if (typeof searchIds !== 'undefined') url += `&searchIds=${searchIds}`;
    navigateTo(url);
  };

  /**
   * Renders the breadcrumb component.
   * @returns The breadcrumb component.
   */
  const breadcrumb = () => {
    return (
      <ol className="breadcrumb">
        <li onClick={redirectJournalEntries} className="breadcrumb-clickable">
          Journal Entries
        </li>
        <li className="breadcrumb-separator">
          <IconForwardBtn />
        </li>
        <li className="breadcrumb-current-item">{journalEntriesData && journalEntriesData[0].name}</li>
      </ol>
    );
  };
  /**
   * Handles the change event for a table cell value.
   * @param {TableCellValue} cellValue - The new value of the table cell.
   */
  const onChange = (cellValue: TableCellValue) => {
    const tempJEPayload = journalEntriesPayload ? [...journalEntriesPayload] : [];
    const newEditableJournalEntries = transformedJournalEntriesData ? [...transformedJournalEntriesData] : [];
    const newValue = isNumber(cellValue.value) ? currencyFormatter2Decimals(cellValue.value) : cellValue.value;
    const tableMappedCell = {
      ...newEditableJournalEntries[cellValue.row as any],
      debit: cellValue.key === 'credit' ? 'x' : newEditableJournalEntries[cellValue.row as any].debit,
      credit: cellValue.key === 'debit' ? 'x' : newEditableJournalEntries[cellValue.row as any].credit,
      [cellValue.key as any]: newValue,
    };
    const tableCellPayload = {
      ...newEditableJournalEntries[cellValue.row as any],
      account: cellValue.key === 'account' ? newValue : tempJEPayload[cellValue.row as any].account,
      debit: cellValue.key === 'credit' ? 'x' : newEditableJournalEntries[cellValue.row as any].debit,
      credit: cellValue.key === 'debit' ? 'x' : newEditableJournalEntries[cellValue.row as any].credit,
      [cellValue.key as any]: newValue,
    };
    if (tempJEPayload) {
      tempJEPayload[cellValue.row as any] = tableCellPayload as any;
      setJournalEntriesPayload(tempJEPayload);
    }

    newEditableJournalEntries[cellValue.row as any] = tableMappedCell as any;
    setJournalEntriesEditable(newEditableJournalEntries);
  };

  const totalDebit = useMemo(() => {
    if (enableEditMode && journalEntriesEditable) {
      return journalEntriesEditable.reduce((acc, item) => {
        const debitValue = currencyStringToNumber(item.debit);
        if (debitValue) return acc + Number(debitValue);
        return acc;
      }, 0);
    }
    return journalEntriesData && journalEntriesData[0].totalDebits;
  }, [journalEntriesData, journalEntriesEditable, enableEditJe]);

  const totalCredit = useMemo(() => {
    if (enableEditMode && journalEntriesEditable) {
      return journalEntriesEditable.reduce((acc, item) => {
        const creditValue = currencyStringToNumber(item.credit);
        if (creditValue) return acc + Number(creditValue);
        return acc;
      }, 0);
    }
    return journalEntriesData && journalEntriesData[0].totalCredits;
  }, [journalEntriesData, journalEntriesEditable, enableEditJe]);

  const balanceIsZero = useMemo(() => {
    return (
      totalDebit && totalCredit && currencyFormatter2Decimals(totalDebit) === currencyFormatter2Decimals(totalCredit)
    );
  }, [totalDebit, totalCredit]);

  const balanceDebitGreater = useMemo(() => {
    const calculatedDebit = totalDebit && totalCredit && totalDebit > totalCredit && totalDebit - totalCredit;

    return calculatedDebit;
  }, [totalDebit, totalCredit]);

  const balanceCreditGreater = useMemo(() => {
    const calculatedCredit = totalDebit && totalCredit && totalDebit < totalCredit && totalCredit - totalDebit;
    return calculatedCredit;
  }, [totalDebit, totalCredit]);

  const handleSaveJe = () => {
    if (journalEntries) {
      const newLines = journalEntriesPayload ? [...journalEntriesPayload] : [];
      const newJournalEntriesLines: JournalEntryLine[] = newLines.map((item, idx) => {
        const matchingCoa = coa && coa.find((acc: any) => acc.Id === item.account);

        const accountExternalName = matchingCoa
          ? matchingCoa.Name
          : item && item.account && (item.account as string).split(' ').slice(1).join(' ');
        const accountExternalNumber = matchingCoa
          ? matchingCoa.AcctNum
          : item && item.account && (item.account as string).split(' ')[0];
        const accountExternalId = matchingCoa
          ? matchingCoa.Id
          : coa && coa.find((acc: any) => acc.Name === accountExternalName)?.Id;

        return {
          accountExternalName: accountExternalName as string,
          accountExternalNumber: (accountExternalNumber as string) || '',
          amount: currencyStringToNumber(item.debit) || currencyStringToNumber(item.credit),
          description: item.description,
          postingType: item.debit && item.debit !== 'x' ? PostingTypes.Debit : PostingTypes.Credit,
          accountExternalId: accountExternalId as string,
        };
      });
      journalEntries[0].lines = newJournalEntriesLines;
      journalEntries[0].totalDebits = totalDebit || 0;
      journalEntries[0].totalCredits = totalCredit || 0;
      createUpdateJeMutation([...journalEntries]);
    }
  };

  const handleChangeMemo = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const tempJe = [...journalEntries];
    const newJournalEntries: JournalEntry[] = tempJe ? [...tempJe] : [];
    newJournalEntries[0].memo = e.target.value;
    setJournalEntries(newJournalEntries);
    setEntriesHasChanged(true);
  };

  const handleCancelEdition = () => {
    if (totalCredit !== totalDebit || entriesHasChanged) setConfirmationCancelModalOpen(true);
    else setEnableEditMode(false);
  };

  const handleConfirmCancelEdition = () => {
    setEnableEditMode(false);
    setConfirmationCancelModalOpen(false);
    setJournalEntriesEditable(undefined);
    setJournalEntriesPayload(undefined);
    refetch();
  };

  useEffect(() => {
    if (!journalEntriesData) return;
    setJournalEntriesEditable([]);

    const newLines =
      journalEntriesData &&
      journalEntriesData[0] &&
      journalEntriesData[0].lines.map((item: any) => ({
        account: `${item.accountExternalNumber} ${item.accountExternalName}`,
        debit: item.postingType === PostingTypes.Debit ? currencyFormatter2Decimals(item.amount) : null,
        credit: item.postingType === PostingTypes.Credit ? currencyFormatter2Decimals(item.amount) : null,
        description: item.description,
      }));
    setJournalEntriesEditable(newLines);
  }, [isRefetching]);

  const handleCloseEditionModal = () => {
    setConfirmationCancelModalOpen(false);
  };

  /**
   * Handles the click event on a row in the journal entries table.
   * Sets the highlighted row and type to "info".
   *
   * @param item - The journal entry line item.
   * @param idxRow - The index of the clicked row.
   */
  const handleClickRow = (item: JournalEntryLineFormmatted, idxRow: number) => {
    setHighlighRow({ idx: idxRow, type: 'info' });
  };

  /**
   * Moves an element in the transformedJournalEntriesData array from one index to another.
   *
   * @param idxFrom - The index of the element to move.
   * @param idxToMove - The index to move the element to.
   */
  const moveElement = (idxFrom: number, idxToMove: number) => {
    if (transformedJournalEntriesData) {
      const sortedArray = moveObjectAtIndex<JournalEntryLineFormmatted>(
        transformedJournalEntriesData,
        idxFrom,
        idxToMove
      );
      const sortedArrayPayload =
        journalEntriesPayload &&
        moveObjectAtIndex<JournalEntryLineFormmatted>(journalEntriesPayload, idxFrom, idxToMove);

      setJournalEntriesEditable([]);
      setReorderLoading(true);
      setTimeout(() => {
        setHighlighRow({ idx: idxToMove, type: 'info' });
        setJournalEntriesPayload(sortedArrayPayload);
        setJournalEntriesEditable(sortedArray);
        setReorderLoading(false);
      }, 1);
    }
  };

  /**
   * Moves an element in the array one position up.
   * @param {number} idxFrom - The index of the element to move.
   * @returns {void}
   */
  const handleClickOrderUp = (idxFrom: number) => {
    if (idxFrom === 0) return;
    moveElement(idxFrom, idxFrom - 1);
  };

  /**
   * Handles the click event for ordering an element down in the journal entries list.
   * Moves the element at the specified index down by one position.
   *
   * @param {number} idxFrom - The index of the element to be moved.
   */
  const handleClickOrderDown = (idxFrom: number) => {
    if (transformedJournalEntriesData && idxFrom === transformedJournalEntriesData.length - 1) return;
    moveElement(idxFrom, idxFrom + 1);
  };

  const isEditModeAndRowSelected = enableEditMode && highlighRow;

  /**
   * Adds a new line to the journal entries payload.
   */
  const handleAddNewLine = () => {
    const newLines = journalEntriesPayload ? [...journalEntriesPayload] : [];
    const coaExists = coa && coa.length > 0;
    const acctNum = coaExists && coa[0] && coa[0].AcctNum;
    const accountName = coaExists && coa[0] && coa[0].Name;
    const newRow: JournalEntryLineFormmatted = {
      account: acctNum ? acctNum.concat(` ${coa[0].Name}`) : accountName,
      debit: null,
      credit: null,
      description: '',
    };

    if (balanceCreditGreater) {
      newRow.debit =
        balanceCreditGreater && balanceCreditGreater > 0.001 ? currencyFormatter2Decimals(balanceCreditGreater) : 'x';
    }

    if (balanceDebitGreater) {
      newRow.credit =
        balanceDebitGreater && balanceDebitGreater > 0.001 ? currencyFormatter2Decimals(balanceDebitGreater) : 'x';
    }

    newLines.push(newRow);
    setJournalEntriesPayload(newLines);
    setJournalEntriesEditable(newLines);
  };

  const handleNumberToPlaceholder = (value: number) => {
    return value === 0 ? 'x' : currencyFormatter2Decimals(value);
  };

  const handlePlaceholderToNumber = (value: string): number => {
    return value === 'x' ? 0 : Number(value);
  };

  const handleBalanceJe = () => {
    //validate if the balance is zero or if the journal entries payload is empty or if the highlighted row is not selected
    if (balanceIsZero || !journalEntriesPayload || !highlighRow) return;
    //get current journal entries payload
    const newLines = journalEntriesPayload ? [...journalEntriesPayload] : [];
    //get the selected row
    const selectedRow = journalEntriesPayload[highlighRow.idx as number];
    //get the selected row debit and credit values and remove the $ and commas tranforming on number
    const selectedRowDebit = selectedRow.debit ? selectedRow.debit?.replace('$', '').replace(',', '') : 0;
    const selectedRowCredit = selectedRow.credit ? selectedRow.credit?.replace('$', '').replace(',', '') : 0;

    //check if the balance debit is greater than zero
    if (balanceCreditGreater) {
      //calculate the new value of the selected row debit
      const newCalculatedValue = selectedRowDebit
        ? handlePlaceholderToNumber(String(selectedRowDebit)) + balanceCreditGreater
        : balanceCreditGreater;
      //calculate the credit value of the selected row
      const creditCalculation =
        +handlePlaceholderToNumber(selectedRowCredit as string) - +newCalculatedValue.toFixed(2);
      //check if the credit calculation is greater than zero
      if (creditCalculation > 0) {
        selectedRow.credit = selectedRowCredit !== 'x' ? handleNumberToPlaceholder(creditCalculation) : 'x';
        selectedRow.debit = selectedRowCredit === 'x' ? handleNumberToPlaceholder(newCalculatedValue) : 'x';
      } else {
        selectedRow.credit = 'x';
        selectedRow.debit = handleNumberToPlaceholder(creditCalculation * -1);
      }
    } // check if the balance credit is greater and it's unbalanced
    else if (balanceDebitGreater) {
      //calculate the new value of the selected row credit
      const newCalculatedValue = selectedRow.credit
        ? handlePlaceholderToNumber(String(selectedRowCredit)) + balanceDebitGreater
        : balanceDebitGreater;
      //calculate the debit value of the selected row
      const debitCalculation = +handlePlaceholderToNumber(selectedRowDebit as string) - +newCalculatedValue.toFixed(2);
      //check if the debit calculation is greater than zero
      if (debitCalculation > 0) {
        //update the selected row credit and debit values when calculation it's greater than zero
        selectedRow.debit = selectedRowDebit !== 'x' ? handleNumberToPlaceholder(debitCalculation) : 'x';
        selectedRow.credit = selectedRowDebit === 'x' ? handleNumberToPlaceholder(newCalculatedValue) : 'x';
      } else {
        selectedRow.debit = 'x';
        selectedRow.credit = handleNumberToPlaceholder(debitCalculation * -1);
      }
    }
    // Update the selected row in newLines array
    newLines[highlighRow.idx as number] = selectedRow;
    // Update the journal entries payload and set it as editable
    setJournalEntriesPayload(newLines);
    setJournalEntriesEditable(newLines);
  };

  const newRowContainer = () => {
    return (
      <>
        <button className="hone-table-add-new-row-button enable-hover" onClick={handleAddNewLine}>
          NEW ROW <IconAdd />
        </button>
        <button
          className="hone-table-add-new-row-button second-button orange-bg"
          onClick={handleBalanceJe}
          disabled={!!balanceIsZero}
        >
          APPLY BALANCE
        </button>
      </>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={JournalEntriesErrorView}>
      <div className="report-view">
        <PageHeader icon={<IconAccruals />} pageTitle={breadcrumb()} addPadding></PageHeader>

        <div className="report-view-journal-entries-header">
          <div>
            <div className="report-view-journal-entries-header__item">
              <label>Journal Date</label>
              <input value={journalEntriesData && journalEntriesData[0].date} />
            </div>
            <div className="report-view-journal-entries-header__item">
              <label>Journal #</label>
              <input value={journalEntriesData && journalEntriesData[0].name} />
            </div>
          </div>
          <div>
            <div className="report-view-journal-entries-header__item">
              {/* <label>IMPORT</label>
              <button disabled>EXCEL</button> */}
            </div>
            <div>
              <div className="report-view-journal-entries-header__item actions-btn-container">
                {enableEditMode ? (
                  <>
                    <button onClick={handleCancelEdition}>CANCEL</button>
                    <JournalEntriesConfirmationModal
                      title="Cancel Journal Entries Edition"
                      isOpen={confirmationCancelModalOpen}
                      handleConfirm={handleConfirmCancelEdition}
                      handleClose={handleCloseEditionModal}
                    />
                    <button disabled={!balanceIsZero} onClick={handleSaveJe}>
                      SAVE
                    </button>
                  </>
                ) : (
                  <>
                    <div className="report-view-journal-entries-header__item_container">
                      {/* <label>ACTIONS</label> */}
                      {/* <button disabled>REVERSE</button> */}
                      <button
                        onClick={redirectAccruals}
                        disabled={!journalEntriesData || !journalEntriesData[0]?.canAccrue}
                      >
                        ACCRUAL
                      </button>
                      {/* <button disabled>RECURRING</button> */}
                    </div>
                    {enableEditJe && (
                      <button
                        className="report-view-journal-entries-header__item-edit"
                        onClick={() => setEnableEditMode(true)}
                      >
                        <IconEdit />
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="report-view-je">
          {isLoading || createJeLoading || isRefetching || coaLoading ? (
            <Loading />
          ) : (
            <>
              {transformedJournalEntriesData && (
                <HoneTable<JournalEntryLineFormmatted>
                  containerHeight={enableEditMode ? '500px' : '600px'}
                  data={transformedJournalEntriesData}
                  headers={journalEntriesHeaders}
                  enableOddBackground
                  enabledRowCounter
                  onChange={onChange}
                  handleClickRow={enableEditMode ? handleClickRow : () => {}}
                  hightLightRow={enableEditMode ? highlighRow : undefined}
                  handleClickOrderDown={handleClickOrderDown}
                  handleClickOrderUp={handleClickOrderUp}
                  handleDeleteRow={handleDeleteRow}
                ></HoneTable>
              )}
              {enableEditMode && <div className="report-view-je-footer-new-row">{newRowContainer()}</div>}
              <div className="report-view-je-footer">
                <div className={'report-view-je-footer-total'}>
                  <div
                    className={classNames('report-view-je-footer-total__title', {
                      'edit-mode-enabled': isEditModeAndRowSelected,
                    })}
                  >
                    TOTAL
                  </div>
                  <div
                    className={classNames('report-view-je-footer-total_item debit', {
                      'edit-mode-enabled': isEditModeAndRowSelected,
                    })}
                  >
                    {totalDebit && currencyFormatter2Decimals(totalDebit)}
                  </div>
                  <div
                    className={classNames('report-view-je-footer-total_item credit', {
                      'edit-mode-enabled': isEditModeAndRowSelected,
                    })}
                  >
                    {totalCredit && currencyFormatter2Decimals(totalCredit)}
                  </div>
                </div>
                {!balanceIsZero && (
                  <div className={'report-view-je-footer-total'}>
                    <div
                      className={classNames('report-view-je-footer-total__title', {
                        'edit-mode-enabled': isEditModeAndRowSelected,
                      })}
                    >
                      BALANCE
                    </div>
                    <div
                      className={classNames('report-view-je-footer-total_item balance', {
                        'balance-greater': balanceDebitGreater,
                        'balance-lower': !balanceDebitGreater,
                        'edit-mode-enabled': isEditModeAndRowSelected,
                      })}
                    >
                      {balanceDebitGreater ? currencyFormatter2Decimals(balanceDebitGreater) : 'x'}
                    </div>
                    <div
                      className={classNames('report-view-je-footer-total_item balance', {
                        'balance-greater': balanceCreditGreater,
                        'balance-lower': !balanceCreditGreater,
                        'edit-mode-enabled': isEditModeAndRowSelected,
                      })}
                    >
                      {balanceCreditGreater ? currencyFormatter2Decimals(balanceCreditGreater) : 'x'}
                    </div>
                  </div>
                )}
                <div className="report-view-je-text-area">
                  <TextArea
                    label="JE Notes"
                    value={journalEntries[0] && journalEntries[0].memo}
                    rows={5}
                    type="default"
                    disabled={!enableEditMode}
                    width="497px"
                    onChange={handleChangeMemo} //todo
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}
