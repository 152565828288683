import { useQuery } from '@tanstack/react-query';
import { makeRemoteAskRequestsCount } from 'main/factories/usecases/ask-requests/remote-get-ask-requests-count';
import { useParams } from 'react-router-dom';

const askRequestsCount = makeRemoteAskRequestsCount();

export const useAskRequestsCountQuery = () => {
  const { locationId } = useParams();
  return useQuery({
    queryKey: ['askRequestsCount', locationId],
    staleTime: 5000,
    queryFn: async () => {
      return askRequestsCount.get({ locationId });
    },
  });
};
