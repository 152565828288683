import { useMutation } from '@tanstack/react-query';
import { FIVE_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';

import { makeRemoteGenerateAccruals } from 'main/factories/usecases';

export const useJournalEntriesGenerateAccruals = (locationId: string, externalId: number) => {
  return useMutation({
    mutationFn: (data: any) => {
      const service = makeRemoteGenerateAccruals();
      return service.createAccruals({ locationId, externalId });
    },
  });
};
