import { IconLeft, IconRight } from 'components/Icons';
import { MouseEvent } from 'react';
import KSDropdown from '../KSDropdown';
import { Option } from 'types';

import './KSInputNumberAutoincrease.scss';

export type KSInputNumberAutoincrease = {
  value: number | undefined;
  label?: string;
  disabledIncrease: boolean;
  disabledDecrease: boolean;
  onIncrease: (e: MouseEvent<HTMLButtonElement>) => void;
  onDecrease: (e: MouseEvent<HTMLButtonElement>) => void;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function KSInputNumberAutoincrease({
  value,
  label,
  disabledIncrease,
  disabledDecrease,
  onChangeInput,
  onIncrease,
  onDecrease,
}: KSInputNumberAutoincrease) {
  return (
    <div className="ks-input-number-auto-increase">
      <span className="ks-input-number-label">{label}</span>
      <div className="ks-input-number-content">
        <div className="ks-input-number">
          <button onClick={onDecrease} type="button" disabled={disabledDecrease}>
            <IconLeft />
          </button>
          <input className="quantity" min="0" name="quantity" value={value} type="number" onChange={onChangeInput} />
          <button onClick={onIncrease} type="button" disabled={disabledIncrease}>
            <IconRight />
          </button>
        </div>
      </div>
    </div>
  );
}
