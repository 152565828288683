import { useEffect } from 'react';
import { useNTContext } from '../NTContext';
import { useNTScroll } from '../NTScrollContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { useNTSelectionContext } from '../NTSelectionContext';
import { useNTTableContext } from '../NTTableContext';

export const useScrollProperties = () => {
  const { scrollState, setScrollState, scrollChange } = useNTScroll();
  const { filters } = useNTContext();
  const { actions } = useNTUtilsContext();
  const { isResizing } = useNTTableContext();
  const { setIsScrolling } = useNTSelectionContext();

  useEffect(() => {
    updateScrollProperties(scrollChange.axis, scrollChange.amount, scrollChange.browser);
  }, [scrollChange]);

  // I have a ton of clean up to do here regarding the axis. Probably will need to update all properties at once?
  // Maybe create a holder like tableScrollElement = axis === 'x' ? tableMiddle : tableYScroll ? that will  clean up a ton of if cases
  const updateScrollProperties = (axis: 'x' | 'y', movementAmount?: number, browser?: boolean) => {
    // scroll elements
    const headerMiddle = document.getElementById('header-middle');
    const tableMiddle = document.getElementById('table-middle');
    const tableYScroll = document.getElementById('table-y-scroll');
    const tableScroll = axis === 'x' ? tableMiddle : tableYScroll;

    const table = document.getElementById('new-table');

    // browser elements
    const columnBrowserArea = document.getElementById('columnBrowserArea');
    const columnBrowserWindow = document.getElementById('columnBrowserWindow');
    const columnBrowserElemPadding = getComputedStyle(document.documentElement)
      .getPropertyValue('--ks-column-browser-padding')
      .trim();

    // mminimap elements
    const miniMapArea = document.getElementById('miniMapArea');
    const miniMapWindow = document.getElementById('miniMapWindow');
    const miniMapWindowArea = document.getElementById('miniMapWindowArea');

    const maskedAreaTotal =
      axis === 'x'
        ? columnBrowserArea
          ? columnBrowserArea.offsetWidth - parseInt(columnBrowserElemPadding)
          : 0
        : miniMapArea
          ? miniMapArea.offsetHeight
          : 0;

    if (!table || !headerMiddle || !tableMiddle || !tableYScroll || !tableScroll) return;

    // calculate scroll properties
    const scrollProperty = axis === 'x' ? 'scrollLeft' : 'scrollTop';
    const scrollDimension = axis === 'x' ? 'scrollWidth' : 'scrollHeight';
    const visibleAreaOfScroll =
      axis === 'x'
        ? tableMiddle.offsetWidth / tableMiddle[scrollDimension]
        : tableYScroll.offsetHeight / tableYScroll[scrollDimension];
    const maskedArea = maskedAreaTotal * visibleAreaOfScroll;

    const scrollObj = { position: 0, percentage: 0 };

    if (axis === 'x') {
      scrollObj.position = movementAmount ? movementAmount : tableMiddle[scrollProperty];
      scrollObj.percentage =
        (movementAmount ? movementAmount : tableMiddle[scrollProperty]) / tableMiddle[scrollDimension];
    } else {
      scrollObj.position = movementAmount ? movementAmount : tableYScroll[scrollProperty];
      scrollObj.percentage =
        (movementAmount ? movementAmount : tableYScroll[scrollProperty]) / tableYScroll[scrollDimension];
    }

    // if browser is true, we are moving the browser window
    if (movementAmount) {
      if (browser) {
        const legalMax = Math.max(0, Math.min(movementAmount, maskedAreaTotal - maskedArea));
        scrollObj.percentage = legalMax / maskedAreaTotal;
        scrollObj.position =
          axis === 'x'
            ? tableMiddle[scrollDimension] * scrollObj.percentage
            : tableYScroll[scrollDimension] * scrollObj.percentage;
      } else {
        const minPosition =
          axis === 'x'
            ? tableMiddle[scrollDimension] - tableMiddle.offsetWidth
            : tableYScroll[scrollDimension] - tableYScroll.offsetHeight;
        const legalMax = Math.max(0, Math.min(movementAmount, minPosition));

        scrollObj.percentage =
          axis === 'x' ? legalMax / tableMiddle[scrollDimension] : legalMax / tableYScroll[scrollDimension];
      }
    }

    const scrollPosition = scrollObj.position;
    const scrollPercentage = scrollObj.percentage;

    const browserAreaX = scrollPercentage * maskedAreaTotal + parseInt(columnBrowserElemPadding) / 2;
    const browserAreaY = scrollPercentage * maskedAreaTotal - 4;

    // paint
    // We only update the column browser if it exists and we are scrolling horizontally
    if (columnBrowserWindow && axis === 'x') {
      columnBrowserWindow.style.width = `${maskedArea}px`;
      columnBrowserWindow.style.left = `${browserAreaX}px`;
      headerMiddle[scrollProperty] = scrollPosition;
    }
    if (miniMapWindow) {
      if (axis === 'y') {
        miniMapWindow.style.height = `${maskedArea}px`;
        miniMapWindow.style.top = `${browserAreaY}px`;
      }
      if (miniMapWindowArea && axis === 'x') {
        const miniMapAreaWidth = miniMapWindow.offsetWidth - 16 - 6; // firstcolumnWidth (16px) + left Offset (-6px)
        miniMapWindowArea.style.width = `${miniMapAreaWidth * visibleAreaOfScroll}px`;
        // Let's add the right margin (-6px) to compensate the margins of the container fro display the inner window
        miniMapWindowArea.style.left = `${(miniMapAreaWidth - 6) * scrollPercentage + 16 + 4}px`;
      }
    }
    tableScroll[scrollProperty] = scrollPosition;

    const scrollStatetoUpdate: any = {
      [axis === 'x' ? 'horizontalScroll' : 'verticalScroll']: scrollPosition,
      [axis === 'x' ? 'horizontalScrollPercentage' : 'verticalScrollPercentage']: scrollPercentage,
      scrollAreaWidth: tableMiddle.offsetWidth,
      scrollAreaHeight: tableYScroll[scrollDimension],
    };
    if (axis === 'x') {
      scrollStatetoUpdate.visibleAreaOfScrollHorizontal = visibleAreaOfScroll;
      scrollStatetoUpdate.columnBrowserVisible = visibleAreaOfScroll < 1;
    } else {
      scrollStatetoUpdate.visibleAreaOfScrollVertical = visibleAreaOfScroll;
      scrollStatetoUpdate.minimapVisible = visibleAreaOfScroll < 1;
    }

    setScrollState(prevState => ({ ...prevState, ...scrollStatetoUpdate }));
  };

  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      updateScrollProperties('y');
      updateScrollProperties('x');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Effect to update scroll properties when filters or actions are applied
  useEffect(() => {
    updateScrollProperties('y');
    updateScrollProperties('x');
  }, [filters, actions]);

  // Enable custom scroll to control properly the scroll of the table, specially the horizontal one and the browsers
  // It also prevents the excessive scroll to trigger the back button of the browser (Trackpad)
  useEffect(() => {
    let wheelTimeout: NodeJS.Timeout;
    let wheelStartTimeout: NodeJS.Timeout;
    let isWheeling = false;

    const handleWheel = (event: WheelEvent) => {
      // In order to speed up the scroll, we set the isScrolling value of the selection to true, so when we are scrolling, the cell is not trying to update the value of hover on the SelectionState
      if (!isWheeling) {
        isWheeling = true;
        clearTimeout(wheelStartTimeout);
        wheelStartTimeout = setTimeout(() => {
          //console.log('Wheel event started');
          setIsScrolling(true);
        }, 10);
      }

      // Prevent excessive horizontal scroll (e.g., swiping too much to the left)
      const target = event.target as HTMLElement;
      const isInHeaderOrTable = target.closest('.new-table') !== null;
      const headerMiddle = document.getElementById('header-middle');
      const tableMiddle = document.getElementById('table-middle');
      const tableYScroll = document.getElementById('table-y-scroll');
      if (tableMiddle && headerMiddle && isInHeaderOrTable && tableYScroll && !isResizing) {
        const axis = Math.abs(event.deltaX) > Math.abs(event.deltaY) + 1 ? 'x' : 'y';
        const scrollProperty = axis === 'x' ? 'scrollLeft' : 'scrollTop';
        const scrollPosition = axis === 'x' ? tableMiddle[scrollProperty] : tableYScroll[scrollProperty];
        const scroll = axis === 'x' ? event.deltaX : event.deltaY;
        updateScrollProperties(axis, scrollPosition + scroll);
      }
      if (event.deltaX < -50) {
        event.preventDefault();
      }

      // Clear the previous timeout
      clearTimeout(wheelTimeout);

      // Set a new timeout
      wheelTimeout = setTimeout(() => {
        isWheeling = false;
        setIsScrolling(false);
      }, 100);
    };
    // Add wheel event listener when the component mounts
    window.addEventListener('wheel', handleWheel, { passive: false });

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('wheel', handleWheel);
      clearTimeout(wheelStartTimeout);
      clearTimeout(wheelTimeout);
    };
  }, []);

  return {
    updateScrollProperties,
    scrollState,
  };
};
