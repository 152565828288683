import React from 'react';

type ScreenSettingsTypeProps = {
  children: React.ReactNode;
};

function ScreenSettings({ children }: ScreenSettingsTypeProps): JSX.Element {
  return <div className="screen-settings">{children}</div>;
}

export default ScreenSettings;
