import { Cell, CellProps } from 'rsuite-table';
import { memo, useMemo } from 'react';
import { DismissedBalanceSheetPayload } from '@hone-automation/common';
import SparklinesChart from 'presentation/components/ReportTable/SparklinesChart';
import { useParams } from 'react-router-dom';

function GraphCell(props: CellProps): JSX.Element | null {
  const { locationId } = useParams();

  const { rowData } = props;

  const { data, display, dismissed } = rowData;

  const chartData = useMemo(() => data.map(({ amount }: { amount: number }) => amount), [data]);

  if (display.includes('header')) {
    return <Cell {...props} className={rowData.display} />;
  }
  const dismissPayload: DismissedBalanceSheetPayload = {
    locationId: String(locationId),
    title: rowData.title,
    dismissedSection: {
      dismissed: !dismissed,
    },
  };

  return (
    <Cell {...props} className={rowData.display}>
      <SparklinesChart chartData={chartData} dismissed={dismissed} dismissPayload={dismissPayload} />
    </Cell>
  );
}

export default memo(GraphCell);
