import React, { createContext, useContext, useState } from 'react';

type NTTableContextType = {
  redimParent: HTMLDivElement | null;
  setRedimParent: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  isResizing: boolean;
  setIsResizing: React.Dispatch<React.SetStateAction<boolean>>;
  tableWidth: string;
  setTableWidth: React.Dispatch<React.SetStateAction<string>>;
};

const NTTableContext = createContext<NTTableContextType | undefined>(undefined);
export const NTTableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [redimParent, setRedimParent] = useState<HTMLDivElement | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [tableWidth, setTableWidth] = useState('100%');
  return (
    <NTTableContext.Provider
      value={{ redimParent, setRedimParent, isResizing, setIsResizing, tableWidth, setTableWidth }}
    >
      {children}
    </NTTableContext.Provider>
  );
};

export const useNTTableContext = () => {
  const context = useContext(NTTableContext);
  if (!context) {
    throw new Error('useNTTableContext must be used within a NTTableProvider');
  }
  return context;
};
