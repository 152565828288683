import { prefixClass } from '../../../lib/utils';
import React, { useState, useEffect } from 'react';
import { IconClose, IconDown } from '../../../components/Icons';
import './KSLocationItem.scss';
import classNames from 'classnames';
import { Tooltip, Whisper } from 'rsuite';

type KSLocationItemProps = {
  location: string;
  org?: string | null;
  locationId: string;
  colorIndex?: number;
  activeLocation?: boolean;
  onLocationChange?: (locationId: string) => void;
  onRemoveLocation?: (locationId: string) => void;
};

const KSLocationItem = ({
  location,
  org = null,
  locationId,
  colorIndex = 0,
  activeLocation = false,
  onLocationChange,
  onRemoveLocation,
}: KSLocationItemProps) => {
  const prefix = prefixClass('ks-location-container');
  const tooltip = org ? (
    <>
      <b>{location}</b>
      <br />
      <p style={{ textTransform: 'uppercase', fontSize: '10px', opacity: 0.8 }}>{org}</p>
    </>
  ) : (
    <>
      <b>{location}</b>
    </>
  );
  const initialColors = [
    'green-bluish',
    'bluish',
    'cyanish',
    'purple',
    'deep-purple',
    'orangish',
    'redish',
    'yellowish',
  ];
  let initials = location
    .split(' ')
    .map(word => word[0])
    .join('');

  const InitialsBG = 'var(--ks-chart-' + initialColors[colorIndex] + ')';

  if (location.split(' ').length > 1) initials = initials.substring(0, 2);
  else initials = location.substring(0, 2);

  return (
    <Whisper
      placement="bottom"
      trigger="hover"
      speaker={<Tooltip>{tooltip}</Tooltip>}
      delayOpen={600} // Delay in milliseconds before the tooltip appears
      delayClose={300} // Delay in milliseconds before the tooltip disappears
      className="ks-location-tooltip"
    >
      <div
        className={classNames(prefix(), { active: activeLocation })}
        // Select
        onClick={e => {
          e.stopPropagation();
          onLocationChange && onLocationChange(locationId);
        }}
      >
        <div className={prefix('legend')}>
          <div className={prefix('legend-avatar')}>
            <div className={prefix('legend-avatar-initials')} style={{ backgroundColor: InitialsBG }}>
              {initials}
            </div>
          </div>
          <div className={prefix('legend-text')}>{location}</div>
        </div>
        <div className={prefix('action')}>
          <div
            className={prefix('action-icon')}
            // Close
            onClick={e => {
              e.stopPropagation();
              onRemoveLocation && onRemoveLocation(locationId);
            }}
          >
            <IconClose />
          </div>
        </div>
      </div>
    </Whisper>
  );
};

export default KSLocationItem;
