import { makeApiUrl } from 'main/factories/http';
import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { GetFindMonthlyDataRows } from 'domain/usecases/reports';
import { RemoteFindMonthlyDataRows } from 'data/usecases';

export const makeFindMonthlyDatarows = (): GetFindMonthlyDataRows => {
  const getFindMonthlyDataRows = `/findMonthlyDataRows`;

  return new RemoteFindMonthlyDataRows(makeApiUrl(getFindMonthlyDataRows), makeAuthorizeHttpClientDecorator());
};
