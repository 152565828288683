import { Option } from 'types';
import { ComparisonTypes, ReportTimeframes } from '@hone-automation/common';

export type ReportTimeframeOption = {
  value: 'Week' | 'Month' | 'Quarter' | 'Year to Date' | 'Year';
  label: string;
}[];

export const reportTimeframeOptions: ReportTimeframeOption = [
  { value: ReportTimeframes.Week, label: 'Week' },
  { value: ReportTimeframes.Month, label: 'Month' },
  { value: ReportTimeframes.Quarter, label: 'Quarter' },
  { value: ReportTimeframes.Year_to_Date, label: 'YTD' },
  { value: ReportTimeframes.Year, label: 'Year' },
];

export const reportTimeframeOptionsComparePrior: Option[] = [
  { value: ComparisonTypes.Prior_Period, label: 'Quarters' },
  { value: ComparisonTypes.Prior_Period, label: 'Weeks' },
  { value: ComparisonTypes.Prior_Period, label: 'Months' },
  { value: ComparisonTypes.Year_Over_Year, label: 'Years' },
];

export const checkboxOptionsByReport = {
  'Manager Weekly': [
    { options: 'Show Budget', isDisabled: false, isChecked: false },
    { options: 'Show Total', isChecked: false, isDisabled: false },
    { options: 'Break', isChecked: false, isDisabled: false },
    { options: 'Show YTD', isChecked: false, isDisabled: false },
  ],
  'Manager Monthly': [
    { options: 'Show Budget', isChecked: false, isDisabled: false },
    { options: 'Show Variance', isChecked: false, isDisabled: false },
    { options: 'Show YTD', isChecked: false, isDisabled: false },
  ],
  'Investor Period': [
    { options: 'Show Budget', isChecked: false, isDisabled: false },
    { options: 'Show Variance', isChecked: false, isDisabled: false },
    { options: 'Show YTD', isChecked: false, isDisabled: false },
  ],
  Custom: [
    { options: 'Show Budget', isChecked: false, isDisabled: false },
    { options: 'Show Total', isChecked: false, isDisabled: false },
    { options: 'Breakdown by Weeks/Months/Period', isChecked: false, isDisabled: false },
    { options: 'Reverse Column Order', isChecked: false, isDisabled: false },
  ],
  NoCNop: [
    { options: 'Show Budget', isChecked: false, isDisabled: false },
    { options: 'Show Variance', isChecked: false, isDisabled: false },
    { options: 'Show YTD', isChecked: false, isDisabled: false },
  ],
};
export type ReportPLDatesType = 'Custom' | 'Manager Weekly' | 'Manager Monthly' | 'Investor Period' | 'NoCNop';

export const optionsPLReports: ReportPLDatesType[] = [
  'Custom',
  'Manager Weekly',
  'Manager Monthly',
  'Investor Period',
  'NoCNop',
];
