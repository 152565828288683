import React from 'react';
interface FallbackProps {
  error?: Error;
}
function AccrualsErrorView({ error }: FallbackProps) {
  return (
    <div className="report-view">
      <div className="forecast-layout">
        <div className="card forecast-view h-100">
          <h2>Accruals is not active for this location</h2>
          <pre style={{ display: process.env.NODE_ENV === 'development' ? 'block' : 'none' }}>{error?.message}</pre>
        </div>
      </div>
    </div>
  );
}

export default AccrualsErrorView;
