import { subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import { produce } from 'immer';

import { FIVE_SECONDS, showToast, TOAST_ERROR } from 'lib/utils';
import { PostFlaggedTransactionComment } from 'domain/usecases';
import { makeRemotePostFlaggedTransactionComment } from 'main/factories/usecases/ask-customer/remote-post-flagged-transaction-comment';
import { ApiStatusToUIStatusMapType, UIFlaggedTransaction, UIFlaggedTransactionStatus } from 'types/askcustomer';
import { AskRequest, AskRequestStatus, FlaggedTransaction } from '@hone-automation/common';
import { findIndex } from 'lodash';

interface FlaggedTransactionsState {
  activeFlaggedCell: AskRequest | undefined;
  activeFlaggedTransaction: UIFlaggedTransaction | undefined;
  transactionBeingFlagged: any | undefined;
  flaggedTransactions: UIFlaggedTransaction[];
  animatedTransaction: AskRequestStatus | undefined;
  setAnimatedTransaction: (status: AskRequestStatus | undefined) => void;
  addComment: (props: CreateCommentProps) => void;
  loadingFlaggedTransactions: boolean;
  setActiveTransaction: (trx: UIFlaggedTransaction | undefined) => void;
  setTransactionBeingFlagged: (trx: UIFlaggedTransaction | undefined) => void;
  addSingleFlaggedTransaction: (value: UIFlaggedTransaction) => void;
}

const ApiStatusToUIStatusMap: ApiStatusToUIStatusMapType = {
  Open: UIFlaggedTransactionStatus.INFORMATION,
  'In Progress': UIFlaggedTransactionStatus.SUBMITTED,
  Closed: UIFlaggedTransactionStatus.CATEGORIZED,
};

export function transformApiTrxToUITrx(apiTrx: FlaggedTransaction): UIFlaggedTransaction {
  return {
    ...apiTrx,
    status: ApiStatusToUIStatusMap[apiTrx.status],
    updatedAt: new Date(apiTrx.updatedAt),
  };
}

type CreateCommentProps = PostFlaggedTransactionComment.Body & PostFlaggedTransactionComment.Params;

export const useFlaggedTransactions = create(
  subscribeWithSelector<FlaggedTransactionsState>(set => ({
    flaggedTransactions: [],
    activeFlaggedCell: undefined,
    loadingFlaggedTransactions: false,
    activeFlaggedTransaction: undefined,
    transactionBeingFlagged: undefined,
    animatedTransaction: undefined,
    setAnimatedTransaction: (status: AskRequestStatus | undefined) => {
      set({ animatedTransaction: status });
    },
    addSingleFlaggedTransaction: (value: UIFlaggedTransaction) => {
      set((state: FlaggedTransactionsState): FlaggedTransactionsState => {
        state.flaggedTransactions.push(value);
        state.transactionBeingFlagged!.flagged = value;
        return state;
      });
    },
    addComment: async ({ userId, trxId, comment, locationId }: CreateCommentProps) => {
      const service = makeRemotePostFlaggedTransactionComment(trxId);
      try {
        const response = (await service.post({ userId, comment, locationId })) as FlaggedTransaction;

        set(state => {
          const flaggedTransactionIdx = findIndex(state.flaggedTransactions, trx => trx.id === response.id);
          const UIFlaggedTrx = transformApiTrxToUITrx(response as FlaggedTransaction);
          return {
            activeFlaggedTransaction: UIFlaggedTrx,
            flaggedTransactions: produce(state.flaggedTransactions, draft => {
              if (draft) {
                draft[flaggedTransactionIdx] = UIFlaggedTrx;
              }
            }),
          };
        });
      } catch (e) {
        showToast('An unexpected error occurred while sending your message', TOAST_ERROR, FIVE_SECONDS);
      }
    },
    setActiveTransaction: (trx: UIFlaggedTransaction | undefined) => {
      set({
        activeFlaggedTransaction: trx,
      });
    },
    setTransactionBeingFlagged: (trx: UIFlaggedTransaction | undefined) => {
      set({
        transactionBeingFlagged: trx,
      });
    },
  }))
);
