import { AskRequest, ReportRow, ReportTransactionDataRow } from '@hone-automation/common';
import { DateTransaction } from 'components/Table/ReportTransactions';
import { UIFlaggedTransaction } from 'types';

export const HoneReportStatuses = {
  Published: 'Published',
  Draft: 'Draft',
} as Readonly<Record<string, HoneReportStatus>>;

export const HoneReportVisibilities = {
  AllEmployees: 'All Employees',
  OwnerOnly: 'Owner Only',
} as Readonly<Record<string, HoneReportVisibility>>;

export const HoneReportTimeframes = {
  Weekly: 'Weekly',
  Monthly: 'Monthly',
  YTDMonthly: 'YTD Monthly',
} as Readonly<Record<string, HoneReportTimeframe>>;

export const HoneReportTypes = {
  BalanceSheet: 'Balance Sheet',
  CashFlow: 'Cash Flow',
  IncomeStatement: 'Income Statement',
  PLComparison: 'P&L Comparison',
  ApAging: 'AP Aging',
} as Readonly<Record<string, HoneReportType>>;

export const HoneReportTypeTimeframes = {
  AllReportTypes: 'All Report Types',
  MonthlyBalanceSheet: 'Monthly Balance Sheet',
  MonthlyCashFlow: 'Monthly Cash Flow',
  MonthlyPLComparison: 'Monthly P&L Comparison',
  WeeklyPLComparison: 'Weekly P&L Comparison',
  YTDIncomeStatement: 'YTD Income Statement',
} as Readonly<Record<string, HoneReportTypeTimeframe>>;

export const HoneReportFormats = {
  JSON: 'json',
  PDF: 'pdf',
} as Readonly<Record<string, HoneReportFormat>>;

export const HoneTemplateDisplays = {
  Empty: 'empty',
  Default: 'default',
  Hidden: 'hidden',
  Header1: 'header_1',
  Header2: 'header_2',
  Header3: 'header_3',
  Header4: 'header_4',
  Header5: 'header_5',
  Total1: 'total_1',
  Total2: 'total_2',
} as Readonly<Record<string, HoneTemplateDisplay>>;

export const AccountTags = {
  BevCost: 'Bev Cost',
  BevSales: 'Bev Sales',
  ControllableExpenses: 'Controllable Expenses',
  FoodCost: 'Food Cost',
  FoodSales: 'Food Sales',
  Labor: 'Labor',
  NetSales: 'Net Sales',
  TotalExpenses: 'Total Expenses',
} as Readonly<Record<string, string>>;

export interface ReportDataTable {
  templateId: string;
  title: string;
  type: string;
  timeframe: string;
  period: number;
  currency: string;
  smoothing: boolean;
  sections: ReportDataTableSection[];
  dates: Date[];
  createdAt: string;
  updatedAt: string;
}

interface Date {
  start: string;
  end: string;
}

export interface ReportDataTableSection {
  id: string;
  title: string;
  level: number;
  glCode: number;
  data: Datum[];
  sections: ReportDataTableSection[];
  qboId: string;
  display: string;
  classification: string;
  accountType: string;
  chart: Chart;
}

export interface Chart {
  axisY: string;
  order: number;
  goal: Goal;
}

interface Goal {
  target: number;
  hiLo: boolean;
  def: string;
}

export interface Datum {
  amount: number;
  amtAvg: number;
  perc?: number;
  percAvg: number;
  dataRows: any[];
}

export interface GroupReportPayload {
  type: 'P&L Comparison' | 'Balance Sheet';
  startDate?: string;
  endDate?: string;
  locationId?: string;
  templateId?: string;
  total?: boolean;
  difference?: boolean;
  limit?: number;
}

export interface QueryParamsPayload {
  total?: string;
  difference?: string;
  breakdownPeriods?: string;
  reverse?: string;
  chartCollapsed?: string;
  comparisonPeriods?: number;
  compareLocations?: string;
  consolidated?: string;
  compareEnabled?: string;
  comparisonType?: string;
  dateRange?: string;
}

export interface FindMonthlyDataRowsPayload {
  sectionName: string;
  locationId: string;
  year: string;
  month: string;
}

export interface AttachmentsPayload {
  locationId: string;
  dataRows?: ReportTransactionDataRow[];
}

export interface DataCellState {
  date: DateTransaction;
  dataRows: ReportRow[];
  modalOpen: boolean;
  title: string;
  parent: string | null;
  total: number;
  loading: boolean;
  audit?: any;
  hasDataRowsOrAudit?: boolean;
  cellTransactionFlagged?: UIFlaggedTransaction | AskRequest;
}
