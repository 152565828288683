import { ReportGraphDataSummary } from 'domain/models';
import { filter, isEmpty, isNull, round } from 'lodash';
import { RowDataType } from 'rsuite-table';
import { currencyFormatterShort, percentageFormatter } from './utils';
import { format, parse } from 'date-fns';

export interface ReportData {
  amount: number;
  perc?: number;
  dataRows: RowDataType[];
}
interface Goal {
  target: number;
  hiLo: boolean;
  def: string;
}

interface Chart {
  axisY: string;
  order: number;
  goal: Goal;
}

export interface Report {
  className?: string;
  match?: unknown;
  id?: string;
  title: string;
  titlePerc?: string;
  level: number;
  glCode?: number;
  data?: ReportData[];
  qboId?: string;
  display?: string;
  classification?: string;
  accountType?: string;
  chart?: Chart;
  children?: Report[] | null;
  sections?: Report[] | null;
  rowKey?: string;
}

type NewSection = {
  children?: Report[] | null;
};

export function traverseSections(sections: NestedHoneReportSection[], isCFReport?: boolean): NestedHoneReportSection[] {
  return sections
    .filter(s => s.display !== 'hidden' && s.display !== 'empty')
    .map((reportSection, index, _sections) => {
      const last =
        typeof _sections[index + 1] !== 'undefined' && _sections[index + 1]?.display?.toLowerCase()?.includes('total');

      if (reportSection.sections) {
        // check the next one,
        // we don't want to mark "last", one preceded by a "total"
        reportSection.sections[reportSection.sections.length - 1] = {
          ...reportSection.sections[reportSection.sections.length - 1],
          last: !(index + 1 < _sections.length && _sections[index + 1]?.display?.includes('total')),
        };

        return {
          ...reportSection,
          level: isCFReport ? reportSection.level - 1 : reportSection.level,
          sections: traverseSections(reportSection.sections, isCFReport),
        };
      }

      return {
        ...reportSection,
        level: isCFReport ? reportSection.level - 1 : reportSection.level,
        last,
      };
    });
}

export function renameSectionsToChildren(sections: Report[], rowCount = 0): Report[] {
  let currentRowCount = rowCount;

  return sections.reduce((filtered: Report[], section: Report): Report[] => {
    const className = currentRowCount % 2 === 0 ? 'even' : 'odd';
    currentRowCount++;
    if (!Object.prototype.hasOwnProperty.call(section, 'sections')) {
      filtered.push({ ...section, className });
      return filtered;
    }
    const newSection: Report = {
      title: '',
      level: 0,
      className,
    };
    const hiddenSection = section.display === 'hidden';
    if (!hiddenSection) {
      Object.assign(newSection, section, {
        id: section && section.id && section?.id.length > 0 ? section.id : Math.random(),
        rowKey: `${section.title.replace(' ', '-')?.toLowerCase()}.${section?.id}`,
        children: section.sections
          ? section.sections.length > 0 && !hiddenSection
            ? renameSectionsToChildren(section.sections, currentRowCount)
            : null
          : null,
      });
      delete (newSection as any).sections;
      if (Array.isArray(newSection.children) && newSection.children?.length === 0) {
        newSection.children = null;
      }
      filtered.push(newSection);
    }
    return filtered;
  }, []);
}

export function getNodesWithChildren(data: Report[]): string[] {
  return data.reduce((acc: string[], section: Report) => {
    if (Object.prototype.hasOwnProperty.call(section, 'children') && section.children) {
      acc.push(String(section.rowKey));
      acc.push(...getNodesWithChildren(section.children));
    }
    return acc;
  }, []);
}

export function getExpandedRows(initialExpandedRows: any[], expanded: boolean, rowData: any): string[] {
  const currentExpandedRowIds = [...initialExpandedRows];
  const rowIndex = currentExpandedRowIds.findIndex((item: any) => item === rowData.rowKey);

  if (!expanded) {
    currentExpandedRowIds.splice(rowIndex, 1);
  } else {
    currentExpandedRowIds.push(rowData.rowKey);
  }

  return currentExpandedRowIds;
}

const getAmountObject = (
  dataAmount: ReportData[],
  title: string,
  showMoneyValues: boolean,
  showPercentagesValues: boolean
) => {
  const amountTitleArray = [];
  amountTitleArray.push(title);
  dataAmount.map(itemData => {
    amountTitleArray.push(
      `${showMoneyValues ? currencyFormatterShort(itemData.amount) : ''}\n${
        itemData?.perc && showPercentagesValues ? percentageFormatter(itemData?.perc) : ''
      }`
    );
  });

  return amountTitleArray;
};

type SearchValue = string | RegExp;
type ReplaceValue = string;

type GetMaskedTitleProps = {
  title: string;
  titlePerc: string;
  level: number;
  separator?: string;
  replace?: [SearchValue, ReplaceValue];
  showMoneyValues: boolean;
  showPercentagesValues: boolean;
};

function getMaskedTitle({
  title,
  titlePerc,
  level,
  separator = '\t',
  replace = ['->', ''],
  showMoneyValues,
  showPercentagesValues,
}: GetMaskedTitleProps) {
  const percentageTitle = titlePerc && showPercentagesValues ? `% OF ${titlePerc.replace(...replace)}` : '';
  const innerTitle = title.replace(...replace);
  const separatorsTabs = Array(level).fill(separator).join('');

  return separatorsTabs + innerTitle + '\n' + separatorsTabs + percentageTitle;
}

type PrintRow = {
  [key: number]: string;
};

export function getPrintingInformation(
  data: NestedHoneReportSection[] | undefined,
  expandedRows: string[],
  showMoneyValues: boolean,
  showPercentagesValues: boolean
): PrintRow[] {
  function getRows(data: Report[]): PrintRow[] {
    const rows: any = [];
    if (data && data.length === 0) {
      return rows;
    }
    data.map((item: Report, i: number) => {
      const rowKey = `${item.title.replace(' ', '-')?.toLowerCase()}.${item?.id}`;

      const firstTabs =
        item.title?.includes('GROSS') ||
        item.title?.includes('TOTAL') ||
        item.title?.includes('Total') ||
        item.title?.includes('NET PROFIT') ||
        (item.title?.includes('NET') && item.title?.includes('INCOME'));
      if (firstTabs) {
        item.level = 4;
      }
      const maskedTitle = getMaskedTitle({
        title: item.title,
        level: item.level,
        titlePerc: item?.titlePerc || '',
        showMoneyValues,
        showPercentagesValues,
      });
      const values =
        item.data && item.title && getAmountObject(item.data, maskedTitle, showMoneyValues, showPercentagesValues);
      // /* CHILDREN */
      rows.push({ ...values });

      if (expandedRows.includes(rowKey)) {
        // two children scenarios
        // scenario 1: row with nested children
        if (item.children && !isEmpty(item.children)) {
          item.children.map(childrenItem => {
            const childrenRowKey = `${item.title.replace(' ', '-')?.toLowerCase()}.${item?.id}`;
            const maskedTitle = getMaskedTitle({
              title: childrenItem.title,
              level: childrenItem.level,
              titlePerc: childrenItem?.titlePerc || '',
              showMoneyValues,
              showPercentagesValues,
            });
            const values =
              childrenItem.data &&
              childrenItem.title &&
              getAmountObject(childrenItem.data, maskedTitle, showMoneyValues, showPercentagesValues);

            rows.push({ ...values });
            if (expandedRows.includes(childrenRowKey)) {
              if (childrenItem.children && !isEmpty(childrenItem.children)) {
                const innerRows = getPrintingInformation(
                  childrenItem.children as NestedHoneReportSection[],
                  expandedRows,
                  showMoneyValues,
                  showPercentagesValues
                );
                rows.push(...innerRows);
              }
            }
          });
        } else {
          const maskedTitle = getMaskedTitle({
            title: item.title,
            level: item.level,
            titlePerc: item?.titlePerc || '',
            showMoneyValues,
            showPercentagesValues,
          });
          const values =
            item.data && item.title && getAmountObject(item.data, maskedTitle, showMoneyValues, showPercentagesValues);
          rows.push({ ...values });
        }
      }
    });
    return rows;
  }
  return getRows(data!);
}
export function getRecursiveItem(
  reportSections: NestedSection[],
  category: string,
  items: NestedSection[] = []
): NestedSection | undefined {
  const initialItems = items;
  const section = reportSections.find(item => item.title === category);
  if (section) {
    initialItems.push(section);
    return section;
  } else {
    reportSections.forEach(item => {
      getRecursiveItem(item.sections, category, initialItems);
    });
  }
  return initialItems[0];
}

export function getSummaryReportSales(
  reportData: NestedHoneReport,
  headers: string[],
  category: string,
  enableSmoothing: boolean
): ReportGraphDataSummary {
  const isWeekly = reportData.timeframe === 'Weekly';
  const isYTD = reportData.timeframe?.includes('YTD');

  const reportValues: number[] = [];
  const reportLabels: string[] = isWeekly || isYTD ? headers.slice(1) : headers;
  const reportPercentages: number[] = [];
  const section = getRecursiveItem(reportData?.sections, category);
  if (section) {
    section.data.forEach(rv => {
      let amount = rv.amount;
      let percentage = rv.perc;
      if (enableSmoothing) {
        if (rv.amtAvg) {
          amount = rv.amtAvg;
        }
        if (rv.percAvg) {
          percentage = rv.percAvg;
        }
      }

      reportValues.push(Number(round(amount)));
      // prevents array of NaN
      reportPercentages.push(Number(percentage));
    });
  }

  const getShowLastColumn = () => {
    const lastHeader = headers && headers.length > 1 ? headers[headers.length - 1].toLowerCase() : '';
    return !['', 'total', 'delta'].includes(lastHeader);
  };

  const shouldShowLastColumn = getShowLastColumn();

  const shownValues = reportValues;
  const shownPercentages = reportPercentages;
  const shownLabels = shouldShowLastColumn ? reportLabels : reportLabels.splice(0, reportLabels.length - 1);
  return { reportValues: shownValues, reportLabels: shownLabels, reportPercentages: shownPercentages };
}

export function getCategories(sections: NestedSection[], initialCategories: string[] = []): string[] {
  const categories: string[] = initialCategories;

  sections.forEach(item => {
    if (Object.keys(item)?.includes('chart') && !isNull(item?.title)) {
      categories.push(item?.title);
    }
    if (!isEmpty(item.sections)) {
      getCategories(item.sections, categories);
    }
  });

  return categories;
}
export function getDefaultCategory(sections: NestedSection[]): string {
  for (const item of sections) {
    if (item.title === 'NET SALES') {
      return item.title;
    }

    if (!isEmpty(item.sections)) {
      const found = getDefaultCategory(item.sections);
      if (found) return found;
    }
  }

  return sections[0]?.title ?? '';
}

export function auditReport(report: Report[], audit: AuditReport[]): any {
  return report.map(row => {
    const match = filter(audit, function (a) {
      if (row.glCode) {
        return a.fieldName.includes(row.glCode.toString());
      }
      if (row.title) {
        return a.fieldName.toLowerCase() === row.title.toLowerCase();
      }
    });

    if ('children' in row && row.children) {
      return {
        ...row,
        audit: match,
        children: auditReport(row.children as Report[], audit),
      };
    }

    return {
      ...row,
      audit: match,
    };
  });
}

export function countByType<T extends { type: string }>(array: T[], needle: AuditReportType): number {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i].type === needle) {
      count++;
    }
  }
  return count;
}

export const getTableHeight = (graphCollapsed = false, isYtd = false, noGraph = false, auditMode = false): number => {
  if (auditMode) {
    return window.innerHeight - 270;
  }
  const footer = 150;
  let actions = 0;
  actions = isYtd && !graphCollapsed ? actions - 10 : actions;

  return window.innerHeight - actions - footer - (graphCollapsed ? 50 : noGraph ? 50 : 365);
};

export function flattenTree(tree: any): any {
  const result: string[] = [];
  for (const node of tree) {
    if (!['hidden'].includes(node.display)) {
      result.push(node);
      if (node.sections) {
        result.push(...flattenTree(node.sections));
      }
    }
  }
  return result;
}

export const renderDate = (date: string): string => {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
};
