// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import { JournalEntry } from '@hone-automation/common';
import { makeRemoteGetJournalEntries } from 'main/factories/usecases/journal-entries/remote-get-journal-entries';

export const useJournalEntries = (startDate: string, endDate: string, searchIds: string) => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ['journalEntries', locationId, searchIds, startDate, endDate],
    queryFn: async (): Promise<JournalEntry[]> => {
      const service = makeRemoteGetJournalEntries();
      const jeParamsGet = { locationId, startDate, endDate, searchIds };
      if (searchIds === 'undefined') delete jeParamsGet.searchIds;
      return service.get(jeParamsGet);
    },
    enabled: !!locationId,
  });
};
