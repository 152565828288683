import React from 'react';
import './KSListTitle.scss';

interface KSListTitleProps {
  children: React.ReactNode;
}

function KSListTitle({ children }: KSListTitleProps) {
  return (
    <div className="list-title">
      <label>{children}</label>
    </div>
  );
}

export default KSListTitle;
