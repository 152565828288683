import { find } from 'lodash';
import BookkeeperAccountsAutocomplete from './BookkeeperAccountsAutocomplete';
import BookkeeperTagsAutocomplete from './BookkeeperTagsAutocomplete';
import { IconAddCircle, IconRemoveCircle, IconUp, IconDown } from '../../../../components/Icons';
import { AccountTags } from '../../../../constants';
import type { SyntheticEvent } from 'react';

interface EventHandlerProps {
  onRemoveSection: (e: SyntheticEvent) => void;
  onTitleChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  onTagChange: (selected: string | undefined) => void;
  onAccountChange: (selected: HoneAccount | undefined) => void;
  onPercentChange: (selected: string | undefined) => void;
  onAddSameLevel: (e: SyntheticEvent) => void;
  handleMoveAfterNextSection: (e: SyntheticEvent, section: ForecastMappingSection) => void;
  handleMoveBeforePreviousSection: (e: SyntheticEvent, section: ForecastMappingSection) => void;
  forecastMapping?: ForecastMappingSection[];
}

interface Props extends EventHandlerProps {
  chartOfAccounts?: HoneAccount[];
  calculatableAccounts?: HoneAccount[];
  section: ForecastMappingSection;
  showControls?: boolean;
}

function BookkeeperForecastSectionForm({
  section,
  chartOfAccounts = [],
  calculatableAccounts = [],
  showControls = true,
  forecastMapping,
  ...eventHandlers
}: Props): JSX.Element {
  function getTags() {
    const tags: Record<string, string> = {};
    forecastMapping?.forEach(section => {
      if (section.tag) {
        tags[section.tag] = '';
      }
    });
    return Object.keys(tags).sort();
  }

  return (
    <div>
      <div className={`BKForm-section level-${section.level} flex-gap`}>
        <div className="BKForm-debug">
          <code>{JSON.stringify(section)}</code>
        </div>
        {showControls && (
          <button
            className="button button_icon"
            type="button"
            aria-label="Remove section"
            onClick={e => eventHandlers.onRemoveSection(e)}
          >
            <IconRemoveCircle />
          </button>
        )}
        <span className="d-flex label-small">{section.type}</span>
        <span className="d-flex">
          <input
            className="rounded-left-0"
            type="text"
            aria-label="Section title"
            placeholder="Row Title"
            value={section.title}
            onChange={e => eventHandlers.onTitleChange(e)}
          />
        </span>
        <span className="d-flex label-small"> tag:</span>
        <span className="d-flex">
          <BookkeeperTagsAutocomplete
            initialInputItems={Object.values(AccountTags)}
            placeholder="Tag"
            onChange={selected => eventHandlers.onTagChange(selected)}
            selectedItem={section.tag}
          />
        </span>

        <span className="d-flex label-small"> value:</span>
        {section.value && section.value.eq && <input type="text" value={section.value.eq.acct_num} disabled hidden />}
        {chartOfAccounts && (
          <BookkeeperAccountsAutocomplete
            initialInputItems={calculatableAccounts}
            placeholder="GL Code / Title"
            selectedItem={
              find(calculatableAccounts, { id: section.value?.section }) ||
              find(calculatableAccounts, { AcctNum: section.value?.eq?.acct_num || '' })
            }
            onChange={selected => eventHandlers.onAccountChange(selected)}
          />
        )}

        <span className="d-flex label-small"> %:</span>
        <span className="d-flex">
          <select
            defaultValue={section.goalPercTag ? section.goalPercTag : undefined}
            onChange={e => eventHandlers.onPercentChange(e.currentTarget.value ? e.currentTarget.value : undefined)}
          >
            <option></option>
            {getTags().map(tagName => (
              <option value={tagName} key={tagName}>
                {tagName}
              </option>
            ))}
          </select>
        </span>
        {showControls && (
          <button
            className="button button_icon"
            type="button"
            title="Add section"
            aria-label="Add section"
            onClick={e => eventHandlers.onAddSameLevel(e)}
          >
            <IconAddCircle />
          </button>
        )}
        {showControls && (
          <>
            <button
              className="button button_icon"
              aria-label="Move before previous section"
              type="button"
              onClick={e => eventHandlers.handleMoveBeforePreviousSection(e, section)}
            >
              <IconUp />
            </button>
            <button
              className="button button_icon"
              aria-label="Move after previous section"
              type="button"
              onClick={e => eventHandlers.handleMoveAfterNextSection(e, section)}
            >
              <IconDown />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default BookkeeperForecastSectionForm;
