import { prefixClass } from 'lib/utils';
import './KSMLMFooter.scss';
import { KSMLocation } from '../KSMultipleLocationModal';
import classNames from 'classnames';

type KSMLMFooterProps = {
  selectedLocations: KSMLocation[];
  onSelectAll?: () => void;
  onSelectNone?: () => void;
  onApplyChanges?: () => void;
};

const KSMLMFooter = ({ selectedLocations, onSelectAll, onSelectNone, onApplyChanges }: KSMLMFooterProps) => {
  const prefix = prefixClass('ks-mlm-footer');
  return (
    <div className={prefix()}>
      <div className={prefix('select-actions')}>
        <span className={prefix('select-actions-label')}>select</span>
        <div className={prefix('select-actions-button')} onClick={onSelectAll}>
          All
        </div>
        <div className={prefix('select-actions-button')} onClick={onSelectNone}>
          None
        </div>
      </div>
      <div
        className={classNames(prefix('apply-button'), { disabled: selectedLocations?.length > 1 ? false : true })}
        onClick={onApplyChanges}
      >
        {selectedLocations?.length > 1 ? 'Compare (' + (selectedLocations.length - 1) + ')' : 'Apply'}
      </div>
    </div>
  );
};

export default KSMLMFooter;
