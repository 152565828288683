import { Capabilities } from './types';

export const getReportCapabilities = (reportType: HoneReportType): Capabilities => {
  switch (reportType) {
    case 'P&L Comparison':
    case 'Income Statement':
      return {
        money: true,
        budget: true,
        percentage: true,
        transactions: true,
        charts: true,
        sparklines: 'bar',
        smoothing: true,
        anomalies: true,
      };
    case 'Balance Sheet':
      return {
        money: true,
        budget: false,
        percentage: false,
        transactions: true,
        charts: true,
        sparklines: 'line',
        smoothing: false,
        anomalies: true,
      };
    case 'Cash Flow':
      return {
        money: true,
        budget: false,
        percentage: false,
        transactions: false,
        charts: false,
        sparklines: 'none',
        smoothing: false,
        anomalies: false,
      };
    case 'AP Aging':
      return {
        money: true,
        budget: false,
        percentage: false,
        transactions: true,
        charts: false,
        sparklines: 'none',
        smoothing: false,
        anomalies: true,
      };
  }
};
