import React, { useState } from 'react';
import './KSLocationGroup.scss';
import { prefixClass } from '../../../lib/utils';
import { IconMoreH } from '../../../components/Icons';
import KSLocationModal from '../KSLocationModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecentLocations, MAX_RECENT_LOCATIONS } from '../../../hooks/useRecentLocations';
import KSLocationItem from '../KSLocationItem';
import { Tooltip, Whisper } from 'rsuite';
import { useActiveReportStore } from '../../../hooks/useActiveReportStore';
import { useReportsStore } from '../../../hooks/useReportsStore';

interface KSLocationGroupProps {
  activeLocation?: string;
  onLocationChange?: (locationId: string) => void;
  locations: HoneLocationUser[];
}

export function KSLocationGroup({ locations, activeLocation, onLocationChange }: KSLocationGroupProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const prefix = prefixClass('ks-location-group');

  const { recentLocations, setRecentLocations } = useRecentLocations();

  const handleRemoveLocation = (locationId: string) => {
    const selectedLocation = locations.find(loc => loc.locationId === locationId);
    if (!selectedLocation) return;

    setRecentLocations((prevRecentLocations: HoneLocationUser[]) => {
      const updatedLocations = prevRecentLocations.filter(loc => loc.locationId !== locationId);
      const newRecentLocations = updatedLocations.slice(0, MAX_RECENT_LOCATIONS);
      localStorage.setItem('recentLocations', JSON.stringify(newRecentLocations));
      return newRecentLocations;
    });
  };

  const handleLocationClick = (locationId: string) => {
    const selectedLocation = locations.find(loc => loc.locationId === locationId);
    if (!selectedLocation) return;

    // Update recent locations
    setRecentLocations((prevRecentLocations: HoneLocationUser[]) => {
      if (prevRecentLocations.findIndex(loc => loc.locationId === locationId)) {
        return prevRecentLocations;
      }
      const updatedLocations = [selectedLocation, ...prevRecentLocations.filter(loc => loc.locationId !== locationId)];
      const newRecentLocations = updatedLocations.slice(0, MAX_RECENT_LOCATIONS);
      localStorage.setItem('recentLocations', JSON.stringify(newRecentLocations));
      useActiveReportStore.setState({ currentReport: undefined });
      useReportsStore.setState({ selectedReport: undefined });
      return newRecentLocations;
    });

    // Trigger the location change callback
    setTimeout(() => {
      onLocationChange?.(locationId);
    });
  };

  const handleAddToRecentLocations = (locationId: string) => {
    const selectedLocation = locations.find(loc => loc.locationId === locationId);
    if (!selectedLocation) return;
    setRecentLocations((prevRecentLocations: HoneLocationUser[]) => {
      const updatedLocations = [selectedLocation, ...prevRecentLocations.filter(loc => loc.locationId !== locationId)];
      const newRecentLocations = updatedLocations.slice(0, MAX_RECENT_LOCATIONS);
      localStorage.setItem('recentLocations', JSON.stringify(newRecentLocations));
      return newRecentLocations;
    });
  };

  const handleRemoveFromRecentLocations = (locationId: string) => {
    const selectedLocation = locations.find(loc => loc.locationId === locationId);
    if (!selectedLocation) return;
    setRecentLocations((prevRecentLocations: HoneLocationUser[]) => {
      const updatedLocations = prevRecentLocations.filter(loc => loc.locationId !== locationId);
      const newRecentLocations = updatedLocations.slice(0, MAX_RECENT_LOCATIONS);
      localStorage.setItem('recentLocations', JSON.stringify(newRecentLocations));
      return newRecentLocations;
    });
  };

  const toggleLocationModal = () => {
    setIsModalOpen(state => !state);
  };

  return (
    <div className={prefix()}>
      {recentLocations.map((location, index) => (
        <KSLocationItem
          key={location.locationId}
          locationId={location.locationName}
          org={location.org}
          colorIndex={index}
          onLocationChange={() => handleLocationClick(location.locationId)}
          onRemoveLocation={() => handleRemoveLocation(location.locationId)}
          location={location.locationName}
          activeLocation={activeLocation === location.locationId}
        />
      ))}

      <div className={prefix('overflow')}>
        <Whisper trigger="hover" placement="right" delayOpen={800} speaker={<Tooltip>Manage Tab Locations</Tooltip>}>
          <div onClick={toggleLocationModal} className={prefix('overflow-control')}>
            <div className={prefix('overflow-control-icon')}>
              <IconMoreH />
            </div>
            {locations.length > 1 && (
              <div className={prefix('overflow-control-text')}>+{locations.length - recentLocations.length}</div>
            )}
          </div>
        </Whisper>
        <KSLocationModal
          handleClose={() => setIsModalOpen(false)}
          recentLocations={recentLocations}
          activeLocation={activeLocation}
          onLocationChange={handleLocationClick}
          onAddToRecentLocations={handleAddToRecentLocations}
          onRemoveFromRecentLocations={handleRemoveFromRecentLocations}
          isOpen={isModalOpen}
          locations={locations}
        />
      </div>
    </div>
  );
}

function withCurrentLocation(WrappedComponent: any) {
  return function (props: KSLocationGroupProps) {
    const navigateTo = useNavigate();
    const { locationId } = useParams();

    const handleLocationClick = (locationId: string) => {
      navigateTo(`/app/location/${locationId}`);
    };

    return <WrappedComponent activeLocation={locationId} onLocationChange={handleLocationClick} {...props} />;
  };
}

export default withCurrentLocation(KSLocationGroup);
