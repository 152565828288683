import React from 'react';
import { useForm } from 'react-hook-form';
import { useHoneLocations } from '../../../../components/HoneLocations';
import { useAuthContext } from '../../../../context/useAuthContext';
import { showToast, TOAST_SUCCESS } from '../../../../lib/utils';

type Form = {
  name: string;
  org: string;
};

function CreateLocation() {
  const { userId } = useAuthContext();
  const { register, handleSubmit, reset } = useForm<Form>();

  const { saveLocation } = useHoneLocations();

  const onSubmit = (data: Form) => {
    // @ts-ignore
    saveLocation({ ...data, status: 'active' }, userId)
      .then(res => {
        reset();
        showToast(`Location ${data.name} created succesfully`, TOAST_SUCCESS);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="mb-4" id="tab-settings" style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
      <h4>Create new location</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="BKForm-table">
          <tbody>
            <tr>
              <td>
                <label className="mr-3">Location name:</label>
              </td>
              <td>
                <input type="text" id="name" {...register('name')} placeholder="Location name" className="mr-3" />
              </td>
            </tr>
            <tr>
              <td>
                <label className="mr-3">Organization name:</label>
              </td>
              <td>
                <input type="text" id="name" {...register('org')} placeholder="Organization name" className="mr-3" />
              </td>
            </tr>
            <tr>
              <td />
              <td align="right">
                <button className="button BKForm-btn-save" type="submit">
                  <span>Save</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default CreateLocation;
