import React, { useEffect, useState } from 'react';

import type { UIFlaggedTransaction } from 'types/askcustomer';
import { UIAskRequestsTitles } from 'types/askcustomer';
import { useQueryState } from 'hooks/useQueryState';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import AskRequestTab from './Tab';
import { AskRequestStatus, AskRequestStatuses } from '@hone-automation/common';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { useAskRequestsCountQuery } from 'hooks/useAskRequestsCountQuery';
import classNames from 'classnames';
import { shallow } from 'zustand/shallow';
import { IconAdd } from 'components/Icons';
import CreateRequestModal from 'presentation/components/AskRequest/CreateRequestModal';
import { AskRequestCount } from 'domain/models';

type AskCustomerTableProps = {
  transactions: UIFlaggedTransaction[];
  activeTransaction?: UIFlaggedTransaction;
  onNewCommentSubmit: (comment: string, activeTransaction: UIFlaggedTransaction) => void;
  onSelectActiveTransaction: (trx: UIFlaggedTransaction | undefined) => void;
};

function AskCustomerTable({
  onNewCommentSubmit,
  activeTransaction,
  onSelectActiveTransaction,
}: AskCustomerTableProps): JSX.Element {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [createRequestModalOpen, setCreateRequestModalOpen] = useState<boolean>(false);
  const { data: askRequestsCount } = useAskRequestsCountQuery();
  const { animatedTransaction } = useFlaggedTransactions(
    state => ({
      animatedTransaction: state.animatedTransaction,
    }),
    shallow
  );
  const [activeTab, setActiveTab] = useQueryState<AskRequestStatus>('activeTab', 'Open');
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const handleClick = (transaction: UIFlaggedTransaction) => {
    onSelectActiveTransaction(transaction);
  };

  const handleNewCommentSubmit = (comment: string) => {
    if (activeTransaction) {
      onNewCommentSubmit(comment, activeTransaction);
    }
  };

  const toggleCreateRequestModal = () => {
    setCreateRequestModalOpen(val => !val);
  };

  const count: [number, number, number] = [
    (askRequestsCount as AskRequestCount)?.Open || 0,
    (askRequestsCount as AskRequestCount)?.In_Progress || 0,
    (askRequestsCount as AskRequestCount)?.Closed || 0,
  ];

  const handleTabSelect = (index: number) => {
    useFlaggedTransactions.setState({
      activeFlaggedTransaction: undefined,
    });
    setActiveTab(Object.values(AskRequestStatuses)[index]);
    setActiveIndex(Object.values(AskRequestStatuses).indexOf(activeTab as any));
  };

  useEffect(() => {
    handleTabSelect(Object.values(AskRequestStatuses).indexOf(activeTab as any));
  }, [activeTab]);

  const statuses: AskRequestStatus[] = ['Open', 'In Progress', 'Closed'];
  return (
    <div className="ask-customer-table">
      {isMobile && !activeTransaction && (
        <button onClick={toggleCreateRequestModal} className="ask-customer-create-mobile-button">
          <IconAdd />
          Create new request
        </button>
      )}
      <Tabs onSelect={handleTabSelect} selectedIndex={activeIndex} className="ask-customer-tabs">
        <TabList>
          <Tab className={classNames('information react-tabs__tab', { animated_open: animatedTransaction === 'Open' })}>
            <div>
              {UIAskRequestsTitles.CUSTOMER}
              {count[0] > 0 && (
                <div className={classNames('counter', { animated_counter: animatedTransaction === 'Open' })}>
                  <span>{count[0]}</span>
                </div>
              )}
            </div>
          </Tab>
          <Tab
            className={classNames('submitted react-tabs__tab', {
              animated_in_progress: animatedTransaction === 'In Progress',
            })}
          >
            <div>
              {UIAskRequestsTitles.BOOKKEEPER}
              {count[1] > 0 && (
                <div
                  className={classNames('counter', {
                    animated_counter: animatedTransaction === 'In Progress',
                  })}
                >
                  <span>{count[1]}</span>
                </div>
              )}
            </div>
          </Tab>
          <Tab
            className={classNames('categorized react-tabs__tab', { animated_closed: animatedTransaction === 'Closed' })}
          >
            {UIAskRequestsTitles.RESOLVED}
          </Tab>
          {!isMobile && (
            <button onClick={toggleCreateRequestModal} className="create-request-toggle">
              <IconAdd />
            </button>
          )}
        </TabList>
        {statuses.map((status: AskRequestStatus) => (
          <TabPanel key={status}>
            <AskRequestTab
              status={status}
              onSelectActiveTransaction={handleClick}
              activeTransaction={activeTransaction}
              handleNewCommentSubmit={handleNewCommentSubmit}
            />
          </TabPanel>
        ))}
      </Tabs>
      <CreateRequestModal isOpen={createRequestModalOpen} handleClose={toggleCreateRequestModal} />
    </div>
  );
}

export default AskCustomerTable;
