import classNames from 'classnames';
import { prefixClass } from 'lib/utils';
import { ReactNode, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { QboAccount } from 'domain/models';

type QboContentProps = {
  depth: number;
  isSelected: boolean;
  isParent: boolean;
  isChildren?: boolean;
  item: any;
  onClick: (item: QboAccount) => void;
  onParentSelection: (item: QboAccount, e?: SyntheticEvent<HTMLButtonElement>) => void;
  onSplitClick: (e: SyntheticEvent<HTMLButtonElement>, item: QboAccount) => void;
};

const CoAItem = ({
  isSelected,
  isParent,
  isChildren,
  item,
  onClick,
  onSplitClick,
  onParentSelection,
}: QboContentProps): ReactNode => {
  const prefix = prefixClass('qboItem-content');
  const { watch } = useFormContext();

  const selected = watch('selected');
  const parent = watch('parent');

  return (
    <>
      <div
        onClick={() => onClick(item)}
        className={classNames(prefix('container'), {
          [prefix('container-select')]: isSelected,
          [prefix('container-parent')]: isParent,
        })}
      >
        <div
          className={classNames(prefix('item'), {
            [prefix('item-select')]: isSelected,
          })}
        ></div>

        {isChildren && Array.from({ length: item.depth }).map(() => <div className={prefix('children-container')} />)}
        <div className={prefix()}>
          <div className={prefix('data')}>
            <p
              className={classNames(prefix('data-id'), {
                [prefix('data-id-parent')]: isParent,
              })}
            >
              {item?.glCode}
            </p>
            <h5 className={prefix('data-name')}>{item.name}</h5>
            <p className={prefix('data-description')}>{item.classification}</p>
          </div>
          <div className={prefix('container-type')}>
            <div
              className={classNames(prefix('type'), {
                [prefix('type-state')]: isSelected || isParent,
              })}
            >
              <h6 className={prefix('type-name')}>{item.accountType}</h6>
            </div>
          </div>
          {/*<button type="button" onClick={(e) => onSplitClick(e, item)} className={prefix("item-action")}>*/}
          {/*  {isChildren && <IconSplit />}*/}
          {/*</button>*/}
          {item.externalId !== parent ? (
            <button type="button" onClick={e => onParentSelection(item, e)} className={prefix('item-action-parent')}>
              P
            </button>
          ) : (
            <div className={prefix('item-action-parent-hidden')} />
          )}
        </div>
        {isParent ? <div className={prefix('item-parent')} /> : <div className={prefix('item-parent-hidden')} />}
      </div>
      {item.children.map((c: any) => (
        <CoAItem
          depth={c.depth}
          isSelected={selected.findIndex((sel: any) => sel.externalId === c.externalId) > -1}
          isChildren={true}
          isParent={watch('parent') === c.externalId}
          item={c}
          onClick={onClick}
          onSplitClick={onSplitClick}
          onParentSelection={onParentSelection}
        />
      ))}
    </>
  );
};

export default CoAItem;
