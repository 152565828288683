import { ReactNode, SyntheticEvent } from 'react';
import { noop } from 'lodash';
import './FloatingActionButton.css';

export interface Action {
  label: string;
  icon: ReactNode;
  type?: string;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

interface IFab {
  actions: Action[];
  visible: boolean;
}

const FloatingActionButton = ({ actions, visible }: IFab): JSX.Element | null => {
  return visible ? (
    <ul className="fab-container open">
      {actions.map((action, index) => (
        <li
          style={{ transitionDelay: `${index * 25}ms` }}
          className="fab-action open"
          key={action.label.replace(' ', '-')}
        >
          <button onClick={action.onClick ?? noop}>{action.icon}</button>
          <span className="tooltip">{action.label}</span>
        </li>
      ))}
    </ul>
  ) : null;
};

export default FloatingActionButton;
