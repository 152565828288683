import './NTColumnBrowser.scss';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { prefixClass } from 'lib/utils';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNTScroll } from '../NTScrollContext';

const NTColumnBrowser = () => {
  const { columns, filters } = useNTContext();
  const { tooltip, actions } = useNTUtilsContext();
  const { scrollState, setScrollState, scrollChange, setScrollChange } = useNTScroll();
  const prefix = prefixClass('nt-column-browser');
  const [changed, setChanged] = useState<boolean>(true);
  const [done, setDone] = useState<boolean>(false);
  const { columnBrowserVisible } = scrollState;

  useEffect(() => {
    setDone(true);
    setTimeout(() => {
      setChanged(false);
    }, 200);
  }, [filters, actions]);

  useEffect(() => {
    setChanged(true);
  }, [tooltip]);

  const scrollingColumns = columns - (actions.total ? 2 : 1); // first column is always fixed, but last column may or not be fixed
  return (
    <div className={prefix()}>
      <div className={prefix('gradient')}>
        {tooltip.tooltip && (
          <div
            className={classNames(prefix('gradient-tooltip'), {
              'zoom-fade': changed,
              pulse: changed,
              'zoom-fade-out': !changed,
            })}
          >
            {done ? tooltip.tooltip : 'Done'}
          </div>
        )}
        <div
          id="columnBrowserArea"
          className={classNames(prefix('gradient-container'), { hidden: !columnBrowserVisible })}
        >
          {Array.from({ length: scrollingColumns }, (_, index) => (
            <div key={index} className={prefix('gradient-container-column')}></div>
          ))}
          <div
            id="columnBrowserWindow"
            className={prefix('gradient-container-window')}
            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
              e.preventDefault();
              e.stopPropagation();
              const startX = e.clientX;
              const myobj = document.getElementById('columnBrowserWindow');
              if (!myobj) return;
              const startLeft = myobj.offsetLeft;

              const handleMouseMove = (moveEvent: MouseEvent) => {
                const deltaX = moveEvent.clientX - startX;
                const newLeft = startLeft + deltaX;
                setScrollChange({ axis: 'x', amount: newLeft, browser: true });
              };

              const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
              };

              document.addEventListener('mousemove', handleMouseMove);
              document.addEventListener('mouseup', handleMouseUp);
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default NTColumnBrowser;
