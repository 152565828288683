import classNames from 'classnames';
import DropdownMenu from './DropdownMenu';
import { useAuthContext } from 'context/useAuthContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface UserCircleProps {
  closedNavigationMenu: boolean;
}

function UserCircle({ closedNavigationMenu }: UserCircleProps): JSX.Element {
  const { user } = useAuthContext();
  const { enableV3Location } = useFlags();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isClosed = isMobile ? !closedNavigationMenu : closedNavigationMenu;
  return (
    <div
      className={classNames('user-container', { 'user-container-closed': closedNavigationMenu, v3: enableV3Location })}
    >
      <div className="user-circle">{user && user.email?.substring(0, 2)}</div>
      {!isClosed && !enableV3Location && <div>{user && user?.email?.split('@')[0].replaceAll('.', ' ')}</div>}
    </div>
  );
}

interface UserDropdownMenuProps {
  closedNavigationMenu: boolean;
}

export default function UserDropdownMenu({ closedNavigationMenu }: UserDropdownMenuProps): JSX.Element {
  const { enableV3Location } = useFlags();

  if (enableV3Location) {
    return <UserCircle closedNavigationMenu={closedNavigationMenu} />;
  }

  return (
    <DropdownMenu>
      <UserCircle closedNavigationMenu={closedNavigationMenu} />
    </DropdownMenu>
  );
}
