import { Suspense, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHoneForecasts } from '../../../../components/HoneForecasts';
import * as forecastUtils from '../../../../lib/forecastUtils';
import Loading from '../../../../components/Loading';
import './BookkeeperForm.css';
import BookkeeperForecastSectionsForm from './BookkeeperForecastSectionsForm';
import type { SyntheticEvent } from 'react';
import { AxiosError } from 'axios';

import {
  dismissToast,
  getLocalStorage,
  setLocalStorage,
  showToast,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS,
  FIVE_SECONDS,
  ONE_MINUTE,
} from 'lib/utils';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { refreshAccounts } from 'hooks/useReportsStore';

import { confirmAlert } from 'react-confirm-alert';

const TOAST_GENERATE_FORECAST_MAPPING_TEXT = 'Generating new forecast mapping, will take a second';
const TOAST_COA_TEXT = 'Refreshing Chart of Accounts, will take a moment to fetch the latest';

function BookkeeperForecastForm(): JSX.Element {
  const { status: locationsStatus, currentLocation } = useLocationsStore();
  const {
    downloadActuals,
    forecastMapping,
    generateForecastMapping,
    generateForecasts,
    getForecastMapping,
    setForecastMapping,
    saveForecastMapping,
  } = useHoneForecasts();
  const [actualsButtonEnabled, setActualsButtonEnabled] = useState(true);
  const [genForeButtonEnabled, setGenForeButtonEnabled] = useState(true);

  function onForecastMappingChange(newMapping: ForecastMappingSection[]) {
    setForecastMapping(newMapping);
  }

  const saveButtonEnabled = true;

  useMemo(() => {
    const localTimeActuals = getLocalStorage('hone:timeActuals');
    const enabled =
      (localTimeActuals ? new Date(localTimeActuals).getTime() + FIVE_SECONDS : 0) <= new Date().getTime();
    setActualsButtonEnabled(enabled);
  }, []);

  useMemo(() => {
    const localTimeGenFore = getLocalStorage('hone:timeGenFore');
    const enabled =
      (localTimeGenFore ? new Date(localTimeGenFore).getTime() + FIVE_SECONDS : 0) <= new Date().getTime();
    setGenForeButtonEnabled(enabled);
  }, []);

  const handleGenerateForecastMapping = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3 className="alert">
              WARNING: Generating Forecast Mapping will override existing mapping for this location!!!
            </h3>
            <h4 className="text-center">Are you sure you want to continue?</h4>
            <button className="button BKForm-btn-secondary mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();
                const toastId = showToast(TOAST_GENERATE_FORECAST_MAPPING_TEXT, TOAST_DEFAULT, FIVE_SECONDS);
                if (currentLocation) {
                  generateForecastMapping(currentLocation.id).catch((error: AxiosError) => {
                    dismissToast(toastId);
                    const errorMsg = error?.message ? JSON.parse(error?.message).message : '';
                    showToast(`Error generating forecast mapping: ${errorMsg}`, TOAST_ERROR, FIVE_SECONDS);
                  });
                }
              }}
            >
              Generate Forecast Mapping
            </button>
          </div>
        );
      },
    });
  };

  const handleRefreshAccounts = async (e: SyntheticEvent<HTMLButtonElement>) => {
    if (currentLocation) {
      const toastId = showToast(TOAST_COA_TEXT, TOAST_DEFAULT, ONE_MINUTE);
      refreshAccounts(currentLocation.id)
        .then(() => {
          dismissToast(toastId);
          showToast('Successfully refreshed accounts (COAs)', TOAST_SUCCESS, FIVE_SECONDS);
        })
        .catch((error: any) => {
          dismissToast(toastId);
          showToast(`Error refreshing accounts: ${error.toString()}`, TOAST_ERROR, FIVE_SECONDS);
        });
    }
  };

  const handleDownloadActuals = () => {
    if (currentLocation) {
      setLocalStorage('hone:timeActuals', new Date().toString());
      setActualsButtonEnabled(false);

      const toastId = showToast('Queing downloading of actual data', TOAST_DEFAULT, ONE_MINUTE);
      downloadActuals(currentLocation.id)
        .then((result: any) => {
          dismissToast(toastId);
          showToast(
            'Queued actual downloading, allow 10 minutes to download all actual data',
            TOAST_SUCCESS,
            FIVE_SECONDS
          );
        })
        .catch((error: any) => {
          dismissToast(toastId);
          showToast('Error setting up actuals download', TOAST_ERROR, FIVE_SECONDS);
        });
    }
  };

  const handleGenerateForecasts = () => {
    if (currentLocation) {
      const toastId = showToast('Queing generation of weekly/monthly forecast data', TOAST_DEFAULT, ONE_MINUTE);
      generateForecasts(currentLocation.id)
        .then((result: any) => {
          dismissToast(toastId);
          showToast(
            'Queued forecast generation, allow 5 minutes to generate weekly/monthly forecast data',
            TOAST_SUCCESS,
            FIVE_SECONDS
          );
        })
        .catch((error: any) => {
          dismissToast(toastId);
          showToast('Error queueing forecast generation', TOAST_ERROR, FIVE_SECONDS);
        });
    }
  };

  const handleSubmit = () => {
    if (currentLocation) {
      // Nest data
      const nested = forecastUtils.flatToNested(forecastMapping);

      const toastId = showToast('Saving forecast mapping', TOAST_DEFAULT, ONE_MINUTE);
      saveForecastMapping(currentLocation.id, nested)
        .then((result: any) => {
          dismissToast(toastId);
          showToast('Successfuly saved forecast mapping.', TOAST_SUCCESS, FIVE_SECONDS);
        })
        .catch((error: any) => {
          dismissToast(toastId);
          showToast('Error saving forecast mapping', TOAST_ERROR, FIVE_SECONDS);
        });
    }
  };

  useEffect(() => {
    if (currentLocation) {
      getForecastMapping(currentLocation.id).then(result => {
        const flatMapping = forecastUtils.nestedToFlat(JSON.parse(result.data.data));
        setForecastMapping(flatMapping as ForecastMappingSection[]);
      });
    }
  }, [currentLocation]);

  return (
    <>
      <div className="BKForm-section d-flex justify-content-center mb-4">
        {(locationsStatus === 'loading' || !forecastMapping) && (
          <div className="mr-3">
            <Loading loadingCircleClass="Loading-circle-small" />
          </div>
        )}
      </div>
      {forecastMapping && (
        <section data-testid="active-template-section">
          <div className="d-flex justify-content-center mb-8">
            <span className="mt-3">
              <button
                className="button BKForm-btn-alert"
                type="button"
                disabled={!currentLocation}
                onClick={() => handleGenerateForecastMapping()}
              >
                Generated Forecast Mapping
              </button>

              <button
                className="button BKForm-btn btn-secondary"
                type="button"
                disabled={!currentLocation}
                onClick={e => handleRefreshAccounts(e)}
              >
                Refresh CoA
              </button>

              <button
                className="button BKForm-btn btn-secondary mr-2"
                type="submit"
                disabled={!!isEmpty(forecastMapping) || !actualsButtonEnabled}
                onClick={handleDownloadActuals}
              >
                Download Actuals
              </button>

              <button
                className="button BKForm-btn btn-secondary mr-3"
                type="submit"
                disabled={!!isEmpty(forecastMapping) || !genForeButtonEnabled}
                onClick={handleGenerateForecasts}
              >
                Generate Forecasts
              </button>

              <button
                className="button  BKForm-btn-save"
                type="submit"
                disabled={!(!isEmpty(forecastMapping) && saveButtonEnabled)}
                onClick={handleSubmit}
              >
                Save
              </button>
            </span>
          </div>
          <div className="BKForm-tabs" id="tab-sections" style={{ display: 'block' }}>
            {!currentLocation && <Loading />}
            {currentLocation && (
              <Suspense fallback={<Loading />}>
                <BookkeeperForecastSectionsForm
                  currentLocation={currentLocation}
                  currentMapping={forecastMapping}
                  onMappingChange={(newMapping: ForecastMappingSection[]) => {
                    onForecastMappingChange(newMapping);
                  }}
                />
              </Suspense>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default BookkeeperForecastForm;
