import { IconPlComparison } from 'components/Icons';
import React, { ReactNode } from 'react';

interface HeaderProps {
  icon?: ReactNode;
  customTitle?: ReactNode;
  pageTitle?: string | ReactNode;
  children: ReactNode;
}

function Header({ children }: HeaderProps): ReactNode {
  return (
    <div className="header">
      <div className="view--header">
        {/*TODO */}
        {/*<div className="view--header__item">*/}
        {/*  <div className="view--header__item_span">*/}
        {/*    <span>*/}
        {/*      <IconPlComparison />*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*  <div className="view--header__item_title">{pageTitle}</div>*/}
        {/*</div>*/}
        {children}
      </div>
    </div>
  );
}

export default Header;
