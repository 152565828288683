import { PostFlaggedTransaction, PostFlaggedTransactionRequest } from 'domain/usecases';

import { BaseHttpService } from 'main/factories/http';

export class RemotePostFlaggedTransaction
  extends BaseHttpService<PostFlaggedTransactionRequest.Model>
  implements PostFlaggedTransaction
{
  async post(body: PostFlaggedTransactionRequest.Body): Promise<PostFlaggedTransactionRequest.Model> {
    return await this.request('post', body);
  }
}
