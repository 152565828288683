import { AppLayout } from 'presentation/components';
import DashboardView from 'presentation/pages/dashboard/DashboardView';

export const DashboardViewFactory = (): JSX.Element => {
  return (
    <AppLayout noFooter={true}>
      <DashboardView />
    </AppLayout>
  );
};
