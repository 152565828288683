import { ReportGraphDataSummary } from 'domain/models/ReportGraph';

import { EChartsOption } from 'echarts-for-react';
import { currencyFormatter, getReportTitle, percentageFormatter } from 'lib/utils';
import find from 'lodash/find';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { formatDateQbo, formatDateQboWeekly } from 'presentation/utils';

export const buildChartConfig = (
  isYTD: boolean,
  categoryData: ReportGraphDataSummary | undefined,
  timeframe: string,
  barWidth = '90%',
  extraCategoryData: ReportGraphDataSummary | undefined,
  years: { period: number; reportId: string }[],
  activeActions: string[],
  daysTillEndOfReport: number,
  plot1?: string,
  plot2?: string
): EChartsOption => {
  const report = useActiveReportStore.getState().currentReport;
  const normalizeData = categoryData?.reportValues;
  const percentageData = categoryData?.reportPercentages;

  let extraData: number[] = [];
  let extraPercentages: number[] = [];
  if (extraCategoryData && typeof plot2 !== 'undefined' && plot2 !== '-') {
    extraData = extraCategoryData?.reportValues;
    extraPercentages = extraCategoryData?.reportPercentages;
  }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const reportDates = report?.dates && [...report?.dates];

  const styledData: undefined | ChartBar[] = normalizeData?.slice();

  let labels =
    // eslint-disable-next-line no-unsafe-optional-chaining
    categoryData?.reportLabels && Array.isArray(categoryData?.reportLabels) ? [...categoryData?.reportLabels] : [];

  if (['Weekly', 'Monthly'].includes(timeframe) && labels && styledData && normalizeData) {
    labels =
      timeframe === 'Weekly' && reportDates
        ? [...reportDates.splice(0, labels.length)].map((rd: any, idx: number) => formatDateQboWeekly(rd.start, rd.end))
        : [...labels].map((lb: string) => formatDateQbo(new Date(lb)));
    if (daysTillEndOfReport > 0) {
      styledData[styledData.length - 1] = {
        value: normalizeData[normalizeData.length - 1],
        itemStyle: {
          borderColor: '#3e6379',
          borderWidth: 2,
          borderType: 'dotted',
        },
      };
    }
  } else {
    labels = [...labels].map((lb: string) => lb.toUpperCase());
  }

  const activeYears = [plot1, plot2];
  const barSeriesNames = years
    .filter(year => {
      return year.reportId === plot1 || year.reportId === plot2;
    })
    .map(report => ({ name: report.period, reportId: report.reportId }));

  const hasBars = activeActions.includes('bars');
  const hasLines = activeActions.includes('lines');

  barWidth = plot2 ? '45%' : '70%';

  const barsSeries = [
    {
      name: plot2 ? find(barSeriesNames, { reportId: plot1 })?.name : '$',
      type: 'bar',
      symbolSize: 12,
      barWidth,
      data: styledData,
      emphasis: {
        itemStyle: {
          color: 'var(--ks-chart-primary)', // Change the color of the bars on hover/focus
        },
        lineStyle: {
          color: 'var(--ks-chart-primary)', // Change the color of the bars on hover/focus
        },
      },
      tooltip: {
        valueFormatter: (value: number) => currencyFormatter(value),
      },
      lineStyle: {
        color: 'var(--ks-chart-primary)',
        width: 4,
      },
      itemStyle: {
        border: 'none',
        color: 'var(--ks-chart-primary)',
      },
    },
  ];

  if (plot2) {
    barsSeries.push({
      name: find(barSeriesNames, { reportId: plot2 })?.name,
      type: timeframe === 'Weekly' ? 'line' : 'bar',
      symbolSize: 12,
      barWidth,
      emphasis: {
        itemStyle: {
          color: 'var(--ks-chart-secondary)', // Change the color of the bars on hover/focus
        },
        lineStyle: {
          color: 'var(--ks-chart-secondary)', // Change the color of the bars on hover/focus
        },
      },
      data: extraData,
      tooltip: {
        valueFormatter: (value: number) => currencyFormatter(value),
      },
      lineStyle: {
        color: 'var(--ks-chart-secondary)',
        width: 4,
      },
      itemStyle: {
        border: 'none',
        color: 'var(--ks-chart-secondary)',
      },
    });
  }

  const linesSeries = activeYears.map((series, index) => {
    const color = index === 0 ? 'var(--ks-dashboard-selected-text)' : 'var(--ks-dashboard-netsales-text)';
    const name = find(barSeriesNames, { reportId: series })?.name;
    return {
      name: plot2 ? `${name} %` : '%',
      type: 'line',
      barWidth,
      yAxisIndex: 1,
      data: index === 0 ? percentageData : plot2 !== undefined ? extraPercentages : [],
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value: number) => percentageFormatter(value),
      },
      symbol: 'circle',
      symbolSize: 12,
      lineStyle: {
        color: color,
        width: 2,
        type: 'dashed',
      },
      emphasis: {
        itemStyle: {
          color: color, // Change the color of the bars on hover/focus
        },
        lineStyle: {
          color: color, // Change the color of the bars on hover/focus
        },
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: color,
        color: '#f7f7f7',
      },
    };
  });

  const options = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: labels || [],
        name: getReportTitle(report).toUpperCase(),
        nameLocation: 'start',
        nameGap: 15,
        nameTextStyle: {
          fontSize: 12,
          fontWeight: 'bold',
          align: 'right',
          padding: [14, -5, 0, 0],
          verticalAlign: 'top',
        },
        axisLabel: {
          padding: [5, 0, 0, 0],
          fontSize: 13,
        },
      },
    ],

    yAxis: [
      {
        type: 'value',
        axisLabel: {
          show: true,
          formatter: (value: any) => {
            if (value === 0) return null;
            return currencyFormatter(value);
          },
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => {
            if (value === 0) return null;
            return percentageFormatter(value);
          },
        },
      },
    ],
    animationEasing: 'elasticOut',
    animationDelay: function (idx: number) {
      return idx * 10;
    },
    animationDelayUpdate: function (idx: number) {
      return idx * 10;
    },
    series: [...(hasLines ? linesSeries : []), ...(hasBars ? barsSeries : [])],
    grid: {
      top: 60,
      left: 60,
      right: 0,
      bottom: 50,
    },
    dataZoom: {
      show: false,
      start: 0,
      end: 100,
    },
  };

  return options;
};
