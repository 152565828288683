// @ts-nocheck
import classNames from 'classnames';
import { currencyFormatter2Decimals } from 'lib/utils';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { shallow } from 'zustand/shallow';
import { useQueryState } from 'hooks/useQueryState';
import { AskRequest, AskRequestStatus } from '@hone-automation/common';
import { IconCustomerRequest, IconFlaggedCell } from 'components/Icons';
import Tag from '../Tag';

type AskCustomerRowProps = {
  request: AskRequest;
  onClick: () => void;
};

function FlaggedCellRow({ request, onClick }: AskCustomerRowProps) {
  const { activeFlaggedTransaction, animatedTransaction } = useFlaggedTransactions(
    state => ({
      activeFlaggedTransaction: state.activeFlaggedTransaction,
      animatedTransaction: state.animatedTransaction,
    }),
    shallow
  );
  const [activeTab] = useQueryState<AskRequestStatus>('activeTab', 'Open');
  const trxDate = String(request?.createdAt).split('T')[0];
  const name = request?.identifier;
  const isActiveTransaction = activeFlaggedTransaction?.id === request?.id;
  const isLastRow = true;

  const amount = request?.flaggedAmount;
  const description = request.comments?.[0]?.comment;

  return (
    <div
      className={classNames('ask-customer-row with-icon', {
        active_row_categorized: isActiveTransaction && activeTab === 'Closed',
        active_row_information: isActiveTransaction && activeTab === 'Open',
        active_row_submitted: isActiveTransaction && activeTab === 'In Progress',
        animation_closed: isActiveTransaction && animatedTransaction === 'Closed',
        animation_open: isActiveTransaction && animatedTransaction === 'Open',
        animation_in_progress: isActiveTransaction && animatedTransaction === 'In Progress',
        should_animate: isActiveTransaction && !!animatedTransaction,
      })}
      onClick={onClick}
    >
      <div className={classNames('data-row', { isLastRow })}>
        <div className="info">
          <div className="name-description">
            <span className="mobile-date">{trxDate}</span>
            <span className="money-date mobile-hidden">{trxDate}</span>
            <div className="name">
              <div data-testid="row-name" style={{ display: 'flex' }}>
                {request.type === 'Flagged Cell' && <IconFlaggedCell style={{ opacity: 0.4, marginRight: '3px' }} />}
                {request.type === 'Customer Request' && <IconCustomerRequest style={{ marginRight: '3px' }} />}

                {name === '' ? 'No Vendor Name' : name}
              </div>
              <div className="mobile-money">${amount}</div>
            </div>
            <span className="description">{description}</span>
          </div>
          <div className="money">
            <span className="money-value">{amount && currencyFormatter2Decimals(amount)}</span>
          </div>
          <div className="tag-info">{request.recentlyResolved && <Tag content="Newest" type={'info'} />}</div>
        </div>
      </div>
    </div>
  );
}

export default FlaggedCellRow;
