import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { isNull } from 'lodash';
import { IconClear } from '../../../../components/Icons';

interface Props {
  initialInputItems: string[];
  placeholder: string;
  selectedItem?: string | null;
  disabled?: boolean;
  onChange: (selected: string | undefined) => void;
}

function BookkeeperTagsAutocomplete({
  initialInputItems,
  placeholder = 'Choose tag ...',
  selectedItem = null,
  disabled = false,
  onChange,
}: Props): JSX.Element {
  const [inputItems, setInputItems] = useState(initialInputItems);
  const itemToString = (item: string | null) => {
    if (item) {
      return item;
    }
    return '';
  };

  // keep options updated when input items changes
  useEffect(() => {
    setInputItems(initialInputItems);
  }, [initialInputItems]);

  const { isOpen, openMenu, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps, reset } =
    useCombobox({
      items: inputItems,
      itemToString,
      selectedItem,
      onInputValueChange: ({ inputValue }) => {
        onChange(inputValue);
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (isNull(selectedItem)) return onChange(undefined);
        onChange(selectedItem);
      },
    });

  const resetAndChange = () => {
    reset();
    onChange(undefined);
  };

  return (
    <div className="Autocomplete">
      <label {...getLabelProps()} className="visually-hidden">
        Choose an account:
      </label>
      <div>
        <input
          {...getInputProps({ disabled, onFocus: () => !isOpen && openMenu() })}
          placeholder={placeholder}
          className="select"
        />
        <button type="button" onClick={resetAndChange} aria-label="Clear">
          <IconClear />
        </button>
      </div>
      <ul {...getMenuProps()} className="Autocomplete-baseMenu">
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className="Autocomplete-item"
              style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {itemToString(item)}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default BookkeeperTagsAutocomplete;
