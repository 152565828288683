import { useState } from 'react';
import { DismissedBalanceSheetPayload } from '@hone-automation/common';
import classNames from 'classnames';
import EChartsReact from 'echarts-for-react';
import { useActiveReportStore } from 'hooks/useActiveReportStore';

type SparklinesChartProps = {
  dismissed: boolean | undefined;
  chartData: number[];
  dismissPayload: DismissedBalanceSheetPayload;
};

export default function SparklinesChart({ dismissed, chartData, dismissPayload }: SparklinesChartProps) {
  const dismissSparklines = useActiveReportStore(state => state.dismissSparklines);
  const [innerDismissed, setInnerDismissed] = useState<boolean | undefined>(dismissed);
  const options = {
    animationDuration: 200,
    animation: false,
    xAxis: {
      show: false,
      type: 'category',
    },
    yAxis: {
      show: false,
      type: 'value',
    },
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        {
          lt: -100,
          color: 'red',
        },
        {
          lte: 100,
          color: 'black',
        },
        {
          gt: 100,
          color: 'black',
        },
      ],
    },
    series: [
      {
        data: chartData,
        type: 'line',
        smooth: true,
        showSymbol: false,
      },
    ],
  };
  return (
    <div
      className={classNames('hone-report-table--sparklines', {
        'hone-report-table__sparklines_dismissed_overlay': innerDismissed,
      })}
      onClick={() => {
        setInnerDismissed(!innerDismissed);
        dismissSparklines(dismissPayload);
      }}
    >
      <EChartsReact opts={{ renderer: 'svg' }} style={{ height: '100px', width: '100%' }} option={options} />
    </div>
  );
}
