import { useMutation } from '@tanstack/react-query';
import { useLocationsStore } from '../useLocationsStore';
import { makeRemoteGetReportsConfig } from 'main/factories/usecases';

const reportConfigurationService = makeRemoteGetReportsConfig();

export const useGetReportConfiguration = () => {
  return useMutation({
    mutationFn: (currentLocationId: string) => {
      return reportConfigurationService.getConfig({
        locationId: currentLocationId!,
      });
    },
  });
};
