import React, { useState, useEffect } from 'react';
import './KSClock.scss';

const KSClock = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatDateTime = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'pm' : 'am';
    return (
      <>
        {formattedDate}{' '}
        <span className="ks-hours">
          {hours}:{minutes}
        </span>{' '}
        <span className="ks-ampm">{ampm}</span>
      </>
    );
  };

  return <div className="ks-clock">{formatDateTime(dateTime)}</div>;
};

export default KSClock;
