import { AppLayout } from 'presentation/components';
import AccrualsView from 'presentation/pages/accurals/AccrualsView';
import JournalEntriesView from 'presentation/pages/journal-entries/JournalEntriesView';

export const JournalEntriesFactory = () => {
  return (
    <AppLayout noPadding>
      <JournalEntriesView />
    </AppLayout>
  );
};
