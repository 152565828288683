import React, { useEffect, useState } from 'react';
import { useDashboardReport } from 'hooks/useDashboard';

import Loading from 'components/Loading';
import DashboardCardsLayout from './components/DashboardCardsLayout';
import { IconNbDash } from 'components/Icons';
import { PageHeader, TimeSelector } from 'presentation/components';
import { ForecastTimeframe, timesframes } from 'domain/models';
import useLocationRouting from 'hooks/useLocationRouting';
import usePrevious from 'hooks/usePrevious';

import { isEmpty } from 'lodash';
import { CardTimeframe, SummaryCard } from '@hone-automation/common';
import { useLocationsStore } from 'hooks/useLocationsStore';
import DashboardCardsLayoutMobile from './components/DashboardCardsLayoutMobile';

// Importing here the controls related to ask request warnings
import { useAskRequestsPendingCount } from 'hooks/useAskRequestsPendingCount';
import { useNavigate } from 'react-router-dom';
import { useHoneLocationUsers } from 'components/HoneLocationUsers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import KSHeader from '../../components/KSHeader';

export default function DashboardView(): JSX.Element {
  const { enableV3Location } = useFlags();
  useLocationRouting();
  const { currentLocationId, currentLocation } = useLocationsStore(state => ({
    currentLocationId: state.currentLocationId,
    currentLocation: state.currentLocation,
  }));

  const { fetchDashboardReport, loadingDashboard, timeframe, setTimeFrame, dashboardReport, setActiveBussinessMetric } =
    useDashboardReport();
  const prevTimeframe = usePrevious(timeframe);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [showNoDataMessage, setshowNoDataMessage] = useState<boolean>(false);
  const prevCurrentLocationId = usePrevious(currentLocation?.id);

  useEffect(() => {
    if (prevTimeframe === 'Current Week') setTimeFrame('Last Week');
    if (
      currentLocation &&
      currentLocation?.id &&
      (prevCurrentLocationId !== currentLocation?.id || prevTimeframe !== timeframe)
    ) {
      setActiveBussinessMetric('Cost of Goods Sold');
      fetchDashboardReport(currentLocation?.id, timeframe);
    }
  }, [
    currentLocation?.id,
    fetchDashboardReport,
    timeframe,
    setActiveBussinessMetric,
    setTimeFrame,
    prevCurrentLocationId,
  ]);

  useEffect(() => {
    if (dashboardReport !== undefined && dashboardReport?.cards.length > 0) {
      if (!dashboardReport?.cards.some((item: SummaryCard) => !isEmpty(item.data.items))) {
        setshowNoDataMessage(true);
        setTimeFrame('Last Week');
        return;
      }
    }
  }, [dashboardReport, setTimeFrame]);

  const handleChangeTimeframeSeletor = (timeframe: CardTimeframe | ForecastTimeframe) => {
    setTimeFrame(timeframe as CardTimeframe);
  };

  const navigateTo = useNavigate();
  const { isBookkeeperAdmin } = useHoneLocationUsers();

  const redirectAskCustomer = () => {
    const activeTab = isBookkeeperAdmin ? 'In Progress' : 'Open';
    navigateTo(`/app/location/${currentLocationId}/ask-requests?activeTab=${activeTab}`);
  };

  const askRequestsCountForCurrentRole = useAskRequestsPendingCount();

  const renderCardsLayout = () => {
    if (isMobile) {
      return (
        <DashboardCardsLayoutMobile
          showNoDataMessage={showNoDataMessage}
          handleChangeTimeframeSeletor={handleChangeTimeframeSeletor}
        />
      );
    }

    return (
      <div className="dashboard-content-area">
        {/*           {typeof askRequestsCountForCurrentRole === "number"  && askRequestsCountForCurrentRole>0 && (
            <div className="informative-message-container">
              <InformativeMessage type="checklist" clickable>
                <div className="dashboard-informative-flagged-transactions" onClick={redirectAskCustomer}>
                  Ask Requests Pending
                  <div className="badge-icon">
                    <Badge content={askRequestsCountForCurrentRole} />
                    <IconForwardBtn />
                  </div>
                </div>
              </InformativeMessage>
            </div>
          )}  */}

        <TimeSelector
          disabledItem={showNoDataMessage ? 'Current Week' : undefined}
          timeframes={timesframes}
          timeframeSelected={timeframe}
          onTimeframeChange={handleChangeTimeframeSeletor}
        />
        <DashboardCardsLayout showNoDataMessage={showNoDataMessage} />
      </div>
    );
  };

  return (
    <div className="dashboard-view">
      {enableV3Location && <KSHeader />}
      {!isMobile && <PageHeader icon={<IconNbDash />} pageTitle="Dashboard" />}

      {loadingDashboard ? (
        <div className="h-100 center">
          <Loading />
        </div>
      ) : (
        renderCardsLayout()
      )}
    </div>
  );
}
