export const getKSSettings = (sheets: boolean) => {
  const ksScale = sheets ? 0.65 : 0.85;

  const ksHeights = {
    sheets: {
      isBigHeader: 36 * ksScale,
      isHeader: 26 * ksScale,
      isTotal: 26 * ksScale,
      regular: 24 * ksScale,
      header: 24 * ksScale,
      lastHeader: 18 * ksScale,
      first: 25 * ksScale,
      second: 24 * ksScale,
      third: 30 * ksScale,
    },
    excel: {
      isBigHeader: 40 * ksScale,
      isHeader: 26 * ksScale,
      isTotal: 27 * ksScale,
      regular: 24 * ksScale,
      header: 28 * ksScale,
      lastHeader: 20 * ksScale,
      first: 25 * ksScale,
      second: 26 * ksScale,
      third: 30 * ksScale,
    },
  };

  const ksBaseStyleSettings = {
    font: 'Arial',
    height: ksHeights[sheets ? 'sheets' : 'excel'],
    width: {
      money: (sheets ? 15 : 17) * ksScale,
      percentage: (sheets ? 13 : 14) * ksScale,
      account: 50 * ksScale,
      gl: 7 * ksScale,
      percentOf: 25 * ksScale,
    },
    sizes: {
      small: (sheets ? 10 : 9) * ksScale,
      normal: 12 * ksScale,
      intermediate: 12 * ksScale,
      large: 13 * ksScale,
      big: 15 * ksScale,
      huge: 20 * ksScale,
    },
    colors: {
      cellText: { argb: '888888' },
      cellBG: { argb: 'FFFFFF' },
      cellAlternateBG: { argb: 'fbfcfb' },
      percentOfText: { argb: '999999' },

      headerText: { argb: '000000' },
      headerBG: { argb: 'f7f8f7' },
      scndHeaderText: { argb: '777777' },
      lastHeaderText: { argb: 'AAAAAA' },

      report: { argb: '666666' },
      company: { argb: '000000' },
      timeFrame: { argb: '999999' },

      bigHeaderBG: { argb: 'f1f2f1' },
      totalText: { argb: '000000' },
      totalBG: { argb: 'f6f7f6' },

      borders: {
        white: { argb: 'FFFFFF' },
        normal: { argb: 'eeeeee' },
        lightest: { argb: 'f0f1f0' },
        lighter: { argb: 'e7e8e7' },
        concepts: { argb: 'eEeFeE' },
        mainColumns: { argb: 'd7d8d7' },
      },
    },
  };

  const ksStyles = {
    cell: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.normal,
        color: ksBaseStyleSettings.colors.cellText,
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: ksBaseStyleSettings.colors.cellBG,
      },
      alignment: { vertical: 'middle', indent: 1 },
    },
    percentColumn: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.small,
        color: ksBaseStyleSettings.colors.percentOfText,
      },
      border: {
        left: { style: 'thin', color: ksBaseStyleSettings.colors.borders.normal },
      },
    },
    glColumn: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.small,
        color: ksBaseStyleSettings.colors.percentOfText,
      },
      border: {
        right: { style: 'thin', color: ksBaseStyleSettings.colors.borders.normal },
      },
      alignment: { vertical: 'middle', horizontal: 'left', indent: 1 },
      alignmentBH: { vertical: 'bottom', horizontal: 'left', indent: 1 },
    },
    cellBorder: {
      border: {
        top: { style: 'thin', color: ksBaseStyleSettings.colors.borders.normal },
        left: { style: 'thin', color: ksBaseStyleSettings.colors.borders.normal },
        right: { style: 'thin', color: ksBaseStyleSettings.colors.borders.normal },
        bottom: { style: 'thin', color: ksBaseStyleSettings.colors.borders.normal },
      },
    },
    cellAlternate: {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: ksBaseStyleSettings.colors.cellAlternateBG,
      },
    },
    white: {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: ksBaseStyleSettings.colors.cellBG,
      },
      border: {
        top: { style: 'thin', color: ksBaseStyleSettings.colors.borders.white },
        left: { style: 'thin', color: ksBaseStyleSettings.colors.borders.white },
        bottom: { style: 'thin', color: ksBaseStyleSettings.colors.borders.white },
        right: { style: 'thin', color: ksBaseStyleSettings.colors.borders.white },
      },
    },
    header: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.large,
        bold: false,
        color: ksBaseStyleSettings.colors.headerText,
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: ksBaseStyleSettings.colors.headerBG,
      },
      border: {
        top: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter }, //cbdbeb
        left: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
        bottom: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
        right: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
      },
    },
    isTotal: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.normal,
        bold: false,
        color: ksBaseStyleSettings.colors.totalText,
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: ksBaseStyleSettings.colors.totalBG,
      },
      border: {
        bottom: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter }, //cbdbeb
        left: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
        top: { style: 'dotted', color: ksBaseStyleSettings.colors.borders.lighter },
        right: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
      },
    },
    isBigTotal: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.normal,
        bold: true,
        color: ksBaseStyleSettings.colors.totalText,
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: ksBaseStyleSettings.colors.bigHeaderBG,
      },
      border: {
        bottom: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter }, //cbdbeb
        left: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
        top: { style: 'dotted', color: ksBaseStyleSettings.colors.borders.lighter },
        right: { style: 'thin', color: ksBaseStyleSettings.colors.borders.lighter },
      },
    },
    isHeader: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.normal,
        bold: false,
        color: ksBaseStyleSettings.colors.totalText,
      },
    },
    isTotal2: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.normal,
        bold: false,
        color: ksBaseStyleSettings.colors.totalText,
      },
    },
    isBigHeader: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.intermediate,
        bold: true,
        color: ksBaseStyleSettings.colors.totalText,
      },
      alignment: { vertical: 'bottom', horizontal: 'left', indent: 1 },
    },
    headerData: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.large,
        bold: false,
        color: ksBaseStyleSettings.colors.scndHeaderText,
      },
      alignment: { vertical: 'middle', horizontal: 'center', indent: 1 },
    },
    headerDataFirst: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.large,
        bold: false,
        color: ksBaseStyleSettings.colors.headerText,
      },
    },
    headerDataLast: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.normal,
        bold: false,
        color: ksBaseStyleSettings.colors.lastHeaderText,
      },
    },
    infoTimeFrame: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.large,
        bold: false,
        color: ksBaseStyleSettings.colors.timeFrame,
      },
    },
    infoCompany: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.huge,
        bold: false,
        color: ksBaseStyleSettings.colors.headerText,
      },
    },
    infoReport: {
      font: {
        name: ksBaseStyleSettings.font,
        family: 2,
        size: ksBaseStyleSettings.sizes.big,
        bold: false,
        color: ksBaseStyleSettings.colors.report,
        underline: true,
      },
    },
  };

  return {
    ksScale,
    ksBaseStyleSettings,
    ksStyles,
  };
};
