import { ChangeEvent, createRef, memo, useEffect, useMemo, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import EChartsReact from 'echarts-for-react';
import { shallow } from 'zustand/shallow';

import { Category, ReportGraphDataSummary } from 'domain/models/ReportGraph';

import { buildChartConfig } from './config';
import { transformedCategoriesColor } from 'presentation/components/CategoriesContainer/CategoriesContainer';

import Loading from 'components/Loading';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { useReportGraphStore } from 'hooks/useReportGraphStore';
import { useQueryState } from 'hooks/useQueryState';
import useActiveActions, { Action } from 'hooks/useActiveActions';
import { differenceInCalendarDays } from 'date-fns';
import GraphControls from '../GraphControls';

interface ReportGraphProps {
  graphWidth: number;
  isYTD: boolean;
  currentReportId?: string;
  selectedReport: HoneReportSummary;
  reports: HoneReportSummary[];
  timeframe: string;
  extraReportDataSummary: ReportGraphDataSummary | null;
  onReportChange: (newReportId: string) => void;
}

function ReportGraph({
  graphWidth,
  isYTD,
  selectedReport,
  reports,
  timeframe,
  onReportChange,
}: ReportGraphProps): JSX.Element {
  const summaryReport = useReportGraphStore(state => state.summaryReport);

  const extraReportSummary = useReportGraphStore(state => state.extraReportSummary);
  const extraReportId = useReportGraphStore(state => state.extraReportId);
  const { activeCategory, allCategories } = useActiveReportStore(
    state => ({ activeCategory: state.activeCategory, allCategories: state.allCategories }),
    shallow
  );
  const { setYearPlotted } = useActiveReportStore(state => ({ setYearPlotted: state.setYearPlotted }), shallow);
  const { activeActions } = useActiveActions();
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const chartRef = createRef<EChartsReact>();

  const plot1 = selectedReport?.id;

  const [plot2, setPlot2] = useQueryState('extraReportId');

  useEffect(() => {
    if (extraReportId === selectedReport.id) {
      setPlot2(undefined);
      useReportGraphStore.setState({ extraReportId: undefined });
    }
  }, [extraReportId, selectedReport.id]);

  const handleExtraReportChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    let extraReportId: string | undefined = value;
    setPlot2(value);
    if (value === '') {
      extraReportId = undefined;
      setChartLoading(true);
    }
    useReportGraphStore.setState({ extraReportId, extraReportSummary: undefined });
  };

  const years = useMemo(
    () =>
      reports.map(report => ({
        period: new Date(report.endDate).getFullYear(),
        reportId: report.id,
      })),
    [reports]
  );

  const handleReportChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const yearPlotted = years.find(year => year.reportId === e.target.value);
    yearPlotted?.period && setYearPlotted(yearPlotted?.period);
    onReportChange(e.target.value);
    setChartLoading(true);
  };

  useEffect(() => {
    setChartLoading(false);
  }, [summaryReport, plot2]);

  const columnIndex = timeframe === 'Weekly' ? 3 : 1;

  const daysTillEndOfReport = differenceInCalendarDays(
    new Date(summaryReport?.reportLabels[columnIndex] as unknown as Date),
    new Date()
  );

  const echartsOptions: EChartsOption = buildChartConfig(
    isYTD,
    summaryReport,
    timeframe,
    isYTD ? '90%' : '30%',
    extraReportSummary,
    years,
    activeActions,
    daysTillEndOfReport,
    plot1,
    extraReportId
  );

  const activeColor = transformedCategoriesColor.blue;

  useEffect(() => {
    setChartLoading(true);
    setTimeout(() => setChartLoading(false), 1000);
  }, [activeActions.length]);

  useEffect(() => {
    return () => {
      useReportGraphStore.setState({ extraReportId: undefined });
    };
  }, []);

  useEffect(() => {
    if (plot1) {
      setChartLoading(true);
      setTimeout(() => {
        setChartLoading(false);
      }, 1000);
    }
  }, [plot1]);

  return (
    <div key={`wrapper-${plot1} ${plot2} ${graphWidth}`} className="report-graphs-container">
      {allCategories && (
        <>
          <div className="report-graphs-title">{activeCategory?.name?.replace('->', '')}</div>
          <div className="graph-wrapper" style={{ width: `${graphWidth}px` }}>
            {chartLoading ? (
              <div className="graph-loading">
                <Loading />
              </div>
            ) : (
              <>
                <ReactECharts
                  key={`graph-${plot1} ${plot2}`}
                  opts={{ renderer: 'svg' }}
                  ref={chartRef}
                  option={echartsOptions}
                />
              </>
            )}
            {isYTD && (
              <GraphControls
                key={`controls-${plot1} ${plot2}`}
                daysTillEndOfReport={daysTillEndOfReport}
                plot1={plot1}
                plot2={extraReportId}
                selectedReport={selectedReport}
                activeColor={activeColor}
                periods={years}
                onReportChange={handleReportChange}
                onExtraReportChange={handleExtraReportChange}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default memo(ReportGraph);
