import { Anomaly } from './types';

export function detectAnomaly(series: number[], threshold: number = 0.75, ignore: boolean = false): Anomaly {
  const emptyReturn = { anomalies: [], anomalyScores: [], stats: { mean: 0, variance: 0, stdDev: 0 } };
  if (series.length < 3 || ignore) return emptyReturn;

  const nonZeroValues = series.filter(val => val !== null && val !== 0);
  if (nonZeroValues.length < 3) return emptyReturn;

  const nonZeroCount = nonZeroValues.length;
  if (nonZeroCount / series.length < 0.35) {
    return emptyReturn;
  }

  const mean = series.reduce((sum, val) => sum + val, 0) / series.length;
  const variance = series.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / series.length;
  const stdDev = Math.sqrt(variance);

  const anomalies: number[] = [];
  const anomalyScores: number[] = series.map(num => {
    if (num === 0) {
      return 0;
    }
    const deviation: number = num - mean;
    if (stdDev === 0) {
      anomalies.push(0);
      return 0;
    }
    const score = deviation / stdDev;
    const normalizedScore = score / 2; // Normalize to [-1, 1] range
    const clampedScore = Math.max(-1, Math.min(1, normalizedScore));
    if (Math.abs(clampedScore) >= threshold) {
      anomalies.push(num);
      return clampedScore;
    }
    return 0;
  });

  return { anomalies, anomalyScores, stats: { mean, variance, stdDev } };
}
