import './IconButton.scss';
import { Whisper, Tooltip } from 'rsuite';
import classNames from 'classnames';

type IconButtonProps = {
  tooltip: string;
  status?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  label?: string;
  boundary?: boolean;
  onClick?: () => void;
  iconSize?: number;
};

const IconButton = ({
  tooltip,
  status = true,
  disabled = false,
  icon,
  label,
  boundary = false,
  onClick,
  iconSize = 24,
}: IconButtonProps) => {
  const handleClick = () => {
    onClick?.();
  };
  return (
    <Whisper placement="top" trigger="hover" disabled={disabled} speaker={<Tooltip>{tooltip}</Tooltip>} delayOpen={600}>
      <div
        style={{ width: label ? 'auto' : `${iconSize}px`, height: `${iconSize}px` }}
        className={classNames('icon-button', {
          status: status,
          boundary: boundary,
          disabled: disabled,
          label: !!label,
        })}
        onClick={handleClick}
      >
        {label ? label : icon}
      </div>
    </Whisper>
  );
};

export default IconButton;
