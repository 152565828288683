import './ForecastTimeFrameSelector.scss';
import classNames from 'classnames';
import { useForecastUIStore } from 'hooks/useForecastUIStore';
import { useForecastReport } from 'hooks/useForecastReport';
import { useAuthContext } from '../../../../context/useAuthContext';

export default function ForecastTimeFrameSelector(): JSX.Element {
  const { userId } = useAuthContext();
  const fetchForecastReport = useForecastReport(state => state.fetchForecastReport);
  const { timeframe } = useForecastUIStore(state => ({
    timeframe: state.timeframe,
  }));

  const onTimeframeChange = (timeframe: ForecastTimeframe) => {
    const type = timeframe === 'weeks' ? 'Weekly Forecast' : 'Monthly Forecast';
    useForecastUIStore.setState({ timeframe });
    if (userId) {
      fetchForecastReport(userId, type);
    }
  };

  return (
    <div className="forecast-timeframe-selector">
      <div>
        <button className={classNames({ active: timeframe === 'weeks' })} onClick={() => onTimeframeChange('weeks')}>
          2 Weeks
        </button>
        <button className={classNames({ active: timeframe === 'months' })} onClick={() => onTimeframeChange('months')}>
          2 Months
        </button>
      </div>
    </div>
  );
}
