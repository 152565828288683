import classNames from 'classnames';
import './KSCheckbox.scss';
import { ChangeEvent } from 'react';

interface CheckOptionProps {
  rounded?: boolean;
  label: string;
  disabled?: boolean;
  checked: boolean;
  smallCaps?: boolean;
  onChange: (checked: boolean) => void;
}

export default function KSCheckBox({
  rounded = false,
  checked,
  label,
  disabled,
  smallCaps,
  onChange,
}: CheckOptionProps) {
  return (
    <label className={classNames('ks-check-option', { rounded: rounded })}>
      <span
        className={classNames('label', { 'is-disabled': disabled, 'is-checked': checked, 'small-caps': smallCaps })}
      >
        {label}
      </span>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
      />
      <span className={classNames('checkmark', { 'is-disabled': disabled })}></span>
    </label>
  );
}
