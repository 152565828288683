import { getLocalStorage, setLocalStorage } from 'lib/utils';
import { create } from 'zustand';

interface UserAuthState {
  session: string | null;
  setSession: (session: string | null) => void;
  deleteSession: () => void;
  userId: string | null;
  setUser: (user: User) => void;
  user: User | null;
}

export const useAuthUser = create<UserAuthState>()(set => ({
  session: getLocalStorage('hone:session'),
  setSession: (session: string | null) => {
    if (session) {
      setLocalStorage('hone:session', session);
    } else {
      localStorage.removeItem('hone:session');
    }
    set({ session });
  },
  deleteSession: () => {
    set({ session: null, userId: null });
    localStorage.removeItem('hone:session');
    localStorage.removeItem('hone:signedParamsCDN');
    localStorage.removeItem('hone:locationId');
  },
  userId: null,
  user: null,
  setUser: (user: User) => set({ user, userId: user.uid }),
}));
