import React, { createContext, FC, useContext, useState } from 'react';

type HoverContextProps = {
  isHovered: boolean;
  setIsHovered: (isHovered: boolean) => void;
};

const HoverContext = createContext<HoverContextProps>({
  isHovered: false,
  setIsHovered: () => {
    //
  },
});

export const HoverProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);

  return <HoverContext.Provider value={{ isHovered, setIsHovered }}>{children}</HoverContext.Provider>;
};

export const useHighlightReportSelector = () => {
  const context = useContext(HoverContext);
  if (!context) {
    throw new Error('useHover must be used within a HoverProvider');
  }
  return context;
};
