import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const bucket = process.env.VITE_GCS_BUCKET_NAME;

function useVersionQuery() {
  return useQuery({
    queryKey: ['version'],
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    queryFn: async () => {
      if (!bucket) {
        console.error('no bucket setup');
        return;
      }
      const url = `https://${bucket}.storage.googleapis.com/manifest/manifest.json`;

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data.replace('{version: ', '').replace('}', '').replace('\n', '');
    },
  });
}

export default useVersionQuery;
