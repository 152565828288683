import Modal from 'react-modal';
import { IconBack, IconClear } from 'components/Icons';
import { useHoneLocationUsers } from 'components/HoneLocationUsers';

import { useForm } from 'react-hook-form';

import { CreateAskRequest } from '@hone-automation/common';
import classNames from 'classnames';
import { useCreateAskRequestMutation } from 'hooks/flaggedTransactions';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAuthContext } from 'context/useAuthContext';

interface CreateRequestModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

function CreateRequestModal(props: CreateRequestModalProps) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const { locationId } = useParams();
  const { isBookkeeperAdmin } = useHoneLocationUsers();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateAskRequest>({
    defaultValues: {
      type: 'Customer Request',
      identifier: '',
      comment: '',
      for: isBookkeeperAdmin ? 'Open' : 'In Progress',
      locationId,
    },
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const create = useCreateAskRequestMutation(locationId!, () => {
    reset();
    props.handleClose();
  });

  const { user } = useAuthContext();

  // TODO: upcoming version
  // const title = isBookkeeperAdmin ? "Inbound Request" : "Request for Bookkeeper";
  const title = 'New Ask Request';

  const onSubmit = async (data: CreateAskRequest) => {
    create.mutate(data);
    reset();
  };

  const renderExperience = () => {
    return (
      <form className="form-flex" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          {isMobile && (
            <div className="close">
              <button style={{ background: 'transparent' }} onClick={props.handleClose}>
                <IconBack />
              </button>
            </div>
          )}
          <div>
            <h3>{title}</h3>
          </div>
          {!isMobile && (
            <div className="close">
              <button type="button" style={{ background: 'transparent' }} onClick={props.handleClose}>
                <IconClear />
              </button>
            </div>
          )}
        </div>
        <div className="modal-body">
          <div className="details">
            {isBookkeeperAdmin && (
              <>
                {/* TODO: upcoming version */}
                {/*<div className="customer">*/}
                {/*  <label htmlFor="from">Initiated from</label>*/}
                {/*  <div className="button-group">*/}
                {/*    <button className="active">*/}
                {/*      <IconEmail />*/}
                {/*    </button>*/}
                {/*    <button>*/}
                {/*      <IconPhone />*/}
                {/*    </button>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className={classNames('customer', { 'has-error': errors.for })}>
                  <label>For</label>
                  <select
                    {...register('for', {
                      required: 'Please select who this request is for.',
                    })}
                  >
                    <option value="Open">Customer</option>
                    <option value="In Progress">Bookkeeper</option>
                  </select>
                  {errors.for && <span className="error">{errors.for.message}</span>}
                </div>
              </>
            )}
            <div className="customer">
              <label>Created by</label>
              <span>{user?.email}</span>
            </div>
          </div>
          <div className="request">
            <h4>Request</h4>
            <div className={classNames('form-control', { 'has-error': errors.identifier })}>
              <label htmlFor="identifier">Title</label>
              <input
                {...register('identifier', {
                  required: 'Required',
                })}
              />
              {errors.identifier && <span className="error">{errors.identifier.message}</span>}
            </div>
            <div className={classNames('form-control', { 'has-error': errors.comment })}>
              <label htmlFor="comment">Description</label>
              <textarea
                {...register('comment', {
                  required: 'Please select who this request is for.',
                })}
              ></textarea>
              {errors.comment && <span className="error">{errors.comment.message}</span>}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {!isMobile && <button onClick={props.handleClose}>Cancel</button>}
          <button type="submit">Create Request</button>
        </div>
      </form>
    );
  };

  if (!props.isOpen) {
    return null;
  }

  return !isMobile ? (
    <Modal onRequestClose={props.handleClose} shouldCloseOnEsc id="create-request-modal" isOpen={props.isOpen}>
      {renderExperience()}
    </Modal>
  ) : (
    <div id="create-request-modal" className="mobile-experience">
      {renderExperience()}
    </div>
  );
}

export default CreateRequestModal;
