import React from 'react';
import './ForecastTitle.scss';
import { IconUnion } from 'components/Icons';

function ForecastTitle(): JSX.Element {
  return (
    <header className="forecast-title">
      <ol className="breadcrumb">
        <li className="breadcrumb-image">
          <IconUnion />
        </li>
        <li>
          <a>Forecast</a>
        </li>
      </ol>
      <ol className="options right">
        <li>
          <p>Beta</p>
        </li>
        <li />
      </ol>
    </header>
  );
}

export default ForecastTitle;
