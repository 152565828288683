import { formatRangeValues } from 'lib/reportUtils';
import { ExportFilters, ExportData, ExportDataRow, ExportAmountObj, ExportContentObj } from './types';
import { TransformedData, Column } from './reportNTParser/types';

export const EXCLUDED_KEYS = ['GL', 'accounts', 'percentOf', 'budgetAvailable', 'style'] as const;
export const SECONDARY_KEYS = ['actuals', 'budget', 'variance'] as const;
const EMPTY_RETURN = ' ';

export const titleCase = (title: string) =>
  title.charAt(0).toUpperCase() + title.slice(1).toLowerCase().replace(/ /g, '_');

export const spacer = (level: number | undefined, spacerString = '    ') => {
  if (level === undefined) return '';
  return level === 0 ? '' : spacerString.repeat(level);
};

type ExportParserProps = {
  transformedData: TransformedData | null;
  filters: ExportFilters | null;
  trace?: boolean;
};

type ParseAmountsProps = {
  money: number;
  percent: number;
  filters?: ExportFilters | null;
  allowMoney?: boolean;
  allowPercentage?: boolean;
};

const parseAmounts = ({ money, percent, filters, allowMoney, allowPercentage }: ParseAmountsProps): ExportAmountObj => {
  if (!allowMoney && !allowPercentage) {
    return { money: EMPTY_RETURN, percent: EMPTY_RETURN };
  }
  const response: ExportAmountObj = {};
  if (allowMoney) {
    response.money = !filters || filters.money ? money : EMPTY_RETURN;
  }
  if (allowPercentage) {
    response.percent = !filters || filters.percentage ? percent : EMPTY_RETURN;
  }
  return response;
};

const generateColumnTitle = (column: Column) => {
  const { start, end } = column.date || {};
  return column.isLocation
    ? column.name
    : column.type[0].type === 'Direct'
      ? start
      : formatRangeValues(start && end ? `${start} - ${end}` : start, 'KS');
};

export const formatValue = (amount: number, decimals: number = 0, locale: string = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);
};

export const formatAmount = (
  amount: number | null,
  decimals: number = 0,
  compact: boolean = false,
  unitless: boolean = false
) => {
  if (amount === null) return ' ';
  if (amount === 0) return compact ? '' : ' -- ';
  const units = unitless ? '' : compact ? '$' : '$ ';
  if (amount < 0) {
    return unitless ? formatValue(amount, decimals) : `(${units}${formatValue(Math.abs(amount), decimals)})`;
  }
  return `${units}${formatValue(amount, decimals)}`;
};

// This function parses the TransformedData object to create the intermediate data structure that will be used in all exports.
export const exportParser = ({
  transformedData,
  filters = null,
  trace = false,
}: ExportParserProps): ExportData | null => {
  if (trace) console.time('exportParser');
  if (!transformedData) {
    return null;
  }
  // Filter the columns to exclude compound columns (total & difference columns from the report)
  const filteredColumns: Column[] = transformedData.columns.filter((column: Column) => {
    return !column.compoundColumn;
  });

  const table: ExportData = {
    name: transformedData.locationName || transformedData.name,
    type: transformedData.type,
    timeFrame: transformedData.timeFrame,
    date: transformedData.range,

    data: [],
  };

  table.fileName = `KS-Report -- ${transformedData?.locationName} -- ${transformedData.name}`;
  const rowCount = filteredColumns[0]?.data?.length || 0;
  const capabilities = transformedData.capabilities;
  const allowMoney = capabilities?.money && (!filters || filters.money);
  const allowPercentage = capabilities?.percentage && (!filters || filters.percentage);
  const allowBudget = capabilities?.budget && (!filters || filters.budget);
  const useSmoothing = capabilities.smoothing && (!filters || filters.smoothing);

  if (trace)
    console.log('exportParser', {
      rowCount,
      allowMoney,
      allowPercentage,
      allowBudget,
      useSmoothing,
      trace,
      filters,
    });

  if (rowCount === 0) {
    console.log('(silent error) Export:: No data to export');
    return table;
  }
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    table.budgetAvailable = transformedData.budgetAvailable;
    const row: ExportDataRow = {
      style: { isTotal: false, isHeader: false, isBigHeader: false, level: 0 },
    };

    filteredColumns.forEach((column: Column, index: number) => {
      const firstColumn = index === 0;
      const cell = column.data?.[rowIndex];

      if (column && column.name && cell) {
        const title = generateColumnTitle(column);
        if (firstColumn) {
          row.GL = cell.glCode ? cell.glCode : EMPTY_RETURN;
          //Let's add row specific properties (not renderable)
          row.style.isTotal = cell.vizHelpers?.isTotal;
          row.style.isHeader = cell.vizHelpers?.isHeader;
          row.style.isBigHeader = cell.vizHelpers?.isBigHeader;
          row.style.level = cell.level;

          row.accounts = (useSmoothing ? '*' : '') + cell.amountTitle;
          if (allowPercentage) row.percentOf = cell.percentTitle ? cell.percentTitle : EMPTY_RETURN;
        } else {
          const content: ExportContentObj = {};
          if (table.budgetAvailable) {
            if (!filters || filters.actuals) {
              content.actuals = parseAmounts({
                money: !useSmoothing ? cell.amount : cell.amountAVG,
                percent: !useSmoothing ? cell.percent : cell.percentAVG,
                filters,
                allowMoney,
                allowPercentage,
              });
            }
            if (allowBudget) {
              content.budget = parseAmounts({
                money: cell.budget,
                percent: cell.budgetPercent,
                filters,
                allowMoney,
                allowPercentage,
              });

              if (allowBudget && filters && filters.variance && filters.actuals) {
                content.variance = parseAmounts({
                  money: cell.difference,
                  percent: cell.variance,
                  filters,
                  allowMoney,
                  allowPercentage,
                });
              }
            }
          } else {
            content.actuals = parseAmounts({
              money: !useSmoothing ? cell.amount : cell.amountAVG,
              percent: !useSmoothing ? cell.percent : cell.percentAVG,
              filters,
              allowMoney,
              allowPercentage,
            });
          }
          // Finally we add the content to the row. The title is the column name.
          row[title || column.name] = content;
        }
      }
    });
    table.data.push(row);
  }
  if (trace) console.timeEnd('exportParser');
  return table;
};
