import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { reportTitleToUrl } from 'lib/utils';

interface SidenavState {
  isSidenavCollapsed: boolean;
  toggleSidenav: () => void;
}

export const useSidenavStore = create(
  subscribeWithSelector<SidenavState>(set => ({
    isSidenavCollapsed: false,
    toggleSidenav: () =>
      set(state => {
        return { isSidenavCollapsed: !state.isSidenavCollapsed };
      }),
  }))
);

export function getMenuItems(allReports?: HoneReportSummary[]) {
  if (!allReports) {
    return {
      sortedTitles: [],
      subReports: new Map<string, HoneReportSummary>(),
    };
  }

  const subReports = new Map<string, HoneReportSummary>();

  const sortedReports = allReports.sort((a, b) => {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  });

  sortedReports.forEach(report => {
    if (subReports.get(report.title) === undefined) {
      subReports.set(reportTitleToUrl(report.title), report);
    }
  });

  const sortedTitles = Array.from(subReports.keys()).sort((a, b) => {
    if (subReports.get(a)?.timeframe === 'Weekly') {
      return -1;
    } else if (subReports.get(b)?.timeframe === 'Weekly') {
      return 1;
    }

    if (subReports.get(a)?.timeframe === 'Monthly' && subReports.get(a)?.type === 'P&L Comparison') {
      return -1;
    } else if (subReports.get(b)?.timeframe === 'Monthly' && subReports.get(b)?.type === 'P&L Comparison') {
      return 1;
    }

    if (subReports.get(a)?.timeframe === 'Monthly' && subReports.get(a)?.type === 'Balance Sheet') {
      return -1;
    } else if (subReports.get(b)?.timeframe === 'Monthly' && subReports.get(b)?.type === 'Balance Sheet') {
      return 1;
    }

    if (subReports.get(a)?.timeframe === 'Monthly' && subReports.get(a)?.type === 'Cash Flow') {
      return -1;
    } else if (subReports.get(b)?.timeframe === 'Monthly' && subReports.get(b)?.type === 'Cash Flow') {
      return 1;
    }

    if (subReports.get(a)?.timeframe === 'Monthly') {
      return -1;
    } else if (subReports.get(a)?.timeframe === subReports.get(b)?.timeframe) {
      return 0;
    }
    return 1;
  });

  return { subReports, sortedTitles };
}
