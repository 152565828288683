import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { doGet, doPost, reportingOrigin, setLocalStorage } from 'lib/utils';
import { initializeAnalytics } from 'lib/analytics';
import { FullStory } from '@fullstory/browser';
import { useAuthUser } from 'hooks/useAuthUser';

const isTest = process.env.NODE_ENV === 'test';

interface LoginDto {
  username: string;
  password: string;
}

interface PasswordResetDto {
  email: string;
}

interface AuthContextType {
  loading: boolean;
  user: User | null;
  session: string | null;
  userId: string | null;
  signOut: () => Promise<void>;
  login: ({ username, password }: LoginDto) => Promise<string>;
  sendPasswordResetEmail: ({ email }: PasswordResetDto) => Promise<string>;
}

const AuthContext = createContext<AuthContextType>({
  loading: false,
  user: null,
  userId: null,
  session: null,
  signOut: () => new Promise(resolve => resolve()),
  login: () => new Promise(resolve => resolve('success')),
  sendPasswordResetEmail: () => new Promise(resolve => resolve('success')),
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [session, setSession] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const login = async ({ username, password }: LoginDto) => {
    return await doPost(`${reportingOrigin()}/login`, { username, password })
      .then(result => {
        if (result && result.data.status === 'success') {
          const user = result.data.user;
          setUser(user);
          setSession(result.data.session);
          setLocalStorage('hone:signedParamsCDN', result.data.signedParamsCDN);
          setLocalStorage('hone:session', result.data.session);

          initializeAnalytics(user);

          useAuthUser.setState({ user, userId: user.uid });

          if (!isTest) {
            FullStory('setProperties', {
              type: 'user',
              properties: {
                displayName: user.displayName ? user.displayName : '',
                email: user.email ? user.email : '',
              },
            });
          }
        }
        return 'success';
      })
      .catch(() => {
        // setErrorMessage("Error sending id token.");
        return 'error';
      });
  };

  const sendPasswordResetEmail = async ({ email }: PasswordResetDto) => {
    return await doPost(`${reportingOrigin()}/users/passwordResetEmail`, { email })
      .then(result => {
        return 'success';
      })
      .catch(() => {
        // setErrorMessage("Error sending id token.");
        return 'error';
      });
  };

  const signOut = async () => {
    deleteSession();
  };

  const deleteSession = () => {
    localStorage.removeItem('hone:session');
    localStorage.removeItem('hone:signedParamsCDN');
    localStorage.removeItem('hone:locationId');
    setUser(null);
    setSession(null);
  };

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      if (!localStorage.getItem('hone:session')) return;
      setLoading(true);
      try {
        const response = await doGet(`${reportingOrigin()}/users/firebaseUser`);
        setUser(response.data);
        setSession(localStorage.getItem('hone:session'));
        initializeAnalytics(response.data);
      } catch (e) {
        deleteSession();
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{ loading, user, userId: user?.uid ?? null, session, login, signOut, sendPasswordResetEmail }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
