import { makeApiUrl } from 'main/factories/http';
import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { GetReportLinks } from 'domain/usecases/reports/get-report-links';
import { RemoteGetReportLinks } from 'data/usecases/reports/remote-get-report-links';

export const makeRemoteGetReportLink = (reportId: string, periodReport: boolean): GetReportLinks => {
  const period = periodReport ? '/period' : '';
  return new RemoteGetReportLinks(
    makeApiUrl(`/reports${period}/${reportId}/links/resolve`),
    makeAuthorizeHttpClientDecorator()
  );
};
