import { PostFlaggedTransactionComment } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemotePostFlaggedTransactionComment
  extends BaseHttpService<PostFlaggedTransactionComment.Model>
  implements PostFlaggedTransactionComment
{
  async post(body: PostFlaggedTransactionComment.Body): Promise<PostFlaggedTransactionComment.Model> {
    return await this.request('post', body);
  }
}
