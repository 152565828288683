// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import { JournalEntry } from '@hone-automation/common';
import { makeRemoteGetJournalEntries } from 'main/factories/usecases/journal-entries/remote-get-journal-entries';

export const useJournalEntriesByExternalId = (externalId: string) => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ['journalEntries', locationId, externalId],
    queryFn: async (): Promise<JournalEntry[]> => {
      const service = makeRemoteGetJournalEntries(locationId, externalId);
      return service.get({ locationId, externalId });
    },
    enabled: !!locationId || !!externalId,
  });
};
