import { DeleteMetricReport } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemoteDeleteMetricReport extends BaseHttpService<DeleteMetricReport.Model> implements DeleteMetricReport {
  async deleteMetric(params: DeleteMetricReport.Params): Promise<DeleteMetricReport.Model> {
    return await this.request('post', {
      params,
    });
  }
}
