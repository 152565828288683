import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeRemoteAskRequestsReCategorization } from '../../main/factories/usecases/ask-requests/remote-post-ask-requests-recategorization';
import { FIVE_SECONDS, showToast, TOAST_ERROR, TOAST_SUCCESS } from '../../lib/utils';
import { onFlaggedTransactionUpdate } from './handlers';
import { useAskRequestsCountQuery } from '../useAskRequestsCountQuery';
import { useFlaggedTransactions } from './useFlaggedTransactions';
import { useAskRequestsQuery } from '../useAskRequestsQuery';
import { AskRequestStatus } from '@hone-automation/common';
import { UIFlaggedTransaction } from '../../types/askcustomer';
import { useQueryState } from '../useQueryState';

export const useAskRequestReCategorizationMutation = (
  locationId: string,
  status: AskRequestStatus,
  onSuccessCallback: (trx: UIFlaggedTransaction | undefined) => void
) => {
  const { refetch } = useAskRequestsQuery(status);
  const { refetch: refetchCounts } = useAskRequestsCountQuery();
  const [activeTab] = useQueryState<AskRequestStatus>(status);

  const { setAnimationTransaction } = useFlaggedTransactions(state => ({
    setAnimationTransaction: state.setAnimatedTransaction,
  }));
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      const service = makeRemoteAskRequestsReCategorization(data.trxId);
      return service.post({ locationId, newAccountId: data.newAccountId, comment: data.comment });
    },
    onSuccess: (data, variables) => {
      refetchCounts();
      setAnimationTransaction(undefined);
      if (activeTab !== 'Closed') {
        useFlaggedTransactions.setState({
          activeFlaggedTransaction: undefined,
        });
        refetch();
      }
      onFlaggedTransactionUpdate(data, variables, locationId, queryClient, onSuccessCallback);
      showToast('Request Re-categorized', TOAST_SUCCESS, FIVE_SECONDS);
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, FIVE_SECONDS);
    },
  });
};
