import { makeApiUrl } from 'main/factories/http';
import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { GetAccrualsConfig, PostGenerateAccruals } from 'domain/usecases';
import { RemoteGetAccrualsConfig } from 'data/usecases';

export const makeGetAccrualsConfig = (currentLocationId: string): GetAccrualsConfig => {
  return new RemoteGetAccrualsConfig(
    makeApiUrl(`/journal-entries/accruals/config?locationId=${currentLocationId}`),
    makeAuthorizeHttpClientDecorator()
  );
};
