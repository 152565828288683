import React, { ChangeEvent } from 'react';

import classNames from 'classnames';
import { CategoriesSelector } from 'presentation/components';
import { Category } from 'domain/models';
import { Action } from 'hooks/useActiveActions';
import { find } from 'lodash';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import { useHighlightReportSelector } from 'hooks/useHighlightReportSelector';
import { WhiteGraphs, WhiteLines } from 'components/Icons';

type Period = { period: number; reportId: string };

type GraphsControls = {
  currentReportId?: string;
  selectedReport: HoneReportSummary;
  activeColor?: string | null;
  periods: Period[];
  onExtraReportChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onReportChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  plot1?: string;
  plot2?: string;
  daysTillEndOfReport: number;
};

function transformOptions(periods: Period[], value?: string | null) {
  return periods
    .filter(period => !(value && value === period.reportId))
    .map(period => ({ label: period.period, id: period.reportId }));
}

function GraphControls({
  selectedReport,
  activeColor = 'var(--ks-chart-primary)',
  periods,
  onExtraReportChange,
  onReportChange,
  plot1,
  plot2,
  daysTillEndOfReport,
}: GraphsControls): JSX.Element {
  const { setIsHovered } = useHighlightReportSelector();

  const options1 = periods.map(period => ({ label: period.period, id: period.reportId }));
  const options2 = transformOptions(periods, plot1);

  return (
    <div className="graph-controls">
      {selectedReport.timeframe.includes('YTD') && options2.length > 0 && (
        <div>
          <span className="title">Compare</span>
          <Whisper placement="topStart" speaker={<Tooltip>To change the active period use the selector above</Tooltip>}>
            <div
              className="select-wrapper"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span style={{ backgroundColor: activeColor ?? '' }} />
              <select value={plot1} disabled onChange={onReportChange}>
                {options1.map(option => (
                  <option key={1 + option.label + option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </Whisper>
          <div className="select-wrapper">
            <span />
            <select value={plot2} onChange={onExtraReportChange}>
              <option value="">-</option>
              {options2.map(option => (
                <option key={2 + option.label + option.id} value={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

export default GraphControls;
