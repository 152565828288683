import { makeApiUrl } from 'main/factories/http';

import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { GetPostReport } from 'domain/usecases';
import { RemotePostReport } from 'data/usecases';

export const makeRemotePostReport = (): GetPostReport => {
  const reportsUrl = `/report`;

  return new RemotePostReport(makeApiUrl(reportsUrl), makeAuthorizeHttpClientDecorator());
};
