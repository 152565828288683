import React from 'react';
import classNames from 'classnames';
import { auditTextResult, prefixClass } from 'lib/utils';
import useAuditReportDismiss from 'hooks/useAuditReportDismiss';
import { IconClear, IconCircleCheck, IconTriangleWarning } from 'components/Icons';

type AuditResultProps = {
  audit: AuditReport;
  onDismiss: () => void;
};

const ICONS = {
  Pass: IconCircleCheck,
  Fail: IconClear,
  Error: IconClear,
  Warning: IconTriangleWarning,
};

function AuditResult({ audit, onDismiss }: AuditResultProps): JSX.Element | null {
  const { onDismissRequest } = useAuditReportDismiss(onDismiss);
  if (!audit) return null;

  const prefix = prefixClass('audit-report');

  const handleDismiss = () => {
    onDismissRequest([audit]);
  };

  const Icon = ICONS[audit.type];

  return (
    <div
      className={classNames(prefix(), {
        [prefix('success')]: audit.type === 'Pass',
        [prefix('danger')]: audit.type === 'Fail' || audit.type === 'Error',
        [prefix('warning')]: audit.type === 'Warning',
      })}
    >
      <div className={prefix('container')}>
        <div className={prefix('icon')}>
          <Icon />
        </div>
        <div className={prefix('content')}>
          <div className={prefix('info')}>
            <div>Audit Result: {audit.type}</div>
            <div>{audit.reportName.slice(0, 10) + ' - ' + audit.reportName.slice(11)}</div>
          </div>
          <div className={prefix('description')}>{auditTextResult(audit)}</div>
          {audit.type !== 'Pass' && (
            <button onClick={handleDismiss} className={prefix('dismiss-btn')}>
              Dismiss
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuditResult;
