import './ForecastActions.scss';
import classNames from 'classnames';
import { useForecastUIStore } from 'hooks/useForecastUIStore';
import { useForecastReport } from 'hooks/useForecastReport';
import { useAuthContext } from '../../../../context/useAuthContext';

function ForecastActions(): JSX.Element {
  const { userId } = useAuthContext();
  const fetchForecastReport = useForecastReport(state => state.fetchForecastReport);
  const { timeframe, activeType } = useForecastUIStore(state => ({
    timeframe: state.timeframe,
    activeType: state.activeType,
  }));

  const onActiveTypeChange = (activeType: ForecastActiveType) => {
    const type = timeframe === 'weeks' ? 'Weekly Forecast' : 'Monthly Forecast';
    useForecastUIStore.setState({ activeType });
    if (userId) {
      fetchForecastReport(userId, type);
    }
  };

  return (
    <>
      <div className="forecast-actions">
        <div>
          <button
            className={classNames({ active: activeType === 'Revenue' })}
            onClick={() => onActiveTypeChange('Revenue')}
          >
            Sales
          </button>
          <button
            className={classNames({ active: activeType === 'Expense' })}
            onClick={() => onActiveTypeChange('Expense')}
          >
            Cost & Expenses
          </button>
        </div>
      </div>
    </>
  );
}

export default ForecastActions;
