import { ReportConfiguration } from '@hone-automation/common';
import { UpdateReportConfig } from '../../domain/usecases/update-report-config';

import { BaseHttpService } from 'main/factories/http';

export class RemoteUpdateReportConfig extends BaseHttpService<UpdateReportConfig> implements UpdateReportConfig {
  async update(body: UpdateReportConfig.Params): Promise<UpdateReportConfig.Model> {
    return await this.request('post', body);
  }
}
