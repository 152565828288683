import { subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import { makeRemoteGetForecastReport, makeRemoteUpdateForecast } from 'main/factories/usecases';
import { useLocationsStore } from './useLocationsStore';
import { useAuthUser } from './useAuthUser';
import { useForecastUIStore } from './useForecastUIStore';
import {
  calculateAmountsFromReport,
  generateUIReport,
  getActiveDate,
  getDisplaySections,
  resetForecast,
  roundReport,
} from '../main/factories/pages/forecast-report/utils';
import { cloneDeep, update } from 'lodash';
import { ForecastPeriodData } from './useForecasts';
import { FIVE_SECONDS, showToast, TOAST_ERROR } from 'lib/utils';
import { useAuthContext } from '../context/useAuthContext';

interface ForecastState {
  remoteForecastReport: RemoteForecastReport | null;
  fetchForecastReport: (userId: string, type?: ForecastType) => void;
  activeRemoteForecastPeriod: ForecastPeriodData | null;
  activeRemoteForecast?: Forecast;
  originalRemoteReport: RemoteForecastReport | null;
  categories: string[];
  loadingForecast: boolean;
}

const getForecastReport = makeRemoteGetForecastReport();
const updateForecast = makeRemoteUpdateForecast();

export const useForecastReport = create(
  subscribeWithSelector<ForecastState>(set => ({
    remoteForecastReport: null,
    originalRemoteReport: null,
    categories: [],
    activeRemoteForecastPeriod: null,
    loadingForecast: false,
    fetchForecastReport: async (userId: string, type: ForecastType = 'Weekly Forecast') => {
      const currentLocationId = useLocationsStore.getState().currentLocationId;

      if (!currentLocationId || !userId) {
        return;
      }

      set({ loadingForecast: true });
      try {
        const remoteReport = await getForecastReport.get({
          locationId: currentLocationId,
          includeFullForecast: true,
          onlyHistorical: false,
          limit: 8,
          type: type,
          years: ['YearAgo'],
          includeActuals: true,
          userId: userId,
        });
        set({ remoteForecastReport: remoteReport, loadingForecast: false });
      } catch (error) {
        showToast('Error trying to get forecast report', TOAST_ERROR, FIVE_SECONDS);
      }
    },
  }))
);

function loadRemoteReport(remoteReport: RemoteForecastReport | null) {
  if (!remoteReport) return;
  const { timeframe, activePeriod, activeType } = useForecastUIStore.getState();
  const activeDate = getActiveDate(remoteReport, timeframe);

  if (activeDate) {
    const remoteReportCopy = cloneDeep(remoteReport);

    const activeRemoteForecast = (
      remoteReportCopy[timeframe][activeDate] as WithRequiredProperty<ForecastBase, 'forecast'>
    ).forecast;
    if (!activeRemoteForecast) {
      throw new Error('No active remote forecast found');
    }
    update(remoteReportCopy, `${timeframe}[${activeDate}].forecast`, () =>
      calculateAmountsFromReport(activeRemoteForecast, activeDate, timeframe)
    );

    const generated = generateUIReport(remoteReportCopy, timeframe, activePeriod, activeDate);
    const activeRemoteForecastPeriod = activeRemoteForecast.forecastData[timeframe][activePeriod];

    const categories = [
      activeRemoteForecastPeriod.sections[0].title,
      ...getDisplaySections(activeRemoteForecastPeriod.sections)
        .filter(section => section.type === activeType)
        .map(section => section.title),
    ];

    useForecastUIStore.setState({
      pristine: true,
      activeDate: activeDate,
      report: generated.report,
      roundedReport: roundReport(generated.report),
      activeCategory: categories?.length ? categories[0] : null,
    });

    useForecastReport.setState({
      originalRemoteReport: remoteReport,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      activeRemoteForecastPeriod,
      activeRemoteForecast,
      categories,
    });
  }
}

useForecastReport.subscribe(state => state.remoteForecastReport, loadRemoteReport);

// TODO type
export async function saveRemoteForecast(): Promise<any> {
  const { activeRemoteForecastPeriod, remoteForecastReport: remoteReport } = useForecastReport.getState();
  const { report, activeDate, timeframe, activePeriod } = useForecastUIStore.getState();
  const locationId = useLocationsStore.getState().currentLocationId;
  const userId = useAuthUser.getState().userId;

  if (!report) return;

  const updatedForecastPeriodSections = activeRemoteForecastPeriod?.sections?.map(section => {
    if (section.type === 'Revenue') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      section.goalPerc = parseFloat((report['net-sales'].goalPerc / 100).toString());
      delete section.goalAmount;
    }

    const type = section.type === 'Expense' ? 'expenses' : section.type.toLowerCase();

    section.sections = section.sections?.map((innerSection, index) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      innerSection.goalPerc = parseFloat(String(report[type][index]?.goalPerc / 100));
      delete innerSection.goalAmount;
      return innerSection;
    });

    return section;
  });
  if (remoteReport && activeDate && timeframe) {
    const fullReport = cloneDeep(remoteReport);
    const requestReport = cloneDeep(remoteReport[timeframe][activeDate].forecast);

    if (requestReport) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestReport.forecastData[timeframe][activePeriod].sections = updatedForecastPeriodSections;

      try {
        await updateForecast.update({
          locationId,
          userId,
          id: requestReport.id,
          goalsSet: true,
          data: {
            ...requestReport.forecastData,
          },
        });

        fullReport[timeframe][activeDate].forecast = requestReport;
        useForecastReport.setState({ remoteForecastReport: fullReport });
      } catch (e) {
        showToast('An error has occurred while trying to save', TOAST_ERROR, FIVE_SECONDS);
      }
    }
  }
}

export function resetForecastReport(): void {
  const originalRemoteReport = useForecastReport.getState().originalRemoteReport;
  if (!originalRemoteReport) return;

  const { timeframe, activePeriod, activeDate } = useForecastUIStore.getState();
  if (!activeDate || activePeriod === null) return;

  const newReport = resetForecast(originalRemoteReport, activeDate, timeframe, activePeriod);
  const generated = generateUIReport(newReport, timeframe, activePeriod, activeDate);

  useForecastReport.setState({ remoteForecastReport: newReport });

  useForecastUIStore.setState({
    ...generated,
    pristine: false,
    roundedReport: roundReport(generated.report),
  });
}
