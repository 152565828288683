import { prefixClass } from 'lib/utils';
import './SFActions.scss';
import {
  FilterActuals,
  FilterBudget,
  FilterDifference,
  FilterPercentage,
  IconChart,
  IconSmooth,
  FilterActual,
  FilterAnomaly,
  FilterTransaction,
  FilterConstrain,
  FilterUnconstrain,
  FilterTotalColumn,
  FilterTotalRow,
  FilterReverseColumns,
  FilterDifferenceColumn,
} from 'components/Icons';
import IconButton from './IconButton/IconButton';
import { useState } from 'react';

import { useQueryState } from 'hooks/useQueryState';
import { ReportType } from '../NTSearchAndFilter';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { useNTContext } from '../../../NewTable/NTContext';
import { useNTUtilsContext } from '../../../NewTable/NTUtilsContext';

type SFActionsProps = {
  reportType: ReportType;
  selectedReportType: HoneReportType;
};

const SFActions = ({ reportType, selectedReportType }: SFActionsProps) => {
  const prefix = prefixClass('sf-actions');

  const { filters, setFilters, columns } = useNTContext();
  const { tooltip, setTooltip, actions, setActions, capabilities } = useNTUtilsContext();

  // Getting URL Params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [chartCollapsed, setChartCollapsed] = useQueryState('chartCollapsed', 'true');
  const currentLocation = useLocationsStore(state => state.currentLocation);
  const budgetInclude = urlParams.get('budgetInclude') === 'true';

  // Component Inner States
  const [graphState, setGraphState] = useState(!chartCollapsed);

  // Chart Collapsing
  const toggleChartCollapse = () => {
    setChartCollapsed(chartCollapsed === 'true' ? 'false' : 'true');
    setGraphState(g => !g);
  };

  const toggleFilter = (filterName: string, filtersToUpdate: string = 'filters', value: boolean | null = null) => {
    switch (filtersToUpdate) {
      case 'filters':
        //console.log('filters', filterName, filters);
        setFilters({
          ...filters,
          [filterName]: value !== null ? value : !filters[filterName as keyof typeof filters],
        });
        break;
      case 'actions':
        //console.log('actions', filterName, actions, !actions[filterName as keyof typeof actions]);
        setActions({
          ...actions,
          [filterName]: value !== null ? value : !actions[filterName as keyof typeof actions],
        });
        break;
    }
  };

  const handleTableFiltering = (filter: string, filtersToUpdate: string) => {
    switch (filter) {
      case 'actuals':
        // Actual and Budgets can be both active simultaneousl but  we cannot deactivate both at the same time. One must be always active
        if (!filters.budget && filters.actuals) {
          setFilters({
            ...filters,
            budget: true,
            actuals: false,
          });
        } else toggleFilter(filter, filtersToUpdate);
        break;
      case 'budget':
        if (filters.budget && !filters.actuals) {
          setFilters({
            ...filters,
            budget: false,
            actuals: true,
          });
        } else toggleFilter(filter, filtersToUpdate);
        break;

      case 'money':
        if (!filters.percentage && filters.money) {
          setFilters({
            ...filters,
            percentage: true,
            money: false,
          });
        } else toggleFilter(filter, filtersToUpdate);
        break;
      case 'percentage':
        if (filters.percentage && !filters.money) {
          //
          setFilters({
            ...filters,
            percentage: false,
            money: true,
          });
        } else toggleFilter(filter, filtersToUpdate);
        break;

      case 'difference':
        // When difference is visible , if we click on difference and it is already selected, we hide it
        if (actions.difference) {
          /* setFilters({
            ...filters,
            difference: false,
          }); */
          toggleFilter(filter, filtersToUpdate, false);
        } else {
          // when it is disabled if we click on it, we show it and it becomes a switch with total, only one can be active at a time
          setActions({
            ...actions,
            total: actions.difference,
            difference: !actions.difference,
          });
        }
        break;
      case 'total':
        if (columns === 3) {
          // When difference is visible , reverse behavior of difference
          if (actions.total) {
            /*             setFilters({
              ...filters,
              total: false,
            }); */
            toggleFilter(filter, filtersToUpdate, false);
          } else {
            setActions({
              ...actions,
              total: !actions.total,
              difference: actions.total,
            });
          }
        } else toggleFilter(filter, filtersToUpdate);
        break;

      default:
        toggleFilter(filter, filtersToUpdate);
        break;
    }
  };

  // Pay
  const showPayBillsButton =
    reportType === 'Payable' &&
    currentLocation?.config &&
    currentLocation.config.links &&
    currentLocation.config.links.paymentLink;

  const handlePaymentLinkClick = () => {
    window.open(
      currentLocation?.config && currentLocation.config.links && currentLocation.config.links.paymentLink,
      '_blank'
    );
  };

  const setUpTooltipText = (filter: string, trueWord: string, falseWord: string, comment: string) => {
    if (filters[filter as keyof typeof filters]) {
      return trueWord + ' ' + comment;
    } else {
      return falseWord + ' ' + comment;
    }
  };

  interface BuildIconProps {
    capability?: boolean;
    type?: 'filters' | 'actions';
    verbs: {
      true: string;
      false: string;
      comment: string;
      hide?: boolean;
    };
    filter: string;
    status: boolean;
    icon?: JSX.Element;
    iconTrue?: JSX.Element;
    label?: string;
    boundary?: boolean;
    iconSize?: number;
    onClick?: () => void;
  }

  const buildIcon = ({
    capability = true,
    type = 'filters',
    verbs,
    filter,
    status,
    icon,
    iconTrue,
    label,
    boundary,
    iconSize,
    onClick,
  }: BuildIconProps): JSX.Element => {
    const convertToIngForm = (verb: string): string => {
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      if (vowels.includes(verb[verb.length - 1])) {
        return verb.slice(0, -1) + 'ing';
      }
      return verb + 'ing';
    };
    if (!capability) {
      return <></>;
    }
    return (
      <IconButton
        tooltipLabel={setUpTooltipText(filter, verbs.true, verbs.false, verbs.comment)}
        status={status}
        iconSize={iconSize}
        icon={icon}
        iconTrue={iconTrue}
        boundary={boundary}
        label={label}
        onClick={() =>
          onClick
            ? onClick()
            : setTooltip({
                ...tooltip,
                updating: true,
                tooltip: !verbs.hide
                  ? convertToIngForm(status ? verbs.true : verbs.false) + ' ' + verbs.comment + ' ...'
                  : null,
              }).then(() => {
                setTimeout(() => {
                  handleTableFiltering(filter, type);
                }, 30);
              })
        }
      />
    );
  };

  return (
    <div className={prefix()}>
      {/* Actuals, Budget and Variance are only visible if budget is included */}
      {budgetInclude && (
        <>
          {buildIcon({
            verbs: { true: 'Hide', false: 'Show', comment: 'Actual Values' },
            filter: 'actuals',
            status: filters.actuals,
            icon: <FilterActual />,
          })}
          {buildIcon({
            capability: capabilities.budget,
            verbs: { true: 'Hide', false: 'Show', comment: 'Budget Values' },
            filter: 'budget',
            status: filters.budget,
            icon: <FilterBudget />,
          })}
          {/* Variance is only visible if budget is active */}
          {filters.budget &&
            filters.actuals &&
            buildIcon({
              verbs: { true: 'Hide', false: 'Show', comment: 'Budget Variance' },
              filter: 'variance',
              status: filters.variance,
              icon: <FilterDifference />,
            })}
        </>
      )}
      {buildIcon({
        capability: capabilities.transactions,
        verbs: { true: 'Hide', false: 'Show', comment: 'Transactions' },
        filter: 'transactions',
        status: filters.transactions,
        icon: <FilterTransaction />,
        boundary: budgetInclude,
      })}
      {buildIcon({
        capability: capabilities.money,
        verbs: { true: 'Hide', false: 'Show', comment: 'Currency' },
        filter: 'money',
        status: filters.money,
        icon: <FilterActuals />,
      })}
      {buildIcon({
        capability: capabilities.percentage,
        verbs: { true: 'Hide', false: 'Show', comment: 'Percentage' },
        filter: 'percentage',
        status: filters.percentage,
        icon: <FilterPercentage />,
      })}

      {/* Anomalies, Smooting, Totals and Reverse can only be visible if we have at least 2 columns (plus the first one) */}
      {columns > 2 && (
        <>
          {buildIcon({
            capability: capabilities.anomalies,
            verbs: { true: 'Disable', false: 'Enable', comment: 'Anomalies detection' },
            filter: 'anomalies',
            status: filters.anomalies,
            icon: <FilterAnomaly />,
            boundary: true,
            iconSize: 26,
          })}
          {buildIcon({
            capability: capabilities.smoothing,
            verbs: { true: 'Remove', false: 'Activate', comment: 'Smoothing' },
            filter: 'smoothing',
            status: filters.smoothing,
            icon: <IconSmooth />,
            iconSize: 26,
          })}
          {/* Difference is only visible if we have exactly 2 data columns (plus the first one) */}
          {buildIcon({
            verbs: { true: 'Restore', false: 'Reverse', comment: 'Columns Order' },
            type: 'actions',
            filter: 'reverseColumns',
            status: actions.reverseColumns,
            icon: <FilterReverseColumns />,
            boundary: true,
          })}
          {columns == 3 &&
            buildIcon({
              verbs: { true: 'Hide', false: 'Show', comment: 'Difference Column' },
              type: 'actions',
              filter: 'difference',
              status: actions.difference,
              icon: <FilterDifferenceColumn />,
            })}
          {buildIcon({
            verbs: { true: 'Hide', false: 'Show', comment: 'Total Column' },
            type: 'actions',
            filter: 'total',
            status: actions.total,
            icon: <FilterTotalColumn />,
          })}
        </>
      )}
      {buildIcon({
        verbs: { true: 'Hide', false: 'Show', comment: 'Total Row' },
        filter: 'totalRow',
        type: 'actions',
        status: actions.totalRow,
        icon: <FilterTotalRow />,
        boundary: columns < 3,
      })}
      {buildIcon({
        capability: capabilities.charts,
        verbs: { true: 'Hide', false: 'Show', comment: 'graph' },
        filter: 'graph',
        status: graphState,
        icon: <IconChart />,
        boundary: true,
        onClick: toggleChartCollapse,
      })}
      {reportType === 'Payable' &&
        showPayBillsButton &&
        buildIcon({
          verbs: { true: '', false: '', comment: 'Pay Bills', hide: true },
          filter: 'payBills',
          status: false,
          label: 'Pay Bills',
          boundary: true,
          onClick: handlePaymentLinkClick,
        })}
      {buildIcon({
        verbs: { true: 'Constrain', false: 'Expand', comment: 'the Table', hide: true },
        type: 'actions',
        filter: 'constrained',
        status: actions.constrained,
        icon: <FilterUnconstrain />,
        iconTrue: <FilterConstrain />,
        boundary: true,
      })}
    </div>
  );
};
export default SFActions;
