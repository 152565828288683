import classNames from 'classnames';
import { IconX } from 'components/Icons';
import { currencyFormatter2Decimals } from 'lib/utils';
import { isArray } from 'lodash';

type TableDatCellProps<T> = {
  dataCellData: T;
  contentStyles: React.CSSProperties;
};

export default function TableDataCell<T>({ dataCellData, contentStyles }: TableDatCellProps<T>): JSX.Element {
  return (
    <div
      className="hone-table-body-row-cell"
      style={{ ...contentStyles, backgroundColor: (dataCellData as any).backgroundColor }}
    >
      {isArray((dataCellData as any).values) ? (
        (dataCellData as any).values.map((item: any, idx: number) => {
          return (
            <div
              className={classNames('hone-table-body-row-cell-item', {
                'hone-table-body-row-cell-item-secondary': idx % 2 !== 0,
              })}
              key={`${item}-${idx}`}
            >
              {item ? currencyFormatter2Decimals(item) : 'x'}
            </div>
          );
        })
      ) : (
        <IconX />
      )}
    </div>
  );
}
