import { ForecastActual, ForecastTimeframe } from 'domain/models';
import find from 'lodash/find';
import { getDisplaySections } from 'main/factories/pages/forecast-report/utils';
import { formatDate } from '../../../../utils';
import { currencyFormatter, FIVE_SECONDS, showToast, TOAST_ERROR } from '../../../../../lib/utils';

export const CategoryColorMapping: string[] = [
  'rgba(62, 99, 120, 0.3)',
  'rgba(137, 219, 246, 0.2)',
  'rgba(117,197,207,0.3)',
  'rgba(117, 126, 207, 0.1)',
  'rgba(242, 153, 74, 0.1)',
  'rgba(45, 156, 219, 0.1)',
  'rgba(168, 27, 78, 0.1)',
];

export const ActiveCategoryColorMapping: string[] = [
  'rgba(62, 99, 120, 1)',
  '#89DBF6',
  '#75C5CF',
  '#757ECF',
  '#F2994A',
  '#2D9CDB',
  '#A81B4E',
];

export function getActualsFromReport(report: any, timeframe: 'weeks' | 'months'): ForecastActual[] {
  if (!report[timeframe]) return [];
  return Object.values(report[timeframe]).map((value: any) => {
    if (Object.prototype.hasOwnProperty.call(value, 'actuals')) {
      return value.actuals;
    }
    return null;
  });
}

export function getAllForecasts(report: any, timeframe: ForecastTimeframe): any {
  return Object.values(report[timeframe])
    .filter(x => {
      return Object.prototype.hasOwnProperty.call(x, 'forecast');
    })
    .map((y: any) => {
      return y.forecast.forecastData[timeframe][0];
    });
}

export function getActiveDateForecastData(report: any, timeframe: ForecastTimeframe, activeDate: string): any {
  if (!report[timeframe]) return [];
  return report[timeframe][activeDate]?.forecast?.forecastData[timeframe];
}

export function getHistoricalDataFromReport(report: any, period: number | string, timeframe: 'weeks' | 'months'): any {
  const needle = 'years';
  if (!report[timeframe]) return [];
  return Object.values(report[timeframe]).map((value: any) => {
    if (typeof value[needle] !== 'undefined' && Object.prototype.hasOwnProperty.call(value[needle], period)) {
      return value[needle][period];
    }
    return null;
  });
}

export function transformHistoricalDataIntoGraphData(actuals: ForecastActual[], activeCategory: string): any {
  return actuals
    .map(actual => {
      if (!actual) return [];
      const categoryData = find(actual.dataObj, { title: activeCategory });
      if (categoryData) {
        return {
          value: categoryData.amount,
          tooltip: {
            formatter: `Previous Year <br /> ${actual.startDateText}: <b>${currencyFormatter(categoryData.amount)}</b>`,
          },
        };
      }
      return 0;
    })
    .reverse();
}

export function transformActualsIntoGraphData(actuals: ForecastActual[], activeCategory: string): any {
  return actuals
    .map(actual => {
      if (!actual) return [];
      const categoryData = find(actual.dataObj, { title: activeCategory });
      if (categoryData) {
        return categoryData.amount;
      }
      return 0;
    })
    .reverse();
}

export function transformForecastIntoGraphData(forecastSections: any, activeCategory: string): any {
  return forecastSections?.slice(0, 6).map((forecast: any) => {
    if (!forecast) return [];
    const flattenSections = [...forecast.sections, ...getDisplaySections(forecast.sections)];

    if (flattenSections) {
      const section = find(flattenSections, { title: activeCategory });
      if (section?.tag === 'Net Sales') {
        if (section?.goalPerc === 0) {
          return section?.amount;
        }

        return section?.amount * (1 + section?.goalPerc);
      }

      if (typeof section?.goalAmount === 'undefined') {
        const reference = find(flattenSections, { tag: section?.goalPercTag });
        return reference?.amount * section?.goalPerc;
      }

      return section?.goalAmount;
    }
  });
}

export function getOriginalAmountsFromSections(forecastSections: any, activeCategory: string): any {
  return forecastSections.map((forecast: any) => {
    if (!forecast) return [];
    const flattenSections = [...forecast.sections, ...getDisplaySections(forecast.sections)];
    const { amount: categoryAmount } = find(flattenSections, { title: activeCategory });

    return categoryAmount;
  });
}

export function mapTagValuesToReference(section: any): any {
  const key = (section.tag ? section.tag : section.goalPercTag).replace(' ', '-').toLowerCase();
  if (typeof section.sections === 'undefined') {
    return {
      [key]: section.amount,
    };
  } else {
    return {
      [key]: section.amount,
      ...mapTagValuesToReference(section.sections[0]),
      ...mapTagValuesToReference(section.sections[1]),
    };
  }
}
