import { subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import { FIVE_SECONDS, showToast, TOAST_ERROR, TOAST_SUCCESS } from 'lib/utils';
import { makeRemoteGetMetrics } from 'main/factories/usecases/remote-get-metrics';
import {
  makeRemoteCreateMetricsReport,
  makeRemoteCreateMetrics,
  makeRemoteDeleteMetrics,
  makeRemoteDeleteMetricsReport,
} from 'main/factories/usecases';
import { makeRemoteGetMetricsReport } from 'main/factories/usecases/remote-get-metrics-report';
import { Metrics, MetricsReport, MetricDeleteResponse } from 'domain/models';

interface BookkeeperState {
  bookkeeperMetrics: Metrics[] | undefined;
  bookkeeperMetricsReport: MetricsReport[] | undefined;
  fetchBookkeeperMetrics: (locationId: string) => void;
  loadingBookkeeper: boolean;
  showNewMetricModal: boolean;
  showConfirmModal: boolean;
  setShowConfirmModal: (show: boolean) => void;
  setShowNewMetric: (show: boolean) => void;
  metric: Metrics | undefined;
  metricReport: MetricsReport | undefined;
  handleMetric: (metric: Metrics | undefined) => void;
  handleMetricReport: (metricReport: MetricsReport | undefined) => void;
  createMetric: (metric: Metrics, locationId: string) => void;
  createMetricReport: (metricReport: MetricsReport, locationId: string) => void;
  deleteMetric: (id: string, locationId: string) => void;
  deleteMetricReport: (id: string, locationId: string) => void;
  resetMetrics: () => void;
  fetchBookkeeperMetricsReport: (locationId: string) => void;
}

const getBookkeeperMetrics = makeRemoteGetMetrics();
const getRemoteGetMetricsReport = makeRemoteGetMetricsReport();
const postCreateMetric = makeRemoteCreateMetrics();
const postCreateMetricReport = makeRemoteCreateMetricsReport();
const postDeleteMetric = makeRemoteDeleteMetrics();
const postDeleteMetricReport = makeRemoteDeleteMetricsReport();

export const useBookkeeperReport = create(
  subscribeWithSelector<BookkeeperState>(set => ({
    bookkeeperMetrics: undefined,
    bookkeeperMetricsReport: undefined,
    loadingBookkeeper: false,
    showNewMetricModal: false,
    showConfirmModal: false,
    metric: undefined,
    metricReport: undefined,
    setShowConfirmModal: (show: boolean) => set({ showConfirmModal: show }),
    setShowNewMetric: (show: boolean) => set({ showNewMetricModal: show }),
    handleMetric: (metric: Metrics | undefined) => set({ metric }),
    handleMetricReport: (metricReport: MetricsReport | undefined) => set({ metricReport }),
    resetMetrics: () => set({ bookkeeperMetrics: undefined }),
    fetchBookkeeperMetrics: async (locationId: string) => {
      set({ loadingBookkeeper: true });
      try {
        const bookkeeperMetricsData: Metrics[] = (await getBookkeeperMetrics.getMetricsById({
          locationId,
        })) as Metrics[];

        set({ loadingBookkeeper: false, bookkeeperMetrics: bookkeeperMetricsData });
      } catch (error) {
        showToast('Error trying to get Bookkeeper report', TOAST_ERROR, FIVE_SECONDS);
      }
    },
    fetchBookkeeperMetricsReport: async (locationId: string) => {
      set({ loadingBookkeeper: true });
      try {
        const bookkeeperMetricsReportData = (await getRemoteGetMetricsReport.getMetricsReportById({
          locationId,
        })) as MetricsReport[];

        set({ loadingBookkeeper: false, bookkeeperMetricsReport: bookkeeperMetricsReportData });
      } catch (error) {
        showToast('Error trying to get Bookkeeper report', TOAST_ERROR, FIVE_SECONDS);
      }
    },
    createMetric: async (metric: Metrics, locationId: string) => {
      try {
        const metricResponse = (await postCreateMetric.createMetric(metric)) as Metrics;
        set({ loadingBookkeeper: true, showNewMetricModal: false });
        const bookkeeperMetricsData = (await getBookkeeperMetrics.getMetricsById({ locationId })) as Metrics[];
        set({ loadingBookkeeper: false, metric: metricResponse, bookkeeperMetrics: bookkeeperMetricsData });
        showToast(`Metric was ${metric.id ? 'updated' : 'created'}  successfully`, TOAST_SUCCESS, FIVE_SECONDS);
      } catch (error) {
        showToast('Error trying to create metric', TOAST_ERROR, FIVE_SECONDS);
      }
    },
    createMetricReport: async (metricReport: MetricsReport, locationId: string) => {
      try {
        const metricReportResponse = (await postCreateMetricReport.createMetricReport(metricReport)) as MetricsReport;
        set({ loadingBookkeeper: true, showNewMetricModal: false });
        const bookkeeperMetricsReportData = (await getRemoteGetMetricsReport.getMetricsReportById({
          locationId,
        })) as MetricsReport[];
        set({
          loadingBookkeeper: false,
          metricReport: metricReportResponse,
          bookkeeperMetricsReport: bookkeeperMetricsReportData,
        });
        showToast(
          `Metric report was ${metricReport.id ? 'updated' : 'created'} successfully`,
          TOAST_SUCCESS,
          FIVE_SECONDS
        );
      } catch (error) {
        showToast('Error trying to create metric', TOAST_ERROR, FIVE_SECONDS);
      }
    },
    deleteMetric: async (id: string, locationId: string) => {
      try {
        const deleteMetricResponse = (await postDeleteMetric.deleteMetric({ id })) as MetricDeleteResponse;
        set({ loadingBookkeeper: true, showConfirmModal: false });

        const bookkeeperMetrics = (await getBookkeeperMetrics.getMetricsById({ locationId })) as Metrics[];
        set({
          loadingBookkeeper: false,
          bookkeeperMetrics,
        });
        deleteMetricResponse?.message && showToast(deleteMetricResponse?.message, TOAST_SUCCESS, FIVE_SECONDS);
      } catch (error) {
        showToast('Error trying to delete metric', TOAST_ERROR, FIVE_SECONDS);
      }
    },
    deleteMetricReport: async (id: string, locationId: string) => {
      try {
        const deleteMetricResponse = await postDeleteMetricReport.deleteMetric({ id });
        set({ loadingBookkeeper: true, showConfirmModal: false });

        const bookkeeperMetricsReportData = (await getRemoteGetMetricsReport.getMetricsReportById({
          locationId,
        })) as MetricsReport[];
        set({
          loadingBookkeeper: false,
          bookkeeperMetricsReport: bookkeeperMetricsReportData,
        });
        deleteMetricResponse?.message && showToast(deleteMetricResponse?.message, TOAST_SUCCESS, FIVE_SECONDS);
      } catch (error) {
        showToast('Error trying to delete metric', TOAST_ERROR, FIVE_SECONDS);
      }
    },
  }))
);
