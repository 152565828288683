import { useQuery } from '@tanstack/react-query';
import { makeRemoteGetUserInfo } from 'main/factories/usecases/remote-get-user-info';

export const useHoneGetUserInfo = (user: any, locationId: string | undefined) => {
  const locationUser = makeRemoteGetUserInfo();
  return useQuery({
    // // enabled: !!locationId && !!user && !!user.uid && !!localStorage.getItem('hone:session'),
    queryKey: ['userInfo', locationId, user?.uid],
    queryFn: async () => {
      if (!user) return undefined;
      return locationUser.get({ userId: user.uid, locationId });
    },
  });
};
