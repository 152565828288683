import { HttpClient, HttpRequest, HttpResponse, HttpStatusCode } from 'data/protocols/http';
import { getLocalStorage } from '../../lib/utils';

import axios, { AxiosResponse } from 'axios';

export class AxiosHttpClient implements HttpClient {
  async request(data: HttpRequest, opts?: { signal: AbortSignal }): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;

    // Add session to headers if available
    const session = getLocalStorage('hone:session');
    if (session) {
      if (!data.headers) {
        data.headers = {};
      }
      data['headers'] = { session };
    }

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: data.headers,
        params: data.params,
        signal: opts?.signal,
      });
    } catch (error: any) {
      axiosResponse = error.response || {};

      // every data use case that implements abort controller must implement clientClosedRequest handler to prevent
      // notifying the user about an UnexpectedError that did not happen
      if (error.name === 'CanceledError') {
        axiosResponse.status = HttpStatusCode.clientClosedRequest;
        axiosResponse.data = null;
      }
    }

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }
}
