import { GetFlaggedTransactionsData } from 'domain/usecases';
import { BaseHttpService } from 'main/factories/http';

export class RemoteGetFlaggedTransactionsData
  extends BaseHttpService<GetFlaggedTransactionsData.Model>
  implements GetFlaggedTransactionsData
{
  async get(params: GetFlaggedTransactionsData.Params): Promise<GetFlaggedTransactionsData.Model> {
    return await this.request('get', {}, params);
  }
}
