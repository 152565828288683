import { ReactNode } from 'react';
import { prefixClass } from 'lib/utils';
import { IconClose, IconSplit } from 'components/Icons';

import classNames from 'classnames';
import { QboAccount } from 'domain/models';

type CoASelectedItemProps = {
  item: QboAccount;
  onRemove: (item: QboAccount) => void;
  onSplit: (item: QboAccount) => void;
};

const CoASelectedItem = ({ item, onRemove, onSplit }: CoASelectedItemProps): ReactNode => {
  const prefix = prefixClass('coAParentSelector-container-list-item-wrapper');

  return (
    <div
      className={classNames(prefix(), {
        removed: item.removed,
        new: !item.parentExternalId,
      })}
      key={`${item.name}-${item.classification}`}
    >
      <div className={prefix('container')}>
        <div className={prefix('container-info')}>
          <p className={prefix('container-p')}>{item.glCode}</p>
          <div className={prefix('container-area')}>
            <div className={prefix('container-p-title')}>
              <div className="line" />
              {item.name}
            </div>
            <div className="qboItem-content-type">
              <h6 className="qboItem-content-type-name">{item.accountType}</h6>
            </div>
            <div className="pending">Save to remove</div>
            <div className="new">Save to add</div>
          </div>
        </div>
        {!item.parentExternalId ? (
          <div className={prefix('container-action')} onClick={() => onRemove(item)}>
            <IconClose />
          </div>
        ) : (
          <div className={prefix('container-action')} onClick={() => onSplit(item)}>
            <IconSplit />
          </div>
        )}
        <div className={prefix('container-action-undo')} onClick={() => onSplit(item)}>
          UNDO
        </div>
      </div>
    </div>
  );
};

export default CoASelectedItem;
