import { makeApiUrl } from 'main/factories/http';
import { makeAuthorizeHttpClientDecorator } from 'main/factories/decorators';
import { PostGenerateAccruals } from 'domain/usecases';
import { RemoteGenerateAccruals } from 'data/usecases/journal-entries/remote-generate-accruals';

export const makeRemoteGenerateAccruals = (): PostGenerateAccruals => {
  return new RemoteGenerateAccruals(
    makeApiUrl('/journal-entries/accruals/generate'),
    makeAuthorizeHttpClientDecorator()
  );
};
