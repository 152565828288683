import { prefixClass } from 'lib/utils';
import { IconClose, IconSearch } from 'components/Icons';
import './KSMLMSearch.scss';
import classNames from 'classnames';
import { useRef, useEffect } from 'react';

type KSMLMSearchProps = {
  placeholder?: string;
  searchValue?: string;
  autofocus?: boolean;
  setSearchValue?: (value: string) => void;
  handleClose?: () => void;
};

const KSMLMSearch = ({
  searchValue = '',
  setSearchValue,
  handleClose,
  placeholder = 'Search',
  autofocus = true,
}: KSMLMSearchProps) => {
  const prefix = prefixClass('ks-mlm-search');

  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue?.(event.target.value.toLowerCase());
  };

  const handleClear = () => {
    setSearchValue?.('');
  };

  const handleCloseModal = () => {
    handleClose?.();
  };

  useEffect(() => {
    if (inputRef.current && autofocus) {
      inputRef.current.focus();
    }
  });

  return (
    <>
      <div className={prefix()}>
        <div className={prefix('search-icon')}>
          <IconSearch />
        </div>
        <input type="text" placeholder={placeholder} value={searchValue} onChange={handleSearch} ref={inputRef} />
        {searchValue && (
          <div className={prefix('clear-button')} onClick={handleClear}>
            Clear
          </div>
        )}
        <div className={classNames(prefix('close-button'), { searching: searchValue })} onClick={handleCloseModal}>
          <IconClose />
        </div>
      </div>
    </>
  );
};

export default KSMLMSearch;
