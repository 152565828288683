import React from 'react';
import TableDataCell from './TableDataCell';
import TableDataEditableCell from './TableDataEditableCell';
import { IconTrashCan } from 'components/Icons';

type TableCellProps<T> = {
  rowData: T;
  shouldHide?: boolean;
  shouldHaveBorder?: boolean;
  isLastCell?: boolean;
  isRowSelected?: boolean;
  contentAlign?: 'left' | 'center' | 'right';
  contentStyles: React.CSSProperties;
  isEditable?: boolean;
  cellType?: 'number' | 'text';
  onChange?: (val: string) => void;
  handleDeleteRow?: () => void;
};

export default function TableCell<T>({
  rowData,
  shouldHide,
  isLastCell,
  isRowSelected,
  contentStyles,
  isEditable,
  cellType,

  handleDeleteRow,
  onChange,
}: TableCellProps<T>) {
  const isReactElement = React.isValidElement(rowData);
  if (isReactElement) {
    return (
      <td align="center" className="hone-table-body-row">
        {rowData}
      </td>
    );
  }

  if ((isEditable && onChange) || isEditable !== undefined) {
    return (
      <td
        align="left"
        className="hone-table-body-row"
        style={{
          ...contentStyles,
          paddingLeft: '9px',
          display: shouldHide ? 'none' : 'table-cell',
          opacity: !rowData ? 0.5 : 1,
          borderRight: isLastCell ? 'none' : '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <div className="hone-table-body-row-inner">
          <TableDataEditableCell
            rowData={rowData}
            onChange={onChange}
            contentStyles={contentStyles}
            isEditable={isEditable}
            cellType={cellType}
          />
          {isRowSelected && isLastCell && handleDeleteRow && (
            <button className="hone-table-trash-can-icon" onClick={handleDeleteRow}>
              <IconTrashCan />
            </button>
          )}
        </div>
      </td>
    );
  }

  const isObject = typeof rowData === 'object';

  if (isObject && rowData !== null) {
    return (
      <>
        {Object.values(rowData).map((value: any, idx: number) => (
          <td
            align="center"
            style={{
              borderRight: idx % 2 === 0 ? '1px solid rgba(0, 0, 0, 0.05)' : '5px solid white',
              marginRight: '10px',
              display: shouldHide ? 'none' : 'table-cell',
            }}
            className="hone-table-body-row"
            key={idx}
          >
            <div>
              <TableDataCell dataCellData={value} contentStyles={contentStyles} />
            </div>
          </td>
        ))}
      </>
    );
  }

  return (
    <td
      // align="left"
      style={{
        ...contentStyles,
        paddingLeft: '9px',
        display: shouldHide ? 'none' : 'table-cell',
        opacity: !rowData ? 0.5 : 1,
        borderRight: isLastCell ? 'none' : '1px solid rgba(0, 0, 0, 0.05)',
      }}
      className="hone-table-body-row"
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <div>{rowData || 'x'}</div>
    </td>
  );
}
