import { useEffect, useMemo, useRef, useState } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { IconAttachment, IconDocumentOutline, IconFlag } from '../Icons';
import { currencyFormatter2Decimals, formatDate } from 'lib/utils';
import AuditResult from 'components/Table/AuditResult';
import { useFlaggedTransactionsQuery } from 'hooks/flaggedTransactions';

import {
  AskRequest,
  matchPeriodIncomeStatement,
  matchTransactionToParams,
  validateForCreate,
} from '@hone-automation/common';
import { UIFlaggedTransaction } from 'types/askcustomer';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { useReportsStore } from 'hooks/useReportsStore';
import { HoneReportTypes } from '../../constants';
import classNames from 'classnames';
import { DataCellState } from 'domain/models';

export interface DateTransaction {
  start: string;
  end: string;
  reportId?: string;
}

export type ReportTransactionsProps = {
  scrollTo: string | null;
  modalState: DataCellState;
  handleClose: () => void;
  showAttachment: (attachments: string[], amount: number) => void;
  flagTransaction: (transaction: any) => void;
  audit: any;
};

export const matchFlaggedCell = (
  toMatch: AskRequest,
  date?: DateTransaction,
  sectionTitle?: string,
  report?: HoneReportSummary
): boolean => {
  if (!toMatch.identifier || !sectionTitle?.includes(toMatch.identifier) || !date) {
    return false;
  }
  if (report?.type === HoneReportTypes.IncomeStatement) {
    return matchPeriodIncomeStatement(toMatch, date.start, new Number(report.startDateText.substr(0, 4)).valueOf());
  } else {
    return toMatch.period === date.start;
  }
};

const AttachmentCell = ({ rowData, showAttachment }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (attachments: string[]) => {
    setIsLoading(true);
    showAttachment(attachments, rowData.amount);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  return rowData.attachments ? (
    <div>
      {isLoading ? (
        <Loading loadingCircleClass="Loading-circle-small" />
      ) : (
        <button
          type="button"
          title="Download attachment"
          className="row-attach"
          onClick={e => handleClick(rowData.attachments)}
        >
          <IconDocumentOutline />
          {rowData.attachments && <span className="counter">{rowData.attachments.length}</span>}
        </button>
      )}
    </div>
  ) : null;
};

function ReportTransactions(props: ReportTransactionsProps): JSX.Element {
  const selectedReport = useReportsStore(state => state.selectedReport);
  const tableRef = useRef() as any;

  const { locationId } = useParams();

  const { data: flaggedTransactions } = useFlaggedTransactionsQuery();

  const { dataRows, title, total, loading } = props.modalState;

  const data = useMemo(() => {
    return flaggedTransactions
      ? // TODO type dataRow
        dataRows.map((row: any) => {
          return {
            ...row,
            flagged: flaggedTransactions.find((flaggedTransaction: UIFlaggedTransaction) =>
              matchTransactionToParams(
                {
                  ...flaggedTransaction.transaction,
                  date:
                    flaggedTransaction.transaction &&
                    new Date((flaggedTransaction.transaction.date as unknown as string).replace('T00:00', 'T23:59')),
                },
                {
                  ...row,
                  externalId: row.eventId,
                  externalTransactionType: row.type,
                  date: row.txnDate,
                  memo: row.description,
                }
              )
            ),
          };
        })
      : dataRows;
  }, [flaggedTransactions, dataRows]);

  const getRowClassName = (rowData: any) => {
    if (rowData && typeof rowData.flagged !== 'undefined') {
      return `flagged ${rowData.flagged.status.toLowerCase()}`;
    }
    return '';
  };

  useEffect(() => {
    if (props.scrollTo && tableRef.current) {
      const idx = data.findIndex((row: any) => {
        return row.flagged?.id === props.scrollTo;
      });
      tableRef.current?.scrollTop(idx * 50);
    }
  }, [props.scrollTo]);

  const showsBalanceColumn = selectedReport?.type === HoneReportTypes.ApAging;
  const isAPAgingCurrent = showsBalanceColumn && props.modalState.date.start === 'Current';

  return (
    <div id="report-transactions" className="table-wrapper">
      <Table
        ref={tableRef}
        height={500}
        data={data}
        rowHeight={50}
        loading={loading}
        rowClassName={getRowClassName}
        locale={{ loading: 'Loading transactions...', emptyMessage: 'No transactions found for ' + title }}
      >
        <Column width={80}>
          <HeaderCell>Date {showsBalanceColumn && '/ Due'}</HeaderCell>
          <Cell>
            {rowData => {
              let dueDate: string | null = '';
              try {
                if (rowData.dueDate) {
                  dueDate = formatDate(rowData.dueDate + 'T23:59:59');
                }
              } catch (e) {
                console.error(e);
              }

              return (
                <div className="row-dates">
                  <span className="row-date">{formatDate(rowData.txnDate + 'T23:59:59')}</span>
                  {showsBalanceColumn && <span className="row-date">{dueDate}</span>}
                </div>
              );
            }}
          </Cell>
        </Column>
        {showsBalanceColumn && (
          <Column width={isAPAgingCurrent ? 50 : 60}>
            <HeaderCell align="center">{isAPAgingCurrent ? 'Due in' : 'Past due'}</HeaderCell>
            <Cell align="center">
              {rowData => {
                const unknownDate = rowData.dueDate === '0-00-00';
                return (
                  <span className={classNames('row-pastdue', { green: isAPAgingCurrent })}>
                    {unknownDate ? '-' : Math.abs(rowData.pastDue)}
                  </span>
                );
              }}
            </Cell>
          </Column>
        )}
        <Column width={50}>
          <HeaderCell align="center" style={{ paddingTop: 3 }}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <IconAttachment height="25px" />
          </HeaderCell>
          <Cell align="center">
            {rowData => <AttachmentCell showAttachment={props.showAttachment} rowData={rowData} />}
          </Cell>
        </Column>
        <Column width={130}>
          <HeaderCell>Type</HeaderCell>
          <Cell>
            {rowData => {
              return (
                <>
                  <div>{rowData.type}</div>
                  <div className="row-description">{rowData.num}</div>
                </>
              );
            }}
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Name / Description</HeaderCell>
          <Cell className="name-description-cell">
            {rowData => {
              return (
                <>
                  <div className="name-description-cell--name">{rowData.name}</div>
                  <div className="name-description-cell--desc">{rowData.description}</div>
                </>
              );
            }}
          </Cell>
        </Column>
        <Column width={120}>
          <HeaderCell align="right">Amount</HeaderCell>
          <Cell>
            {rowData => {
              return <span className="row-amount">{currencyFormatter2Decimals(rowData.amount)}</span>;
            }}
          </Cell>
        </Column>
        {showsBalanceColumn && (
          <Column width={120}>
            <HeaderCell align="right">Balance</HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <span className="row-balance">
                    {rowData.balance ? currencyFormatter2Decimals(rowData.balance) : ''}
                  </span>
                );
              }}
            </Cell>
          </Column>
        )}
        <Column width={60}>
          <HeaderCell>{''}</HeaderCell>
          <Cell>
            {rowData => {
              const canBeFlagged = validateForCreate({
                date: rowData.txnDate,
                locationId: locationId!,
                externalTransactionType: rowData.type,
                externalId: rowData.eventId,
                name: rowData.name,
                memo: rowData.description,
                amount: rowData.amount,
                comment: '',
              });
              return (
                canBeFlagged && (
                  <button onClick={() => props.flagTransaction(rowData)} className="ask-bookkepeer-flag-btn">
                    <IconFlag />
                  </button>
                )
              );
            }}
          </Cell>
        </Column>
      </Table>
      <div className="modal-footer">
        <div>
          <AuditResult audit={props.audit} onDismiss={props.handleClose} />
        </div>
        <div>
          <span>{currencyFormatter2Decimals(total)}</span>
          <span>Total</span>
        </div>
      </div>
    </div>
  );
}

export default ReportTransactions;
