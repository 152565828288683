import React from 'react';
import { LocationsAutocomplete } from '../index';
import classNames from 'classnames';
import KSHeader from '../KSHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';

type PageHeaderProps = {
  noV3Location?: boolean;
  icon: React.ReactNode;
  customTitle?: React.ReactNode;
  children?: React.ReactNode;
  pageTitle: string | React.ReactNode;
  canAddNewLocation?: boolean;
  addPadding?: boolean;
};

function PageHeader({
  icon,
  customTitle,
  pageTitle,
  children,
  canAddNewLocation = false,
  addPadding = false,
}: PageHeaderProps): JSX.Element | null {
  const { enableV3Location } = useFlags();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    return null;
  }

  return (
    <>
      <div className="header-container">
        {/*       <div className="report-view--header add-padding"> */}
        <div className="header-container-title">
          {customTitle ? (
            customTitle
          ) : (
            <>
              <div className="header-container-title">
                <span className="header-icon header-icon-bottom">{icon}</span>
                <div className="report-view-header-title">{pageTitle}</div>
              </div>
            </>
          )}
        </div>
        {/*       </div> */}
        {children}
        {!enableV3Location && (
          <div style={{ display: 'flex' }}>
            <LocationsAutocomplete canAddNewLocation={canAddNewLocation} />
          </div>
        )}
      </div>
    </>
  );
}

export default PageHeader;
