// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { currencyFormatter2Decimals } from 'lib/utils';
import React, { useMemo } from 'react';

type TableFooterCellProps<T> = {
  footerCellValue: T;
  footerCellStyles: React.CSSProperties;
  shouldHide?: boolean;
};

export default function TableFooterCell<T>({ footerCellValue, footerCellStyles, shouldHide }: TableFooterCellProps<T>) {
  const formattedValue = useMemo(() => {
    return typeof footerCellValue === 'number' ? currencyFormatter2Decimals(footerCellValue) : footerCellValue;
  }, [footerCellValue]);
  return (
    <>
      <th style={{ ...footerCellStyles, display: shouldHide ? 'none' : 'table-cell' }}>
        <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <line x1="40" x2="120" y1="20" y2="20" stroke="black" strokeWidth="20" strokeLinecap="butt" />
        </svg>
        {formattedValue}
      </th>
    </>
  );
}
