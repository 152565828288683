export const anyToHex = (input: string, opacity?: number): string => {
  if (!input) return 'red';
  const toHex = (c: number): string => {
    const hex = Math.round(c).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  // Check if input is already a hex value
  if (/^#[0-9A-Fa-f]{6}([0-9A-Fa-f]{2})?$/.test(input)) {
    return opacity !== undefined ? input.slice(0, 7) + toHex(Math.round(opacity * 255)) : input;
  }

  // Parse the input string
  const match =
    input.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/) ||
    input.match(/^hsla?\((\d+(?:\.\d+)?),\s*(\d+(?:\.\d+)?)%,\s*(\d+(?:\.\d+)?)%(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  if (!match) {
    //throw new Error('Invalid color string ');
    console.log('Invalid color string ->', input, '<-');
    return 'red';
  }

  if (input.startsWith('rgb')) {
    const [, r, g, b, a] = match.map(Number);
    const alpha = opacity !== undefined ? opacity : a !== undefined ? a : 1;
    return '#' + toHex(r) + toHex(g) + toHex(b) + (alpha !== 1 ? toHex(Math.round(alpha * 255)) : '');
  } else {
    // Convert HSLA to RGBA
    const h = Number(match[1]) / 360;
    const s = Number(match[2]) / 100;
    const l = Number(match[3]) / 100;
    const a = opacity !== undefined ? opacity : match[4] !== undefined ? Number(match[4]) : 1;

    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    const output = '#' + toHex(r * 255) + toHex(g * 255) + toHex(b * 255) + (a !== 1 ? toHex(Math.round(a * 255)) : '');
    /* console.log(input, '->', output); */
    return output;
  }
};
export const getTooltipContent = (columns: any[], rows: any[], row: number, column: number) => {
  return (
    <>
      <div>{columns[column].title}</div>
      <div>{rows[row].title}</div>
      <div>{rows[row].cells[column].amount}</div>
    </>
  );
};
