import Modal from 'react-modal';
import { IconCircleClose } from 'components/Icons';

type Props = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

function AccrualsViewModalConfirmation({ isOpen, title, handleClose, handleConfirm }: Props) {
  return (
    <Modal className="modal-accruals-confirmation" isOpen={isOpen}>
      <div className="modal-header">
        <div className="modal-title">
          <h6>{title}</h6>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <IconCircleClose onClick={handleClose} />
        </div>
      </div>
      <div className="modal-body">
        <p>
          Please be aware that if you confirm the accrual, both the Accrual and the reversal Journal Entries will be
          created.
        </p>
        <div className="modal-body-actions">
          <button onClick={handleClose} className="cancel-btn">
            CANCEL
          </button>
          <button onClick={handleConfirm}>CONFIRM</button>
        </div>
      </div>
    </Modal>
  );
}

export default AccrualsViewModalConfirmation;
