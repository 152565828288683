import { useDashboardReport } from 'hooks/useDashboard';

import DashboardCardDataMetric from './DashboardCardDataMetric';
import DashboardAPAging from './DashboardAPAging/DashboardAPAging';
import DashboardBussinessMetrics from './DashboardBussinessMetrics';
import DashboardChart from './DashboardChart/DashboardChart';
import { isEmpty } from 'lodash';
import InformativeMessage from 'presentation/components/InformativeMessage/InformativeMessage';
import { formatDate, isProdHost, prefixClass } from 'lib/utils';
import { Badge } from 'presentation/components';
import { SummaryCard, SummaryItem } from '@hone-automation/common';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import DashboardFlaggedTransactions from 'presentation/pages/dashboard/components/DashboardFlaggedTransacions/DashboardFlaggedTransactions';
import { IconBulletBlue, IconBulletOrange, IconForwardBtn } from 'components/Icons';
import { useNavigate } from 'react-router-dom';
import { useLocationsStore } from 'hooks/useLocationsStore';

import classNames from 'classnames';
import { useAskRequestsPendingCount } from 'hooks/useAskRequestsPendingCount';
import { useHoneLocationUsers } from 'components/HoneLocationUsers';

import { useAskRequestsCountQuery } from 'hooks/useAskRequestsCountQuery';

export interface PieDataValue {
  value: number;
  name: string;
}

type DashboardCardsLayoutProps = {
  showNoDataMessage: boolean;
};

export default function DashboardCardsLayout({ showNoDataMessage }: DashboardCardsLayoutProps): JSX.Element {
  const navigateTo = useNavigate();
  const { isBookkeeperAdmin } = useHoneLocationUsers();
  const prefix = prefixClass('dashboard-cards');
  const { currentLocationId } = useLocationsStore(state => ({
    currentLocationId: state.currentLocationId,
    currentLocation: state.currentLocation,
  }));
  const { dashboardReport, activeBussinessMetric } = useDashboardReport();

  const plReport: SummaryCard | undefined = dashboardReport?.cards.find(card => card.type === 'Insights');

  const netSales = plReport?.data.items.find(item => item.title === 'Net Sales');
  const expenses = plReport?.data.items.find(item => item.title === activeBussinessMetric);
  const chartItems =
    !isEmpty(netSales) && !isEmpty(expenses) && netSales?.chartItems?.concat(expenses.chartItems as SummaryItem[]);

  const askRequestsCountForCurrentRole = useAskRequestsPendingCount();
  const { data, isSuccess } = useAskRequestsCountQuery();

  const redirectAskCustomer = () => {
    const activeTab = isBookkeeperAdmin ? 'In Progress' : 'Open';
    navigateTo(`/app/location/${currentLocationId}/ask-requests?activeTab=${activeTab}`);
  };

  const getAnnouncementUrl = () => {
    return `https://storage.googleapis.com/hone-portal-public${
      isProdHost() ? '' : '-dev'
    }/announcement_referral_1.html`;
  };

  return (
    <>
      <div className={prefix('container')}>
        <div className={prefix('layout')}>
          {showNoDataMessage && (
            <div className="informative-message-container warning">
              <InformativeMessage type="warning">Data for the current week isn’t available yet. </InformativeMessage>
            </div>
          )}
          {/* {typeof askRequestsCountForCurrentRole === "number"  && askRequestsCountForCurrentRole>0 && (
            <div className="informative-message-container">
              <InformativeMessage type="checklist" clickable>
                <div className="dashboard-informative-flagged-transactions" onClick={redirectAskCustomer}>
                  Ask Requests Pending
                  <div className="badge-icon">
                    <Badge content={askRequestsCountForCurrentRole} />
                    <IconForwardBtn />
                  </div>
                </div>
              </InformativeMessage>
            </div>
          )} */}
          {dashboardReport && dashboardReport?.cards.length > 0 && (
            <InformativeMessage type="informative" center>
              <>
                <span>WEEK ENDING ON</span>
                <b>
                  {formatDate(
                    new Date(dashboardReport?.cards[0].endDate.toString().replaceAll('-', '/').split('T')[0])
                  )}
                </b>
              </>
            </InformativeMessage>
          )}
          <div className={prefix('layout-time-chart-metric')}>
            <div className={prefix('charts-data-container')}>
              <div className={prefix('container-title-card-container')}>
                <div className={prefix('container-title-card')}>Overview</div>
                <div className={prefix('container-title-card')}>
                  <div className={prefix('container-title-card--legend')}>
                    <div>
                      <IconBulletBlue />
                      Net Sales
                    </div>
                    <div>
                      <IconBulletOrange />
                      {activeBussinessMetric}
                    </div>
                  </div>
                </div>
              </div>
              <div className={prefix('layout--charts-container')}>
                <div className={prefix('layout--pl-content metrics')}>
                  <div className={prefix('layout--pl')}>
                    <DashboardCardDataMetric
                      title={netSales?.title || ''}
                      number={Number(netSales?.smoothedAmount ?? netSales?.amount)}
                      percentage={Number(netSales?.smoothedPercent ?? netSales?.percent)}
                      percentTitle={netSales?.percentTitle || ''}
                      type="result"
                    />
                  </div>
                  <div className={prefix('layout--pl-line')}></div>
                  <div className={prefix('layout--pl')}>
                    <DashboardCardDataMetric
                      title={expenses?.title || ''}
                      number={Number(expenses?.smoothedAmount ?? expenses?.amount)}
                      percentage={Number(expenses?.smoothedPercent ?? expenses?.percent)}
                      percentTitle={expenses?.percentTitle || ''}
                      type={'expenses'}
                    />
                  </div>
                </div>

                {chartItems && (
                  <>
                    <DashboardChart
                      chartItems={chartItems}
                      type={'bar'}
                      showLegend={false}
                      legendPosition="bottom"
                      bmSelected={activeBussinessMetric}
                      xAxis2nd="AMOUNT"
                    />

                    <DashboardChart
                      chartItems={chartItems}
                      type={'line'}
                      showLegend={false}
                      bmSelected={activeBussinessMetric}
                      legendPosition="bottom"
                      xAxis2nd="PERCENTAGE"
                    />
                  </>
                )}
              </div>
            </div>
            <div className={prefix('layout--pl-metrics-ctn')}>
              <div className={prefix('layout--pl-container')}>
                {isSuccess && (
                  <div className={classNames(prefix('layout--pl-content flagged-transactions'))}>
                    <DashboardFlaggedTransactions />
                  </div>
                )}

                <div className={prefix('layout--pl-content ap-aging')}>
                  <DashboardAPAging />
                </div>
              </div>

              <DashboardBussinessMetrics />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
