import './NTToolTip.scss';
import { ChartDataColumn } from '../NTChart/NTChart';
import { prefixClass } from 'lib/utils';

export type NTTooltipProps = {
  data?: ChartDataColumn | null;
  position: { x: number; y: number };
};

const NTTooltip = ({ data, position }: NTTooltipProps) => {
  if (!data) return null;
  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`,
          background: 'white',
          border: '1px solid black',
          padding: '5px',
          pointerEvents: 'none',
          zIndex: 1000,
        }}
      >
        <h3>{data.name}</h3>
        <p>Actual: ${data.data.money.actual}</p>
        <p>Budget: ${data.data.money.budget}</p>
        <p>Variance: ${data.data.money.variance}</p>
      </div>
    </>
  );
};

export default NTTooltip;
