import { create } from 'zustand';
import { doGet, reportingOrigin } from 'lib/utils';
import { subscribeWithSelector } from 'zustand/middleware';
import { useAuthUser } from './useAuthUser';
import { Location as KsLocation } from '@hone-automation/common/lib/Location';

interface LocationsState {
  allLocations: KsLocation[];
  fetchAllLocations: (userId?: string) => Promise<void>;
  fetchLocationById: (locationId: string) => Promise<void>;
  status: 'idle' | 'loading' | 'error' | 'success';
  currentLocationId?: string;
  currentLocation: KsLocation | undefined;
  setCurrentLocationId: (newLocationId?: string) => void;
}

const getLocations = (userId: string): Promise<HoneGetLocationsResponse> => {
  return doGet(`${reportingOrigin()}/getLocations?userId=${userId}`);
};

const getLocationById = (locationId: string): Promise<{ data: KsLocation }> => {
  return doGet(`${reportingOrigin()}/getLocation?locationId=${locationId}`);
};

export const getLocationClassList = (locationId: string): Promise<any> => {
  return doGet(`${reportingOrigin()}/getLocationClassList?locationId=${locationId}`);
};

export const syncToken = (locationId: string): Promise<{ data: string }> => {
  return doGet(`${reportingOrigin()}/syncToken?locationId=${locationId}`);
};

export const useLocationsStore = create(
  subscribeWithSelector<LocationsState>(set => ({
    allLocations: [],
    currentLocationId: undefined,
    currentLocation: undefined,
    status: 'idle',
    setCurrentLocationId: (newLocationId?: string) => {
      set(state => {
        if (!newLocationId && newLocationId !== '') return state;

        if (newLocationId === '') {
          return { ...state, currentLocationId: '', currentLocation: undefined };
        }
        return { ...state, currentLocationId: newLocationId };
      });
    },
    fetchAllLocations: async (userId?: string) => {
      if (!userId) {
        userId = useAuthUser.getState().userId || undefined;
      }

      if (!userId) {
        return;
      }

      const response = await getLocations(userId);
      const locations = response?.data;

      const nextState: Partial<LocationsState> = { allLocations: locations };
      set(nextState);
    },
    fetchLocationById: async (locationId: string) => {
      const response = await getLocationById(locationId);
      set({ currentLocation: response?.data, currentLocationId: response?.data.id });
    },
  }))
);
