import React, { Suspense } from 'react';

import { useQueryState } from '../../../hooks/useQueryState';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import NewLayout from '../../components/NewLayout/NewLayout';
import { useNavigate } from 'react-router-dom';
import { useHoneLocationUsers } from '../../../components/HoneLocationUsers';
import Loading from '../../../components/Loading';
import CreateLocation from './components/CreateLocation';
import HoneLocationsProvider from '../../../components/HoneLocations';
import { MENU_ICONS } from '../../../constants';
import { IconAdmin } from '../../../components/Icons';
import UsersAdmin from './UsersAdmin';

const tabs = [
  {
    name: 'Users',
    component: <UsersAdmin />,
  },
  {
    name: 'Location',
    component: <CreateLocation />,
  },
];

function AdminPage() {
  const [activeTab] = useQueryState('activetab', 'Users');
  const navigateTo = useNavigate();
  const { userInfoHasLoaded, currentLocation, currentLocationAbilities } = useHoneLocationUsers();

  const handleClickTab = (tabName: string) => {
    navigateTo(`/app/location/${currentLocation?.locationId}/admin?activetab=${tabName}`);
  };
  const idxActiveTab = tabs.findIndex(tab => tab.name === activeTab);

  return (
    <Tabs selectedTabClassName="react-tabs-tab-selected" selectedIndex={Number(idxActiveTab)}>
      <NewLayout
        icon={IconAdmin}
        pageTitle="Admin"
        className="bookkeeper"
        chartArea={
          <TabList>
            {tabs.map((tab, idx: number) => (
              <Tab
                selected={idx === idxActiveTab}
                className="react-tabs-tab"
                onClick={() => handleClickTab(tab.name)}
                key={`${tab.name}_${idx}`}
              >
                {tab.name}
              </Tab>
            ))}
          </TabList>
        }
      >
        <div className="bookkeeper-layout">
          <div className="Layout">
            <Suspense fallback={<Loading />}>
              <main className="Layout-main">
                <div className="Layout-content">
                  <HoneLocationsProvider>
                    {tabs.map((tab, idx: number) => (
                      <TabPanel key={`${tab.name}_${idx}`}>{tab.component}</TabPanel>
                    ))}
                  </HoneLocationsProvider>
                </div>
              </main>
            </Suspense>
          </div>
        </div>
      </NewLayout>
    </Tabs>
  );
}

export default AdminPage;
