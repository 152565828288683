import { AskRequest, FlaggedTransaction, FlaggedTransactionStatus, ReportRow } from '@hone-automation/common';

export enum UIFlaggedTransactionStatus {
  INFORMATION = 'Information Needed',
  SUBMITTED = 'Submitted',
  CATEGORIZED = 'Categorized',
}

export enum UIAskRequestsTitles {
  CUSTOMER = 'Customer',
  BOOKKEEPER = 'Accountant',
  RESOLVED = 'Resolved',
  RECENTLY_RESOLVED = 'Recently Resolved',
  ASK_REQUESTS = 'Ask Requests',
}

export type UIAskTitles =
  | UIAskRequestsTitles.ASK_REQUESTS
  | UIAskRequestsTitles.CUSTOMER
  | UIAskRequestsTitles.BOOKKEEPER
  | UIAskRequestsTitles.RESOLVED;

export type UIStatus =
  | UIFlaggedTransactionStatus.INFORMATION
  | UIFlaggedTransactionStatus.SUBMITTED
  | UIFlaggedTransactionStatus.CATEGORIZED;

export interface UIFlaggedTransaction extends Omit<FlaggedTransaction, 'status'> {
  status: UIStatus;
  group?: string;
  idx?: number;
  reportId?: string;
}

export type ApiStatusToUIStatusMapType = {
  [key in FlaggedTransactionStatus]: UIStatus;
};

export type FlaggedTransactionReopenPayload = {
  trxId: string;
  comment: string;
};

export interface UIReportTransaction extends ReportRow {
  flagged?: AskRequest;
}

export interface UIReportFlaggedCell {
  flaggedCell?: AskRequest;
}
