import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { cloneDeep, dropWhile, get, isEmpty, isNil, isUndefined, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { doGet, doPost, reportingOrigin } from 'lib/utils';
import { trackError } from 'lib/analytics';
import { isFlatTemplateSection } from 'lib/templateUtils';
import { AxiosResponse } from 'axios';
import type { PropsWithChildren } from 'react';
import { useLocationsStore } from '../hooks/useLocationsStore';
import { refreshAccounts } from 'hooks/useReportsStore';
import { QueryStatus, useQuery } from '@tanstack/react-query';
import { makeRemoteGetTemplates } from 'main/factories/usecases';
import { useQueryState } from 'hooks/useQueryState';

export interface Context {
  currentTemplateId: string | undefined;
  setCurrentTemplateId: (nextTemplateId: string | undefined) => void;
  allTemplates: HoneTemplateSettings[];
  status: QueryStatus;
  templateLoading: boolean;
  currentTemplateSettings?: HoneTemplateSettings;
  currentTemplate: FlatTemplateSection[];
  handleTemplateChange: (template: FlatTemplateSection[]) => void;
  setTemplate: (
    id: string,
    locationId: string,
    title: string,
    type: HoneReportType,
    visibility: HoneReportVisibility,
    status: HoneReportStatus,
    timeframe: HoneReportTimeframe,
    period: number,
    publishDelayDays: number,
    flatTemplate: FlatTemplateSection[]
  ) => Promise<any>;
  copyTemplate: (templateId: string) => Promise<void>;
  deleteTemplate: (templateId: string) => Promise<void>;
  generateTemplate: (locationId: string) => Promise<any>;
  generateDashboard: (locationId: string) => Promise<any>;
  getReportPreview: (
    title: string,
    type: HoneReportType,
    timeframe: HoneReportTimeframe,
    period: number,
    flatTemplate: FlatTemplateSection[]
  ) => Promise<NestedHoneReport | undefined>;
}

export function useHoneChartOfAccounts(locationId?: string) {
  return useQuery({
    gcTime: 60 * 60 * 1000, // 1 hour in milliseconds
    enabled: !!locationId,
    queryKey: ['getChartOfAccounts', locationId],
    queryFn: () => locationId && refreshAccounts(locationId),
    select: (response: any) => {
      return response.data.QueryResponse.Account;
    },
  });
}

const templatesService = makeRemoteGetTemplates();

export function useHoneGetReportTemplates(locationId: string | undefined) {
  return useQuery({
    enabled: !!locationId,
    queryKey: ['getReportTemplates', locationId],
    queryFn: () => templatesService.getAll({ locationId }),
  });
}

export function nestedToFlat(nestedSections: NestedTemplateSections): FlatTemplateSection[] {
  const flatTemplate = [] as FlatTemplateSection[];
  const sections = nestedSections.sections;

  // collect rows from nested sections
  while (sections.length > 0) {
    const section = sections.shift();

    if (section) {
      if (section.sections && section.sections.length > 0) {
        sections.unshift(...section.sections);
      }

      if (isFlatTemplateSection(section)) {
        const flatSection = omit(section, 'sections');
        flatTemplate.push(flatSection);
      }
    }
  }

  return flatTemplate;
}

export function flatToNested(flatSections: FlatTemplateSection[]): NestedTemplateSections {
  const root = { sections: [] } as NestedTemplateSections;
  let levels = [root.sections];
  let prevLevel = 0;

  // we expect to start with a root level 0, remove anything invalid before that
  dropWhile(flatSections, section => section.level !== 0).forEach(section => {
    const nestedSection = cloneDeep(section) as NestedTemplateSection;

    // FIXME we shouldn't need this since we filter out spacers
    if (isUndefined(nestedSection.level)) {
      // TODO hack until we handle nested spacers, need to track level on them too
      if (root.sections[root.sections.length - 1].title !== 'SPACER') {
        root.sections.push(nestedSection); // dont double add spacers
      }
      return;
    }

    if (nestedSection.level === 0) {
      root.sections.push(nestedSection);
      levels = [root.sections];
    } else {
      // prev level
      if (nestedSection.level < prevLevel) {
        // drop all previous levels, and then add this nestedSection to its level root (un-nest)
        levels.length = nestedSection.level + 1;

        levels[nestedSection.level].push(nestedSection);
      }

      // same level
      if (nestedSection.level === prevLevel) {
        // push this nestedSection to the previous level
        const prevRoot = levels[prevLevel];
        prevRoot.push(nestedSection);
      }

      // next level
      if (nestedSection.level > prevLevel) {
        if (nestedSection.level !== prevLevel + 1)
          console.error(
            `unexpected level increase from ${prevLevel} to ${nestedSection.level}, assuming level ${
              prevLevel + 1
            } instead`
          );

        // add a level, and push this nestedSection to the previous level last nestedSection (nest)
        const prevRoot = levels[prevLevel];
        const lastSection = prevRoot[prevRoot.length - 1];

        if (!lastSection.sections) lastSection.sections = [];

        lastSection.sections.push(nestedSection);

        // make this the new root for the level
        levels[nestedSection.level] = lastSection.sections;
      }
    }

    prevLevel = nestedSection.level;
  });

  return root;
}

export const initialValues: Context = {
  currentTemplateId: undefined,
  setCurrentTemplateId: () => {
    //
  },
  handleTemplateChange(template: FlatTemplateSection[]) {
    return null;
  },
  templateLoading: false,
  currentTemplate: [],
  allTemplates: [],
  copyTemplate(templateId: string): Promise<void> {
    return Promise.resolve(undefined);
  },

  deleteTemplate(templateId: string): Promise<void> {
    return Promise.resolve(undefined);
  },
  generateTemplate(locationId: string): Promise<any> {
    return Promise.resolve(undefined);
  },
  generateDashboard(locationId: string): Promise<any> {
    return Promise.resolve(undefined);
  },
  getReportPreview(
    title: string,
    type: HoneReportType,
    timeframe: HoneReportTimeframe,
    period: number,
    flatTemplate: FlatTemplateSection[]
  ): Promise<NestedHoneReport | undefined> {
    return Promise.resolve(undefined);
  },
  setTemplate(
    id: string,
    locationId: string,
    title: string,
    type: HoneReportType,
    visibility: HoneReportVisibility,
    status: HoneReportStatus,
    timeframe: HoneReportTimeframe,
    period: number,
    publishDelayDays: number,
    flatTemplate: FlatTemplateSection[]
  ): Promise<any> {
    return Promise.resolve(undefined);
  },
  status: 'success',
};

export const HoneReportTemplatesContext = createContext<Context | undefined>(undefined);

enum ReportTemplateActionKind {
  SET_ALL_TEMPLATES = 'SET_ALL_TEMPLATES',
  SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE',
  EXPAND_ALL = 'EXPAND_ALL',
  RESET = 'RESET',
}

type ReportTemplateAction =
  | { type: ReportTemplateActionKind.SET_ALL_TEMPLATES; payload: HoneTemplateSettings[] }
  | { type: ReportTemplateActionKind.SET_CURRENT_TEMPLATE; payload: FlatTemplateSection[] }
  | { type: ReportTemplateActionKind.RESET };

interface ReportTemplatesState {
  allTemplates: HoneTemplateSettings[];
  currentTemplate: FlatTemplateSection[];
  expandAll: boolean;
}

const initialState: ReportTemplatesState = {
  allTemplates: [],
  currentTemplate: [],
  expandAll: false,
};

function reportTemplatesReducer(state: ReportTemplatesState, action: ReportTemplateAction): ReportTemplatesState {
  switch (action.type) {
    case ReportTemplateActionKind.RESET:
      return initialState;
    case ReportTemplateActionKind.SET_ALL_TEMPLATES:
      return {
        ...state,
        allTemplates: action.payload,
      };
    case ReportTemplateActionKind.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      };
    default:
      return state;
  }
}

export default function HoneReportTemplatesProvider(props: PropsWithChildren<unknown>) {
  const currentLocationId = useLocationsStore(state => state.currentLocationId);
  const [currentTemplateId, setCurrentTemplateId] = useQueryState<string | undefined>('templateId');

  const { data: remoteAllTemplates, status } = useHoneGetReportTemplates(currentLocationId);
  const [state, dispatch] = useReducer(reportTemplatesReducer, initialState);
  const [currentTemplateSettings, setCurrentTemplateSettings] = useState<HoneTemplateSettings | undefined>();
  const { allTemplates, currentTemplate, expandAll } = state;

  const {
    data: getTemplateByIdData,
    status: getTemplateStatus,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['getTemplate', currentTemplateId],
    staleTime: 0,
    gcTime: 0,
    queryFn: async () => {
      const response = await getTemplateReportingService(currentTemplateId!);
      if (response.status === 200) {
        const details = (response as HoneGetTemplateResponse).data;

        if (!details) throw new Error(`getTemplate: empty data`);

        const templateSettings = omit(details, 'data') as HoneTemplateSettings;
        const nestedTemplate = JSON.parse(details.data) as NestedTemplateSections;

        if (nestedTemplate) {
          const flatTemplate = { ...templateSettings, sections: nestedToFlat(nestedTemplate) };

          const { sections } = flatTemplate;
          if (sections.length > 0) {
            return {
              currentTemplate: sections.filter(section => section.title !== 'SPACER'),
              templateSettings,
            };
          }
        }
      }
    },
    enabled: !!currentTemplateId,
  });

  useEffect(() => {
    if (isSuccess) {
      if (getTemplateByIdData?.templateSettings) {
        setCurrentTemplateSettings(getTemplateByIdData?.templateSettings);
      }

      if (getTemplateByIdData?.currentTemplate) {
        dispatch({
          type: ReportTemplateActionKind.SET_CURRENT_TEMPLATE,
          payload: getTemplateByIdData?.currentTemplate,
        });
      }
    }
  }, [isSuccess, getTemplateByIdData]);

  useEffect(() => {
    dispatch({ type: ReportTemplateActionKind.SET_CURRENT_TEMPLATE, payload: [] });
  }, [currentTemplateId]);

  const getTemplateReportingService = (id: string): Promise<AxiosResponse<any>> => {
    return doGet(`${reportingOrigin()}/getTemplate?id=${id}`);
  };

  const setTemplate = async (
    id: string,
    locationId: string,
    title: string,
    type: HoneReportType,
    visibility: HoneReportVisibility,
    status: HoneReportStatus,
    timeframe: HoneReportTimeframe,
    period: number,
    publishDelayDays: number,
    flatTemplate: FlatTemplateSection[]
  ) => {
    try {
      const nestedTemplate = flatToNested(flatTemplate);
      return doPost(`${reportingOrigin()}/setTemplate`, {
        id,
        locationId,
        title,
        type,
        visibility,
        status,
        timeframe,
        period,
        publishDelayDays,
        data: JSON.stringify(nestedTemplate),
      });
    } catch (error) {
      console.error(error);
      trackError({ error: error as Error });
    }
  };

  const copyTemplate = async (templateId: string) => {
    try {
      await doPost(`${reportingOrigin()}/templates/copyTemplate`, { templateId });
    } catch (error) {
      console.error(error);
      trackError({ error: error as Error });
    }
  };

  const deleteTemplate = async (templateId: string) => {
    try {
      await doPost(`${reportingOrigin()}/templates/deleteTemplate`, { templateId });
    } catch (error) {
      console.error(error);
      trackError({ error: error as Error });
    }
  };

  const generateTemplate = (locationId: string): Promise<AxiosResponse<any>> => {
    return doGet(`${reportingOrigin()}/generateTemplate?locationId=${locationId}`);
  };

  const generateDashboard = async (locationId: string) => {
    try {
      const response = await doPost(`${reportingOrigin()}/generateDashboard`, {
        locationId,
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const getReportPreview = async (
    title: string,
    type: HoneReportType,
    timeframe: HoneReportTimeframe,
    period: number,
    flatTemplate: FlatTemplateSection[]
  ) => {
    try {
      const template = JSON.stringify(flatToNested(flatTemplate));
      const { data } = await doPost(`${reportingOrigin()}/copyTemplate`, {
        title,
        type,
        timeframe,
        period,
        template,
      });

      return data;
    } catch (error) {
      console.error(error);
      trackError({ error: error as Error });
    }
  };

  useEffect(() => {
    if (status !== 'success') {
      // clear current template when we load new templates
      dispatch({ type: ReportTemplateActionKind.RESET });
    }
    if (status === 'success' && remoteAllTemplates && !isEmpty(remoteAllTemplates)) {
      const newTemplates = (remoteAllTemplates as HoneTemplateSettings[]).map(template => {
        if (isNil(template.id)) {
          template.id = uuidv4();
        }
        return template;
      });
      dispatch({ type: ReportTemplateActionKind.SET_ALL_TEMPLATES, payload: newTemplates });
    }
  }, [status, remoteAllTemplates]);

  // useEffect(() => {
  //   // clear templates when location changes
  //   dispatch({ type: ReportTemplateActionKind.RESET });
  // }, [currentLocationId]);

  const handleTemplateChange = (template: FlatTemplateSection[]) => {
    dispatch({ type: ReportTemplateActionKind.SET_CURRENT_TEMPLATE, payload: template });
  };

  return (
    <HoneReportTemplatesContext.Provider
      value={{
        allTemplates,
        status: getTemplateStatus,
        currentTemplate,
        templateLoading: isLoading,
        handleTemplateChange,
        currentTemplateSettings,
        setTemplate,
        copyTemplate,
        deleteTemplate,
        generateTemplate,
        generateDashboard,
        getReportPreview,
        currentTemplateId,
        setCurrentTemplateId,
      }}
      {...props}
    />
  );
}

export function useHoneReportTemplates(): Context {
  const templates = useContext(HoneReportTemplatesContext);

  if (!templates) throw new Error('You must call useHoneReportTemplates from within HoneReportTemplatesProvider tree');

  return templates;
}
