import './NewTable.scss';
import { prefixClass } from 'lib/utils';
import { parseReportIntoTable, parseReportIntoTableProps } from './NTFile';
import { useRef, useEffect, useMemo, useState } from 'react';
import NTLayout from './NTLayout';
import { useNTContext, NTProvider } from './NTContext';
import NTProfiler from './NTProfiler';
import { NTScrollProvider, useNTScroll } from './NTScrollContext';
import { NTUtilsProvider, useNTUtilsContext } from './NTUtilsContext';
import { NTSelectionProvider, useNTSelectionContext } from './NTSelectionContext';
import { NTTableProvider, useNTTableContext } from './NTTableContext';
import NTSearchAndFilter from '../ReportTable/NTSearchAndFilter';
import classNames from 'classnames';
import NTColumnBrowser from './NTColumnBrowser';
import { useScrollProperties } from './hooks/useScrollProperties';
import { useTableState } from './hooks/useTableState';
import DataRowModal from 'components/Table/DataRowModal';
import NTMiniMap from './NTMiniMap';
import { useFlaggedTransactionsQuery } from 'hooks/flaggedTransactions';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { useReportsStore } from 'hooks/useReportsStore';
import { shallow } from 'zustand/shallow';
import { initialDataCellState } from 'hooks/useActiveReportStore';
import useAttachmentsModal from 'hooks/useAttachmentsModal';
import { DataCellState } from 'domain/models';
import { NTChart } from './NTChart';
import parseReportNT from '../KSExport/reportNTParser';
import { difference } from 'lodash';

type NewTableProps = {
  report: NestedHoneReport;
  fixFirstColumn?: boolean;
  fixLastColumn?: boolean;
};

const NewTable = ({ report, fixFirstColumn = true, fixLastColumn = false }: NewTableProps) => {
  const MemoizedNewTableContent = useMemo(
    () => <NewTableContent report={report} fixFirstColumn={fixFirstColumn} fixLastColumn={fixLastColumn} />,
    [report, fixFirstColumn, fixLastColumn]
  );
  return (
    <NTProvider>
      <NTProfiler>
        <NTScrollProvider>
          <NTSelectionProvider>
            <NTUtilsProvider>
              <NTTableProvider>{MemoizedNewTableContent}</NTTableProvider>
            </NTUtilsProvider>
          </NTSelectionProvider>
        </NTScrollProvider>
      </NTProfiler>
    </NTProvider>
  );
};

const NewTableContent = ({ report, fixFirstColumn, fixLastColumn }: NewTableProps) => {
  const { filters, setFilters, budgetAvailable, setBudgetAvailable, columns } = useNTContext();
  const { actions, setActions, capabilities, setCapabilities } = useNTUtilsContext();
  const { setRedimParent, tableWidth } = useNTTableContext();
  const prefix = prefixClass('whole-table');
  const parentRef = useRef<HTMLDivElement>(null);
  const trace = false;

  const currentLocation = useLocationsStore(state => state.currentLocation);

  const { reportsConfiguration } = useReportsStore(
    state => ({
      reportsConfiguration: state.reportsConfiguration,
    }),
    shallow
  );

  const { data: flaggedTransactions } = useFlaggedTransactionsQuery();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const transformedData = useMemo(
    () =>
      report
        ? parseReportNT({
            report: report,
            useComputedColumns: true,
            periodReporting: reportsConfiguration?.data?.periodReporting,
            flaggedTransactions: flaggedTransactions,
            currentLocation: currentLocation,
            trace,
          })
        : null,
    [report]
  );

  if (!transformedData) {
    console.log('(silent error) NT: transformedData: No data to continue rendering');
    return null;
  }

  useTableState();
  useScrollProperties();

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  //console.log('···································· NewTableContent');

  const reportIncludeFixedColumn: boolean = transformedData.totalColumn;
  const showDifference: boolean = transformedData.difference;

  const { getAttachmentsUrl } = useAttachmentsModal();
  const currentLocationId = useLocationsStore(state => state.currentLocationId);

  const readURLParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filter = {
      // unless explicitly set to false, we assume that all the filters are enabled by default
      percentage: urlParams.get('percentages') !== 'false',
      money: urlParams.get('amounts') !== 'false',
      actuals: urlParams.get('actuals') !== 'false',
      transactions: urlParams.get('transactions') !== 'false',
      // for this ones we read the value from the url to see if they are enabled or disabled. Disabled by default
      smoothing: urlParams.get('smoothing') === 'true',
      budget: urlParams.get('budget') === 'true',
      variance: urlParams.get('variance') === 'true',
      anomalies: urlParams.get('anomalies') === 'true',
    };
    const actions = {
      chartCollapsed: urlParams.get('chartCollapsed') === 'true',
      reverseColumns: urlParams.get('reverse') === 'true',
      difference: urlParams.get('difference') === 'true',
      total: urlParams.get('total') === 'true',
    };
    return { filter, actions };
  };

  // Here we setup the config for the table
  useEffect(() => {
    setCapabilities(transformedData.capabilities);
    setBudgetAvailable(transformedData ? !!transformedData?.budgetAvailable : false);
    setRedimParent(parentRef.current);
    const urlReads = readURLParams();
    setFilters({
      ...filters,
      ...urlReads.filter,
    });
    setActions({
      ...actions,
      total: reportIncludeFixedColumn,
      difference: showDifference,
      reverseColumns: urlReads.actions.reverseColumns,
    });
  }, [transformedData, setBudgetAvailable, parentRef]);

  useEffect(() => {
    // Update URL params without page reload
    const urlParams = new URLSearchParams(window.location.search);

    // Update filter params
    urlParams.set('percentages', filters.percentage.toString());
    urlParams.set('amounts', filters.money.toString());
    urlParams.set('actuals', filters.actuals.toString());
    urlParams.set('transactions', filters.transactions.toString());
    urlParams.set('smoothing', filters.smoothing.toString());
    urlParams.set('budget', filters.budget.toString());
    urlParams.set('variance', filters.variance.toString());
    urlParams.set('anomalies', filters.anomalies.toString());

    // Update action params
    /*urlParams.set('chartCollapsed', actions.chart.toString());
    urlParams.set('reverse', actions.reverseColumns.toString());
    urlParams.set('difference', actions.difference.toString());
    urlParams.set('total', actions.total.toString()); */

    // Update URL without reload
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
  }, [filters, actions]);

  const MemoizedNTMiniMap = useMemo(() => <NTMiniMap rawData={transformedData} />, [transformedData]);
  const MemoizedNTChart = useMemo(() => <NTChart rawData={transformedData} />, [transformedData]);
  const MemoizedNTColumnBrowser = useMemo(() => <NTColumnBrowser />, []);
  const [modalState, setModalState] = useState<DataCellState>({ ...initialDataCellState, modalOpen: true });

  const handleClose = () => {
    setModalState({ ...modalState, modalOpen: actions.trxModal });
    setActions({
      ...actions,
      trxModal: false,
    });
  };

  // We open and close the modal from here
  useEffect(() => {
    setModalState({ ...actions.trxModalData, modalOpen: actions.trxModal });
  }, [actions]);

  return (
    <>
      <div
        className={classNames(prefix(), {
          unconstrained: !actions.constrained,
        })}
        ref={parentRef}
        style={{ width: tableWidth }}
      >
        <NTSearchAndFilter
          liveReport={transformedData?.liveReport ? report?.timeframe : null}
          selectedReportType={transformedData?.type ?? 'P&L Comparison'}
          searchValue={searchTerm}
          onSearch={handleSearch}
          onClear={handleClear}
        />
        <div className="table-minimap">
          {/* {MemoizedNTChart} */}
          <div className="new-table" id="new-table">
            <NTLayout
              parentRef={parentRef}
              key="myReportTable"
              data={transformedData}
              fixFC={fixFirstColumn}
              fixLC={actions.total || reportIncludeFixedColumn || actions.difference || showDifference}
              searchTerm={searchTerm}
            />
          </div>
          {MemoizedNTMiniMap}
        </div>
        {MemoizedNTColumnBrowser}
      </div>
      {actions.trxModal && (
        <DataRowModal
          modalState={modalState}
          handleClose={handleClose}
          showAttachment={(attachments, amount) => getAttachmentsUrl(currentLocationId, attachments, amount)}
        />
      )}
    </>
  );
};

export default NewTable;
