import { PostAskRequestReCategorization } from 'domain/usecases';
import { RemotePostReCategorizeAskRequest } from 'data/usecases/ask-request/remote-post-recategorize-ask-request';
import { makeApiUrl } from '../../http';
import { makeAuthorizeHttpClientDecorator } from '../../decorators';

export const makeRemoteAskRequestsReCategorization = (trxId: string): PostAskRequestReCategorization => {
  return new RemotePostReCategorizeAskRequest(
    makeApiUrl(`/ask-requests/${trxId}/recategorize`),
    makeAuthorizeHttpClientDecorator()
  );
};
