// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useQuery } from '@tanstack/react-query';
import { useQueryState } from 'hooks/useQueryState';
import { useParams } from 'react-router-dom';
import { useReportsStore } from 'hooks/useReportsStore';
import { FlaggedTransaction, ResolvedLink } from '@hone-automation/common';
import { makeRemoteGetReportLink } from 'main/factories/usecases/reports/remote-get-report-links';
import { isPeriodReport } from '../../lib/utils';

export const useReportFlaggedTransactionQuery = () => {
  const { locationId } = useParams();

  const selectedReport = useReportsStore(state => state.selectedReport);
  const reportId = selectedReport?.id;
  const periodReport = isPeriodReport(selectedReport);
  const [flaggedTransactionId] = useQueryState('flaggedTransaction');
  const [fromType] = useQueryState('fromType', 'Flagged Transaction');

  return useQuery({
    queryKey: ['reportFlaggedTransaction', reportId, locationId, flaggedTransactionId],
    queryFn: async (): Promise<ResolvedLink<FlaggedTransaction>> => {
      const service = makeRemoteGetReportLink(reportId, periodReport);
      return service.get({ locationId, fromId: flaggedTransactionId, fromType });
    },
    enabled: !!reportId && !!locationId && !!flaggedTransactionId,
  });
};
